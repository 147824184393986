var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "single": "",
      "line": "",
      "label": "Name"
    },
    model: {
      value: _vm.name,
      callback: function ($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1), _c('v-col', [_c('v-select', {
    attrs: {
      "label": "Attribute type",
      "items": _vm.customAttributeKeys
    },
    model: {
      value: _vm.customAttributeType,
      callback: function ($$v) {
        _vm.customAttributeType = $$v;
      },
      expression: "customAttributeType"
    }
  })], 1), _c('v-col', [_c('v-select', {
    attrs: {
      "label": "Field Presence",
      "items": _vm.presenceKeys
    },
    model: {
      value: _vm.fieldPresence,
      callback: function ($$v) {
        _vm.fieldPresence = $$v;
      },
      expression: "fieldPresence"
    }
  })], 1), _c('v-col', [_c('v-select', {
    attrs: {
      "label": "Auto Complete Settings",
      "items": ['city', 'coachDesignation', 'collegeYear', 'country', 'gender', 'institutionType', 'school', 'schoolGrade', 'state', 'source', 'yesNo', 'amritaCampus']
    },
    model: {
      value: _vm.autoCompleteSetting,
      callback: function ($$v) {
        _vm.autoCompleteSetting = $$v;
      },
      expression: "autoCompleteSetting"
    }
  })], 1), _c('v-col', [_c('v-select', {
    attrs: {
      "label": "Custom Value Allowed",
      "items": [true, false]
    },
    model: {
      value: _vm.customValueAllowed,
      callback: function ($$v) {
        _vm.customValueAllowed = $$v;
      },
      expression: "customValueAllowed"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "single": "",
      "line": "",
      "label": "Order index"
    },
    model: {
      value: _vm.orderIndex,
      callback: function ($$v) {
        _vm.orderIndex = $$v;
      },
      expression: "orderIndex"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "label": "Condition field name",
      "items": _vm.fieldNames
    },
    model: {
      value: _vm.fieldName,
      callback: function ($$v) {
        _vm.fieldName = $$v;
      },
      expression: "fieldName"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "single": "",
      "line": "",
      "label": "Field value"
    },
    model: {
      value: _vm.fieldValue,
      callback: function ($$v) {
        _vm.fieldValue = $$v;
      },
      expression: "fieldValue"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "single": "",
      "line": "",
      "label": "Help text"
    },
    model: {
      value: _vm.helpText,
      callback: function ($$v) {
        _vm.helpText = $$v;
      },
      expression: "helpText"
    }
  })], 1), _c('v-col', [_c('v-btn', {
    attrs: {
      "color": "accent"
    },
    on: {
      "click": _vm.addAttribute
    }
  }, [_vm._v(" Add")])], 1)], 1), _c('v-row', [_c('v-col', [_c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.customAttributeValues
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('tr', [_c('td', [_vm._v(_vm._s(item.name))]), _c('td', [_vm._v(_vm._s(_vm.customAttributeKeys[item.customAttributeType]))]), _c('td', [_vm._v(_vm._s(_vm.presenceKeys[item.customAttributePresence]))]), _c('td', [_vm._v(_vm._s(_vm.settings(item)))]), _c('td', [_vm._v(_vm._s(item.customValueAllowed))]), _c('td', [_vm._v(_vm._s(item.orderIndex))]), _c('td', [_vm._v(_vm._s(_vm.buildCondition(item.presenceCondition)))]), _c('td', [_vm._v(_vm._s(item.helpText))]), _c('td', [_c('v-btn', {
          attrs: {
            "icon": "",
            "color": "accent"
          },
          on: {
            "click": function ($event) {
              return _vm.removeAttribute(item.name);
            }
          }
        }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.mdiDelete) + " ")])], 1)], 1)])];
      }
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }