<template>
  <div>
    {{ this.email }}
  </div>
</template>
<script>
import { getters, mapActions, mapState, mapMutations, mapGetters } from "vuex";

export default {
  props: {
    id: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      email: null,
    };
  },
  methods: {
    ...mapActions("admin", ["getUsers"]),
  },
  mounted() {
    this.getUsers({ userId: this.id }).then((res) => {
      console.log("response ...", res.getUsersList()[0].getEmail());
      this.email = res.getUsersList()[0].getEmail();
      return res;
    });
  },
};
</script>
