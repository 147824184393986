<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <span class="display-2 font-weight-bold"> Contents </span>
      </v-col>
      <v-col cols="3">
        <create-content></create-content>
      </v-col>
    </v-row>
    <Loader v-if="!contents"></Loader>

    <v-card v-else>
      <v-tabs color="accent" slider-color="accent" v-model="tab">
        <v-tab>Problems</v-tab>
        <v-tab>Drills</v-tab>
        <v-tab>Articles</v-tab>
        <v-tab>Contests</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <content-list :contents="problems"></content-list>
        </v-tab-item>
        <v-tab-item>
          <content-list :contents="drills"></content-list>
        </v-tab-item>
        <v-tab-item>
          <content-list :contents="articles"></content-list>
        </v-tab-item>
        <v-tab-item>
          <content-list :contents="contests"></content-list>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import Loader from "@/components/Loader.vue";
import CreateContent from "../components/CreateContent";
import ContentList from "../components/ContentList";

export default {
  name: "home",
  data() {
    return {
      tab: null,
    };
  },
  props: {
    initialTab: {
      type: String,
      default: null,
      required: false,
    },
  },
  methods: {
    ...mapActions("content", ["fetchContents", "createContent", "migrateTags"]),
    ...mapMutations("content", ["setContents"]),
  },
  computed: {
    ...mapState("content", ["contents"]),
    ...mapState("user", ["user"]),
    ...mapGetters("content", ["contentTypeKeys"]),
    problems() {
      return this.contents
        .filter((c) => this.contentTypeKeys[c.getContentType()] == "PROBLEM")
        .map((c) => c.toObject());
    },
    drills() {
      return this.contents
        .filter((c) => this.contentTypeKeys[c.getContentType()] == "DRILL")
        .map((c) => c.toObject());
    },
    articles() {
      return this.contents
        .filter((c) => this.contentTypeKeys[c.getContentType()] == "ARTICLE")
        .map((c) => c.toObject());
    },
    contests() {
      return this.contents
        .filter((c) => this.contentTypeKeys[c.getContentType()] == "CONTEST")
        .map((c) => c.toObject());
    },
  },
  watch: {
    user: function (user) {
      console.log("Changing user ...");
      if (user) this.fetchContents();
      else this.setContents([]);
    },
  },
  mounted() {
    if (this.user) this.fetchContents();
    var idx = ["problems", "drills", "articles", "contests"].indexOf(
      this.initialTab
    );
    if (idx == -1) idx = 0;
    this.tab = idx;
  },
  components: {
    Loader,
    CreateContent,
    ContentList,
  },
};
</script>
<style scoped>
.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02);
}
</style>
