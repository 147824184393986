var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [!this.user ? _c('div', [_vm._v("Please login first")]) : _vm._e(), this.user && _vm.loading ? _c('Loader') : _vm._e(), this.user && !_vm.loading && !_vm.content ? _c('NotFoundText', {
    attrs: {
      "item": "MCQ"
    }
  }) : _vm._e(), this.user && !_vm.loading && _vm.content ? _c('span', [_c('div', {
    staticClass: "grey lighten-5 pl-12 pr-5 mx-0 my-0"
  }, [_c('v-row', [_c('v-col', [_c('span', {
    staticClass: "display-2 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.content.getTitle()) + " ")])])], 1)], 1), _c('v-container', {
    staticClass: "pl-12 pr-5 mx-0 my-0",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_c('v-col', [_c('v-btn', {
    attrs: {
      "loading": _vm.isSkillSaved,
      "disabled": _vm.isSkillSaved,
      "color": "accent",
      "height": "35"
    },
    on: {
      "click": _vm.saveMcq
    }
  }, [_vm._v("Save")])], 1), _c('v-col', [_c('v-btn', {
    attrs: {
      "loading": _vm.isSkillPublished,
      "disabled": _vm.isPublishButtonDisabled,
      "color": "accent",
      "height": "35"
    },
    on: {
      "click": _vm.publishContentRequest
    }
  }, [_vm._v("Publish")])], 1), _c('v-col', [_c('v-btn', {
    attrs: {
      "color": "accent"
    },
    on: {
      "click": _vm.exportSkillAndSave
    }
  }, [_vm._v("Export Skill Markdown")])], 1)], 1), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "sm": "2"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Publish Type",
      "items": _vm.publishTypeKeys,
      "value": _vm.publishType
    },
    on: {
      "change": _vm.changePublishType
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-tabs', {
    attrs: {
      "color": "accent",
      "slider-color": "accent"
    },
    model: {
      value: _vm.skillTab,
      callback: function ($$v) {
        _vm.skillTab = $$v;
      },
      expression: "skillTab"
    }
  }, [_c('v-tab', [_vm._v("Preview")]), _c('v-tab', [_vm._v("Description")]), _c('v-tab', [_vm._v("Questions")]), _c('v-tab', [_vm._v("Metadata")]), _c('v-tab', [_vm._v("Selection Rules")]), _c('v-tab', [_vm._v("Mandatory Questions")]), _c('v-tab', [_vm._v("Attachments")]), _c('v-tab', [_vm._v("Permissions")])], 1), _c('v-tabs-items', {
    model: {
      value: _vm.skillTab,
      callback: function ($$v) {
        _vm.skillTab = $$v;
      },
      expression: "skillTab"
    }
  }, [_c('v-tab-item', [_c('div', [_vm._v("Description preview")]), _c('v-row', {
    staticClass: "py-5 px-2"
  }, [_c('v-col', [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.view.getDataView().getSkillView().getDescriptionHtml())
    }
  })])], 1), _c('div', [_vm._v("Expert details preview")]), _c('v-row', {
    staticClass: "py-5 px-2"
  }, [_c('v-col', [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.view.getDataView().getSkillView().getExpertDetailsHtml())
    }
  })])], 1)], 1), _c('v-tab-item', [_c('v-row', [_c('v-col', [_vm._v(" Description ")])], 1), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "value": _vm.content.getTitle(),
      "label": "Title"
    },
    on: {
      "input": _vm.setTitle
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "value": _vm.getImageUrl,
      "label": "Image URL"
    },
    on: {
      "input": _vm.setImageUrl
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', [_c('v-select', {
    attrs: {
      "multiple": "",
      "items": _vm.languageKeys,
      "value": _vm.getAllowedLanguageList,
      "label": "Select language to be allowed during test"
    },
    on: {
      "input": _vm.setAllowedLanguages
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "py-5 px-2"
  }, [_c('v-col', [_c('MarkdownEditor', {
    attrs: {
      "editorialMode": false,
      "submissionMode": true,
      "code": _vm.getDescription,
      "onCodeChange": _vm.onMarkdownCodeChange
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "value": _vm.getExpertImageUrl,
      "label": "Expert Image URL"
    },
    on: {
      "input": _vm.setExpertImageUrl
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_vm._v(" Expert Details ")])], 1), _c('v-row', {
    staticClass: "py-5 px-2"
  }, [_c('v-col', [_c('MarkdownEditor', {
    attrs: {
      "editorialMode": false,
      "submissionMode": true,
      "code": _vm.getExpertDetails,
      "onCodeChange": _vm.onExpertDetailsChange
    }
  })], 1)], 1)], 1), _c('v-tab-item', [_c('skill-questions-tab')], 1), _c('v-tab-item', [_c('v-row', {
    staticClass: "py-5 px-2"
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Time limit",
      "value": _vm.getSkillTimeLimit
    },
    on: {
      "input": _vm.setSkillTimeLimit
    }
  }), _c('v-combobox', {
    attrs: {
      "color": "accent",
      "items": _vm.tagsTypes,
      "value": _vm.content.getMetaData().getTagsList(),
      "hide-selected": "",
      "hint": "tagType/tag",
      "label": "Add company, problem type tags",
      "persistent-hint": "",
      "multiple": "",
      "small-chips": "",
      "solo": ""
    },
    on: {
      "change": _vm.setTags
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "value": _vm.getSkillMetaSummary,
      "label": "Summary"
    },
    on: {
      "input": _vm.setSkillMetaSummary
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.skillLevelKeys,
      "value": _vm.getSkillMetaSkillLevel,
      "label": "Skill level"
    },
    on: {
      "input": _vm.setSkillMetaSkillLevel
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "value": _vm.getSkillMetaImageUrl,
      "label": "Image url"
    },
    on: {
      "input": _vm.setSkillMetaImageUrl
    }
  })], 1)], 1)], 1), _c('v-tab-item', [_c('skill-selection-rules')], 1), _c('v-tab-item', [_c('included-questions-in-skill')], 1), _c('v-tab-item', [_c('v-row', [_c('v-col', [_c('v-file-input', {
    attrs: {
      "rules": _vm.imageSizeRule,
      "accept": "image/*",
      "placeholder": "Pick an image",
      "prepend-icon": "mdi-camera",
      "label": "Attachment Image"
    },
    model: {
      value: _vm.attachmentFile,
      callback: function ($$v) {
        _vm.attachmentFile = $$v;
      },
      expression: "attachmentFile"
    }
  }), _c('v-btn', {
    attrs: {
      "color": "accent"
    },
    on: {
      "click": _vm.uploadAttachmentSave
    }
  }, [_vm._v("Save Attachment ")])], 1)], 1), _vm.attachment && _vm.attachment.getUrl() ? _c('v-row', [_c('v-col', [_vm._v(" " + _vm._s(_vm.attachment.getUrl()) + " ")])], 1) : _vm._e()], 1), _c('v-tab-item', [_c('permissions', {
    attrs: {
      "customerId": _vm.content.getCustomerId(),
      "permissions": _vm.content.getMetaData().getPermissionsMap(),
      "initialRestrictedDomains": _vm.content.getMetaData().getRestrictedDomainList()
    },
    on: {
      "permissionChange": _vm.setPermissions,
      "restrictedDomainChange": _vm.setRestrictedDomains,
      "addCustomers": function ($event) {
        return _vm.addCustomersToSkill($event);
      }
    }
  })], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', [_c('confirm-dialog', {
    ref: "confirm"
  }), _c('v-btn', {
    attrs: {
      "color": "error",
      "height": "35"
    },
    on: {
      "click": _vm.deleteContentRequest
    }
  }, [_vm._v(" Delete Skill ")])], 1)], 1)], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }