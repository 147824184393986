<template>
  <v-snackbar centered v-model="show">
    {{
      (ex && ex.message) || "An error ocurred while performing this operation"
    }}
    <template v-slot:action="{ attrs }">
      <v-btn color="accent" text v-bind="attrs" @click="show = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    ex: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      lastTimeout: null,
      show: false,
    };
  },
  watch: {
    ex(e) {
      console.log("NewError", e);
      if (e) {
        if (this.lastTimeout) clearTimeout(this.lastTimeout);
        this.show = true;
        this.lastTimeout = setTimeout(() => (this.show = false), 12000);
      }
    },
  },
  name: "errorMessage",
};
</script>
