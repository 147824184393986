<template>
  <v-row>
    <v-col class="px-0">
      <v-card outlined>
        <v-row class="py-0">
          <v-col cols="2" class="py-0">
            <v-select
              :items="languages"
              outlined
              class="ml-0"
              hide-details
              v-model="language"
              append-icon=""
            >
            </v-select>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="1" class="py-0 px-1">
            <v-select
              :items="themes"
              outlined
              class="ml-0 font-weight-light"
              hide-details
              v-model="theme"
              append-icon=""
            >
            </v-select>
          </v-col>
          <v-col cols="1" class="py-0 px-0">
            <v-select
              :items="keyMaps"
              outlined
              class="ml-0 font-weight-light"
              hide-details
              v-model="keyMap"
              append-icon=""
            >
            </v-select>
          </v-col>
          <v-col cols="1" class="py-0 px-1">
            <v-select
              :items="fontSizes"
              outlined
              class="ml-0 font-weight-light"
              hide-details
              v-model="fontSize"
              append-icon=""
            >
            </v-select>
          </v-col>
          <v-col cols="1" class="py-0 px-0 pr-3">
            <v-select
              :items="tabSizes"
              outlined
              class="ml-0 font-weight-light"
              hide-details
              v-model="tabSize"
              append-icon=""
            >
            </v-select>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col class="py-0">
            <CodeMirror
              ref="myCm"
              :value="code"
              :options="cmOptions"
              @ready="onCmReady"
              @focus="onCmFocus"
              @input="onCodeChange"
              :class="fontSize"
            ></CodeMirror>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { codemirror as CodeMirror } from "vue-codemirror";
import "@/assets/css/codemirror.css";
import "codemirror/mode/javascript/javascript.js";
import "codemirror/mode/python/python.js";
import "codemirror/mode/clike/clike.js";
import "codemirror/mode/markdown/markdown.js";
import "codemirror/theme/dracula.css";
import "codemirror/theme/solarized.css";
import "codemirror/theme/idea.css";
import "codemirror/keymap/emacs.js";
import "codemirror/keymap/vim.js";
import "codemirror/keymap/sublime.js";
import "codemirror/lib/codemirror";
import "codemirror/mode/sql/sql.js";
export default {
  data() {
    return {
      showHint: false,
      fontSize: "normal",
      tabSize: 4,
      theme: "default",
      language: "markdown",
      keyMap: "default",
      cmOptions: {
        tabSize: 4,
        indentUnit: 4,
        indentWithTabs: true,
        mode: null,
        lineNumbers: true,
        line: true,
        styleActiveLine: true,
        matchBrackets: true,
        lineWrapping: true,
        theme: "default",
        foldGutter: true,
        placeholder: "Please end with ';'",
        keyMap: "default",
      },
      languages: [{ text: "MySQL 8", value: "MY_SQL_8" }],
      themes: [
        { text: "Idea", value: "idea" },
        { text: "Dracula", value: "dracula" },
        { text: "Solarized Dark", value: "solarized dark" },
        { text: "Solarized Light", value: "solarized light" },
        { text: "Default", value: "default" },
      ],
      keyMaps: [
        { text: "Vim", value: "vim" },
        { text: "Emacs", value: "emacs" },
        { text: "Sublime", value: "sublime" },
        { text: "Basic", value: "default" },
      ],
      fontSizes: [
        { text: "Small", value: "small" },
        { text: "Normal", value: "normal" },
        { text: "Large", value: "large" },
      ],
      tabSizes: [
        { text: "2 Spaces", value: 2 },
        { text: "4 Spaces", value: 4 },
      ],
      cmModeMap: {
        CPP_17: "text/x-c++src",
        JAVA_11: "text/x-java",
        KOTLIN_1_3: "text/x-kotlin",
        PYTHON_3: "text/x-python",
        MY_SQL_8: "text/x-mysql",
      },
    };
  },
  props: {
    code: {
      type: String,
      required: false,
    },
    onCodeChange: {
      type: Function,
      required: true,
    },
    value: {
      type: Function,
      required: false,
    },
  },
  components: {
    CodeMirror,
  },
  computed: {
    codemirror() {
      return this.$refs.myCm.codemirror;
    },
  },
  watch: {
    language: function (newLang) {
      this.cmOptions.mode = this.cmModeMap[newLang];
      this.value.language = newLang;
      this.$emit("input", this.value);
    },
    tabSize: function (newTabSize) {
      this.cmOptions.tabSize = newTabSize;
      this.cmOptions.indentUnit = newTabSize;
      this.value.tabSize = newTabSize;
      this.$emit("input", this.value);
    },
    theme: function (newTheme) {
      this.cmOptions.theme = newTheme;
      this.value.theme = newTheme;
      this.$emit("input", this.value);
    },
    keyMap: function (newKeyMap) {
      this.cmOptions.keyMap = newKeyMap;
      this.value.keyMap = newKeyMap;
      this.$emit("input", this.value);
    },
    fontSize: function (newFontSize) {
      this.value.fontSize = newFontSize;
      this.$emit("input", this.value);
    },
  },
  methods: {
    onCmReady(cm) {
      cm.on("keypress", () => {
        if (this.showHint) {
          //cm.showHint();
        }
      });
    },
    onCmFocus() {
      //   console.log("the editor is focus!", cm);
    },
  },
  mounted() {
    // console.log("this is current codemirror object", this.codemirror);
    // you can use this.codemirror to do something...
    if (this.value.language) this.language = this.value.language;
    if (this.value.tabSize) this.tabSize = this.value.tabSize;
    if (this.value.theme) this.theme = this.value.theme;
    if (this.value.keyMap) this.keyMap = this.value.keyMap;
    if (this.value.fontSize) this.fontSize = this.value.fontSize;
    this.cmOptions.mode = this.cmModeMap[this.language];
    console.log("Mode", this.cmOptions.mode, this.language);
  },
};
</script>
