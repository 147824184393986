var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('navbar'), _c('List'), _c('v-content', [_vm.initialized && _vm.user ? _c('router-view') : _vm.user ? _c('Loader') : _c('Login')], 1), _c('tail'), _c('footer', [_c('cookie-law', {
    attrs: {
      "theme": "blood-orange",
      "buttonText": "Agree"
    }
  }, [_c('div', {
    attrs: {
      "slot": "message"
    },
    slot: "message"
  }, [_vm._v(" We use cookies to ensure you get the best user experience on our website. By continuing to use this site, you agree to the use of these cookies. "), _c('a', {
    attrs: {
      "href": "https://codedrills.io/privacy-policy"
    }
  }, [_vm._v("Find out more")])])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }