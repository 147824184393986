<template>
  <v-container fluid>
    <div v-if="!this.user">Please login first</div>
    <Loader v-if="this.user && loading" />
    <span v-if="this.user && !loading">
      <div class="grey lighten-5 pl-12 pr-5 mx-0 my-0">
        <v-row>
          <v-col>
            <span class="display-2 font-weight-bold">
              {{ content.getTitle() }}
            </span>
          </v-col>
        </v-row>
      </div>
      <v-container fluid class="pl-12 pr-5 mx-0 my-0">
        <v-col>
          <v-tabs
            color="accent"
            slider-color="accent"
            v-model="contestAdminTab"
          >
            <v-tab>Submissions</v-tab>
            <v-tab>Scoreboard</v-tab>
            <v-tab>Downloads</v-tab>
            <v-tab>Upload Teams</v-tab>
            <v-tab>Teams</v-tab>
            <v-tab>Import Teams</v-tab>
            <v-tab>Contest Extension</v-tab>
            <v-tab>Send Email</v-tab>
            <v-tab>offline Contest</v-tab>
          </v-tabs>
          <v-tabs-items v-model="contestAdminTab">
            <v-tab-item>
              <!-- submissions -->
              <v-row class="py-5 px-2">
                <v-col>
                  <v-card>
                    <v-card-title>
                      All Submissions
                      <v-spacer></v-spacer>
                      <!-- <v-text-field
												v-model="search"
												append-icon="mdi-magnify"
												label="Search"
												single-line
												hide-details
											></v-text-field> -->
                    </v-card-title>
                    <v-data-table
                      :page="pagination.pageIndex"
                      :pageCount="pageCount"
                      :server-items-length="pagination.total"
                      :headers="headers"
                      :items="allContestSubmissions"
                      :footer-props.sync="footerOptions"
                      :search="pagination.search"
                      :loading="pageLoading"
                      :options.sync="tableOptions"
                    >
                      <template v-slot:item="{ item }">
                        <tr>
                          <td>
                            <router-link
                              class="link"
                              :to="'/submissions/' + item.id"
                            >
                              {{ item.id }}
                            </router-link>
                          </td>
                          <td>{{ formatDate(item.submittedOn) }}</td>
                          <td>{{ item.userPreview.name }}</td>
                          <td>
                            <router-link
                              class="link"
                              :to="'/problems/' + item.problemPreview.url"
                            >
                              {{ item.problemPreview.title }}
                            </router-link>
                          </td>
                          <td>
                            {{ allLanguages[languageKeys[item.language]] }}
                          </td>
                          <td class="text-capitalize">
                            {{
                              statusKeys[item.status]
                                .split("_")
                                .join(" ")
                                .toLowerCase()
                            }}
                          </td>
                          <td>
                            {{
                              item.status == 12
                                ? "-"
                                : item.timeTakenInMs + " ms"
                            }}
                          </td>
                          <td>
                            {{
                              item.status == 16
                                ? "-"
                                : item.memoryTakenInKb + " KiB"
                            }}
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item>
              <v-row>
                <v-col>
                  <v-btn :loading="refreshDone" @click="refreshingScoreboard"
                    >Refresh Scoreboard</v-btn
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn @click="fetchScoreboard">Fetch Scoreboard</v-btn>
                </v-col>
              </v-row>
              <v-row v-if="scoreboard">
                <v-col>
                  <v-card>
                    <v-data-table
                      :items-per-page="16"
                      :hide-default-footer="true"
                      :headers="staticHeaders"
                      :items="userScores"
                    >
                      <template v-slot:item="{ item }">
                        <tr>
                          <td>
                            {{ item.rank }}
                          </td>
                          <td>
                            {{ getName(item) }}
                          </td>
                          <td v-if="isTeamContest">
                            <span
                              v-for="m in item.teamPreview.memberPreviewsList"
                              v-bind:key="m.id"
                            >
                              {{ m.name }}
                              <user :id="m.id"></user><br />
                            </span>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item>
              <v-row class="mt-10">
                <v-col>
                  <v-btn color="green" @click="downloadAllSubmissionFull">
                    <v-icon left color="primary">
                      {{ mdiCloudDownload }}
                    </v-icon>
                    Download Full Submissions
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    :loading="downloadDone"
                    height="40"
                    @click="downloadTeams"
                  >
                    <v-icon left color="primary">
                      {{ mdiCloudDownload }}
                    </v-icon>
                    Download Teams
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="downloadSubmissions">
                    <v-icon left color="primary">
                      {{ mdiCloudDownload }}
                    </v-icon>
                    Download Submissions
                  </v-btn>
                </v-col>
              </v-row>

              <v-divider class="my-4"></v-divider>

              <v-row>
                <v-col>
                  <v-btn class="mt-10" @click="uploadScoreboard">
                    Upload Current Scoreboard to Storage
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="mt-10"
                    text
                    color="primary"
                    @click="downloadParticipants"
                  >
                    Download Participants
                  </v-btn>
                </v-col>
              </v-row>

              <v-row v-if="scoreboard" class="mt-10">
                <v-col>
                  team_name,team_org <br />
                  <span
                    v-for="item in scoreboard.getUserScoresList()"
                    v-bind:key="item.getTeamId()"
                  >
                    {{ item }}
                    {{ getTeamName(item) }},{{ orgName(item) }}
                    <br />
                  </span>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item>
              <!-- upload teams csv -->
              <v-card outlined class="mb-5">
                <v-alert type="error" dense text>
                  This feature is for uploading teams for ICPC contest from
                  CSV.<br />
                  If prefix is provided then offlineparser is used (old ICPC) -
                  DEPRECATED, use offline contest for ICPC.
                </v-alert>
                <v-alert type="success" dense text>
                  Without prefix, onlineparser is used (AlgoQueen).
                </v-alert>
              </v-card>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="emailPrefix"
                    label="Email Prefix"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-file-input
                    accept="*"
                    label="Select teams csv"
                    v-model="teamsCsv"
                    required
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn color="accent" height="35" @click="saveTeamCsv"
                    >Save</v-btn
                  >
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item>
              <!-- teams -->
              <v-row>
                <v-col>
                  <v-text-field v-model="teamId" label="Team Id"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="memberEmail"
                    label="Member Email"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="externalId"
                    label="External TeamId"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn @click="findTeam">Find Team</v-btn>
                </v-col>
              </v-row>
              <v-row v-if="isTeamFetched">
                <v-col>
                  <team-detail
                    :teamView="teamAdmin"
                    :teamExternalId="teamExternalId"
                  ></team-detail>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item>
              <v-row>
                <v-col>
                  <!-- either one of teamIs or from email is required -->
                  <v-form ref="form" lazy-validation>
                    <v-text-field
                      v-model="teamIds"
                      label="Teams Ids if any"
                    ></v-text-field>
                    <v-text-field
                      v-model="fromContest"
                      label="contest: From URL"
                    ></v-text-field>
                    <v-btn color="red" @click="importTeamsFunc"
                      >import team to current contest</v-btn
                    >
                  </v-form>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item>
              <ContestExtension :url="content.getUrl()"></ContestExtension>
            </v-tab-item>

            <v-tab-item>
              <email-template
                @email-sent="(email) => sendEmail(email)"
              ></email-template>
            </v-tab-item>

            <v-tab-item>
              <offline-contest
                :contest-url="content.getUrl()"
                :contentId="content.getId()"
              >
              </offline-contest>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-container>
    </span>
    <span v-else>
      <v-row>
        <v-col>
          {{ userEmail }} doesn't have admin access, please email to
          hello@codedrills.io
        </v-col>
      </v-row>
    </span>
  </v-container>
</template>

<script>
import { getters, mapActions, mapState, mapMutations, mapGetters } from "vuex";
import Loader from "../components/Loader";
import User from "../components/User";
import TeamDetail from "./TeamDetail.vue";
import { mdiCloudDownload } from "@mdi/js";
import EmailTemplate from "./EmailTemplate.vue";
import OfflineContest from "./OfflineContest.vue";
import ContestExtension from "./ContestExtension.vue";
import Contest from "./Contest.vue";

export default {
  props: ["url"],
  data() {
    return {
      mdiCloudDownload,
      downloadDone: false,
      teamCsvText: null,
      scoreText: null,
      fromContest: null,
      teamId: null,
      emailPrefix: null,
      memberEmail: null,
      externalId: null,
      teamsCsv: null,
      teamIds: null,
      footerOptions: {
        itemsPerPageOptions: [16, 25, 50],
      },
      ex: null,
      pagination: {
        pageIndex: 1,
        itemsPerPage: 10,
        total: 0,
        search: null,
      },
      summaryPagination: {
        pageIndex: 1,
        itemsPerPage: 16,
      },
      tableOptions: {},
      pageLoading: false,
      initialLoading: true,
      contestAdminTab: null,
      refreshDone: false,
      search: "",
      candidateCsvText: null,
      staticHeaders: [
        {
          text: "Rank",
          align: "left",
          value: "rank",
        },
        {
          text: "Winners",
          align: "left",
          value: "userPreview.name",
        },
      ],
      headers: [
        {
          text: "ID",
          align: "left",
          value: "id",
        },
        {
          text: "Submitted On",
          align: "left",
          value: "submittedOn",
        },
        {
          text: "Submitted By",
          align: "left",
          value: "userPreview.name",
        },
        {
          text: "Problem",
          align: "left",
          value: "problemPreview.title",
        },
        {
          text: "Language",
          align: "left",
          value: "language",
        },
        {
          text: "Status",
          align: "left",
          value: "status",
        },
        {
          text: "Time",
          align: "left",
          value: "timeTakenInMs",
        },
        {
          text: "Memory",
          align: "left",
          value: "memoryTakenInKb",
        },
      ],
      allLanguages: {
        CPP_17: "C++",
        JAVA_11: "Java",
        // KOTLIN_1_3: 'Kotlin',
        PYTHON_3: "Python",
        TXT: "Text",
      },
    };
  },
  components: {
    Loader,
    User,
    TeamDetail,
    EmailTemplate,
    OfflineContest,
    ContestExtension,
  },
  computed: {
    ...mapState("user", [
      "user",
      "hasPremiumAccess",
      "teamAdmin",
      "teamExternalId",
      "setFromContestId",
      "setToContestId",
    ]),
    ...mapState("contest", [
      "loading",
      "content",
      "allSubmissions",
      "scoreboard",
    ]),
    ...mapGetters("judge", ["statusKeys"]),
    ...mapGetters("user", ["languageKeys"]),

    allContestSubmissions() {
      if (!this.allSubmissions) return [];
      let submissions = new Array();
      this.allSubmissions
        .getSubmissionPreviewsList()
        .forEach((sub) => submissions.push(sub.toObject()));
      return submissions;
    },
    pageCount() {
      if (this.pagination.itemsPerPage == -1) return 1;
      return (
        (this.pagination.total + this.pagination.itemsPerPage - 1) /
        this.pagination.itemsPerPage
      );
    },
    isTeamContest() {
      if (this.scoreboard)
        return (
          this.scoreboard
            .getContestPreview()
            .getMetaData()
            .getContestMeta()
            .getContestType() == 1
        );
    },
    userScores() {
      if (!this.scoreboard) return [];
      var scores =
        this.scoreboard &&
        this.scoreboard.getUserScoresList().map((s) => {
          var us = s.toObject();
          us.userProblemScoresMap = {};
          us.userProblemScoresList.forEach((ps) => {
            us.userProblemScoresMap[ps.problemId] = ps;
          });
          console.log("us", us);
          return us;
        });
      return scores;
    },
    isTeamFetched() {
      console.log("team .......", this.teamAdmin);
      return this.teamAdmin != null;
    },
  },
  methods: {
    ...mapActions("contest", [
      "fetchContest",
      "fetchAllContestSubmissions",
      "fetchContestScoreboard",
      "dumpContestScoreboard",
      "refreshScoreboard",
      "fetchContestParticipents",
      "ScoreDetailsForContest",
    ]),
    ...mapActions("judge", ["uploadContestSubmissions"]),
    ...mapActions("user", [
      "createTeamsBatch",
      "getTeamAdmin",
      "getAllRegisteredMembers",
      "importTeams",
      "sendCustomEmail",
    ]),

    saveStringAsFile(contentString) {
      const uriContent = "data:text/csv," + encodeURIComponent(contentString);
      location.href = uriContent;
    },
    sendEmail(email) {
      console.log(
        "Sending email with contntId ...",
        email,
        this.content.getId()
      );
      if (!email.subject || !email.body) {
        alert("Please enter subject and body");
        return;
      }
      this.sendCustomEmail({
        contentId: this.content.getId(),
        templateId: email.templateId,
        subject: email.subject,
        body: email.body,
        emails: email.emails,
      })
        .then((res) => {
          console.log("Email sent successfully", res);
          alert("Email sent successfully");
          alert("The emails have been sent.");
        })
        .catch((ex) => {
          console.log("Error sending email", ex);
          alert("Error sending email");
        });
    },
    importTeamsFunc() {
      var teamList = null;
      if (this.teamIds) {
        teamList = this.teamIds.trim().split(/[ ,]+/);
      }
      console.log("teamList....", teamList);
      this.importTeams({
        teamIds: teamList,
        from: this.fromContest,
        to: this.content.getId(),
      });
    },
    async userEmail(userId) {
      const email = await this.getUsers({ userId: userId }).then((res) => {
        console.log("response ...", res.getUsersList()[0].getEmail());
        return res;
      });
      console.log("Email", email.getEmail);
      return email.getUsersList()[0].getEmail();
    },
    getName(item) {
      var name;
      if (this.isTeamContest) name = item.teamPreview && item.teamPreview.name;
      else name = item.userPreview && item.userPreview.name;
      return name || "Unnamed";
    },

    orgName(item) {
      if (
        item &&
        item.getTeamPreview() &&
        item.getTeamPreview().getOrganizationsList().length > 0
      )
        return item.getTeamPreview().getOrganizationsList()[0];
    },
    getTeamName(item) {
      if (item && item.getTeamPreview())
        return item.getTeamPreview().getName() || "Unnamed";
    },
    formatDate(date) {
      const d = new Date(date);
      return d.toLocaleString();
    },

    downloadSubmissions() {
      this.uploadContestSubmissions({ contestId: this.content.getId() })
        .then(() => {
          this.$store.dispatch("notifs/addNotif", {
            text: "submission uploaded to gcp!",
            type: "success",
          });
        })
        .catch((ex) => {
          console.log("Error uploading submissions", ex);
          this.$store.dispatch("notifs/addNotif", {
            text:
              ex.message || "Uploading submissions failed, please try again!",
            type: "error",
          });
        });
    },

    downloadTeams() {
      console.log("id ..", this.content.getId());
      this.getAllRegisteredMembers({ contestId: this.content.getId() })
        .then((res) => {
          console.log("res", res.toObject());
          this.clearTeamCsv();
          this.teamCsvText = new Blob([res.getTeamDump()], {
            type: "text/csv;charset=utf-8;",
          });
          const url = URL.createObjectURL(this.teamCsvText);
          const link = document.createElement("a");
          link.setAttribute("href", url);
          link.setAttribute("download", "teams.csv");
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((err) => {
          alert(err.message);
          console.log("err", err);
        });
    },
    downloadAllSubmissionFull() {
      console.log("Downloading all submissions");
      this.ScoreDetailsForContest({ url: this.content.getUrl() })
        .then((res) => {
          console.log("res", res);
          this.scoreText = null;
          this.scoreText = new Blob([res.getSubmissionsCsv()], {
            type: "text/csv;charset=utf-8;",
          });
          const url = URL.createObjectURL(this.scoreText);
          const link = document.createElement("a");
          link.setAttribute("href", url);
          link.setAttribute("download", "scores.csv");
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((err) => {
          alert(err.message);
          console.log("err downloading all submission - full", err);
        });
    },
    clearTeamCsv() {
      this.teamCsvText = null;
    },
    uploadScoreboard() {
      this.dumpContestScoreboard({ url: this.content.getUrl() }).then((res) => {
        console.log("res", res);
        this.$store
          .dispatch("notifs/addNotif", {
            text: "Scoreboard uploaded to storage!",
            type: "success",
          })
          .catch((ex) => {
            console.log("Error uploading scoreboard", ex);
            this.$store.dispatch("notifs/addNotif", {
              text:
                ex.message || "Uploading scoreboard failed, please try again!",
              type: "error",
            });
          });
      });
    },
    async downloadParticipants() {
      const csvData = this.fetchContestParticipents({
        url: this.content.getUrl(),
      }).then((res) => {
        this.candidateCsvText = res.getParticipantsCsv();
        // Set report as uri to save as file
        this.saveStringAsFile(this.candidateCsvText);
        console.log("Csv .....", this.candidateCsvText);
      });
    },
    fetchScoreboard() {
      this.fetchContestScoreboard({ url: this.content.getUrl() });
    },

    async fetchSubmissions() {
      this.pageLoading = true;
      console.log("Url ", this.url);
      console.log("Pagination", this.pagination);
      return this.fetchAllContestSubmissions({
        url: this.url,
        paginationQuery: this.pagination,
      }).then((res) => {
        this.pagination.total =
          res && res.getPaginationResponse()
            ? res.getPaginationResponse().getTotal()
            : 0;
        this.pageLoading = false;
      });
    },

    async saveTeamCsv() {
      await this.teamsCsv.text().then((content) => {
        this.createTeamsBatch({
          teamsCsv: content,
          contentId: this.content.getId(),
          emailPrefix: this.emailPrefix,
        })
          .then((__) => {
            this.clearTeamCsv();
            this.$store.dispatch("notifs/addNotif", {
              text: "teams uploaded succesfully!",
              type: "success",
            });
          })
          .catch((ex) => {
            this.ex = ex;
            this.$store.dispatch("notifs/addNotif", {
              text: ex.message || "Error uploading teams, please try again!",
              type: "error",
            });
          });
      });
    },
    clearTeamCsv() {
      this.teamsCsv = null;
    },

    findTeam() {
      this.getTeamAdmin({
        contentId: this.content.getId(),
        teamId: this.teamId,
        memberEmail: this.memberEmail,
        externalId: this.externalId,
      }).catch((ex) => {
        this.teamAdmin = null;
        this.ex = ex;
        console.log("Error finding team", ex);
        this.$store.dispatch("notifs/addNotif", {
          text: ex.message || "failed in finding team, please try again!",
          type: "error",
        });
      });
    },
    refreshingScoreboard() {
      console.log("refreshing scoreboard ...");
      this.refreshDone = true;
      this.refreshScoreboard({ url: this.content.getUrl() })
        .then((__) => (this.refreshDone = false))
        .catch((ex) => {
          this.ex = ex;
          this.refreshDone = false;
        });
    },
  },

  async created() {
    this.fetchContest({ url: this.url });
    this.initialLoading = true;
    this.fetchSubmissions().then((__) => {
      this.initialLoading = false;
    });
  },

  watch: {
    pagination: {
      handler() {
        this.fetchSubmissions();
      },
      deep: true,
    },
    tableOptions(to) {
      console.log("options.sync", to);
      this.pagination.pageIndex = to.page;
      this.pagination.itemsPerPage = to.itemsPerPage;
    },
  },
};
</script>

<style scoped>
.link {
  text-decoration: none;
  /* color: black; */
}
</style>
