var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.user ? _c('page', {
    attrs: {
      "minimal": "",
      "title": "Subjectives",
      "apiStatusList": [_vm.listContentsStatus]
    }
  }, [_vm.subjectives ? _c('div', [_c('subjective-list', {
    attrs: {
      "contents": _vm.subjectives
    }
  })], 1) : _vm._e()]) : _c('div', [_vm._v("Please Login to continue")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }