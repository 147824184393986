import firebase from "firebase/app";
import "firebase/auth";
import user from "codedrills_proto/io/codedrills/proto/user/user_service_grpc_web_pb";
import team from "codedrills_proto/io/codedrills/proto/user/team_service_grpc_web_pb";
var content_proto = proto.io.codedrills.proto.content;

import { ApiCall } from "@/utils/api.js";

const teamService = new team.TeamServicePromiseClient(
  process.env.VUE_APP_USER_API_URL + "/user",
  null,
  null
);
const userService = new user.UserServicePromiseClient(
  process.env.VUE_APP_USER_API_URL + "/user",
  null,
  null
);
var user_proto = proto.io.codedrills.proto.user;

const state = {
  user: null,
  loginError: null,
  userToken: null,
  lastTokenRrefreshEpochMs: null,
  initialized: false,
  toContestId: null,
  fromContestId: null,
  userPreference:
    new proto.io.codedrills.proto.user.Preference().setEditorConfig(
      new proto.io.codedrills.proto.user.EditorConfig()
    ),
  profile: null,
  basicProfile: null,
  iamAdmin: false,
  usersWithScopes: [],
  getUserPreferenceStatus: 0,
  saveUserPreferenceStatus: 0,
  getProfileStatus: 0,
  getBasicProfileStatus: 0,
  createTeamsBatchStatus: 0,
  isAdminStatus: 0,
  teamAdmin: null,
  teamExternalId: null,
  deleteTeamAdminStatus: 0,
  getAllRegisteredMembersStatus: 0,
  sendCustomEmailStatus: 0,
  getOfflineCredentialsStatus: 0,
  paidCustomers: [],
  getAllPaidCustomersStatus: 0,
};

const getters = {
  user: (state) => {
    return state.user;
  },
  loginError: (state) => {
    return state.loginError;
  },
  userToken: (state) => {
    return state.userToken;
  },
  lastTokenRrefreshEpochMs: (state) => {
    return state.lastTokenRrefreshEpochMs;
  },
  initialized: (state) => {
    return state.initialized;
  },
  languageKeys: () => {
    var ret = {};
    for (var key in proto.io.codedrills.proto.judge.Language) {
      ret[proto.io.codedrills.proto.judge.Language[key]] = key;
    }
    return ret;
  },
  userId: (state) => state.user && state.user.uid,
};

const actions = {
  async logout({ commit, state }) {
    return firebase.auth().signOut();
  },
  async fetchUserToken({ commit, state }) {
    if (state.user) {
      console.log("Fetching user token");
      return firebase
        .auth()
        .currentUser.getIdToken()
        .then((token) => {
          commit("setUserToken", token);
          //console.log("Token", token);
          return token;
        });
    }
  },
  async ensureUserToken({ commit, dispatch, getters }) {
    if (
      !state.userToken ||
      state.lastTokenRrefreshEpochMs < Date.now() - 10 * 60 * 1000
    ) {
      return dispatch("fetchUserToken");
    } else {
      return new Promise((resolve, __) => resolve(getters.userToken));
    }
  },
  async optionalUserToken({ commit, dispatch, getters }) {
    if (!state.user) {
      return new Promise((resolve, __) => resolve(""));
    } else {
      return dispatch("ensureUserToken");
    }
  },
  async userLoggedIn({ commit, dispatch }, data) {
    commit("setUser", data.user);
    return dispatch("fetchUserToken").then((__) =>
      dispatch("getUserPreference")
    );
  },
  userLoggedOut({ commit }) {
    commit("setUser", null);
    commit("setUserToken", null);
  },
  async sendVerificationEmail() {
    return firebase.auth().currentUser.sendEmailVerification();
  },
  async register({ dispatch }) {
    return dispatch("user/ensureUserToken", null, { root: true }).then(
      (userToken) => {
        //console.log("User Tpken ", userToken);
        var registerUserRequest =
          new proto.io.codedrills.proto.user.RegisterUserRequest();
        return userService
          .registerUser(registerUserRequest, { Authorization: userToken })
          .then((res) => {
            console.log("SUCCESS register", res.toObject());
          })
          .catch((err) => {
            console.error("ERROR", err);
          });
      }
    );
  },
  async userTokens() {
    var pass = "loadtest121";
    var tokens = "export const AUTH_TOKENS = [\n";
    for (var i = 0; i < 10; ++i) {
      console.log("Creating for ", i);
      var email = "matcautham+" + i + "@gmail.com";
      var t = await firebase
        .auth()
        .signInWithEmailAndPassword(email, pass)
        .then((uc) => {
          return uc.user.getIdToken(false);
        });
      tokens += '"' + t + '",\n';
    }
    tokens += "]";
    console.log(tokens);
    return tokens;
  },
  getUserPreference: new ApiCall("getUserPreference")
    .authRequired()
    .withServiceCall((r, h) => userService.getUserPreference(r, h))
    .withRequest(
      () => new proto.io.codedrills.proto.user.GetUserPreferenceRequest()
    )
    .onSuccess(({ commit }, res) =>
      commit("setUserPreference", res.getPreference())
    )
    .build(),

  saveUserPreference: new ApiCall("saveUserPreference")
    .authRequired()
    .withServiceCall((r, h) => userService.updateUserPreference(r, h))
    .withRequest((_, __, { state }) =>
      new proto.io.codedrills.proto.user.UpdateUserPreferenceRequest().setPreference(
        state.userPreference
      )
    )
    .build(),

  getBasicProfile: new ApiCall("getBasicProfile")
    .authRequired()
    .withServiceCall((r, h) => userService.getBasicProfile(r, h))
    .withRequest(({ id, email }) => {
      var req = new proto.io.codedrills.proto.user.GetBasicProfileRequest();
      if (id) req.setUserAddress(new user_proto.UserAddress().setId(id));
      else req.setUserAddress(new user_proto.UserAddress().setEmail(email));
      return req;
    })
    .onSuccess(({ commit }, res) => {
      commit("setBasicProfile", res);
      return res;
    })
    .build(),

  createTeamsBatch: new ApiCall("createTeamsBatch")
    .authRequired()
    .withServiceCall((r, h) => teamService.createTeamsBatch(r, h))
    .withRequest(({ teamsCsv, contentId, emailPrefix }) => {
      var req = new proto.io.codedrills.proto.user.CreateTeamsBatchRequest()
        .setContentId(contentId)
        .setEmailPrefix(emailPrefix);
      if (teamsCsv && teamsCsv.length > 0) {
      }
      req.setTeamsCsv(teamsCsv);
      return req;
    })
    .onSuccess(({ commit, res }) => console.log("Done ..."))
    .build(),

  isAdmin: new ApiCall("isAdmin")
    .authRequired()
    .withServiceCall((r, h) => userService.isAdmin(r, h))
    .withRequest(() => {
      var req = new proto.io.codedrills.proto.user.IsAdminRequest();
      return req;
    })
    .onSuccess(({ commit }, res) => {
      console.log("IsAdmin ....", res);
      commit("setIsAdmin", res.getIsAdmin());
      return res;
    })
    .build(),

  getUsersWithScope: new ApiCall("getUsersWithScope")
    .withServiceCall((r, h) => userService.getUsersWithScope(r, h))
    .withRequest(() =>
      new user_proto.GetUsersWithScopeRequest().setScopeList([
        user_proto.Scope.BUILDER,
      ])
    )
    .onSuccess(({ commit }, res) => {
      console.log("Scopes", res);
      commit("setUsersWithScopes", res.getUserList());
    })
    .build(),

  getTeamAdmin: new ApiCall("getTeamAdmin")
    .withServiceCall((r, h) => teamService.getTeamAdmin(r, h))
    .withRequest(({ contentId, teamId, memberEmail, externalId }) => {
      var req = new user_proto.GetTeamAdminRequest();
      req.setContentId(contentId);
      if (teamId != null) req.setTeamId(teamId);
      else if (memberEmail != null) req.setMemberEmail(memberEmail);
      else if (externalId != null) req.setExternalId(externalId);
      return req;
    })
    .onSuccess(({ commit }, res) => {
      console.log("Team", res);
      commit("setTeamAdmin", res);
    })
    .build(),

  deleteTeamAdmin: new ApiCall("deleteTeamAdmin")
    .authRequired()
    .withServiceCall((r, h) => teamService.deleteTeamAdmin(r, h))
    .withRequest(({ teamId }) =>
      new user_proto.DeleteTeamAdminRequest().setTeamId(teamId)
    )
    .onSuccess(({ commit }, res) => console.log("Delete team ", res))
    .build(),

  importTeams: new ApiCall("importTeamsToContest")
    .authRequired()
    .withServiceCall((r, h) => teamService.importTeamsToContest(r, h))
    .withRequest(({ teamIds, from, to }) =>
      new user_proto.ImportTeamsToContestRequest()
        .setTeamIdList(teamIds)
        .setFromContest(
          new content_proto.ContentId().setContentAddress(
            new content_proto.ContentAddress()
              .setContentType(content_proto.ContentType.CONTEST)
              .setUrl(from)
          )
        )
        .setToContest(new content_proto.ContentId().setId(to))
    )
    .onSuccess(({ commit }, res) => console.log("imported ", from, to))
    .build(),

  getAllRegisteredMembers: new ApiCall("getAllRegisteredMembers")
    .withServiceCall((r, h) => userService.getAllRegisteredMembers(r, h))
    .withRequest(({ contestId }) =>
      new user_proto.GetAllRegisteredMembersRequest().setContentId(
        new proto.io.codedrills.proto.content.ContentId().setId(contestId)
      )
    )
    .onSuccess(({ commit }, res) => {})
    .build(),

  sendCustomEmail: new ApiCall("sendCustomEmail")
    .withServiceCall((r, h) => userService.sendCustomEmail(r, h))
    .withRequest(({ contentId, templateId, subject, body, emails }) => {
      var req = new user_proto.SendCustomEmailRequest();
      if (templateId) req.setTemplateId(templateId);
      else {
        req.setSubject(subject);
        req.setBody(body);
      }
      if (emails) req.setEmailList(emails);
      req.setContentId(new content_proto.ContentId().setId(contentId));
      return req;
    })
    .onSuccess(({ commit }, res) => {
      console.log("Email sent", res);
      return res;
    })
    .build(),

  getOfflineCredentials: new ApiCall("getOfflineCredentials")
    .withServiceCall((r, h) => teamService.getOfflineLoginDetails(r, h))
    .withRequest(({ url }) =>
      new user_proto.GetOfflineLoginDetailsRequest().setContestId(
        new proto.io.codedrills.proto.content.ContentId().setContentAddress(
          new proto.io.codedrills.proto.content.ContentAddress()
            .setUrl(url)
            .setContentType(
              proto.io.codedrills.proto.content.ContentType.CONTEST
            )
        )
      )
    )
    .onSuccess(({ commit }, res) => {
      console.log("Offline credentials", res);
      return res;
    })
    .build(),

  getAllPaidCustomers: new ApiCall("getAllPaidCustomers")
    .withServiceCall((r, h) => userService.getAllPaidCustomers(r, h))
    .withRequest(({ planType }) =>
      new user_proto.GetAllPaidCustomersRequest().setHiringPlanType(
        user_proto.HiringPlanType[planType]
      )
    )
    .onSuccess(({ commit }, res) => {
      commit("setPaidCustomers", res.getCustomerList());
      console.log("Paid customers", res);
      return res.getCustomerList();
    })
    .build(),
};

const mutations = {
  setUser(state, user) {
    state.user = JSON.parse(JSON.stringify(user)); //https://stackoverflow.com/questions/38365075/vuex-vuejs-do-not-mutate-vuex-store-state-outside-mutation-handlers
  },
  setLoginError(state, error) {
    state.loginError = error;
  },
  setUserToken(state, token) {
    state.userToken = token;
    state.lastTokenRrefreshEpochMs = Date.now();
  },
  setUserPreference(state, up) {
    state.userPreference = up;
  },
  initialized(state) {
    state.initialized = true;
  },
  setEditorConfig(state, config) {
    if (!state.userPreference.getEditorConfig()) {
      state.userPreference.setEditorConfig(
        new proto.io.codedrills.proto.user.EditorConfig()
      );
    }
    var lang = proto.io.codedrills.proto.judge.Language[config.language];
    if (lang) state.userPreference.getEditorConfig().setLanguage(lang);
    if (config.tabSize)
      state.userPreference.getEditorConfig().setTabSize(config.tabSize);
    if (config.theme)
      state.userPreference.getEditorConfig().setTheme(config.theme);
    if (config.keyMap)
      state.userPreference.getEditorConfig().setKeyMap(config.keyMap);
    if (config.fontSize)
      state.userPreference.getEditorConfig().setFontSize(config.fontSize);
    console.log("Saving editor config", state.userPreference.toObject());
  },
  getUserPreferenceStatus(state, s) {
    state.getUserPreferenceStatus = s;
  },
  saveUserPreferenceStatus(state, s) {
    state.saveUserPreferenceStatus = s;
  },
  setProfile(state, res) {
    state.profile = res;
  },
  getProfileStatus(state, status) {
    state.getProfileStatus = status;
  },
  setBasicProfile(state, res) {
    state.basicProfile = res;
  },
  getBasicProfileStatus(state, status) {
    state.getBasicProfileStatus = status;
  },
  createTeamsBatchStatus(state, status) {
    state.createTeamsBatchStatus = status;
  },
  setPaidCustomers(state, res) {
    state.paidCustomers = res;
  },
  getAllPaidCustomersStatus(state, status) {
    state.getAllPaidCustomersStatus = status;
  },
  setIsAdmin(state, res) {
    console.log("State is", res);
    state.iamAdmin = res;
    console.log("State is", state.iamAdmin);
  },
  isAdminStatus(state, status) {
    state.isAdminStatus = status;
  },
  setUsersWithScopes(state, res) {
    state.usersWithScopes = res;
  },
  setTeamAdmin(state, res) {
    state.teamAdmin = res.getTeamDetails();
    state.teamExternalId = res.getExternalId();
  },
  getTeamAdminStatus(state, status) {
    state.getTeamAdminStatus = status;
  },
  deleteTeamAdminStatus(state, status) {
    state.deleteTeamAdminStatus = status;
  },
  getAllRegisteredMembersStatus(state, status) {
    state.getAllRegisteredMembersStatus = status;
  },
  sendCustomEmailStatus(state, status) {
    state.sendCustomEmailStatus = status;
  },
  getOfflineCredentialsStatus(state, status) {
    state.getOfflineCredentialsStatus = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
