var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          attrs: {
            "color": "accent",
            "height": "35"
          }
        }, on), [_vm._v("Create new Content")])];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [!_vm.loading ? _c('v-card', [_c('v-card-title', {
    staticClass: "headline accent lighten-2",
    attrs: {
      "primary-title": ""
    }
  }, [_vm._v(" Create New Content ")]), _c('v-card-text', [_c('v-form', [_c('v-text-field', {
    attrs: {
      "label": "Content Title",
      "required": ""
    },
    model: {
      value: _vm.title,
      callback: function ($$v) {
        _vm.title = $$v;
      },
      expression: "title"
    }
  })], 1)], 1), _c('v-card-text', [_c('v-form', [_c('v-select', {
    attrs: {
      "label": "Content Type",
      "items": _vm.contentTypeKeys
    },
    model: {
      value: _vm.contentType,
      callback: function ($$v) {
        _vm.contentType = $$v;
      },
      expression: "contentType"
    }
  })], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-file-input', {
    attrs: {
      "accept": "*",
      "label": "Select Import File"
    },
    on: {
      "change": _vm.importedFile
    },
    model: {
      value: _vm.problemFile,
      callback: function ($$v) {
        _vm.problemFile = $$v;
      },
      expression: "problemFile"
    }
  }), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "accent"
    },
    on: {
      "click": _vm.saveContent
    }
  }, [_vm._v(" Create ")])], 1)], 1) : _c('v-card', [_c('v-card-title', {
    staticClass: "headline accent lighten-2",
    attrs: {
      "primary-title": ""
    }
  }, [_vm._v(" Creating New Content ")]), _c('v-card-actions', [_c('loader')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }