<template>
  <div class="notifications-list">
    <Notification
      v-for="notif in notifications"
      :key="notif.id"
      :id="notif.id"
      :type="notif.type"
      :text="notif.text"
      :duration="notif.duration"
    />
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import Notification from "./Notification.vue";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState("notifs", ["notifications"]),
  },
  methods: {
    ...mapMutations("notifs", ["addNotif", "removeNotif"]),
  },
  components: { Notification },
};
</script>
<style scoped></style>
