<template>
  <v-list-item two-line>
    <v-list-item-content>
      <v-list-item-title class="subtitle-1">{{ title }}</v-list-item-title>
      <v-list-item-subtitle class="subtitle-2"
        ><slot name="default"></slot
      ></v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
<style scoped></style>
