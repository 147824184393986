<template>
  <span>
    <v-row>
      <v-col>
        <v-form @submit.prevent="validateAndAddPermissions">
          <v-text-field
            v-model="emails"
            label="Emails (comma-separated)"
            required
          ></v-text-field>
          <v-select
            v-model="role"
            :items="roles"
            label="Role"
            required
          ></v-select>
          <v-btn type="submit" color="primary">Add Permission</v-btn>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <v-data-table
          :headers="headers"
          :items="permissionsItems"
          :search="search"
          item-key="email"
          v-model="selected"
          show-select
          class="elevation-1"
        ></v-data-table>
        <v-btn color="error" @click="deleteSelected"
          >Delete Selected Email's Permissions</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-combobox
          color="accent"
          :value="restrictedDomains"
          hide-selected
          hint="Restricted domains"
          label="Add domain restrictions"
          multiple
          small-chips
          solo
          @change="setRestrictedDomains"
        >
        </v-combobox>
      </v-col>
    </v-row>
    <v-card class="pa-3" outlined>
      <v-row>
        <v-col>
          <v-list dense>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="headline pt-5"
                  >Customer Information</v-list-item-title
                >
                <v-list-item-subtitle class="subtitle-1 pt-5">
                  <v-btn
                    text
                    :to="{
                      name: 'CustomerDetails',
                      params: { customerId: customerId },
                    }"
                  >
                    <strong>CustomerId:</strong> {{ customerId }}
                  </v-btn>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="subtitle-1"
                  ><strong>Customer Name:</strong>
                  {{ customerName }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p>
            Enter either an Email, Domain, or Customer ID to fetch a customer:
          </p>
        </v-col>
        <v-col>
          <v-text-field
            label="Name"
            v-model="nameInput"
            hint="Enter customer name"
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="text-center">OR</v-col>
        <v-col>
          <v-text-field
            label="Email"
            v-model="emailInput"
            hint="Enter customer email"
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="text-center">OR</v-col>
        <v-col>
          <v-text-field
            label="Domain"
            v-model="domainInput"
            hint="Enter customer domain"
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="text-center">OR</v-col>
        <v-col>
          <v-text-field
            label="Customer ID"
            v-model="customerIdInput"
            hint="Enter customer ID"
            dense
          ></v-text-field>
        </v-col>
        <v-btn @click="searchCustomers" color="primary">Search</v-btn>
      </v-row>
      <v-row v-if="customers.length > 0">
        <v-col>
          <v-data-table
            :headers="customerHeaders"
            :items="customers"
            show-select
            v-model="selectedCustomers"
            class="elevation-1"
          >
          </v-data-table>
          <v-btn color="error" @click="addSelectedCustomers"
            >Add selected customers to this SKILL and save</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
  </span>
</template>
<script>
import { mapActions, mapMutations } from "vuex";
import "codedrills_proto/io/codedrills/proto/content/content_pb";
const Access = proto.io.codedrills.proto.content.Access;
const AccessKeys = Object.keys(Access);

export default {
  props: {
    permissions: {
      type: Object,
      required: true,
    },
    initialRestrictedDomains: {
      type: Array,
      required: true,
    },
    customerId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      customerName: "",
      nameInput: "",
      emailInput: "",
      domainInput: "",
      customerIdInput: "",
      customers: [],
      selectedCustomers: [],
      errorMessage: null,
      search: "",
      selected: [],
      permissionsItems: [],
      authorEmails: [],
      restrictedDomains: [],
      emails: "",
      role: "",
      roles: AccessKeys,
      customerHeaders: [
        { text: "Name", value: "name" },
        { text: "ID", value: "id" },
        // Add other headers as needed
      ],
      headers: [
        { text: "Email", value: "email" },
        { text: "Role", value: "role" },
      ],
    };
  },
  methods: {
    ...mapActions("user", ["getBasicProfile"]),
    ...mapActions("admin", ["getCustomersSearch", "getCustomer"]),
    getCustomerName() {
      this.customerName = "";
      if (this.customerId) {
        this.getCustomer({ id: this.customerId })
          .then((customer) => {
            this.customerName = customer.getCustomer().getName();
          })
          .catch((err) => {
            console.log("Error fetching customer ..", err);
            this.$store.dispatch("notifs/addNotif", {
              text:
                err.message || "Fetching customer failed, please try again!",
              type: "error",
            });
          });
      }
    },
    searchCustomers() {
      console.log("Searching customers...");
      this.getCustomersSearch({
        name: this.nameInput,
        email: this.emailInput,
        domain: this.domainInput,
        customerId: this.customerIdInput,
      })
        .then((customers) => {
          console.log("Customers found: ", customers);
          if (customers.getCustomerList().length === 0) {
            this.$store.dispatch("notifs/addNotif", {
              text: "NO Customer found, Please check the search criteria!",
              type: "info",
            });
            return;
          }
          this.customers = customers.getCustomerList().map((c) => {
            return {
              id: c.getId(),
              name: c.getName(),
            };
          });
        })
        .catch((err) => {
          console.log("Error finding customer ..", err);
          this.$store.dispatch("notifs/addNotif", {
            text: err.message || "Customer search failed, please try again!",
            type: "error",
          });
        });
    },

    handleErrorMessage(message) {
      this.errorMessage = message;
    },

    async deleteSelected() {
      console.log("selected ", this.selected);
      this.permissionsItems = this.permissionsItems.filter(
        (item) => !this.selected.includes(item)
      );
      console.log("new ", this.permissionsItems);

      const ids = await Promise.all(
        this.selected.map((item) => {
          if (item.email && item.email.startsWith("customer:")) {
            return Promise.resolve(item.email);
          } else {
            return this.getBasicProfile({ email: item.email }).then((p) =>
              p.getId()
            );
          }
        })
      );

      this.$emit("removePermission", {
        ids,
        role: null,
      });
      this.selected = [];
    },

    addSelectedCustomers() {
      console.log("selected customers", this.selectedCustomers);
      this.$emit("addCustomers", this.selectedCustomers);
      this.selectedCustomers = [];
    },
    validateEmail(email) {
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },

    validateAndAddPermissions() {
      const emailArray = this.emails
        .split(",")
        .map((email) => email.trim())
        .filter((email) => email);
      const invalidEmails = emailArray.filter(
        (email) => !this.validateEmail(email)
      );

      if (invalidEmails.length === 0) {
        this.addPermissions();
      } else {
        alert(
          `The following emails are invalid: ${invalidEmails.join(
            ", "
          )}. Please check and try again.`
        );
      }
    },

    addPermissions() {
      if (!this.role) {
        this.errorMessage = "Role is required";
        return;
      }
      const emailList = this.emails
        .split(",")
        .map((email) => email.trim())
        .filter((email) => email);
      this.setPermissions(emailList, this.role);
      this.emails = "";
      this.role = "";
    },

    async setPermissions(emails, role) {
      const ids = await Promise.all(
        emails
          .filter((email) => email)
          .map((email) => {
            if (email && email.startsWith("customer:")) {
              return Promise.resolve(email);
            } else {
              return this.getBasicProfile({ email: email })
                .then((p) => p.getId())
                .catch((err) => {
                  console.log("Error updating contest ..", err);
                  this.$store.dispatch("notifs/addNotif", {
                    text:
                      err.message ||
                      "Updating contest failed, please try again!",
                    type: "error",
                  });
                });
            }
          })
      ).then((ids) => ids.filter((id) => id !== null));
      this.$emit("permissionChange", { ids, role });
      this.$store.dispatch("notifs/addNotif", {
        text: "Make sure to 'Save' to persist permissions!",
        type: "success",
      });
    },

    setRestrictedDomains: async function (restrictedDomains) {
      console.log("restrictedDomains", restrictedDomains);
      this.$emit("restrictedDomainChange", restrictedDomains);
    },

    async fetchPermissionsData() {
      const permissionsItems = await Promise.all(
        Array.from(this.permissions.keys()).map(async (id) => {
          const profile = await this.getBasicProfile({ id: id });
          const email = profile.getEmail();
          const role = AccessKeys[this.permissions.get(id).getAccess()];
          return {
            email,
            role,
          };
        })
      );
      this.permissionsItems = permissionsItems;
    },
  },
  async created() {
    this.fetchPermissionsData();
    this.restrictedDomains = [...this.initialRestrictedDomains];
    console.log("Domains are ....", this.initialRestrictedDomains);
    this.$on("error", this.handleErrorMessage);
    this.getCustomerName();
  },
  watch: {
    permissions: {
      immediate: true,
      handler() {
        this.fetchPermissionsData();
      },
    },
  },
};
</script>
