<template>
  <v-card outlined class="pa-3 mb-3">
    <v-text-field v-model="id" label="ID" readonly></v-text-field>
    <v-text-field
      v-if="points >= 0"
      v-model="points"
      label="Points"
      type="number"
      :rules="pointsRules"
    ></v-text-field>
    <v-text-field
      v-else-if="weightage >= 0"
      v-model="weightage"
      label="Weightage"
      type="number"
      :rules="weightageRules"
    ></v-text-field>
    <v-btn color="red" @click="$emit('update', group)">Update</v-btn>
  </v-card>
</template>

<script>
export default {
  props: ["group"],
  data() {
    return {
      weightageRules: [
        (value) =>
          (value >= 0 && value <= 1) || "Weightage must be between 0 and 1",
      ],
      pointsRules: [
        (value) => value >= 0 || "Points must be greater than or equal to 0",
      ],
    };
  },
  computed: {
    weightage: {
      get() {
        return this.group.getWeightage();
      },
      set(value) {
        this.group.setWeightage(parseFloat(value));
      },
    },
    points: {
      get() {
        return this.group.getPoints();
      },
      set(value) {
        this.group.setPoints(parseInt(value, 10));
      },
    },
    id: {
      get() {
        return this.group.getId();
      },
    },
  },
  created() {
    console.log(this.group.toObject());
  },
};
</script>
