var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('v-row', [_c('v-col', [_c('v-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.validateAndAddPermissions.apply(null, arguments);
      }
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Emails (comma-separated)",
      "required": ""
    },
    model: {
      value: _vm.emails,
      callback: function ($$v) {
        _vm.emails = $$v;
      },
      expression: "emails"
    }
  }), _c('v-select', {
    attrs: {
      "items": _vm.roles,
      "label": "Role",
      "required": ""
    },
    model: {
      value: _vm.role,
      callback: function ($$v) {
        _vm.role = $$v;
      },
      expression: "role"
    }
  }), _c('v-btn', {
    attrs: {
      "type": "submit",
      "color": "primary"
    }
  }, [_vm._v("Add Permission")])], 1)], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Search",
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.permissionsItems,
      "search": _vm.search,
      "item-key": "email",
      "show-select": ""
    },
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }), _c('v-btn', {
    attrs: {
      "color": "error"
    },
    on: {
      "click": _vm.deleteSelected
    }
  }, [_vm._v("Delete Selected Email's Permissions")])], 1)], 1), _c('v-row', [_c('v-col', [_c('v-combobox', {
    attrs: {
      "color": "accent",
      "value": _vm.restrictedDomains,
      "hide-selected": "",
      "hint": "Restricted domains",
      "label": "Add domain restrictions",
      "multiple": "",
      "small-chips": "",
      "solo": ""
    },
    on: {
      "change": _vm.setRestrictedDomains
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "pa-3",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', [_c('v-col', [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "headline pt-5"
  }, [_vm._v("Customer Information")]), _c('v-list-item-subtitle', {
    staticClass: "subtitle-1 pt-5"
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "to": {
        name: 'CustomerDetails',
        params: {
          customerId: _vm.customerId
        }
      }
    }
  }, [_c('strong', [_vm._v("CustomerId:")]), _vm._v(" " + _vm._s(_vm.customerId) + " ")])], 1), _c('v-list-item-subtitle', {
    staticClass: "subtitle-1"
  }, [_c('strong', [_vm._v("Customer Name:")]), _vm._v(" " + _vm._s(_vm.customerName))])], 1)], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" Enter either an Email, Domain, or Customer ID to fetch a customer: ")])]), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Name",
      "hint": "Enter customer name",
      "dense": ""
    },
    model: {
      value: _vm.nameInput,
      callback: function ($$v) {
        _vm.nameInput = $$v;
      },
      expression: "nameInput"
    }
  })], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v("OR")]), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Email",
      "hint": "Enter customer email",
      "dense": ""
    },
    model: {
      value: _vm.emailInput,
      callback: function ($$v) {
        _vm.emailInput = $$v;
      },
      expression: "emailInput"
    }
  })], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v("OR")]), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Domain",
      "hint": "Enter customer domain",
      "dense": ""
    },
    model: {
      value: _vm.domainInput,
      callback: function ($$v) {
        _vm.domainInput = $$v;
      },
      expression: "domainInput"
    }
  })], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v("OR")]), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Customer ID",
      "hint": "Enter customer ID",
      "dense": ""
    },
    model: {
      value: _vm.customerIdInput,
      callback: function ($$v) {
        _vm.customerIdInput = $$v;
      },
      expression: "customerIdInput"
    }
  })], 1), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.searchCustomers
    }
  }, [_vm._v("Search")])], 1), _vm.customers.length > 0 ? _c('v-row', [_c('v-col', [_c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.customerHeaders,
      "items": _vm.customers,
      "show-select": ""
    },
    model: {
      value: _vm.selectedCustomers,
      callback: function ($$v) {
        _vm.selectedCustomers = $$v;
      },
      expression: "selectedCustomers"
    }
  }), _c('v-btn', {
    attrs: {
      "color": "error"
    },
    on: {
      "click": _vm.addSelectedCustomers
    }
  }, [_vm._v("Add selected customers to this SKILL and save")])], 1)], 1) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }