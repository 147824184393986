<template>
  <v-container fluid>
    <div v-if="!this.user">Please login first</div>
    <Loader v-if="this.user && loading" />
    <NotFoundText v-if="this.user && !loading && !content" item="drill" />
    <span v-if="this.user && !loading && content">
      <div class="grey lighten-5 pl-12 pr-5 mx-0 my-0">
        <v-row>
          <v-col>
            <span class="display-2 font-weight-bold">
              {{ content.getTitle() }}
            </span>
          </v-col>
        </v-row>
      </div>
      <v-container fluid class="pl-12 pr-5 mx-0 my-0">
        <v-row>
          <v-col>
            <v-btn
              :loading="isDrillSaved"
              :disabled="isDrillSaved"
              color="accent"
              height="35"
              @click="saveDrill"
              >Save</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              :loading="isDrillPublished"
              :disabled="isPublishButtonDisabled"
              color="accent"
              height="35"
              @click="publishContentRequest"
              >Publish</v-btn
            >
          </v-col>
        </v-row>
        <v-row class="px-2">
          <v-col cols="4" sm="2">
            <v-select
              label="Publish Type"
              :items="publishTypeKeys"
              :value="publishType"
              @change="changePublishType"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-tabs color="accent" slider-color="accent" v-model="drillTab">
              <v-tab>Preview</v-tab>
              <v-tab>Introduction</v-tab>
              <v-tab>Problems</v-tab>
              <v-tab>Attachment</v-tab>
            </v-tabs>

            <v-tabs-items v-model="drillTab">
              <v-tab-item>
                <!-- drill preview -->
                <v-row class="py-5 px-2">
                  <v-col>
                    <div
                      v-html="
                        view.getDataView().getDrillView().getDescriptionHtml()
                      "
                    ></div>
                  </v-col>
                </v-row>
                <v-row class="py-5 px-2">
                  <v-col>
                    <v-chip
                      class="mr-1"
                      color="accent"
                      v-for="tag in content.getMetaData().getTagsList()"
                      :key="tag"
                    >
                      {{ tag }}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item>
                <!-- description -->
                <v-row class="px-2">
                  <v-col>
                    <v-text-field
                      label="Title"
                      :value="content.getTitle()"
                      @change="setTitle"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="px-2">
                  <v-col>
                    <v-text-field
                      label="Image Url"
                      :value="content.getMetaData().getImageUrl()"
                      @change="setImage"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="px-2">
                  <v-col>
                    <v-combobox
                      color="accent"
                      :value="content.getMetaData().getTagsList()"
                      :items="items"
                      hide-selected
                      hint="tagType/tag"
                      label="Add company, problem type tags"
                      persistent-hint
                      multiple
                      small-chips
                      solo
                      @change="setTags"
                    >
                    </v-combobox>
                  </v-col>
                </v-row>
                <v-row class="py-5 px-2">
                  <v-col>
                    <MarkdownEditor
                      :editorialMode="false"
                      :submissionMode="true"
                      :code="content.getData().getDrill().getDescription()"
                      :onCodeChange="onMarkdownCodeChange"
                      :value="editorConfig"
                    />
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item>
                <v-row>
                  <v-col>
                    <div
                      v-for="(section, index) in content
                        .getData()
                        .getDrill()
                        .getSectionsList()"
                      :key="index"
                    >
                      <section-card
                        :availableProblems="contentsObject"
                        :section="section"
                        @remove="removeSection(index)"
                        @changeTitle="
                          (title) => updateSectionTitle(index, title)
                        "
                        @changeDescription="
                          (description) =>
                            updateSectionDescription(index, description)
                        "
                        @changeProblemIds="
                          (problemIds) =>
                            updateSectionProblemIds(index, problemIds)
                        "
                      >
                      </section-card>
                    </div>
                    <v-row class="mt-5">
                      <v-col>
                        <v-btn @click="addSection">Add new section</v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item>
                <!--Attachment-->
                <v-row>
                  <v-col>
                    <v-file-input
                      :rules="rules"
                      accept="image/*"
                      placeholder="Pick an avatar"
                      prepend-icon="mdi-camera"
                      label="Avatar"
                      v-model="drillAvatar"
                    ></v-file-input>
                    <v-btn color="accent" @click="uploadAttachmentSave"
                      >Save Attachment
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="attachment && attachment.getUrl()">
                  <v-col>
                    {{ attachment.getUrl() }}
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
      <v-row>
        <v-col>
          <confirm-dialog ref="confirm"></confirm-dialog>
          <v-btn color="error" height="35" @click="deleteContentRequest"
            >Delete Drill</v-btn
          >
        </v-col>
      </v-row>
    </span>
  </v-container>
</template>

<script>
import { getters, mapActions, mapState, mapMutations, mapGetters } from "vuex";
import MarkdownEditor from "../components/MarkdownEditor";
import CodeEditor from "../components/CodeEditor";
import NotFoundText from "../components/NotFoundText";
import Loader from "../components/Loader";
import SectionCard from "../components/SectionCard";
import ConfirmDialog from "../components/ConfirmDialog.vue";

export default {
  props: ["url"],
  data() {
    return {
      activeTab: "description",
      drillTab: null,
      deletedFiles: new Map(),
      drillAvatar: null,
      isDrillSaved: false,
      isDrillPublished: false,
      rules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Avatar size should be less than 2 MB!",
      ],
      items: [
        "types/interview",
        "types/competitive",
        "drills/topic",
        "drills/company",
      ],
    };
  },
  components: {
    MarkdownEditor,
    NotFoundText,
    Loader,
    SectionCard,
    ConfirmDialog,
  },
  computed: {
    ...mapState("drill", ["content", "view", "loading", "attachment"]),
    ...mapState("user", ["user", "hasPremiumAccess"]),
    ...mapState("content", ["contents"]),
    contentsObject() {
      if (this.contents) return this.contents.map((c) => c.toObject());
    },
    premiumBlock() {
      return this.premiumOnly && !this.hasPremiumAccess;
    },
    ...mapGetters("drill", ["publishType", "publishTypeKeys"]),
    styles() {
      return { width: `${this.rating * 20}%` };
    },
    isPublishButtonDisabled() {
      return (
        this.content.getVersion() == this.content.getPublishVersion() ||
        this.isDrillPublished
      );
    },
  },
  methods: {
    ...mapActions("drill", ["fetchDrill", "updateDrill", "updateAttachment"]),
    ...mapActions("content", [
      "fetchContents",
      "publishContent",
      "deleteContent",
    ]),
    ...mapMutations("drill", [
      "setDescription",
      "setTitle",
      "changePublishType",
      "setImage",
      "newSection",
      "setSectionTitle",
      "setSectionDescription",
      "setSectionProblemIds",
      "setTags",
      "removeDrillSection",
      "updateDrillSection",
    ]),
    onMarkdownCodeChange(newCode) {
      this.setDescription(newCode);
    },
    changeTab(tab) {
      this.activeTab = tab;
    },
    run() {},
    editorConfig(newConfig) {
      console.log("Change ", newConfig);
    },
    saveDrill() {
      this.isDrillSaved = true;
      this.updateDrill({}).then((__) =>
        this.$router
          .push("/drills/" + this.content.getUrl())
          .catch((__) => {})
          .finally((__) => (this.isDrillSaved = false))
      );
    },
    publishContentRequest() {
      if (
        this.content.toObject().version !=
        this.content.toObject().publishVersion
      ) {
        this.isDrillPublished = true;
        this.publishContent({ id: this.content.getId() }).then((__) =>
          this.$router
            .push("/drills/" + this.content.getUrl())
            .catch((__) => {})
            .finally((__) => {
              this.isDrillPublished = false;
            })
        );
      }
    },
    deleteContentRequest() {
      this.$refs.confirm
        .open("Delete", "Are you sure you want to delete this drill?", {
          color: "red",
        })
        .then((confirm) => {
          if (confirm)
            this.deleteContent({ id: this.content.getId() }).then((_) =>
              this.$router.push("/")
            );
        });
    },
    uploadAttachmentSave() {
      this.updateAttachment({ attachment: this.drillAvatar });
    },
    addSection(index) {
      this.newSection(index);
    },
    removeSection(index) {
      this.removeDrillSection(index);
    },
    updateSection(index, section) {
      this.updateDrillSection({ index: index, section: section });
    },
    updateSectionTitle(index, title) {
      this.setSectionTitle({ index: index, title: title });
    },
    updateSectionDescription(index, description) {
      this.setSectionDescription({ index: index, description: description });
    },
    updateSectionProblemIds(index, problemIds) {
      this.setSectionProblemIds({ index: index, ids: problemIds });
    },
  },
  watch: {
    user: function () {
      console.log("Changing user ...");
    },
    editorConfig: function () {
      this.updateUserPreference();
    },
    content: function (content) {
      if (!content) return;
      console.log("title", content.getTitle());
      document.title = content.getTitle() + " [Drill] - CodeDrills";
    },
  },
  created() {
    console.log("Creating...", this.url);
    this.fetchDrill({ url: this.url }).then((_) => {
      this.fetchContents();
    });
  },
};
</script>
<style scoped>
.is-deleted {
  text-decoration: line-through;
}
</style>
