<template>
  <div class="my-8 mx-4">
    <v-row>
      <v-col cols="2"> Content Type </v-col>
      <v-col cols="2"> Count </v-col>
      <v-col cols="2"> Question Duration </v-col>
      <v-col cols="2"> Question Max Score </v-col>
      <v-col cols="4"> Tags List </v-col>
    </v-row>
    <v-row
      v-for="(rule, i) in this.content
        ?.getData()
        ?.getSkill()
        ?.getQuestionSelectionCriteria()
        .getSelectionRuleList()"
      :key="i"
    >
      <v-col cols="2">
        {{ contentTypeKeys[rule.getContentType()] }}
      </v-col>
      <v-col cols="2">
        {{ rule.getNumberOfQuestions() }}
      </v-col>
      <v-col cols="2">
        {{ rule.getQuestionDuration() }}
      </v-col>
      <v-col cols="2">
        {{ rule.getQuestionMaxScore() }}
      </v-col>
      <v-col cols="4">
        {{ rule.getTagList() }}
      </v-col>
      <v-col>
        <v-btn color="red" @click="deleteRule(i)">Delete rule</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <v-select
          label="Content Type"
          :items="ruleContentTypes"
          v-model="contentType"
        ></v-select>
      </v-col>
      <v-col cols="2">
        <v-text-field
          type="number"
          placeholder="Number of questions"
          :rules="[
            (v) => !!v || Number.isInteger(v) || 'Enter Valid Number',
            (v) => (v && v >= 0 && v <= 150) || 'Between 0 to 150',
          ]"
          v-model="count"
        >
        </v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
          type="number"
          placeholder="Question Duration in S"
          :rules="[
            (v) => !!v || Number.isInteger(v) || 'Enter Valid Number',
            (v) => (v && v >= 0 && v <= 31536000) || 'Between 0s to 1yr',
          ]"
          v-model="duration"
        >
        </v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
          type="number"
          placeholder="Question Max Score"
          :rules="[
            (v) => !!v || Number.isInteger(v) || 'Enter Valid Number',
            (v) => (v && v >= 0 && v <= 10000) || 'Between 0 to 10000',
          ]"
          v-model="score"
        >
        </v-text-field>
      </v-col>
      <v-col cols="6">
        <v-combobox
          color="accent"
          :items="ruleValues"
          v-model="tags"
          hide-selected
          hint="tagType/tag"
          label="Add company, problem type tags"
          persistent-hint
          multiple
          small-chips
          solo
          @change="setRuleTags()"
        >
        </v-combobox>
      </v-col>
      <v-col cols="2">
        <v-btn color="accent" @click="addRule">Add</v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      ruleContentTypes: ["MCQ", "SUBJECTIVE", "PROBLEM"],
      tags: [],
      finalRules: [],
      count: 0,
      contentType: 0,
    };
  },
  computed: {
    ...mapState("skill", ["content"]),
    ...mapGetters("skill", ["contentTypeKeys"]),
    ruleValues() {
      return [
        "types/interview",
        "types/competitive",
        "drills/topic",
        "drills/company",
        "companies/adobe",
        "companies/amazon",
        "companies/apple",
        "companies/atlassian",
        "companies/cisco",
        "companies/directi",
        "companies/ebay",
        "companies/facebook",
        "companies/flipkart",
        "companies/goldman_sachs",
        "companies/google",
        "companies/jpmorgan_chase",
        "companies/linkedin",
        "companies/microsoft",
        "companies/oracle",
        "companies/paypal",
        "companies/sap",
        "companies/twitter",
        "companies/uber",
        "companies/vmware",
        "companies/walmart",
        "difficulties/easy",
        "difficulties/medium",
        "difficulties/hard",
        "libraries/standard",
        "libraries/premium",
      ];
    },
  },
  methods: {
    ...mapMutations("skill", ["setSelectionRules", "deleteSelectionRule"]),
    setRuleTags() {
      console.log("Tags ..", this.tags);
    },
    addRule() {
      console.log(
        "Data..",
        this.contentType,
        this.count,
        this.duration,
        this.score,
        this.tags
      );
      this.setSelectionRules({
        type: this.contentType,
        count: this.count,
        duration: this.duration,
        score: this.score,
        tags: this.tags,
      });
    },
    deleteRule(index) {
      this.deleteSelectionRule({ index: index });
    },
  },
  created() {
    const selRules = this.content
      ?.getData()
      ?.getSkill()
      ?.getQuestionSelectionCriteria()
      .getSelectionRuleList();
    // selRules.length > 0 &&
    //   selRules.forEach((rule) => {
    //     if (this.contentTypeKeys[rule.getContentType()] === "MCQ") {
    //       // index 0
    //       this.ruleQuestionCounts[0] = rule.getNumberOfQuestions();
    //       this.ruleTags[0] = rule.getTagList();
    //     } else if (
    //       this.contentTypeKeys[rule.getContentType()] === "SUBJECTIVE"
    //     ) {
    //       // index 1
    //       this.ruleQuestionCounts[1] = rule.getNumberOfQuestions();
    //       this.ruleTags[1] = rule.getTagList();
    //     } else if (this.contentTypeKeys[rule.getContentType()] === "PROBLEM") {
    //       // index 2
    //       this.ruleQuestionCounts[2] = rule.getNumberOfQuestions();
    //       this.ruleTags[2] = rule.getTagList();
    //     }
    //   });
  },
};
</script>
