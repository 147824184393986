<template>
  <v-expansion-panels class="mb-2" multiple v-if="resultViews">
    <v-expansion-panel v-for="rv in resultViews" :key="rv.getId()">
      <v-expansion-panel-header>
        Test #{{ rv.getId() }}
      </v-expansion-panel-header>

      <v-expansion-panel-content v-if="!rv.getExpectedView()">
        <v-row> <v-col class="subtitle-2"> Error </v-col> </v-row>
        <v-row>
          <v-col>
            <text-block>
              <pre
                >{{ rv.getLogs() }}
                    </pre
              >
            </text-block>
          </v-col>
        </v-row>
      </v-expansion-panel-content>

      <v-expansion-panel-content
        v-else-if="rv.getExpectedView().getArgOutputView()"
      >
        <v-row> <v-col class="subtitle-2"> Input </v-col> </v-row>
        <v-row
          v-for="input in rv.getInputView().getArgInputViewsList()"
          :key="input.getName()"
        >
          <v-col cols="4">
            <v-text-field
              single
              outlined
              readonly
              :label="input.getName()"
              :value="input.getValue()"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row> <v-col class="subtitle-2"> Output </v-col> </v-row>
        <v-row
          ><v-col>
            <text-block v-if="rv.getActualView()">
              <pre>{{ rv.getActualView().getArgOutputView().getValue() }}</pre>
            </text-block>
          </v-col></v-row
        >

        <v-row> <v-col class="subtitle-2"> Expected </v-col> </v-row>
        <v-row
          ><v-col>
            <text-block>
              <pre>{{
                rv.getExpectedView().getArgOutputView().getValue()
              }}</pre>
            </text-block>
          </v-col></v-row
        >

        <v-row> <v-col class="subtitle-2"> Logs </v-col> </v-row>
        <v-row
          ><v-col>
            <text-block>
              <pre>{{ rv.getLogs() }}</pre>
            </text-block>
          </v-col></v-row
        >
      </v-expansion-panel-content>

      <v-expansion-panel-content v-else>
        <v-row> <v-col class="subtitle-2"> Input </v-col> </v-row>
        <v-row>
          <v-col>
            <text-block>
              <pre>{{ rv.getInputView().getRawInput() }}</pre>
            </text-block>
          </v-col>
        </v-row>

        <v-row> <v-col class="subtitle-2"> Output </v-col> </v-row>
        <v-row
          ><v-col>
            <text-block v-if="rv.getActualView()">
              <pre>{{ rv.getActualView().getRawOutput() }}</pre>
            </text-block>
          </v-col></v-row
        >

        <v-row> <v-col class="subtitle-2"> Expected </v-col> </v-row>
        <v-row
          ><v-col>
            <text-block>
              <pre>{{ rv.getExpectedView().getRawOutput() }}</pre>
            </text-block>
          </v-col></v-row
        >
        <v-row> <v-col class="subtitle-2"> Logs </v-col> </v-row>
        <v-row
          ><v-col>
            <text-block>
              <pre>{{ rv.getLogs() }}</pre>
            </text-block>
          </v-col></v-row
        >
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import Editor from "../components/Editor";
import StatsCol from "../components/StatsCol";
import SubmissionStatus from "../components/SubmissionStatus";
import TextBlock from "./TextBlock.vue";

export default {
  props: {
    resultViews: {
      type: Array,
      required: true,
    },
  },
  computed: {},
  methods: {},
  components: {
    TextBlock,
  },
};
</script>
<style scoped></style>
