var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "px-5 py-5"
  }, [_c('v-col', {
    attrs: {
      "cols": 12
    }
  }, [_c('v-card', {
    staticClass: "simpleMdEditor",
    attrs: {
      "outlined": "",
      "max-height": "150px"
    }
  }, [_c('CodeMirror', {
    ref: "myCm",
    staticClass: "markdown",
    class: _vm.fontSize,
    attrs: {
      "value": _vm.code,
      "options": _vm.cmOptions
    },
    on: {
      "ready": _vm.onCmReady,
      "focus": _vm.onCmFocus,
      "input": _vm.onCodeChange
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }