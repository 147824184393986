/* eslint-disable no-console */
import builder from "codedrills_proto/io/codedrills/proto/builder/content_service_grpc_web_pb";
import { ApiCall } from "../../utils/api";

var content_proto = proto.io.codedrills.proto.content;
var builder_proto = proto.io.codedrills.proto.builder;
var judge_proto = proto.io.codedrills.proto.judge;

const builderService = new builder.ContentServicePromiseClient(
  process.env.VUE_APP_BUILDER_API_URL + "/builder",
  null,
  null
);

const state = {
  content: null,
  view: null,
  loading: null,
  attachment: null,
  fetchSkillStatus: null,
  updateSkillStatus: null,
  updateAttachmentStatus: null,
};

const actions = {
  fetchSkill: new ApiCall("fetchSkill")
    .withServiceCall((r, h) => builderService.getContent(r, h))
    .withRequest(({ url }) => {
      var getContentRequest = new builder_proto.GetContentRequest();
      var contentId = new content_proto.ContentId();
      var contentAddress = new content_proto.ContentAddress();
      contentAddress.setContentType(
        proto.io.codedrills.proto.content.ContentType.SKILL
      );
      contentAddress.setUrl(url);
      contentId.setContentAddress(contentAddress);
      getContentRequest.setContentId(contentId);

      return getContentRequest;
    })
    .onSuccess(({ commit }, res) => {
      commit("setSkill", res);
      console.log(`setSkill:onSuccess: `, res.toObject());
      if (
        !state.content?.getData()?.getSkill()?.getQuestionSelectionCriteria()
      ) {
        const newCriteria = new content_proto.QuestionSelectionCriteria()
          .setAlwaysIncludedQuestionIdList([])
          .setSelectionRuleList([]);

        state.content
          ?.getData()
          ?.getSkill()
          ?.setQuestionSelectionCriteria(newCriteria);
      }
    })
    .build(),
  updateSkill: new ApiCall("updateSkill")
    .withServiceCall((r, h) => builderService.updateContent(r, h))
    .withRequest(({ autoPublish }) => {
      return new builder_proto.UpdateContentRequest()
        .setId(state.content.getId())
        .setTitle(state.content.getTitle())
        .setCustomerId(state.content.getCustomerId())
        .setVersion(state.content.getVersion())
        .setMetaData(state.content.getMetaData())
        .setData(state.content.getData())
        .setPublishType(state.content.getPublishType())
        .setContentType(state.content.getContentType())
        .setAutoPublish(autoPublish);
    })
    .onSuccess(({ commit }, res) => {
      commit("setSkill", res);
    })
    .build(),
  exportSkill: new ApiCall("exportSkill")
    .withServiceCall((r, h) => builderService.exportContentMarkdown(r, h))
    .withRequest(() => {
      return new builder_proto.ExportContentMarkdownRequest().setContentId(
        new content_proto.ContentId().setId(state.content.getId())
      );
    })
    .onSuccess(({ commit }, res) => {
      return res.getContentMarkdown();
    })
    .build(),

  async updateAttachment({ commit, state, getters, dispatch }, { attachment }) {
    return dispatch("user/ensureUserToken", null, { root: true }).then(
      async (userToken) => {
        var createAttachmentRequest =
          new builder_proto.CreateAttachmentRequest();
        createAttachmentRequest.setContentId(state.content.getId());
        createAttachmentRequest.setIsPublic(true);
        await attachment.arrayBuffer().then((arrayBuffer) => {
          createAttachmentRequest.setData(new Uint8Array(arrayBuffer));
        });
        createAttachmentRequest.setName(attachment.name);
        createAttachmentRequest.setType(attachment.type);

        console.log("Request", createAttachmentRequest);
        return builderService
          .createAttachment(createAttachmentRequest, {
            Authorization: userToken,
          })
          .then((res) => {
            console.log("SUCCESS", res.toObject());
            commit("setAttachment", res);
          })
          .catch((err) => {
            console.error("ERROR", err);
          });
      }
    );
  },
};

const getters = {
  userToken: function (state, getters, rootState, rootGetters) {
    parameters.addValue("meta_data", content.getMetaData().toByteArray());
    console.log("rootGetters", rootGetters);
    return rootGetters["user/userToken"];
  },
  contentTypeKeys() {
    return Object.keys(proto.io.codedrills.proto.content.ContentType);
  },
  publishType(state, getters) {
    return getters.publishTypeKeys[state.content.getPublishType()];
  },
  publishTypeKeys() {
    return Object.keys(content_proto.PublishType);
  },
  skillLevelKeys() {
    return Object.keys(content_proto.SkillLevel);
  },
  languageKeys() {
    return Object.keys(judge_proto.Language);
  },
  getDescription(state) {
    return state.content.getData().getSkill().getDescription() || "Statement";
  },
  getImageUrl(state) {
    return state.content.getMetaData().getSkillMeta()?.getImageUrl() || "";
  },
  getExpertDetails(state) {
    return state.content.getData().getSkill().getExpertDetails() || "";
  },
  getExpertImageUrl(state) {
    return state.content.getData().getSkill().getExpertImageUrl() || "";
  },
  getSkillMetaTimeInS(state) {
    return state.content.getMetaData().getTimeLimit() || "";
  },
  getSkillMetaSummary(state) {
    return state.content.getMetaData().getSkillMeta()?.getSummary() || "";
  },
  getSkillMetaSkillLevel(state, getters) {
    return (
      getters.skillLevelKeys[
        state.content.getMetaData().getSkillMeta()?.getSkillLevel()
      ] || proto.io.codedrills.proto.content.SkillLevel.SKILL_LEVEL_UNKNOWN
    );
  },
  getSkillMetaImageUrl(state) {
    return state.content.getMetaData().getSkillMeta()?.getImageUrl() || "";
  },
  getSkillTimeLimit(state) {
    return state.content.getMetaData()?.getTimeLimit() || 0;
  },
};

const mutations = {
  toggleLoading(state, content) {
    state.loading = content;
  },

  setCustomerId(state, customerId) {
    state.content.setCustomerId(customerId);
  },
  setSkill(state, res) {
    state.content = res.getContent();
    state.view = res.getContentView();
  },
  updateSkillStatus(state, status) {
    state.updateSkillStatus = status;
  },
  fetchSkillStatus(state, status) {
    state.fetchSkillStatus = status;
  },
  setTitle(state, title) {
    state.content.setTitle(title);
  },

  setDescription(state, statement) {
    state.content.getData().getSkill().setDescription(statement);
  },
  setExpertDetails(state, expertDetails) {
    state.content.getData().getSkill().setExpertDetails(expertDetails);
  },
  setExpertImageUrl(state, expertImageUrl) {
    state.content.getData().getSkill().setExpertImageUrl(expertImageUrl);
  },

  setImageUrl(state, url) {
    console.log(`setImageUrl:`, url);
    state.content.getData().getSkill().setImageUrl(url);
  },

  setAttachment(state, res) {
    console.log(`setAtt`, res);
    state.attachment = res.getAttachment();
  },
  setSelectedQuestions(state, questions) {
    state.content.getData().getSkill().setQuestionList(questions);
    console.log(
      `setSelectedQuestions: `,
      state.content.getData().getSkill().getQuestionList().length
    );
  },
  changePublishType(state, publishType) {
    console.log("Publish Type", publishType);
    state.content.setPublishType(content_proto.PublishType[publishType]);
  },
  updateAttachmentStatus(state, status) {
    state.updateAttachmentStatus = status;
  },
  setPermissions(state, ids) {
    console.log("ids", ids);
    state.content.getMetaData().getPermissionsMap().clear();
    for (var id of ids) {
      state.content
        .getMetaData()
        .getPermissionsMap()
        .set(
          id,
          new content_proto.Permission().setAccess(content_proto.Access.AUTHOR)
        );
    }
    console.log(
      "MetaData with permissions on skill",
      state.content.getMetaData().toObject()
    );
  },
  setTags(state, tags) {
    state.content.getMetaData().setTagsList(tags);
  },
  setSkillMetaTimeInS(state, timeInS) {
    state.content.getMetaData()?.setTimeLimit(timeInS);
  },
  setSkillMetaSummary(state, summary) {
    if (!state.content.getMetaData()?.getSkillMeta()) {
      state.content.getMetaData()?.setSkillMeta(new content_proto.SkillMeta());
    }
    state.content.getMetaData()?.getSkillMeta()?.setSummary(summary);
  },
  setSkillMetaSkillLevel(state, skillLevel) {
    if (!state.content.getMetaData()?.getSkillMeta()) {
      state.content.getMetaData()?.setSkillMeta(new content_proto.SkillMeta());
    }
    state.content
      .getMetaData()
      ?.getSkillMeta()
      ?.setSkillLevel(content_proto.SkillLevel[skillLevel]);
  },
  setSkillMetaImageUrl(state, imageUrl) {
    if (!state.content.getMetaData()?.getSkillMeta()) {
      state.content.getMetaData()?.setSkillMeta(new content_proto.SkillMeta());
    }
    state.content.getMetaData()?.getSkillMeta()?.setImageUrl(imageUrl);
  },
  setSkillTimeLimit(state, value) {
    state.content.getMetaData()?.setTimeLimit(value) || 0;
  },
  setAllowedLanguages(state, allowedLanguages) {
    console.log("Allow ...", allowedLanguages);
    var langs = allowedLanguages.map((lang) => judge_proto.Language[lang]);
    console.log("langs ...", langs);
    state.content
      .getData()
      ?.getSkill()
      ?.getLanguageRestriction()
      ?.clearAllowLanguageList();
    state.content
      .getData()
      ?.getSkill()
      ?.getLanguageRestriction()
      ?.setAllowLanguageList(langs);
  },

  setSelectionRules(state, { type, count, duration, score, tags }) {
    const newRule = new content_proto.SelectionRule()
      .setContentType(content_proto.ContentType[type])
      .setTagList(tags)
      .setNumberOfQuestions(count)
      .setQuestionDuration(duration)
      .setQuestionMaxScore(score);
    console.log("New rule is ..", newRule.toObject());
    if (!state.content?.getData()?.getSkill()?.getQuestionSelectionCriteria()) {
      state.content
        ?.getData()
        ?.getSkill()
        ?.setQuestionSelectionCriteria(
          new content_proto.QuestionSelectionCriteria()
        );
    }
    state.content
      ?.getData()
      ?.getSkill()
      ?.getQuestionSelectionCriteria()
      .getSelectionRuleList()
      .push(newRule);

    console.log(
      "Updated rules",
      state.content
        ?.getData()
        ?.getSkill()
        ?.getQuestionSelectionCriteria()
        .getSelectionRuleList()[0]
        .toObject()
    );
  },

  deleteSelectionRule(state, { index }) {
    console.log("Deleing index", index);
    state.content
      ?.getData()
      ?.getSkill()
      ?.getQuestionSelectionCriteria()
      .getSelectionRuleList()
      .splice(index, 1);
  },
  setAlwaysIncludedQuestions(state, questions) {
    state.content
      ?.getData()
      ?.getSkill()
      ?.getQuestionSelectionCriteria()
      ?.setAlwaysIncludedQuestionIdList(questions);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
