var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('v-row', [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": 4
    }
  }, [_c('v-text-field', {
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Search (title, tags)",
      "clearable": "",
      "single-line": "",
      "outlined": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)], 1), _c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.contents,
      "disable-pagination": "",
      "search": _vm.search
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-dialog', {
          attrs: {
            "max-width": "500px"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref) {
              var on = _ref.on,
                attrs = _ref.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "mb-2",
                attrs: {
                  "color": "accent",
                  "dark": ""
                }
              }, 'v-btn', attrs, false), on), [_vm._v(" Add MCQ ")])];
            }
          }]),
          model: {
            value: _vm.addMcqDialog,
            callback: function ($$v) {
              _vm.addMcqDialog = $$v;
            },
            expression: "addMcqDialog"
          }
        }, [!_vm.savingMcq ? _c('v-card', [_c('v-card-title', {
          staticClass: "headline accent lighten-2",
          attrs: {
            "primary-title": ""
          }
        }, [_vm._v(" Create New MCQ ")]), _c('v-card-text', [_c('v-form', {
          model: {
            value: _vm.addMcqFormValid,
            callback: function ($$v) {
              _vm.addMcqFormValid = $$v;
            },
            expression: "addMcqFormValid"
          }
        }, [_c('v-text-field', {
          attrs: {
            "label": "MCQ Title",
            "required": ""
          },
          model: {
            value: _vm.newMcqTitle,
            callback: function ($$v) {
              _vm.newMcqTitle = $$v;
            },
            expression: "newMcqTitle"
          }
        })], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
          attrs: {
            "disabled": !_vm.addMcqFormValid,
            "color": "accent"
          },
          on: {
            "click": _vm.createMcq
          }
        }, [_vm._v(" Create ")])], 1)], 1) : _c('v-card', [_c('v-card-title', {
          staticClass: "headline accent lighten-2",
          attrs: {
            "primary-title": ""
          }
        }, [_vm._v(" Creating New MCQ ")]), _c('v-card-actions', [_c('loader')], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "item",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('tr', [_c('td', [_c('router-link', {
          staticClass: "ml-1",
          attrs: {
            "to": _vm.getUrl(item)
          }
        }, [_vm._v(" " + _vm._s(item.title) + " ")])], 1), _c('td', {
          attrs: {
            "colspan": 3
          }
        }, _vm._l(item.metaData.tagsList, function (t) {
          return _c('v-chip', {
            key: t,
            staticClass: "mr-2",
            attrs: {
              "outlined": "",
              "color": "grey lighten-2",
              "text-color": "grey",
              "label": ""
            },
            on: {
              "click": _vm.searchTag
            }
          }, [_vm._v(" " + _vm._s(t) + " ")]);
        }), 1)])];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }