var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-icon', {
    on: {
      "click": _vm.removeSection
    }
  }, [_vm._v("mdi-close")]), _c('v-text-field', {
    attrs: {
      "label": "Title",
      "value": _vm.section.getTitle()
    },
    on: {
      "change": _vm.updateTitle
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Description",
      "value": _vm.section.getDescription()
    },
    on: {
      "change": _vm.updateDescription
    }
  }), _c('search', {
    attrs: {
      "availableProblems": _vm.availableProblems,
      "selectedProblems": _vm.selectedProblems
    },
    on: {
      "change": _vm.updateProblems
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }