/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import builder from "codedrills_proto/io/codedrills/proto/builder/content_service_grpc_web_pb";
import judge from "codedrills_proto/io/codedrills/proto/judge/judge_pb";
import judgeServiceProto from "codedrills_proto/io/codedrills/proto/judge/judge_service_grpc_web_pb";
import { ApiCall } from "@/utils/api.js";
var content_proto = proto.io.codedrills.proto.content;
var builder_proto = proto.io.codedrills.proto.builder;
var judge_proto = proto.io.codedrills.proto.judge;

const builderService = new builder.ContentServicePromiseClient(
  process.env.VUE_APP_BUILDER_API_URL + "/builder",
  null,
  null
);
const judgeService = new judgeServiceProto.JudgeServicePromiseClient(
  process.env.VUE_APP_JUDGE_API_URL + "/judge",
  null,
  null
);

const state = {
  content: null,
  view: null,
  loading: null,
  code: null,
  submissionId: null,
  submissionStatus: null,
  judgingCompleted: false,
  contentJsonPath: null,
  attachment: null,
  input: null,
  output: null,
  getTestCaseStatus: 0,
};

const actions = {
  async fetchProblem({ commit, state, getters, dispatch }, { url }) {
    console.log("TRYING TO FETCH", url);
    commit("toggleLoading", true);
    commit("clearProblemState");
    commit("clearCode");

    return dispatch("user/ensureUserToken", null, { root: true }).then(
      (userToken) => {
        var getContentRequest = new builder_proto.GetContentRequest();
        var contentId = new content_proto.ContentId();
        var contentAddress = new content_proto.ContentAddress();
        contentAddress.setContentType(
          proto.io.codedrills.proto.content.ContentType.PROBLEM
        );
        contentAddress.setUrl(url);
        contentId.setContentAddress(contentAddress);
        getContentRequest.setContentId(contentId);

        return builderService
          .getContent(getContentRequest, { Authorization: userToken })
          .then((res) => {
            console.log("SUCCESS", res.toObject());
            commit("setProblem", res);
          })
          .catch((err) => {
            console.error("ERROR", err);
          })
          .finally(() => {
            commit("toggleLoading", false);
          });
      }
    );
  },

  // TODO: Move to common file
  async updateAttachment({ commit, state, getters, dispatch }, { attachment }) {
    return dispatch("user/ensureUserToken", null, { root: true }).then(
      async (userToken) => {
        var createAttachmentRequest =
          new builder_proto.CreateAttachmentRequest();
        createAttachmentRequest.setContentId(state.content.getId());
        createAttachmentRequest.setIsPublic(true);
        await attachment.arrayBuffer().then((arrayBuffer) => {
          createAttachmentRequest.setData(new Uint8Array(arrayBuffer));
        });
        createAttachmentRequest.setName(attachment.name);
        createAttachmentRequest.setType(attachment.type);

        console.log("Request", createAttachmentRequest);
        return builderService
          .createAttachment(createAttachmentRequest, {
            Authorization: userToken,
          })
          .then((res) => {
            console.log("SUCCESS", res.toObject());
            commit("setAttachment", res);
          })
          .catch((err) => {
            console.error("ERROR", err);
          });
      }
    );
  },

  async updateProblem(
    { commit, state, getters, dispatch },
    { testFiles, sample, deleteTestFiles, language, codeTemplate }
  ) {
    return dispatch("user/ensureUserToken", null, { root: true }).then(
      async (userToken) => {
        commit("deleteTestFiles", deleteTestFiles);
        var updateContentRequest = new builder_proto.UpdateContentRequest();
        updateContentRequest.setId(state.content.getId());
        updateContentRequest.setTitle(state.content.getTitle());
        updateContentRequest.setVersion(state.content.getVersion());
        updateContentRequest.setMetaData(state.content.getMetaData());
        updateContentRequest.setData(state.content.getData());
        updateContentRequest.setPublishType(state.content.getPublishType());

        if (language && codeTemplate) {
          console.log("Language", language);
          var uploadCodeTeamplateFile = new builder_proto.UploadFile();
          uploadCodeTeamplateFile.setFilename(language);
          await codeTemplate
            .text()
            .then((content) =>
              uploadCodeTeamplateFile.setContentString(content)
            );
          updateContentRequest.addCodeTemplateFiles(uploadCodeTeamplateFile);
        }

        if (testFiles && testFiles.length > 0) {
          for (var i = 0; i < testFiles.length; ++i) {
            var uploadFile = new builder_proto.UploadFile();
            uploadFile.setFilename(testFiles[i].name);
            await testFiles[i]
              .text()
              .then((content) => uploadFile.setContentString(content));
            if (sample) uploadFile.setTestsetId("sample");
            else uploadFile.setTestsetId("full");
            updateContentRequest.addTestFiles(uploadFile);
          }
        }

        console.log("REQUEST", updateContentRequest.toObject());

        return builderService
          .updateContent(updateContentRequest, { Authorization: userToken })
          .then((res) => {
            console.log("SUCCESS", res.toObject());
            commit("setProblem", res);
            return res;
          });
        // .catch((err) => {
        //   console.error("ERROR ...", err);
        //   return err;
        // });
      }
    );
  },

  async rejudge({ commit, state, getters, dispatch }, { min, max }) {
    return dispatch("user/ensureUserToken", null, { root: true }).then(
      (userToken) => {
        var rejudgeRequest = new judge_proto.master.RejudgeRequest();
        rejudgeRequest
          .setContentId(state.content.getId())
          .setMinSubmissionId(min)
          .setMaxSubmissionId(max);

        return judgeService
          .rejudge(rejudgeRequest, { Authorization: userToken })
          .then((res) => {
            console.log("SUCCESS", res.toObject());
          })
          .catch((err) => {
            console.log("ERROR", err);
          });
      }
    );
  },

  async fetchRejudge({ commit, state, getters, dispatch }) {
    return dispatch("user/ensureUserToken", null, { root: true }).then(
      (userToken) => {
        var fetchRejudgeRequest = new judge_proto.master.FetchRejudgeRequest();
        fetchRejudgeRequest.setContentId(state.content.getId());

        return judgeService
          .fetchRejudge(fetchRejudgeRequest, {
            Authorization: userToken,
          })
          .then((res) => {
            console.log("SUCCESS", res.toObject());
            commit("setRejudges", res.toObject());
          })
          .catch((err) => {
            console.log("ERROR", err);
          });
      }
    );
  },

  async submissionStatus({ commit, state, getters, dispatch }) {
    return dispatch("user/ensureUserToken", null, { root: true }).then(
      (userToken) => {
        var submissionStatusRequest =
          new judge_proto.master.SubmissionStatusRequest();
        submissionStatusRequest.setSubmissionId(state.submissionId);

        return judgeService
          .submissionStatus(submissionStatusRequest, {
            Authorization: userToken,
          })
          .then((res) => {
            console.log("SUCCESS", res.toObject());
            commit("setSubmissionStatus", res.toObject());
            if (state.submissionStatus.status < 10)
              setTimeout(() => dispatch("submissionStatus"), 2000);
            else commit("setJudgingCompleted", true);
          })
          .catch((err) => {
            console.log("ERROR", err);
            setTimeout(() => dispatch("submissionStatus"), 2000);
          })
          .catch((err) => {
            console.err("ERROR", err);
          });
      }
    );
  },

  async exportProblem({ commit, state, getters, dispatch }, { url }) {
    return dispatch("user/ensureUserToken", null, { root: true }).then(
      (userToken) => {
        console.log("export ut", userToken);
        var exportProblemRequest = new builder_proto.ExportProblemRequest();
        exportProblemRequest.setContentId(
          new content_proto.ContentId().setContentAddress(
            new content_proto.ContentAddress()
              .setContentType(content_proto.ContentType.PROBLEM)
              .setUrl(url)
          )
        );

        return builderService
          .exportProblem(exportProblemRequest, { Authorization: userToken })
          .then((res) => {
            console.log("SUCCESS", res.toObject());
            commit("setContentJsonPath", res.toObject().contentJsonPath);
          });
      }
    );
  },

  getTestCase: new ApiCall("getTestCase")
    .authRequired()
    .withServiceCall((r, h) => builderService.getTestCase(r, h))
    .withRequest(({ url, testSet, testId }) => {
      return new builder_proto.GetTestCaseRequest()
        .setContentId(
          new content_proto.ContentId().setContentAddress(
            new content_proto.ContentAddress()
              .setContentType(content_proto.ContentType.PROBLEM)
              .setUrl(url)
          )
        )
        .setTestSet(testSet)
        .setTestId(testId);
    })
    .onSuccess(({ commit }, res) => commit("setTestCase", res))
    .build(),

  setReturnType({ commit, getters }, returnType) {
    console.log("setReturnType", returnType, getters.argTypeMap[returnType]);
    commit("setReturnType", getters.argTypeMap[returnType]);
  },

  setArgs({ commit, getters }, args) {
    var argsToAdd = [];
    for (var i = 0; i < args.length; ++i) {
      if (args[i].delete) continue;
      var curArg = new judge_proto.Arg();
      curArg.setName(args[i].name);
      curArg.setType(getters.argTypeMap[args[i].type]);
      argsToAdd.push(curArg);
    }
    commit("setArgs", argsToAdd);
  },
};

const getters = {
  userToken: function (state, getters, rootState, rootGetters) {
    parameters.addValue("meta_data", content.getMetaData().toByteArray());
    console.log("rootGetters", rootGetters);
    return rootGetters["user/userToken"];
  },
  types: function () {
    return Object.keys(proto.io.codedrills.proto.content.ContentType);
  },
  statement: function (state) {
    return state.content.data.problem.statement;
  },

  problemType(state, getters) {
    return getters.problemTypeKeys[
      state.content.getMetaData().getProblemMeta().getProblemType()
    ];
  },

  problemTypeKeys() {
    return Object.keys(judge_proto.ProblemType);
  },

  submissionVisibilityKeys() {
    return Object.keys(content_proto.SubmissionVisibility);
  },

  submissionVisibilityType(state, getters) {
    return getters.submissionVisibilityKeys[
      state.content.getMetaData().getSubmissionVisibility()
    ];
  },
  ioType(state, getters) {
    return getters.ioTypeKeys[
      state.content.getMetaData().getProblemMeta().getIoType()
    ];
  },

  ioTypeKeys() {
    return Object.keys(judge_proto.IOType);
  },

  evaluationType(state, getters) {
    return getters.evaluationTypeKeys[
      state.content.getMetaData().getProblemMeta().getEvaluationType()
    ];
  },

  evaluationTypeKeys() {
    return Object.keys(judge_proto.EvaluationType);
  },
  argTypeMap() {
    var map = {};

    const primitiveTypes = ["Int32", "Int64", "Double", "String", "Boolean"];

    map["Int32"] = new judge_proto.ArgType().setPrimitiveType(
      judge_proto.PrimitiveType.INT32
    );
    map["Int64"] = new judge_proto.ArgType().setPrimitiveType(
      judge_proto.PrimitiveType.INT64
    );
    map["Double"] = new judge_proto.ArgType().setPrimitiveType(
      judge_proto.PrimitiveType.DOUBLE
    );
    map["String"] = new judge_proto.ArgType().setPrimitiveType(
      judge_proto.PrimitiveType.STRING
    );
    map["Boolean"] = new judge_proto.ArgType().setPrimitiveType(
      judge_proto.PrimitiveType.BOOLEAN
    );
    map["QuantumCircuit"] = new judge_proto.ArgType().setPrimitiveType(
      judge_proto.PrimitiveType.QUANTUM_CIRCUIT
    );

    // array types
    for (const primitiveType of primitiveTypes) {
      map[`Array<${primitiveType}>`] = new judge_proto.ArgType().setNestedType(
        new judge_proto.NestedType()
          .setOuterType(judge_proto.CompositeType.ARRAY)
          .setInnerTypesList([map[primitiveType]])
      );
    }

    // 2D array types
    for (const primitiveType of primitiveTypes) {
      map[`Array<Array<${primitiveType}>>`] =
        new judge_proto.ArgType().setNestedType(
          new judge_proto.NestedType()
            .setOuterType(judge_proto.CompositeType.ARRAY)
            .setInnerTypesList([map[`Array<${primitiveType}>`]])
        );
    }

    // List types
    for (const primitiveType of primitiveTypes) {
      map[`List<${primitiveType}>`] = new judge_proto.ArgType().setNestedType(
        new judge_proto.NestedType()
          .setOuterType(judge_proto.CompositeType.LIST)
          .setInnerTypesList([map[primitiveType]])
      );
    }

    // array of lists
    for (const primitiveType of primitiveTypes) {
      map[`Array<List<${primitiveType}>>`] =
        new judge_proto.ArgType().setNestedType(
          new judge_proto.NestedType()
            .setOuterType(judge_proto.CompositeType.ARRAY)
            .setInnerTypesList([map[`List<${primitiveType}>`]])
        );
    }

    // tree types
    for (const primitiveType of primitiveTypes) {
      map[`Tree<${primitiveType}>`] = new judge_proto.ArgType().setNestedType(
        new judge_proto.NestedType()
          .setOuterType(judge_proto.CompositeType.TREE)
          .setInnerTypesList([map[primitiveType]])
      );
    }

    // binary tree types
    for (const primitiveType of primitiveTypes) {
      map[`BinaryTree<${primitiveType}>`] =
        new judge_proto.ArgType().setNestedType(
          new judge_proto.NestedType()
            .setOuterType(judge_proto.CompositeType.BINARY_TREE)
            .setInnerTypesList([map[primitiveType]])
        );
    }

    console.log("map: ", map);
    console.log("keys: ", Object.keys(map));
    return map;
  },

  argTypeKeys(state, getters) {
    console.log("amap", Object.keys(getters.argTypeMap));
    return Object.keys(getters.argTypeMap);
  },

  publishType(state, getters) {
    return getters.publishTypeKeys[state.content.getPublishType()];
  },

  publishTypeKeys() {
    return Object.keys(content_proto.PublishType);
  },

  tagTypeKeys() {
    return Object.keys(judge_proto.TestTag);
  },
  languageKeys: () => {
    var ret = {};
    for (var key in proto.io.codedrills.proto.judge.Language) {
      ret[proto.io.codedrills.proto.judge.Language[key]] = key;
    }
    return ret;
  },
};

const mutations = {
  toggleLoading(state, content) {
    state.loading = content;
  },

  setProblem(state, response) {
    state.content = response.getContent();
    state.view = response.getContentView().getDataView();
  },

  setRejudges(state, response) {
    state.rejudges = response;
  },

  setStatement(state, statement) {
    state.content.getData().getProblem().setStatement(statement);
  },

  setSqlInit(state, sqlInit) {
    if (!state.content.getData().getProblem().getSqlInfo()) {
      state.content
        .getData()
        .getProblem()
        .setSqlInfo(new judge_proto.SqlInfo());
    }
    console.log(
      "Info ..",
      state.content.getData().getProblem().getSqlInfo().toObject()
    );
    state.content.getData().getProblem().getSqlInfo().setSqlInit(sqlInit);
  },

  setSubtaskSettings(state, subtaskGroup) {
    if (!state.content.getData().getProblem().getSubtaskSettings()) {
      state.content
        .getData()
        .getProblem()
        .setSubtaskSettings(new judge_proto.SubtaskSettings());
    }
    console.log(
      "Info ..",
      state.content.getData().getProblem().getSubtaskSettings().toObject()
    );
    console.log("SubtaskGroup", subtaskGroup);
    state.content
      .getData()
      .getProblem()
      .getSubtaskSettings()
      .addSubtaskGroups(subtaskGroup);

    console.log(
      "Problem is ..",
      state.content.getData().getProblem().toObject()
    );
  },
  updateSubtaskGroupsInSettings(state, subtaskGroups) {
    state.content
      .getData()
      .getProblem()
      .getSubtaskSettings()
      .setSubtaskGroupsList(subtaskGroups);
  },
  setLibraryInit(state, libraryInit) {
    if (!state.content.getData().getProblem().getLibraryInfo()) {
      state.content
        .getData()
        .getProblem()
        .setLibraryInfo(new judge_proto.LibraryInfo());
    }
    console.log(
      "Info ..",
      state.content.getData().getProblem().getLibraryInfo().toObject()
    );
    state.content
      .getData()
      .getProblem()
      .getLibraryInfo()
      .setLibraryInit(libraryInit);
  },

  setEditorial(state, editorial) {
    state.content.getData().getProblem().setEditorial(editorial);
  },

  setProblemView(state, response) {
    state.problemView = response.view.problemView;
    state.view = response.getContentView().getDataView().getProblemView();
  },

  setTitle(state, title) {
    state.content.setTitle(title);
  },

  setTags(state, tags) {
    state.content.getMetaData().setTagsList(tags);
  },

  setProblemType(state, type) {
    state.content
      .getMetaData()
      .getProblemMeta()
      .setProblemType(judge_proto.ProblemType[type]);
  },

  setIOType(state, type) {
    console.log("IO type ..", type);
    state.content
      .getMetaData()
      .getProblemMeta()
      .setIoType(judge_proto.IOType[type]);
  },
  setEvaluationType(state, type) {
    console.log("Evaluation type ..", type);
    state.content
      .getMetaData()
      .getProblemMeta()
      .setEvaluationType(judge_proto.EvaluationType[type]);
  },
  setSubmissionVisibility(state, visibilty) {
    state.content
      .getMetaData()
      .setSubmissionVisibility(content_proto.SubmissionVisibility[visibilty]);
  },
  setLanguageCodeTemplate(state, { language: language, template: template }) {
    console.log("meta is  ..", state.content.getMetaData().toObject());
    // check if code template map is present
    if (!state.content.getMetaData().getProblemMeta().getCodeTemplateMap()) {
      state.content
        .getMetaData()
        .getProblemMeta()
        .setCodeTemplateMap(new judge_proto.CodeTemplateMap());
    }
    console.log("Language ..", language);

    state.content
      .getMetaData()
      .getProblemMeta()
      .getCodeTemplateMap()
      .set(judge_proto.Language[language], template);
    console.log(
      "MetaData with template ..",
      state.content.getMetaData().toObject()
    );
  },
  setClassName(state, className) {
    if (!state.content.getMetaData().getProblemMeta().getWrappedIo())
      state.content
        .getMetaData()
        .getProblemMeta()
        .setWrappedIo(new judge_proto.WrappedIO());
    state.content
      .getMetaData()
      .getProblemMeta()
      .getWrappedIo()
      .setClassName(className);
  },

  setMethodName(state, methodName) {
    if (!state.content.getMetaData().getProblemMeta().getWrappedIo())
      state.content
        .getMetaData()
        .getProblemMeta()
        .setWrappedIo(new judge_proto.WrappedIO());
    if (
      state.content
        .getMetaData()
        .getProblemMeta()
        .getWrappedIo()
        .getMethodsList().length == 0
    )
      state.content
        .getMetaData()
        .getProblemMeta()
        .getWrappedIo()
        .addMethods(new judge_proto.MethodDef());
    state.content
      .getMetaData()
      .getProblemMeta()
      .getWrappedIo()
      .getMethodsList()[0]
      .setMethodName(methodName);
  },

  setReturnType(state, returnType) {
    console.log("WIO", new judge_proto.WrappedIO().toObject());
    if (!state.content.getMetaData().getProblemMeta().getWrappedIo())
      state.content
        .getMetaData()
        .getProblemMeta()
        .setWrappedIo(new judge_proto.WrappedIO());
    if (
      state.content
        .getMetaData()
        .getProblemMeta()
        .getWrappedIo()
        .getMethodsList().length == 0
    )
      state.content
        .getMetaData()
        .getProblemMeta()
        .getWrappedIo()
        .addMethods(new judge_proto.MethodDef());
    state.content
      .getMetaData()
      .getProblemMeta()
      .getWrappedIo()
      .getMethodsList()[0]
      .setReturnType(returnType);
  },

  appendPermissions(state, { ids, role }) {
    console.log("ids and role ..", ids, role);
    for (var id of ids) {
      state.content
        .getMetaData()
        .getPermissionsMap()
        .set(
          id,
          new content_proto.Permission().setAccess(content_proto.Access[role])
        );
    }
    console.log(
      "MetaData with permissions",
      state.content.getMetaData().toObject()
    );
  },
  removePermissions(state, { ids, role }) {
    console.log("ids and role ..", ids, role);
    for (var id of ids) {
      state.content.getMetaData().getPermissionsMap().del(id);
    }
    console.log(
      "MetaData with permissions",
      state.content.getMetaData().toObject()
    );
  },

  setArgs(state, args) {
    if (!state.content.getMetaData().getProblemMeta().getWrappedIo())
      state.content
        .getMetaData()
        .getProblemMeta()
        .setWrappedIo(new judge_proto.WrappedIO());
    if (
      state.content
        .getMetaData()
        .getProblemMeta()
        .getWrappedIo()
        .getMethodsList().length == 0
    )
      state.content
        .getMetaData()
        .getProblemMeta()
        .getWrappedIo()
        .addMethods(new judge_proto.MethodDef());
    state.content
      .getMetaData()
      .getProblemMeta()
      .getWrappedIo()
      .getMethodsList()[0]
      .setArgsList(args);
  },

  setTimeLimitType(state, timeLimit) {
    state.content
      .getMetaData()
      .getProblemMeta()
      .getConstraints()
      .setTimeLimitInMs(timeLimit);
  },

  setMemoryLimitInKb(state, memoryLimitInKb) {
    state.content
      .getMetaData()
      .getProblemMeta()
      .getConstraints()
      .setMemoryLimitInKb(memoryLimitInKb);
  },

  setSourceLimitInBytes(state, sourceLimitInBytes) {
    state.content
      .getMetaData()
      .getProblemMeta()
      .getConstraints()
      .setSourceLimitInBytes(sourceLimitInBytes);
  },

  setOutputLimitInKb(state, outputLimitInKb) {
    state.content
      .getMetaData()
      .getProblemMeta()
      .getConstraints()
      .setOutputLimitInKb(outputLimitInKb);
  },

  uploadTestFile(state, event) {
    console.log("FILE", event.target.files);
  },

  changePublishType(state, publishType) {
    console.log("Publish Type", publishType);
    state.content.setPublishType(content_proto.PublishType[publishType]);
  },

  setCode(state, code) {
    state.code = code;
  },

  setSubmissionId(state, submissionId) {
    state.submissionId = submissionId;
  },

  setSubmissionStatus(state, submissionStatus) {
    state.submissionStatus = submissionStatus;
  },

  setJudgingCompleted(state, judgingCompleted) {
    state.judgingCompleted = judgingCompleted;
  },

  setVerifier(state, newVerifier) {
    console.log("Verifier in js ", newVerifier);
    state.content
      .getData()
      .getProblem()
      .getJudgeSettings()
      .setVerifier(newVerifier);
  },

  setContentJsonPath(state, contentJsonPath) {
    state.contentJsonPath = contentJsonPath;
  },

  deleteTestFiles(state, deleteTestFiles) {
    console.log("delete", deleteTestFiles);
    var testSets = state.content.getData().getProblem().getTestSetsMap();

    testSets.forEach((testSet, testSetId) => {
      var testsList = testSet.getTestsList();
      console.log("Processing", testsList.length, testSetId);
      var newtests = [];
      for (var i = 0; i < testsList.length; ++i) {
        var test = testsList[i];
        console.log("Processing...", testSetId, i, test);
        if (!deleteTestFiles.get(testSetId + "$" + i)) {
          test.setId(newtests.length);
          newtests.push(test);
        }
      }
      console.log("Newtests", newtests.length, newtests);
      state.content
        .getData()
        .getProblem()
        .getTestSetsMap()
        .get(testSetId)
        .setTestsList(newtests);
    });
  },

  setTestTags(state, { setId, idx, tags }) {
    console.log(
      setId,
      idx,
      tags,
      state.content.getData().getProblem().getTestSetsMap()
    );
    state.content
      .getData()
      .getProblem()
      .getTestSetsMap()
      .get(setId)
      .getTestsList()
      [idx].setTagsList(tags.map((t) => judge_proto.TestTag[t]));
  },

  setSubTaskIndex(state, { setId, idx, subTaskIndex }) {
    console.log("setSubTaskIndex", setId, idx, subTaskIndex);
    console.log(
      "setSubTaskIndex .... test map is ..",
      state.content
        .getData()
        .getProblem()
        .getTestSetsMap()
        .get(setId)
        .getTestsList()[idx]
    );
    state.content
      .getData()
      .getProblem()
      .getTestSetsMap()
      .get(setId)
      .getTestsList()
      [idx].setSubtaskIndexList(subTaskIndex);
  },

  removeTestTag(state, { setId, idx, tag }) {
    console.log("removeTestTag", setId, idx, tag);
    console.log(
      "removeTestTag .... test map is ..",
      state.content.getData().getProblem().getTestSetsMap()
    );
    var tagsList = state.content
      .getData()
      .getProblem()
      .getTestSetsMap()
      .get(setId)
      .getTestsList()
      [idx].getTagsList();

    const index = tagsList.indexOf(judge_proto.TestTag[tag]);
    if (index >= 0) tagsList.splice(index, 1);
    state.content
      .getData()
      .getProblem()
      .getTestSetsMap()
      .get(setId)
      .getTestsList()
      [idx].setTagsList(tagsList);
  },

  clearProblemState(state) {
    state.content = null;
    state.view = null;
  },

  clearSubmissionState(state) {
    state.submission = null;
    state.submissionStatus = null;
    state.submissionId = null;
    state.judgingCompleted = false;
  },
  clearCode(state) {
    state.code = null;
  },
  setAttachment(state, res) {
    state.attachment = res.getAttachment();
  },
  setTestCase(state, res) {
    state.input = res.getInput();
    state.output = res.getOutput();
  },
  getTestCaseStatus(state, status) {
    state.getTestCaseStatus = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
