<template>
  <span>
    <v-row v-if="!user">
      <v-col class="pt-2 pb-1">
        Please <router-link to="/login"> login </router-link> to submit.
      </v-col>
    </v-row>
    <v-row class="px-2">
      <v-col>
        <Editor
          v-model="code"
          @setLanguage="setLanguage"
          @resetCode="removeOldCode"
        />
      </v-col>
    </v-row>
    <v-row v-if="user">
      <v-col>
        <v-btn class="mr-2" color="accent" height="35" @click="openFileInput"
          >Load File</v-btn
        >
        <input
          v-show="false"
          type="file"
          ref="codeFileInput"
          accept=".c,.c99,.cxx,.cpp,.h,.java,.py,.txt,text/*"
          @change="loadFile"
        />
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="pt-0 pb-1" align="end">
        <v-btn
          class="mr-2"
          :disabled="submitting"
          color="accent"
          height="35"
          @click="testRun"
          >Test Run</v-btn
        >
        <v-btn
          :disabled="submitting"
          color="accent"
          height="35"
          @click="fullSubmit"
          >Submit</v-btn
        >
      </v-col>
    </v-row>
    <v-row v-else>
      <v-spacer></v-spacer>
      <v-col :cols="2" class="pt-0 pb-1">
        Please <router-link to="/login"> login </router-link> to submit.
      </v-col>
    </v-row>

    <v-row ref="judgingStatus">
      <v-col>
        <div v-if="submissionId">
          <submission-status
            v-if="submissionStatus && submissionStatus.getStatus() == 0"
            title="Waiting..."
            subtitle="Waiting in the queue for your turn. Please be patient."
            imageUrl="judgingImg.svg"
          >
          </submission-status>

          <submission-status
            v-else-if="!judgingCompleted"
            title="Judging..."
            :subtitle="'We are processing your submission. ' + testsProgress"
            imageUrl="judgingImg.svg"
          >
          </submission-status>

          <submission-status
            v-else-if="
              judgingCompleted &&
              submissionStatus.getStatus() == 13 &&
              submissionStatus.getSubmissionType() == 3
            "
            :title="submissionStatusTitle(submissionStatus)"
            :subtitle="scoreSubtitle(submissionStatus)"
            :imageUrl="randomCorrectImage()"
            :status="submissionStatus && submissionStatus.getStatus()"
          >
            <v-row>
              <v-col :cols="6" :md="3" :lg="2">
                <stats-col title="Time Taken">
                  {{ submissionStatus.getTimeTaken() }} ms
                </stats-col>
              </v-col>
              <v-col :cols="6" :md="3" :lg="2">
                <stats-col title="Memory Taken">
                  {{ submissionStatus.getMemoryTaken() }} KiB
                </stats-col>
              </v-col>
              <v-spacer> </v-spacer>
            </v-row>
            <v-row v-if="submissionStatus.getSubtasks()" class="mt-5">
              <v-col>
                Subtasks
                <v-data-table
                  :headers="subtasksHeaders"
                  :items="subtaskList"
                  class="elevation-1"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.getSubtaskId() }}</td>
                      <td v-bind:class="statusClass(item.getStatus())">
                        {{ statusTitle(item.getStatus()) }}
                      </td>
                      <td>{{ 100 * item.getScore() }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </submission-status>

          <submission-status
            v-else-if="
              judgingCompleted &&
              submissionStatus.getStatus() == 13 &&
              submissionStatus.getSubmissionType() == 1
            "
            title="Samples Passed"
            subtitle="Great! Submit to run against full test set"
            :imageUrl="randomCorrectImage()"
            :status="submissionStatus && submissionStatus.getStatus()"
          >
            <v-row>
              <v-col :cols="6" :md="3" :lg="2">
                <stats-col title="Time Taken">
                  {{ submissionStatus.getTimeTaken() }} ms
                </stats-col>
              </v-col>
              <v-col :cols="6" :md="3" :lg="2">
                <stats-col title="Memory Taken">
                  {{ submissionStatus.getMemoryTaken() }} KiB
                </stats-col>
              </v-col>
              <v-spacer> </v-spacer>
              <v-col :cols="6" :md="4" :lg="3" align="end">
                <v-btn
                  :disabled="submitting"
                  color="accent"
                  height="35"
                  @click="fullSubmit"
                  >Submit</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                <h3 class="green--text">SubmissionId:</h3>
              </v-col>
              <v-col cols="1">
                {{ submissionStatus.getSubmissionId() }}
              </v-col>
            </v-row>
            <tests-results
              :resultViews="submissionStatus.getSampleResultViewsList()"
            ></tests-results>
          </submission-status>

          <submission-status
            v-else
            :title="statusTitle(submissionStatus.getStatus())"
            :subtitle="statusSubtitle(submissionStatus)"
            imageUrl="Error.svg"
          >
            <text-block v-if="submissionStatus.getResult()">
              <pre
                >{{ submissionStatus && submissionStatus.getResult() }}
            </pre
              >
            </text-block>
            <tests-results
              v-else-if="submissionStatus.getSampleResultViewsList()"
              :resultViews="submissionStatus.getSampleResultViewsList()"
            ></tests-results>
          </submission-status>
        </div>
      </v-col>
    </v-row>
  </span>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import Editor from "../components/Editor";
import StatsCol from "../components/StatsCol";
import SubmissionStatus from "../components/SubmissionStatus";
import TestsResults from "./TestsResults.vue";
import TextBlock from "./TextBlock.vue";
import {
  statusTitle,
  statusClass,
  isFullyCorrect,
  submissionStatusTitle,
  submissionScoreString,
} from "../utils/status.js";

import judge from "codedrills_proto/io/codedrills/proto/judge/judge_service_grpc_web_pb";
const Status = proto.io.codedrills.proto.judge.Status;

export default {
  props: {
    contentView: {
      type: Object,
      required: true,
    },
    parentPreview: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      code: null,
      codeFile: null,
      language: null,
      subtasksHeaders: [
        { text: "Subtask ID" },
        { text: "Status" },
        { text: "Score" },
      ],
    };
  },
  name: "codingArea",
  computed: {
    ...mapState("user", ["hasPremiumAccess", "editorConfig", "user"]),
    ...mapGetters("judge", [
      "submissionStatus",
      "judgingCompleted",
      "statusKeys",
      "submitStatus",
      "submitted",
      "submitting",
    ]),
    ...mapState("judge", ["submissionId", "submissionStatus", "codeTemplate"]),
    subtaskList() {
      if (this.submissionStatus) {
        console.log(
          "subtaskList",
          this.submissionStatus.getSubtasks()?.getSubtaskResultsList()
        );
        return this.submissionStatus.getSubtasks()?.getSubtaskResultsList();
      }
      return [];
    },
    urlPrefix() {
      if (this.parentPreview) {
        return (
          "/" +
          this.parentPreview.getContentType().toLowerCase() +
          "s/" +
          this.parentPreview.getUrl()
        );
      }
      return null;
    },
    parentId() {
      if (this.parentPreview) {
        return this.parentPreview.getId();
      }
      return null;
    },
    testsProgress() {
      if (!this.submissionStatus || this.submissionStatus.getTotalTests() == 0)
        return "";
      return (
        "" +
        this.submissionStatus.getProcessedTests() +
        "/" +
        this.submissionStatus.getTotalTests() +
        " tests processed."
      );
    },
  },
  methods: {
    ...mapActions("judge", ["submit", "fetchCodeTemplate"]),
    ...mapMutations("judge", ["clearSubmission"]),
    statusClass,
    statusTitle,
    submissionStatusTitle,
    fullSubmit() {
      this.submitCode(true);
    },
    testRun() {
      this.submitCode(false);
    },
    submitCode(isFull) {
      console.log("Contest id", this.parentId);
      this.clearSubmission();
      this.submit({
        id: this.contentView.getId(),
        version: this.contentView.getVersion(),
        code: this.code,
        language: this.language,
        isFull: isFull,
        parentId: this.parentId,
      }).then((__) =>
        this.$nextTick(() => {
          console.log(this.$refs);
          this.$vuetify.goTo(this.$refs.judgingStatus);
        })
      );
    },
    autopick() {
      var url = (this.urlPrefix || "/") + "?autopick=true";
      this.$router.push(url);
    },
    statusSubtitle(submissionStatus) {
      var status = submissionStatus.getStatus();
      if (status == Status.COMPILE_ERROR)
        return "Your solution did not compile";
      if (status == Status.CORRECT_ANSWER)
        return "Congratulations, your solution passed";
      if (11 <= status && status <= 16) {
        if (submissionStatus.getFirstIncorrectTestSet() != "") {
          return (
            "When running test #" + submissionStatus.getFirstIncorrectTestId()
          );
        } else {
          return "There was an error when running one of the tests";
        }
      }
      if (status == Status.JUDGE_ERROR)
        return "Something went wrong when running your solution. Please report to hello@codedrills.io";
      return "";
    },
    scoreSubtitle(submissionStatus) {
      if (isFullyCorrect(submissionStatus))
        return "Whoaa! You solved this challenge.";

      return (
        "Score: " + submissionScoreString(submissionStatus, this.subtaskList)
      );
    },
    randomCorrectImage() {
      return ["Congratulations.svg", "Cheers.svg", "Party.svg"][
        Math.floor(Math.random() * 3)
      ];
    },
    loadFile() {
      var file = this.$refs.codeFileInput.files.item(0);
      var ctx = this;
      file.text().then((code) => {
        this.code = code;
        this.codeFile = null;
      });
    },
    openFileInput() {
      this.$refs.codeFileInput.click();
    },
    setLanguage(language) {
      this.language = language;
      this.loadTemplateOrLocalCode();
    },
    loadTemplateOrLocalCode() {
      if (this.user) {
        var key =
          this.user.uid + "-" + this.language + "-" + this.contentView.getId();
        if (localStorage.getItem(key)) {
          var val = JSON.parse(localStorage.getItem(key));
          const now = Date.now();
          if (now > val.ttl) {
            localStorage.removeItem(key);
            this.loadTemplate();
          } else {
            this.code = val.code;
          }
        } else {
          this.loadTemplate();
        }
      } else {
        this.loadTemplate();
      }
    },
    removeOldCode() {
      if (this.user) {
        var key =
          this.user.uid + "-" + this.language + "-" + this.contentView.getId();
        if (localStorage.getItem(key)) {
          console.log("Removing old code");
          localStorage.removeItem(key);
        }
      }
      this.loadTemplate();
    },
    loadTemplate() {
      console.log("CV", this.contentView.toObject());
      this.fetchCodeTemplate({
        problemId: this.contentView.getId(),
        version: this.contentView.getVersion(),
        language: this.language,
      }).then((__) => {
        this.code = this.codeTemplate;
      });
    },
  },
  components: {
    Editor,
    StatsCol,
    SubmissionStatus,
    TestsResults,
    TextBlock,
  },
  mounted() {
    this.clearSubmission();
    this.loadTemplateOrLocalCode();
  },
  watch: {
    code: {
      handler() {
        console.log("Code Changed !");
        const now = Date.now();
        var expiry = now + 1 * 24 * 60 * 60 * 1000;
        if (this.user) {
          localStorage.setItem(
            this.user.uid +
              "-" +
              this.language +
              "-" +
              this.contentView.getId(),
            JSON.stringify({
              code: this.code,
              language: this.language,
              ttl: expiry,
            })
          );
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped></style>
