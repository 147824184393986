import store from "../store";

function authChange(user) {
  console.log("---Auth change", user);
  store.commit("user/initialized");
  if (user) {
    store.dispatch("user/userLoggedIn", { user: user });
    store.dispatch("user/register");
  } else {
    store.dispatch("user/userLoggedOut");
  }
}

function signInSuccess(authResult) {
  console.log("New user?", authResult.additionalUserInfo.isNewUser);
  if (authResult.additionalUserInfo.isNewUser) {
    console.log("New signin");
    authResult.user.sendEmailVerification();
    store.dispatch("notifs/addNotif", {
      text: "Please check your email to verify your account",
      type: "success",
    });
  }

  return true;
}

export default {
  authChange,
  signInSuccess,
};
