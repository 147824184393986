var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('v-row', [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": 4
    }
  }, [_c('v-text-field', {
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Search (title, tags)",
      "clearable": "",
      "single-line": "",
      "outlined": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)], 1), _c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.contents,
      "disable-pagination": "",
      "search": _vm.search
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('tr', [_c('td', [_c('router-link', {
          staticClass: "ml-1",
          attrs: {
            "to": _vm.getUrl(item)
          }
        }, [_vm._v(" " + _vm._s(item.title) + " ")])], 1), _c('td', {
          attrs: {
            "colspan": 3
          }
        }, _vm._l(item.metaData.tagsList, function (t) {
          return _c('v-chip', {
            key: t,
            staticClass: "mr-2",
            attrs: {
              "outlined": "",
              "color": "grey lighten-2",
              "text-color": "grey",
              "label": ""
            },
            on: {
              "click": _vm.searchTag
            }
          }, [_vm._v(" " + _vm._s(t) + " ")]);
        }), 1)])];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }