/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import builder from "codedrills_proto/io/codedrills/proto/builder/content_service_grpc_web_pb";
import { ApiCall } from "../../utils/api";
// import { version } from "core-js";
var content_proto = proto.io.codedrills.proto.content;
var builder_proto = proto.io.codedrills.proto.builder;

const builderService = new builder.ContentServicePromiseClient(
  process.env.VUE_APP_BUILDER_API_URL + "/builder",
  null,
  null
);

const state = {
  content: null,
  view: null,
  loading: null,
  fetchMcqStatus: null,
  updateMcqStatus: null,
  attachment: null,
};

const actions = {
  fetchMcq: new ApiCall("fetchMcq")
    .withServiceCall((r, h) => builderService.getContent(r, h))
    .withRequest(({ url }) => {
      var getContentRequest = new builder_proto.GetContentRequest();
      var contentId = new content_proto.ContentId();
      var contentAddress = new content_proto.ContentAddress();
      contentAddress.setContentType(
        proto.io.codedrills.proto.content.ContentType.MCQ
      );
      contentAddress.setUrl(url);
      contentId.setContentAddress(contentAddress);
      getContentRequest.setContentId(contentId);

      return getContentRequest;
    })
    .onSuccess(({ commit }, res) => {
      commit("setMcq", res);
      console.log(`setMcq:onSuccess: `, res.toObject());
    })
    .build(),
  updateMcq: new ApiCall("updateMcq")
    .withServiceCall((r, h) => builderService.updateContent(r, h))
    .withRequest(({ autoPublish }) => {
      console.log(
        `updateMcq with: `,
        state.content.getData().getMcq().toObject()
      );
      return new builder_proto.UpdateContentRequest()
        .setId(state.content.getId())
        .setTitle(state.content.getTitle())
        .setVersion(state.content.getVersion())
        .setMetaData(state.content.getMetaData())
        .setData(state.content.getData())
        .setPublishType(state.content.getPublishType())
        .setContentType(state.content.getContentType())
        .setAutoPublish(autoPublish);
    })
    .onSuccess(({ commit }, res) => {
      commit("setMcq", res);
    })
    .build(),

  async updateAttachment({ commit, state, getters, dispatch }, { attachment }) {
    return dispatch("user/ensureUserToken", null, { root: true }).then(
      async (userToken) => {
        var createAttachmentRequest =
          new builder_proto.CreateAttachmentRequest();
        createAttachmentRequest.setContentId(state.content.getId());
        createAttachmentRequest.setIsPublic(true);
        await attachment.arrayBuffer().then((arrayBuffer) => {
          createAttachmentRequest.setData(new Uint8Array(arrayBuffer));
        });
        createAttachmentRequest.setName(attachment.name);
        createAttachmentRequest.setType(attachment.type);

        console.log("Request", createAttachmentRequest);
        return builderService
          .createAttachment(createAttachmentRequest, {
            Authorization: userToken,
          })
          .then((res) => {
            console.log("SUCCESS", res.toObject());
            commit("setAttachment", res);
          })
          .catch((err) => {
            console.error("ERROR", err);
          });
      }
    );
  },
};

const getters = {
  contentTypes: function () {
    return Object.keys(proto.io.codedrills.proto.content.ContentType);
  },
  statement: function (state) {
    return state.content.data.problem.statement;
  },
  publishType(state, getters) {
    return getters.publishTypeKeys[state.content.getPublishType()];
  },
  publishTypeKeys() {
    return Object.keys(content_proto.PublishType);
  },
  mcqTypeKeys() {
    return Object.keys(content_proto.McqType);
  },
  mcqType(state, getters) {
    return getters.mcqTypeKeys[
      state.content.getMetaData().getMcqMeta().getMcqType()
    ];
  },
  getMcqOptions(state) {
    return state.content.getData().getMcq().getOptionList();
  },
  getMcqOptionAnswers(state) {
    return state.content.getData().getMcq().getAnswerIdList();
  },
  getMcqTimeLimit(state) {
    return state.content.getMetaData()?.getTimeLimit() || 0;
  },
};

const mutations = {
  toggleLoading(state, content) {
    state.loading = content;
  },

  setMcq(state, res) {
    state.content = res.getContent();
    state.view = res.getContentView();
    // setting default metaData
    if (!state.content.getMetaData().getMcqMeta()) {
      state.content
        .getMetaData()
        .setMcqMeta(
          new content_proto.McqMeta().setMcqType(
            content_proto.McqType.MCQ_SINGLE_ANSWER
          )
        );
    }
  },
  addMcqOption(state) {
    const numOfOptions =
      state.content.getData().getMcq().getOptionList().length || 0;
    const newOption = new content_proto.ObjectiveOption();
    // should be ideally uuid
    newOption.setId(`${numOfOptions + 1}`);
    newOption.setStatement(`Option ${numOfOptions + 1}`);
    state.content
      .getData()
      .getMcq()
      .setOptionList([
        ...state.content.getData().getMcq().getOptionList(),
        newOption,
      ]);
    console.log(
      `Option List: `,
      state.content.getData().getMcq().toObject().optionList
    );
  },
  setMcqOptionAsAnswer(state, { option, toAdd }) {
    // console.log(`test g: `, state);
    let existingAnsList = state.content.getData().getMcq().getAnswerIdList();
    let updatedAnsList;
    if (toAdd) {
      // if to add (ie, true comes)
      // just add the ID along with existing ones
      // then return
      if (!existingAnsList.includes(option.getId()))
        updatedAnsList = [...existingAnsList, option.getId()];
    } else {
      // delete the option.id from answer list
      updatedAnsList = existingAnsList.filter((v) => v !== option.getId());
    }
    state.content.getData().getMcq().setAnswerIdList(updatedAnsList);

    console.log(
      `setMcqOptionAsAnswer: `,
      state.content.getData().getMcq().getAnswerIdList()
    );
  },
  deleteMcqOption(state, delOpt) {
    // delete mcq answer id from list
    state.content
      .getData()
      .getMcq()
      .setAnswerIdList(
        state.content
          .getData()
          .getMcq()
          .getAnswerIdList()
          .filter((v) => v !== delOpt.getId())
      );
    // delete mcq option from list
    state.content
      .getData()
      .getMcq()
      .setOptionList(
        state.content
          .getData()
          .getMcq()
          .getOptionList()
          .filter((v) => v.getId() !== delOpt.getId())
      );
    console.log(
      `Option List: `,
      state.content.getData().getMcq().toObject().optionList
    );
    console.log(
      `Answer List: `,
      state.content.getData().getMcq().toObject().answerIdList
    );
  },
  updateMcqOption(state, { option, statement }) {
    state.content
      .getData()
      .getMcq()
      .getOptionList()
      .forEach((v) => {
        if (v.getId() === option.getId()) {
          v.setStatement(statement);
        }
      });
    console.log(
      `updateMcqOption: `,
      state.content.getData().getMcq().toObject()
    );
    // id is fixed, update statement
  },
  updateMcqStatus(state, status) {
    state.updateMcqStatus = status;
  },
  fetchMcqStatus(state, status) {
    state.fetchMcqStatus = status;
  },
  setMcqType(state, typ) {
    state.content
      .getMetaData()
      .getMcqMeta()
      .setMcqType(content_proto.McqType[typ]);
    console.log(
      `setMcqType: `,
      typ,
      state.content.getMetaData().getMcqMeta().toObject()
    );
  },

  setTitle(state, title) {
    state.content.setTitle(title);
  },

  setStatement(state, statement) {
    state.content.getData().getMcq().setStatement(statement);
  },

  changePublishType(state, publishType) {
    console.log("Publish Type", publishType);
    state.content.setPublishType(content_proto.PublishType[publishType]);
  },

  setPermissions(state, ids) {
    console.log("ids", ids);
    state.content.getMetaData().getPermissionsMap().clear();
    for (var id of ids) {
      state.content
        .getMetaData()
        .getPermissionsMap()
        .set(
          id,
          new content_proto.Permission().setAccess(content_proto.Access.AUTHOR)
        );
    }
    console.log(
      "MetaData with permissions on skill",
      state.content.getMetaData().toObject()
    );
  },

  setTags(state, tags) {
    state.content.getMetaData().setTagsList(tags);
  },

  setMcqTimeLimit(state, value) {
    state.content.getMetaData().setTimeLimit(value);
  },

  setAttachment(state, res) {
    state.attachment = res.getAttachment();
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
