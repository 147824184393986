<template>
  <div>
    <v-row>
      <v-col>
        <v-text-field single line label="Name" v-model="name"></v-text-field>
      </v-col>
      <v-col>
        <v-select
          label="Attribute type"
          :items="customAttributeKeys"
          v-model="customAttributeType"
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          label="Field Presence"
          :items="presenceKeys"
          v-model="fieldPresence"
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          label="Auto Complete Settings"
          :items="[
            'city',
            'coachDesignation',
            'collegeYear',
            'country',
            'gender',
            'institutionType',
            'school',
            'schoolGrade',
            'state',
            'source',
            'yesNo',
            'amritaCampus',
          ]"
          v-model="autoCompleteSetting"
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          label="Custom Value Allowed"
          :items="[true, false]"
          v-model="customValueAllowed"
        ></v-select>
      </v-col>
      <v-col>
        <v-text-field
          single
          line
          label="Order index"
          v-model="orderIndex"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          label="Condition field name"
          :items="fieldNames"
          v-model="fieldName"
        ></v-select>
      </v-col>
      <v-col>
        <v-text-field
          single
          line
          label="Field value"
          v-model="fieldValue"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          single
          line
          label="Help text"
          v-model="helpText"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-btn color="accent" @click="addAttribute"> Add</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table :headers="headers" :items="customAttributeValues">
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.name }}</td>
              <td>{{ customAttributeKeys[item.customAttributeType] }}</td>
              <td>{{ presenceKeys[item.customAttributePresence] }}</td>
              <td>{{ settings(item) }}</td>
              <td>{{ item.customValueAllowed }}</td>
              <td>{{ item.orderIndex }}</td>
              <td>{{ buildCondition(item.presenceCondition) }}</td>
              <td>{{ item.helpText }}</td>
              <td>
                <v-btn icon color="accent" @click="removeAttribute(item.name)"
                  ><v-icon> {{ mdiDelete }} </v-icon></v-btn
                >
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getters, mapActions, mapState, mapMutations, mapGetters } from "vuex";
import { mdiDelete, mdiArrowUp, mdiArrowDown } from "@mdi/js";
export default {
  props: {
    customAttributesMap: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {
      mdiDelete: mdiDelete,
      mdiArrowUp: mdiArrowUp,
      mdiArrowDown: mdiArrowDown,
      name: null,
      customAttributeType: null,
      autoCompleteSetting: null,
      fieldPresence: null,
      customValueAllowed: false,
      fieldName: null,
      fieldValue: null,
      helpText: null,
      orderIndex: 0,
      headers: [
        {
          text: "Name",
        },
        {
          text: "Type",
        },
        {
          text: "Presence",
        },
        {
          text: "Auto Complete Setting",
        },
        {
          text: "Custom Value Allowed",
        },
        {
          text: "Order index",
        },
        {
          text: "Condition",
        },
        {
          text: "Help text",
        },
      ],
    };
  },
  methods: {
    addAttribute() {
      console.log(
        "updating attribute",
        this.name,
        this.customAttributeType,
        this.autoCompleteSetting,
        this.fieldPresence
      );
      this.$emit("add", {
        name: this.name,
        type: this.customAttributeType,
        setting: this.autoCompleteSetting,
        presence: this.fieldPresence,
        customValueAllowed: this.customValueAllowed,
        orderIndex: this.orderIndex,
        conditionFieldName: this.fieldName,
        conditionFieldValue: this.fieldValue,
        helpText: this.helpText,
      });
      this.name = null;
      this.customAttributeType = null;
      this.autoCompleteSetting = null;
      this.fieldPresence = null;
      this.customValueAllowed = false;
      this.orderIndex = 0;
      this.fieldName = null;
      this.fieldValue = null;
      this.helpText = null;
    },
    removeAttribute(name) {
      // customAttributes.delete(name);
      console.log("removing ...", name);
      this.$emit("remove", name);
    },
    settings(item) {
      if (item.autoCompleteSetting) return item.autoCompleteSetting.valueList;
    },
    buildCondition(presenceCondition) {
      const termList = presenceCondition?.presenceTermList;
      return termList && termList.length > 0
        ? 'customAttribute["' +
            termList[0].fieldName +
            '"] == "' +
            termList[0].fieldValue
        : "";
    },
  },
  computed: {
    ...mapGetters("contest", ["presenceKeys", "customAttributeKeys"]),

    customAttributeValues() {
      return this.customAttributesMap;
    },
    fieldNames() {
      return this.customAttributesMap.map((item) => item.name);
    },
  },
};
</script>
