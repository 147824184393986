var render = function render(){
  var _vm$customer, _vm$customer2, _vm$customer3, _vm$customer4, _vm$customer4$getEmai, _vm$customer5, _vm$customer6, _vm$customer7;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('h1', [_vm._v(_vm._s(_vm.customer && (((_vm$customer = _vm.customer) === null || _vm$customer === void 0 ? void 0 : _vm$customer.getName()) || ((_vm$customer2 = _vm.customer) === null || _vm$customer2 === void 0 ? void 0 : _vm$customer2.getId()))))]), _c('p', [_vm._v("Total usage From Signup")]), _c('p', [_vm._v("Domain: " + _vm._s((_vm$customer3 = _vm.customer) === null || _vm$customer3 === void 0 ? void 0 : _vm$customer3.getCompanyDomain()))]), _c('p', [_vm._v("Add-Ons: " + _vm._s(_vm.addOnsDisplayString))]), _c('p', [_vm._v("Email Domains: " + _vm._s((_vm$customer4 = _vm.customer) === null || _vm$customer4 === void 0 ? void 0 : (_vm$customer4$getEmai = _vm$customer4.getEmailDomainsList()) === null || _vm$customer4$getEmai === void 0 ? void 0 : _vm$customer4$getEmai.join(", ")))]), _c('p', [_vm._v("Hiring Plan: " + _vm._s(_vm.planTypeKeys[(_vm$customer5 = _vm.customer) === null || _vm$customer5 === void 0 ? void 0 : _vm$customer5.getHiringPlan()]))]), _c('p', [_vm._v("Invite Sent Count: " + _vm._s((_vm$customer6 = _vm.customer) === null || _vm$customer6 === void 0 ? void 0 : _vm$customer6.getInviteCount()))]), _c('p', [_vm._v("Test Created Count: " + _vm._s((_vm$customer7 = _vm.customer) === null || _vm$customer7 === void 0 ? void 0 : _vm$customer7.getTestCount()))])]), _c('div', [_c('h3', [_vm._v("Select Range to check usage for that duration")]), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Exclude Domains(comma-separated)",
      "placeholder": "Enter domains to exclude(comma-separated)",
      "small-chips": "",
      "chips": "",
      "clearable": "",
      "rules": [_vm.rules.required]
    },
    on: {
      "input": _vm.handleInput
    },
    model: {
      value: _vm.excludeDomains,
      callback: function ($$v) {
        _vm.excludeDomains = $$v;
      },
      expression: "excludeDomains"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "nudge-right": 40,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": "Start Date",
            "prepend-icon": "mdi-calendar",
            "readonly": ""
          },
          model: {
            value: _vm.startDate,
            callback: function ($$v) {
              _vm.startDate = $$v;
            },
            expression: "startDate"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }])
  }, [_c('v-date-picker', {
    on: {
      "input": function ($event) {
        _vm.startMenu = false;
      }
    },
    model: {
      value: _vm.startDate,
      callback: function ($$v) {
        _vm.startDate = $$v;
      },
      expression: "startDate"
    }
  })], 1)], 1), _c('v-col', [_c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "nudge-right": 40,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": "End Date",
            "prepend-icon": "mdi-calendar",
            "readonly": ""
          },
          model: {
            value: _vm.endDate,
            callback: function ($$v) {
              _vm.endDate = $$v;
            },
            expression: "endDate"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }])
  }, [_c('v-date-picker', {
    on: {
      "input": function ($event) {
        _vm.endMenu = false;
      }
    },
    model: {
      value: _vm.endDate,
      callback: function ($$v) {
        _vm.endDate = $$v;
      },
      expression: "endDate"
    }
  })], 1)], 1), _c('v-col', [_c('v-btn', {
    attrs: {
      "color": "primary",
      "disabled": !_vm.startDate || !_vm.endDate
    },
    on: {
      "click": _vm.fetchCustomerUsage
    }
  }, [_vm._v("Fetch Usage")])], 1)], 1), _c('div', [_c('v-btn', {
    attrs: {
      "color": "primary",
      "disabled": !_vm.customerUsage
    },
    on: {
      "click": _vm.downloadCSV
    }
  }, [_vm._v("Download selected as CSV")])], 1), _vm.customerUsage ? _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Search by Name",
      "prepend-icon": "mdi-magnify"
    },
    model: {
      value: _vm.searchQuery,
      callback: function ($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  })], 1)], 1) : _vm._e(), _vm.customerUsage ? _c('div', [_c('v-row', [_c('v-col', [_c('v-alert', {
    attrs: {
      "type": "info",
      "dense": ""
    }
  }, [_c('p', [_vm._v(" Total Candidates Invited: " + _vm._s(_vm.customerUsage.getTotalCandidatesInvited()) + " ")]), _c('p', [_vm._v(" Total Candidates Completed: " + _vm._s(_vm.customerUsage.getTotalCandidatesCompleted()) + " ")]), _c('p', [_vm._v(" Total Candidates Deleted: " + _vm._s(_vm.customerUsage.getTotalCandidatesDeleted()) + " ")])])], 1)], 1), _c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.statusOptions,
      "label": "Filter by Status",
      "clearable": ""
    },
    model: {
      value: _vm.selectedStatus,
      callback: function ($$v) {
        _vm.selectedStatus = $$v;
      },
      expression: "selectedStatus"
    }
  }), _c('v-select', {
    attrs: {
      "items": _vm.stageOptions,
      "label": "Filter by Stage",
      "clearable": ""
    },
    model: {
      value: _vm.selectedStage,
      callback: function ($$v) {
        _vm.selectedStage = $$v;
      },
      expression: "selectedStage"
    }
  }), _c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.tableHeaders,
      "items": _vm.filteredInviteSummaries,
      "items-per-page": 5
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.getName()) + " ")];
      }
    }, {
      key: "item.email",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.getEmail()) + " ")];
      }
    }, {
      key: "item.finishTime",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(_vm.formatDate(item.getFinishTime())) + " ")];
      }
    }, {
      key: "item.inviteTime",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(_vm.formatDate(item.getInviteTime())) + " ")];
      }
    }, {
      key: "item.stage",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(_vm.testStages(item.getStage())) + " ")];
      }
    }, {
      key: "item.testStatus",
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_vm._v(" " + _vm._s(_vm.getstatus(item.getTestStatus())) + " ")];
      }
    }], null, false, 431312490)
  })], 1)], 1)], 1) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }