var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.resultViews ? _c('v-expansion-panels', {
    staticClass: "mb-2",
    attrs: {
      "multiple": ""
    }
  }, _vm._l(_vm.resultViews, function (rv) {
    return _c('v-expansion-panel', {
      key: rv.getId()
    }, [_c('v-expansion-panel-header', [_vm._v(" Test #" + _vm._s(rv.getId()) + " ")]), !rv.getExpectedView() ? _c('v-expansion-panel-content', [_c('v-row', [_c('v-col', {
      staticClass: "subtitle-2"
    }, [_vm._v(" Error ")])], 1), _c('v-row', [_c('v-col', [_c('text-block', [_c('pre', [_vm._v(_vm._s(rv.getLogs()) + "\n                  ")])])], 1)], 1)], 1) : rv.getExpectedView().getArgOutputView() ? _c('v-expansion-panel-content', [_c('v-row', [_c('v-col', {
      staticClass: "subtitle-2"
    }, [_vm._v(" Input ")])], 1), _vm._l(rv.getInputView().getArgInputViewsList(), function (input) {
      return _c('v-row', {
        key: input.getName()
      }, [_c('v-col', {
        attrs: {
          "cols": "4"
        }
      }, [_c('v-text-field', {
        attrs: {
          "single": "",
          "outlined": "",
          "readonly": "",
          "label": input.getName(),
          "value": input.getValue()
        }
      })], 1)], 1);
    }), _c('v-row', [_c('v-col', {
      staticClass: "subtitle-2"
    }, [_vm._v(" Output ")])], 1), _c('v-row', [_c('v-col', [rv.getActualView() ? _c('text-block', [_c('pre', [_vm._v(_vm._s(rv.getActualView().getArgOutputView().getValue()))])]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', {
      staticClass: "subtitle-2"
    }, [_vm._v(" Expected ")])], 1), _c('v-row', [_c('v-col', [_c('text-block', [_c('pre', [_vm._v(_vm._s(rv.getExpectedView().getArgOutputView().getValue()))])])], 1)], 1), _c('v-row', [_c('v-col', {
      staticClass: "subtitle-2"
    }, [_vm._v(" Logs ")])], 1), _c('v-row', [_c('v-col', [_c('text-block', [_c('pre', [_vm._v(_vm._s(rv.getLogs()))])])], 1)], 1)], 2) : _c('v-expansion-panel-content', [_c('v-row', [_c('v-col', {
      staticClass: "subtitle-2"
    }, [_vm._v(" Input ")])], 1), _c('v-row', [_c('v-col', [_c('text-block', [_c('pre', [_vm._v(_vm._s(rv.getInputView().getRawInput()))])])], 1)], 1), _c('v-row', [_c('v-col', {
      staticClass: "subtitle-2"
    }, [_vm._v(" Output ")])], 1), _c('v-row', [_c('v-col', [rv.getActualView() ? _c('text-block', [_c('pre', [_vm._v(_vm._s(rv.getActualView().getRawOutput()))])]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', {
      staticClass: "subtitle-2"
    }, [_vm._v(" Expected ")])], 1), _c('v-row', [_c('v-col', [_c('text-block', [_c('pre', [_vm._v(_vm._s(rv.getExpectedView().getRawOutput()))])])], 1)], 1), _c('v-row', [_c('v-col', {
      staticClass: "subtitle-2"
    }, [_vm._v(" Logs ")])], 1), _c('v-row', [_c('v-col', [_c('text-block', [_c('pre', [_vm._v(_vm._s(rv.getLogs()))])])], 1)], 1)], 1)], 1);
  }), 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }