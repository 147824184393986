<template>
  <v-dialog v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn color="accent" height="35" v-on="on">Create new Content</v-btn>
    </template>
    <v-card v-if="!loading">
      <v-card-title class="headline accent lighten-2" primary-title>
        Create New Content
      </v-card-title>

      <v-card-text>
        <v-form>
          <v-text-field
            v-model="title"
            label="Content Title"
            required
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-card-text>
        <v-form>
          <v-select
            label="Content Type"
            :items="contentTypeKeys"
            v-model="contentType"
          ></v-select>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-file-input
          accept="*"
          label="Select Import File"
          v-model="problemFile"
          @change="importedFile"
        ></v-file-input>
        <v-spacer></v-spacer>
        <v-btn color="accent" @click="saveContent"> Create </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-card-title class="headline accent lighten-2" primary-title>
        Creating New Content
      </v-card-title>
      <v-card-actions>
        <loader></loader>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import Loader from "../components/Loader";

export default {
  data: function () {
    return {
      dialog: false,
      loading: false,
      title: null,
      contentType: null,
      problemFile: null,
    };
  },
  computed: {
    ...mapGetters("content", ["contentTypeKeys"]),
  },
  methods: {
    ...mapActions("content", ["createContent"]),
    saveContent() {
      this.loading = true;
      this.createContent({
        title: this.title,
        contentType: this.contentType,
        template: this.problemFile,
      })
        .then((res) => {
          this.$router.push(
            this.contentTypeKeys[
              res.getContent().getContentType()
            ].toLowerCase() +
              "s/" +
              res.getContent().getUrl()
          );
        })
        .finally(() => {
          this.loading = false;
          this.dialog = false;
        });
    },
    importedFile() {
      console.log("File", this.problemFile);
    },
  },
  components: {
    Loader,
  },
};
</script>
