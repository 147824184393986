/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import builder from "codedrills_proto/io/codedrills/proto/builder/content_service_grpc_web_pb";
import { version } from "core-js";
var content_proto = proto.io.codedrills.proto.content;
var builder_proto = proto.io.codedrills.proto.builder;

const builderService = new builder.ContentServicePromiseClient(
  process.env.VUE_APP_BUILDER_API_URL + "/builder",
  null,
  null
);

const state = {
  content: null,
  view: null,
  loading: null,
};

const actions = {
  async fetchArticle({ commit, state, getters, dispatch }, { url }) {
    console.log("TRYING TO FETCH", url);
    commit("toggleLoading", true);

    return dispatch("user/ensureUserToken", null, { root: true }).then(
      (userToken) => {
        var getContentRequest = new builder_proto.GetContentRequest();
        var contentId = new content_proto.ContentId();
        var contentAddress = new content_proto.ContentAddress();
        contentAddress.setContentType(
          proto.io.codedrills.proto.content.ContentType.ARTICLE
        );
        contentAddress.setUrl(url);
        contentId.setContentAddress(contentAddress);
        getContentRequest.setContentId(contentId);

        return builderService
          .getContent(getContentRequest, { Authorization: userToken })
          .then((res) => {
            console.log("SUCCESS", res.toObject());
            commit("setArticle", res);
          })
          .catch((err) => {
            console.error("ERROR", err);
          })
          .finally(() => {
            commit("toggleLoading", false);
          });
      }
    );
  },

  async updateArticle({ commit, state, getters, dispatch }) {
    return dispatch("user/ensureUserToken", null, { root: true }).then(
      async (userToken) => {
        var updateContentRequest = new builder_proto.UpdateContentRequest();
        updateContentRequest.setId(state.content.getId());
        updateContentRequest.setTitle(state.content.getTitle());
        updateContentRequest.setVersion(state.content.getVersion());
        updateContentRequest.setData(state.content.getData());
        updateContentRequest.setPublishType(state.content.getPublishType());
        updateContentRequest.setContentType(state.content.getContentType());

        console.log("REQUEST", updateContentRequest.toObject());

        return builderService
          .updateContent(updateContentRequest, { Authorization: userToken })
          .then((res) => {
            console.log("SUCCESS", res.toObject());
            commit("setArticle", res);
          })
          .catch((err) => {
            console.error("ERROR", err);
          });
      }
    );
  },
};

const getters = {
  userToken: function (state, getters, rootState, rootGetters) {
    parameters.addValue("meta_data", content.getMetaData().toByteArray());
    console.log("rootGetters", rootGetters);
    return rootGetters["user/userToken"];
  },
  types: function () {
    return Object.keys(proto.io.codedrills.proto.content.ContentType);
  },
  statement: function (state) {
    return state.content.data.problem.statement;
  },
  publishType(state, getters) {
    return getters.publishTypeKeys[state.content.getPublishType()];
  },

  publishTypeKeys() {
    return Object.keys(content_proto.PublishType);
  },
};

const mutations = {
  toggleLoading(state, content) {
    state.loading = content;
  },

  setArticle(state, res) {
    state.content = res.getContent();
    state.view = res.getContentView();
  },

  setTitle(state, title) {
    state.content.setTitle(title);
  },

  setStatement(state, statement) {
    state.content.getData().setArticle(new content_proto.Article());
    state.content.getData().getArticle().setStatementString(statement);
  },

  changePublishType(state, publishType) {
    console.log("Publish Type", publishType);
    state.content.setPublishType(content_proto.PublishType[publishType]);
  },

  setTags(state, tags) {
    console.log("tags", tags);
    console.log("tags[0]", tags[0]);
    console.log("list", state.content.getMetaData().getTagsList());
    state.content.getMetaData().setTagsList(tags);
    console.log("content", state.content.toObject());
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
