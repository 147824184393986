<template>
  <v-app-bar app flat color="white">
    <v-app-bar-nav-icon class="ml-5 mt-2">
      <router-link to="/">
        <img
          height="35"
          width="50"
          :src="require('../assets/images/CodeDrills.svg')"
        />
      </router-link>
    </v-app-bar-nav-icon>
    <v-toolbar-title v-if="!isProd"> [{{ env }}] </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-toolbar-title v-if="user && !user.isAnonymous">
      <span class="font-weight-light"
        >Welcome, {{ user.displayName || user.email }} to Builder</span
      >
      <span class="font-weight-light" v-if="!user.emailVerified">
        (Please verify your email.
        <a href="#" @click.prevent="sendVerificationEmail">Send email again</a
        >)</span
      >
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn
      v-if="user && !user.isAnonymous"
      color="accent"
      height="35"
      @click="logout"
      >Logout</v-btn
    >
    <v-btn v-else color="accent" height="35" @click="login">Login</v-btn>
  </v-app-bar>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "navbar",
  data: function () {
    return {};
  },
  computed: {
    ...mapState("user", ["user"]),
    isProd() {
      return process.env.NODE_ENV === "production";
    },
    env() {
      return process.env.NODE_ENV;
    },
  },
  methods: {
    ...mapActions("user", ["logout", "sendVerificationEmail"]),
    login() {
      this.$router.push("/login");
    },
  },
};
</script>
