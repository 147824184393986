var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('v-row', {
    staticClass: "ml-4"
  }, [_c('v-col', {
    staticClass: "text-caption-1 font-weight-light"
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.mdiCalendar))]), _vm._v(" " + _vm._s(_vm.formatDate(_vm.contestMeta.getStartAt())) + " - " + _vm._s(_vm.formatDate(_vm.contestMeta.getEndAt())) + " "), _c('v-icon', {
    staticClass: "ml-2"
  }, [_vm._v(_vm._s(_vm.mdiClock))]), _vm._v(" " + _vm._s(_vm.contestDuration(_vm.contestMeta.getStartAt(), _vm.contestMeta.getEndAt())) + " ")], 1)], 1), !_vm.upcomingContest && !_vm.isScoreboard ? _c('v-row', [_c('v-col', {
    class: {
      'text-center': _vm.$vuetify.breakpoint.mobile
    },
    attrs: {
      "cols": 12,
      "sm": 3,
      "lg": 2
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "accent",
      "router-link": "",
      "to": '/contests/' + this.contestUrl + '/scoreboard'
    }
  }, [_vm._v(" Scoreboard ")])], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }