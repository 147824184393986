var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "loading": _vm.isUpdating
    }
  }, [_c('v-form', [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "value": _vm.selectedProblems,
      "disabled": _vm.isUpdating,
      "items": _vm.availableProblems,
      "filled": "",
      "chips": "",
      "color": "blue-grey lighten-2",
      "label": "Select",
      "item-text": "title",
      "item-value": "id",
      "multiple": ""
    },
    on: {
      "change": _vm.updateProblems
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (data) {
        return [_c('v-chip', _vm._b({
          attrs: {
            "input-value": data.selected,
            "close": ""
          },
          on: {
            "click": data.select,
            "click:close": function ($event) {
              return _vm.removeProblem(data.item.id);
            }
          }
        }, 'v-chip', data.attrs, false), [_vm._v(" " + _vm._s(data.item.title) + " ")])];
      }
    }, {
      key: "item",
      fn: function (data) {
        return [_vm.checktype(data) ? [_c('v-list-item-content', {
          domProps: {
            "textContent": _vm._s(data.item)
          }
        })] : [_c('v-list-item-content', [_c('v-list-item-title', {
          domProps: {
            "innerHTML": _vm._s(data.item.title)
          }
        })], 1)]];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }