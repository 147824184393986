<template>
  <div>
    <v-card ref="libraryDialog" raised width="700px">
      <v-card-text class="pt-5">
        <v-row>
          <v-col>
            <v-row>
              <v-col class="d-flex">
                <div class="title">Options</div>
                <v-btn @click="addOption" color="info" class="ml-5"
                  >Add Option</v-btn
                >
                <v-btn
                  :disabled="!answerFormValid"
                  @click="saveOptions"
                  color="success"
                  class="ml-5"
                >
                  Save Options
                </v-btn>
              </v-col>
            </v-row>
            <v-form ref="mcqAdd" v-model="answerFormValid">
              <v-row v-for="(opt, idx) in getMcqOptions" :key="idx">
                <v-col :cols="8">
                  ID: {{ opt.getId() }}
                  <SimpleMarkDownInput
                    :code="opt.getStatement()"
                    :onCodeChange="(v) => optionStatement(opt, v)"
                    :value="editorConfig"
                  />
                </v-col>
                <v-col :cols="4">
                  <v-switch
                    :value="opt.getId()"
                    label="Correct Answer"
                    v-model="getMcqOptionAnswers"
                    @change="(v) => setCorrectAnswer(opt, v)"
                  >
                  </v-switch>
                  <v-btn
                    small
                    class="ml-5"
                    @click="deleteOption(opt)"
                    color="error"
                  >
                    Delete
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import SimpleMarkDownInput from "../components/SimpleMarkdownInput.vue";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
// import "codedrills_proto/io/codedrills/proto/content/content_pb";
// var content_proto = proto.io.codedrills.proto.content;
export default {
  props: {
    mcq: {
      type: Object,
    },
  },
  data() {
    return {
      answerFormValid: false,
      saving: false,
      getMcqOptionAnswers: [],
      answerIds: {},
    };
  },
  computed: {
    ...mapState("mcq", ["content"]),
    ...mapGetters("mcq", ["mcqType", "getMcqOptions"]),
    isMultiChoice() {
      return this.mcqType === "MCQ_MULTIPLE_ANSWER";
    },
  },
  components: {
    SimpleMarkDownInput,
  },
  created() {
    this.getMcqOptionAnswers = this.content
      .getData()
      .getMcq()
      .getAnswerIdList();
    this.getMcqOptions.forEach((o) => {
      console.log(
        `isAnswer? `,
        o.getId(),
        this.getMcqOptionAnswers.includes(o.getId())
      );
      this.$set(
        this.answerIds,
        o.getId(),
        this.getMcqOptionAnswers.includes(o.getId())
      );
    });
    console.log(`type: ${this.mcqType}`);
  },
  methods: {
    ...mapActions("mcq", ["updateMcq"]),
    ...mapMutations("mcq", [
      "addMcqOption",
      "updateMcqOption",
      "setMcqOptionAsAnswer",
      "deleteMcqOption",
    ]),
    addOption() {
      this.addMcqOption();
    },
    deleteOption(opt) {
      this.deleteMcqOption(opt);
    },
    setCorrectAnswer(option, val) {
      this.$refs.mcqAdd.validate();
      // console.log({ o: option.toObject(), toAdd: val });
      this.setMcqOptionAsAnswer({ option, toAdd: val });
    },
    setLocalAnswerIds() {
      // v-model on answerIds,
      // this syncs the local and state answer Ids
      this.getMcqOptions.forEach((o) => {
        this.$set(
          this.answerIds,
          o.getId(),
          this.getMcqOptionAnswers.includes(o.getId())
        );
      });
    },
    editorConfig(newConfig) {
      console.log("Change ", newConfig);
    },
    optionStatement(option, statement) {
      this.updateMcqOption({ option, statement });
    },
    checkFormValidity() {
      let formValid = false;
      if (this.isMultiChoice) {
        formValid =
          this.getMcqOptions.length >= this.getMcqOptionAnswers.length &&
          this.getMcqOptionAnswers.length > 0;
      } else if (!this.isMultiChoice) {
        formValid = this.getMcqOptionAnswers.length === 1;
      }
      console.log(this.getMcqOptions.length, this.getMcqOptionAnswers.length);
      return (
        this.$refs.mcqAdd.validate() &&
        this.getMcqOptions.length >= 2 &&
        formValid
      );
    },
    saveOptions() {
      this.setLocalAnswerIds();
      if (this.checkFormValidity()) {
        console.log("valid form");
        this.saving = true;
        this.updateMcq({ autopublish: true }).then((__) => {
          this.$router
            .push("/mcqs/" + this.content.getUrl())
            .catch((__) => {})
            .finally((__) => (this.saving = false));
        });
      } else {
        alert("Invalid options!");
      }
    },
  },
};
</script>
