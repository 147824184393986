var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app-bar', {
    attrs: {
      "app": "",
      "flat": "",
      "color": "white"
    }
  }, [_c('v-app-bar-nav-icon', {
    staticClass: "ml-5 mt-2"
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    attrs: {
      "height": "35",
      "width": "50",
      "src": require('../assets/images/CodeDrills.svg')
    }
  })])], 1), !_vm.isProd ? _c('v-toolbar-title', [_vm._v(" [" + _vm._s(_vm.env) + "] ")]) : _vm._e(), _c('v-spacer'), _vm.user && !_vm.user.isAnonymous ? _c('v-toolbar-title', [_c('span', {
    staticClass: "font-weight-light"
  }, [_vm._v("Welcome, " + _vm._s(_vm.user.displayName || _vm.user.email) + " to Builder")]), !_vm.user.emailVerified ? _c('span', {
    staticClass: "font-weight-light"
  }, [_vm._v(" (Please verify your email. "), _c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.sendVerificationEmail.apply(null, arguments);
      }
    }
  }, [_vm._v("Send email again")]), _vm._v(")")]) : _vm._e()]) : _vm._e(), _c('v-spacer'), _vm.user && !_vm.user.isAnonymous ? _c('v-btn', {
    attrs: {
      "color": "accent",
      "height": "35"
    },
    on: {
      "click": _vm.logout
    }
  }, [_vm._v("Logout")]) : _c('v-btn', {
    attrs: {
      "color": "accent",
      "height": "35"
    },
    on: {
      "click": _vm.login
    }
  }, [_vm._v("Login")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }