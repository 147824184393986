<template>
  <v-container>
    <v-row>
      <v-col>
        <p>This {{ item }} does not exist</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    item: {
      type: String,
      required: true,
    },
  },
};
</script>
