<template>
  <div>
    <page
      v-if="user"
      minimal
      title="MCQs"
      :apiStatusList="[listContentsStatus]"
    >
      <div v-if="mcqs">
        <mcq-list :contents="mcqs" />
      </div>
    </page>
    <div v-else>Please Login to continue</div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import Page from "../components/Page";
import McqList from "../components/McqList.vue";
export default {
  props: {},
  computed: {
    ...mapState("content", ["listContentsStatus", "contents"]),
    ...mapState("user", ["user"]),
    ...mapGetters("content", ["contentTypeKeys"]),
    mcqs() {
      return (
        this.contents &&
        this.contents
          .filter((c) => this.contentTypeKeys[c.getContentType()] == "MCQ")
          .map((c) => c.toObject())
      );
    },
  },
  methods: {
    // ...mapGetters("content", ["contents"]),
    ...mapActions("content", ["fetchContents"]),
    ...mapMutations("content", ["setContents"]),
    check() {
      console.log(this.mcqs);
    },
  },
  mounted() {
    if (this.user) this.fetchContents();
  },
  watch: {
    user: function (user) {
      console.log("Changing user ...");
      if (user) this.fetchContents();
      else this.setContents([]);
    },
  },
  components: {
    Page,
    McqList,
  },
};
</script>
