<template>
  <span>
    <loader v-if="getTestCaseStatus != 2"></loader>
    <v-container v-else>
      <v-row><v-col> Input </v-col></v-row>
      <v-row
        ><v-col>
          <code-display
            :value="input"
            :availableLanguages="['TXT']"
          ></code-display> </v-col
      ></v-row>
      <v-row><v-col> Output </v-col></v-row>
      <v-row
        ><v-col>
          <code-display
            :value="output"
            :availableLanguages="['TXT']"
          ></code-display> </v-col
      ></v-row>
    </v-container>
  </span>
</template>
<script>
import { mapState, mapActions } from "vuex";
import CodeDisplay from "@/components/CodeDisplay";
import Loader from "@/components/Loader";
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    testSet: {
      type: String,
      required: true,
    },
    testId: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapActions("problem", ["getTestCase"]),
  },
  computed: {
    ...mapState("problem", ["input", "output", "getTestCaseStatus"]),
  },
  mounted() {
    console.log("Mounted");
    this.getTestCase({
      url: this.url,
      testSet: this.testSet,
      testId: parseInt(this.testId),
    });
  },
  components: {
    CodeDisplay,
    Loader,
  },
};
</script>
