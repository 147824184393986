<template>
  <v-row>
    <v-col :cols="12">
      <v-sheet>
        <slot name="default"></slot>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>
<style scoped></style>
