var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [!this.user ? _c('div', [_vm._v("Please login first")]) : _vm._e(), this.user && _vm.loading ? _c('Loader') : _vm._e(), this.user && !_vm.loading && !_vm.content ? _c('NotFoundText', {
    attrs: {
      "item": "subjective"
    }
  }) : _vm._e(), this.user && !_vm.loading && _vm.content ? _c('span', [_c('div', {
    staticClass: "grey lighten-5 pl-12 pr-5 mx-0 my-0"
  }, [_c('v-row', [_c('v-col', [_c('span', {
    staticClass: "display-2 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.content.getTitle()) + " ")])])], 1)], 1), _c('v-container', {
    staticClass: "pl-12 pr-5 mx-0 my-0",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_c('v-col', [_c('v-btn', {
    attrs: {
      "loading": _vm.isSubjectiveSaved,
      "disabled": _vm.isSubjectiveSaved,
      "color": "accent",
      "height": "35"
    },
    on: {
      "click": _vm.saveSubjective
    }
  }, [_vm._v("Save")])], 1), _c('v-col', [_c('v-btn', {
    attrs: {
      "loading": _vm.isSubjectivePublished,
      "disabled": _vm.isPublishButtonDisabled,
      "color": "accent",
      "height": "35"
    },
    on: {
      "click": _vm.publishContentRequest
    }
  }, [_vm._v("Publish")])], 1)], 1), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "sm": "2"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Publish Type",
      "items": _vm.publishTypeKeys,
      "value": _vm.publishType
    },
    on: {
      "change": _vm.changePublishType
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-tabs', {
    attrs: {
      "color": "accent",
      "slider-color": "accent"
    },
    model: {
      value: _vm.subjTab,
      callback: function ($$v) {
        _vm.subjTab = $$v;
      },
      expression: "subjTab"
    }
  }, [_c('v-tab', [_vm._v("Preview")]), _c('v-tab', [_vm._v("Statement")]), _c('v-tab', [_vm._v("Metadata")]), _c('v-tab', [_vm._v("Permissions")])], 1), _c('v-tabs-items', {
    model: {
      value: _vm.subjTab,
      callback: function ($$v) {
        _vm.subjTab = $$v;
      },
      expression: "subjTab"
    }
  }, [_c('v-tab-item', [_c('v-row', {
    staticClass: "py-5 px-2"
  }, [_c('v-col', [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.view.getDataView().getSubjectiveView())
    }
  })])], 1)], 1), _c('v-tab-item', [_c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "value": _vm.content.getTitle()
    },
    on: {
      "change": _vm.setTitle
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "py-5 px-2"
  }, [_c('v-col', [_c('MarkdownEditor', {
    attrs: {
      "editorialMode": false,
      "submissionMode": true,
      "code": _vm.content.getData().getSubjective() && _vm.content.getData().getSubjective().getStatement(),
      "onCodeChange": _vm.onMarkdownCodeChange
    }
  })], 1)], 1)], 1), _c('v-tab-item', [_c('v-row', {
    staticClass: "py-5 px-2"
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Time limit",
      "value": _vm.getSubjectiveTimeLimit
    },
    on: {
      "input": _vm.setSubjectiveTimeLimit
    }
  }), _c('v-combobox', {
    attrs: {
      "color": "accent",
      "items": _vm.tagsTypes,
      "value": _vm.content.getMetaData().getTagsList(),
      "hide-selected": "",
      "hint": "tagType/tag",
      "label": "Add company, problem type tags",
      "persistent-hint": "",
      "multiple": "",
      "small-chips": "",
      "solo": ""
    },
    on: {
      "change": _vm.setTags
    }
  })], 1)], 1)], 1), _c('v-tab-item', [_c('permissions', {
    attrs: {
      "permissions": _vm.content.getMetaData().getPermissionsMap(),
      "initialRestrictedDomains": _vm.content.getMetaData().getRestrictedDomainList()
    },
    on: {
      "permissionChange": _vm.setPermissions,
      "restrictedDomainChange": _vm.setRestrictedDomains
    }
  })], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', [_c('confirm-dialog', {
    ref: "confirm"
  }), _c('v-btn', {
    attrs: {
      "color": "error",
      "height": "35"
    },
    on: {
      "click": _vm.deleteContentRequest
    }
  }, [_vm._v(" Delete Subjective ")])], 1)], 1)], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }