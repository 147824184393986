var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.listContentsStatus !== null ? _c('div', [_c('v-row', [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": 4
    }
  }, [_c('v-text-field', {
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Search (title, tags)",
      "clearable": "",
      "single-line": "",
      "outlined": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.allQsHeaders,
      "items": _vm.allQuestions,
      "loading": _vm.allQuestions.length === 0,
      "items-per-page": 15,
      "search": _vm.search
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_vm._v(" All Questions ")])];
      },
      proxy: true
    }, {
      key: "item.title",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('router-link', {
          attrs: {
            "to": item.url
          }
        }, [_vm._v(" " + _vm._s(item.title) + " ")])];
      }
    }, {
      key: "item.action",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-btn', {
          attrs: {
            "small": "",
            "color": "success"
          },
          on: {
            "click": function ($event) {
              return _vm.addItem(item);
            }
          }
        }, [_vm._v(" Add ")])];
      }
    }], null, false, 35935534)
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.allQsHeaders,
      "items": _vm.selectedQuestions,
      "items-per-page": 15
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_vm._v(" Selected Questions ")])];
      },
      proxy: true
    }, {
      key: "item.title",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('router-link', {
          attrs: {
            "to": item.url
          }
        }, [_vm._v(" " + _vm._s(item.title) + " ")])];
      }
    }, {
      key: "item.action",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-btn', {
          attrs: {
            "small": "",
            "color": "info"
          },
          on: {
            "click": function ($event) {
              return _vm.removeItem(item);
            }
          }
        }, [_vm._v(" Remove ")])];
      }
    }], null, false, 1171387147)
  })], 1)], 1)], 1) : _c('div', [_vm._v("Loading")]);

}
var staticRenderFns = []

export { render, staticRenderFns }