var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [!_vm.user ? _c('v-row', [_c('v-col', {
    staticClass: "pt-2 pb-1"
  }, [_vm._v(" Please "), _c('router-link', {
    attrs: {
      "to": "/login"
    }
  }, [_vm._v(" login ")]), _vm._v(" to submit. ")], 1)], 1) : _vm._e(), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', [_c('Editor', {
    on: {
      "setLanguage": _vm.setLanguage,
      "resetCode": _vm.removeOldCode
    },
    model: {
      value: _vm.code,
      callback: function ($$v) {
        _vm.code = $$v;
      },
      expression: "code"
    }
  })], 1)], 1), _vm.user ? _c('v-row', [_c('v-col', [_c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "color": "accent",
      "height": "35"
    },
    on: {
      "click": _vm.openFileInput
    }
  }, [_vm._v("Load File")]), _c('input', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "codeFileInput",
    attrs: {
      "type": "file",
      "accept": ".c,.c99,.cxx,.cpp,.h,.java,.py,.txt,text/*"
    },
    on: {
      "change": _vm.loadFile
    }
  })], 1), _c('v-spacer'), _c('v-col', {
    staticClass: "pt-0 pb-1",
    attrs: {
      "align": "end"
    }
  }, [_c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "disabled": _vm.submitting,
      "color": "accent",
      "height": "35"
    },
    on: {
      "click": _vm.testRun
    }
  }, [_vm._v("Test Run")]), _c('v-btn', {
    attrs: {
      "disabled": _vm.submitting,
      "color": "accent",
      "height": "35"
    },
    on: {
      "click": _vm.fullSubmit
    }
  }, [_vm._v("Submit")])], 1)], 1) : _c('v-row', [_c('v-spacer'), _c('v-col', {
    staticClass: "pt-0 pb-1",
    attrs: {
      "cols": 2
    }
  }, [_vm._v(" Please "), _c('router-link', {
    attrs: {
      "to": "/login"
    }
  }, [_vm._v(" login ")]), _vm._v(" to submit. ")], 1)], 1), _c('v-row', {
    ref: "judgingStatus"
  }, [_c('v-col', [_vm.submissionId ? _c('div', [_vm.submissionStatus && _vm.submissionStatus.getStatus() == 0 ? _c('submission-status', {
    attrs: {
      "title": "Waiting...",
      "subtitle": "Waiting in the queue for your turn. Please be patient.",
      "imageUrl": "judgingImg.svg"
    }
  }) : !_vm.judgingCompleted ? _c('submission-status', {
    attrs: {
      "title": "Judging...",
      "subtitle": 'We are processing your submission. ' + _vm.testsProgress,
      "imageUrl": "judgingImg.svg"
    }
  }) : _vm.judgingCompleted && _vm.submissionStatus.getStatus() == 13 && _vm.submissionStatus.getSubmissionType() == 3 ? _c('submission-status', {
    attrs: {
      "title": _vm.submissionStatusTitle(_vm.submissionStatus),
      "subtitle": _vm.scoreSubtitle(_vm.submissionStatus),
      "imageUrl": _vm.randomCorrectImage(),
      "status": _vm.submissionStatus && _vm.submissionStatus.getStatus()
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": 6,
      "md": 3,
      "lg": 2
    }
  }, [_c('stats-col', {
    attrs: {
      "title": "Time Taken"
    }
  }, [_vm._v(" " + _vm._s(_vm.submissionStatus.getTimeTaken()) + " ms ")])], 1), _c('v-col', {
    attrs: {
      "cols": 6,
      "md": 3,
      "lg": 2
    }
  }, [_c('stats-col', {
    attrs: {
      "title": "Memory Taken"
    }
  }, [_vm._v(" " + _vm._s(_vm.submissionStatus.getMemoryTaken()) + " KiB ")])], 1), _c('v-spacer')], 1), _vm.submissionStatus.getSubtasks() ? _c('v-row', {
    staticClass: "mt-5"
  }, [_c('v-col', [_vm._v(" Subtasks "), _c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.subtasksHeaders,
      "items": _vm.subtaskList
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('tr', [_c('td', [_vm._v(_vm._s(item.getSubtaskId()))]), _c('td', {
          class: _vm.statusClass(item.getStatus())
        }, [_vm._v(" " + _vm._s(_vm.statusTitle(item.getStatus())) + " ")]), _c('td', [_vm._v(_vm._s(100 * item.getScore()))])])];
      }
    }], null, false, 2424245227)
  })], 1)], 1) : _vm._e()], 1) : _vm.judgingCompleted && _vm.submissionStatus.getStatus() == 13 && _vm.submissionStatus.getSubmissionType() == 1 ? _c('submission-status', {
    attrs: {
      "title": "Samples Passed",
      "subtitle": "Great! Submit to run against full test set",
      "imageUrl": _vm.randomCorrectImage(),
      "status": _vm.submissionStatus && _vm.submissionStatus.getStatus()
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": 6,
      "md": 3,
      "lg": 2
    }
  }, [_c('stats-col', {
    attrs: {
      "title": "Time Taken"
    }
  }, [_vm._v(" " + _vm._s(_vm.submissionStatus.getTimeTaken()) + " ms ")])], 1), _c('v-col', {
    attrs: {
      "cols": 6,
      "md": 3,
      "lg": 2
    }
  }, [_c('stats-col', {
    attrs: {
      "title": "Memory Taken"
    }
  }, [_vm._v(" " + _vm._s(_vm.submissionStatus.getMemoryTaken()) + " KiB ")])], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": 6,
      "md": 4,
      "lg": 3,
      "align": "end"
    }
  }, [_c('v-btn', {
    attrs: {
      "disabled": _vm.submitting,
      "color": "accent",
      "height": "35"
    },
    on: {
      "click": _vm.fullSubmit
    }
  }, [_vm._v("Submit")])], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('h3', {
    staticClass: "green--text"
  }, [_vm._v("SubmissionId:")])]), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_vm._v(" " + _vm._s(_vm.submissionStatus.getSubmissionId()) + " ")])], 1), _c('tests-results', {
    attrs: {
      "resultViews": _vm.submissionStatus.getSampleResultViewsList()
    }
  })], 1) : _c('submission-status', {
    attrs: {
      "title": _vm.statusTitle(_vm.submissionStatus.getStatus()),
      "subtitle": _vm.statusSubtitle(_vm.submissionStatus),
      "imageUrl": "Error.svg"
    }
  }, [_vm.submissionStatus.getResult() ? _c('text-block', [_c('pre', [_vm._v(_vm._s(_vm.submissionStatus && _vm.submissionStatus.getResult()) + "\n          ")])]) : _vm.submissionStatus.getSampleResultViewsList() ? _c('tests-results', {
    attrs: {
      "resultViews": _vm.submissionStatus.getSampleResultViewsList()
    }
  }) : _vm._e()], 1)], 1) : _vm._e()])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }