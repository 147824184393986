import judge from "codedrills_proto/io/codedrills/proto/judge/judge_service_grpc_web_pb";

const Status = proto.io.codedrills.proto.judge.Status;

export function isSkippedTest(status) {
  return status === Status.SKIPPED;
}

export function isCorrectTest(status) {
  return status === Status.CORRECT_ANSWER;
}

export function isIncorrectTest(status) {
  return (
    status >= 10 && status != Status.CORRECT_ANSWER && status != Status.SKIPPED
  );
}

export function statusTitle(status) {
  if (status < 10) return "Judging";
  if (status <= 18)
    return [
      "Compile Error",
      "Runtime Error",
      "Time Limit Exceeded",
      "Correct Answer",
      "Wrong Answer",
      "Source Limit Exceeded",
      "Memory Limit Exceeded",
      "Skipped",
      "Output Limit Exceeded",
    ][status - 10];
  if (status == 30) return "Judge Error";
  return "Unknown";
}

export function statusClass(status) {
  if (!status) return {};
  return {
    "grey--text": isSkippedTest(status),
    "green--text": isCorrectTest(status),
    "red--text": isIncorrectTest(status),
  };
}

export function isFullyCorrect(submissionStatus) {
  if (submissionStatus.getStatus() !== Status.CORRECT_ANSWER) {
    return false;
  }

  if (submissionStatus.getScore() < 1) {
    if (
      submissionStatus.getSubtasks() &&
      submissionStatus
        .getSubtasks()
        .getSubtaskResultsList()
        .every((x) => x.getStatus() == Status.CORRECT_ANSWER)
    )
      return true;
    return false;
  } else {
    return true;
  }
}

export function submissionStatusTitle(submission) {
  if (isCorrectTest(submission.getStatus())) {
    if (!isFullyCorrect(submission)) {
      return "Partially Correct";
    }
    return "Correct Answer";
  }
  return statusTitle(submission.getStatus());
}

export function submissionScoreString(submission) {
  if (
    submission.getSubtasks() &&
    submission.getSubtasks().getSubtaskResultsList().length > 0
  ) {
    return (100 * submission.getScore()).toFixed(0).toString();
  } else {
    return (100 * submission.getScore()).toFixed(2).toString() + " %";
  }
}
