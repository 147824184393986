export function resolvePath(obj, path, separator = ".") {
  var properties = Array.isArray(path) ? path : path.split(separator);
  var value = properties.reduce((prev, curr) => prev && prev[curr], obj);
  return value;
}

function prettyPart(part) {
  if (part < 10) return "0" + part;
  else return part;
}
export function prettyday(diff) {
  return diff > 72 * 60 * 60
    ? prettyPart(Math.floor(diff / (24 * 60 * 60)) + " days")
    : prettyPart(
        Math.floor(diff / (60 * 60)) +
          ":" +
          prettyPart(Math.floor((diff % (60 * 60)) / 60)) +
          ":" +
          prettyPart(Math.floor(diff % 60))
      );
}

export function prettytime(sec) {
  return (
    "" +
    prettyPart(Math.floor(sec / (60 * 60))) +
    ":" +
    prettyPart(Math.floor((sec % (60 * 60)) / 60)) +
    ":" +
    prettyPart(sec % 60)
  );
}
