import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/css/main.css";
import vuetify from "./plugins/vuetify";
import firebase from "firebase";
import auth from "./utils/auth";
import * as firebaseui from "firebaseui";
import DatetimePicker from "vuetify-datetime-picker";
import wysiwyg from "vue-wysiwyg";
Vue.use(wysiwyg, {}); // config is optional. more below

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyChiLDFW5EDgSCV4rEvR8MIEFVmFPvedfQ",
  authDomain: "codedrills.io",
  databaseURL: "https://codedrills.firebaseio.com",
  projectId: "codedrills",
  storageBucket: "codedrills.appspot.com",
  messagingSenderId: "864730259813",
  appId: "1:864730259813:web:7fe150e51205488a19418c",
};

var firebaseConfigLoad = {
  apiKey: "AIzaSyDjixsj0Fhy3bMC0MjilfWcLf65sgs8rCw",
  authDomain: "cdloadtest.firebaseapp.com",
  projectId: "cdloadtest",
  storageBucket: "cdloadtest.appspot.com",
  messagingSenderId: "891713579432",
  appId: "1:891713579432:web:b1bba9fc3ba8312e42159d",
};

var isProd = process.env.NODE_ENV === "production";
var isStaging =
  process.env.NODE_ENV === "development" &&
  process.env.VUE_APP_ENV !== "loadtest";

var app;
// Initialize Firebase
if (isProd || isStaging) firebase.initializeApp(firebaseConfig);
else {
  app = firebase.initializeApp(firebaseConfigLoad);
}

/*
async function tokens() {
  var pass = "loadtest121";
  var tokens = "export const AUTH_TOKENS = [\n";
  var promises = [];
  var c = 0;
  console.log("Creating tokens");
  for (var i  = 4800*1 + 1; i < 4800*2 + 1; i += 3) {
      console.log("Creating for ", i);
      var email = "matcautham+" + i + "@gmail.com";
      var tp = firebase.auth().signInWithEmailAndPassword(email, pass)
      .then((uc) => {
          return uc.user.getIdToken(true);
      }).catch(e => {
        console.log("Error: ", e);
        return null;
      });
      var tok = await tp;
      tokens += "\"" + tok + "\",\n";
      /*
      c += 1;
      if (c % 200 == 0) {
        console.log("Sleeping...");
        await new Promise(r => setTimeout(r, 5000));
      }
      promises.push(tp);
  }
  /*
  await Promise.all(promises)
  .then(toks => {
    toks.forEach(t => {
      if(t) tokens += "\"" + t + "\",\n"
    });
  });
  tokens += "];";
  console.log(tokens);
  console.log("Done");
}
tokens();
*/

firebase.auth().onAuthStateChanged(auth.authChange);
new firebaseui.auth.AuthUI(firebase.auth());

if (isProd) {
  console.log = function () {};
}

Vue.config.productionTip = false;
Vue.use(DatetimePicker);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

console.log("Environment", process.env.NODE_ENV, process.env);
