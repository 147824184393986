var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-title', [_vm._v("Template")]), _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Emails (comma separated), leave it balnk to send email to all the contest participants. To test, use your own email address.",
      "outlined": "",
      "rules": [_vm.emailListRule]
    },
    model: {
      value: _vm.emailList,
      callback: function ($$v) {
        _vm.emailList = $$v;
      },
      expression: "emailList"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Template ID / CodeDrills use only (leave blank if not sure)",
      "outlined": ""
    },
    model: {
      value: _vm.templateId,
      callback: function ($$v) {
        _vm.templateId = $$v;
      },
      expression: "templateId"
    }
  })], 1)], 1), !_vm.templateId ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Subject",
      "outlined": ""
    },
    model: {
      value: _vm.subject,
      callback: function ($$v) {
        _vm.subject = $$v;
      },
      expression: "subject"
    }
  })], 1)], 1) : _vm._e(), !_vm.templateId ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('wysiwyg', {
    model: {
      value: _vm.body,
      callback: function ($$v) {
        _vm.body = $$v;
      },
      expression: "body"
    }
  })], 1)], 1) : _vm._e()], 1)], 1), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "disabled": !_vm.email
    },
    on: {
      "click": _vm.sendEmail
    }
  }, [_vm._v("Send Email")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }