<template>
  <v-card>
    <v-card-title>Template</v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              v-model="emailList"
              label="Emails (comma separated), leave it balnk to send email to all the contest participants. To test, use your own email address."
              outlined
              :rules="[emailListRule]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="templateId"
              label="Template ID / CodeDrills use only (leave blank if not sure)"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="!templateId">
          <v-col cols="12" md="4">
            <v-text-field
              v-model="subject"
              label="Subject"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="!templateId">
          <v-col cols="12" md="8">
            <!-- <v-textarea v-model="body" label="Body" outlined></v-textarea> -->
            <wysiwyg v-model="body" />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="sendEmail" :disabled="!email">Send Email</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import "vue-wysiwyg/dist/vueWysiwyg.css";

export default {
  data() {
    return {
      templateId: "",
      subject: "",
      body: "",
      emailList: "",
    };
  },
  methods: {
    sendEmail() {
      const email = this.email;
      var confirmSend = false;
      if (email) {
        if (this.emailList.length > 1) {
          confirmSend = confirm(
            "Do you want to send email " + this.emailList + " ?"
          );
        } else {
          confirmSend = confirm(
            "Do you want to send email to all the contest participants?"
          );
        }
        if (confirmSend) {
          // Send email using email.templateId, email.subject and email.body
          this.$emit("email-sent", email);
        }
      }
    },
  },
  computed: {
    email() {
      if (this.templateId || (this.subject && this.body)) {
        console.log("emails are ..", this.emailList);
        return {
          templateId: this.templateId,
          subject: this.subject,
          body: this.body,
          emails: this.emailList
            .split(",")
            .map((email) => email.trim())
            .filter((email) => email !== ""),
        };
      } else {
        return null;
      }
    },
    emailListRule() {
      return (value) => {
        if (!value) {
          return "Emails are required";
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const invalidEmails = value
          .split(",")
          .filter((email) => !emailRegex.test(email.trim()));
        if (invalidEmails.length > 0) {
          return `Invalid email(s): ${invalidEmails.join(", ")}`;
        }
        return true;
      };
    },
  },
};
</script>
