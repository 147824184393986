var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [!this.user ? _c('div', [_vm._v("Please login first")]) : _vm._e(), this.user && _vm.loading ? _c('Loader') : _vm._e(), this.user && !_vm.loading && !_vm.content ? _c('NotFoundText', {
    attrs: {
      "item": "article"
    }
  }) : _vm._e(), this.user && !_vm.loading && _vm.content ? _c('span', [_c('div', {
    staticClass: "grey lighten-5 pl-12 pr-5 mx-0 my-0"
  }, [_c('v-row', [_c('v-col', [_c('span', {
    staticClass: "display-2 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.content.getTitle()) + " ")])])], 1)], 1), _c('v-container', {
    staticClass: "pl-12 pr-5 mx-0 my-0",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_c('v-col', [_c('v-btn', {
    attrs: {
      "loading": _vm.isArticleSaved,
      "disabled": _vm.isArticleSaved,
      "color": "accent",
      "height": "35"
    },
    on: {
      "click": _vm.saveArticle
    }
  }, [_vm._v("Save")])], 1), _c('v-col', [_c('v-btn', {
    attrs: {
      "loading": _vm.isArticlePublished,
      "disabled": _vm.isPublishButtonDisabled,
      "color": "accent",
      "height": "35"
    },
    on: {
      "click": _vm.publishContentRequest
    }
  }, [_vm._v("Publish")])], 1)], 1), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "sm": "2"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Publish Type",
      "items": _vm.publishTypeKeys,
      "value": _vm.publishType
    },
    on: {
      "change": _vm.changePublishType
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-tabs', {
    attrs: {
      "color": "accent",
      "slider-color": "accent"
    },
    model: {
      value: _vm.articleTab,
      callback: function ($$v) {
        _vm.articleTab = $$v;
      },
      expression: "articleTab"
    }
  }, [_c('v-tab', [_vm._v("Preview")]), _c('v-tab', [_vm._v("Statement")])], 1), _c('v-tabs-items', {
    model: {
      value: _vm.articleTab,
      callback: function ($$v) {
        _vm.articleTab = $$v;
      },
      expression: "articleTab"
    }
  }, [_c('v-tab-item', [_c('v-row', {
    staticClass: "py-5 px-2"
  }, [_c('v-col', [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.view.getDataView().getArticleView())
    }
  })])], 1), _c('v-row', {
    staticClass: "py-5 px-2"
  }, [_c('v-col', _vm._l(_vm.content.getMetaData().getTagsList(), function (tag) {
    return _c('v-chip', {
      key: tag,
      staticClass: "mr-1",
      attrs: {
        "color": "accent"
      }
    }, [_vm._v(" " + _vm._s(tag) + " ")]);
  }), 1)], 1)], 1), _c('v-tab-item', [_c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "value": _vm.content.getTitle()
    },
    on: {
      "change": _vm.setTitle
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "py-5 px-2"
  }, [_c('v-col', [_c('MarkdownEditor', {
    attrs: {
      "editorialMode": false,
      "submissionMode": true,
      "code": _vm.content.getData().getArticle() && _vm.content.getData().getArticle().getStatementString(),
      "onCodeChange": _vm.onMarkdownCodeChange
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', [_c('confirm-dialog', {
    ref: "confirm"
  }), _c('v-btn', {
    attrs: {
      "color": "error",
      "height": "35"
    },
    on: {
      "click": _vm.deleteContentRequest
    }
  }, [_vm._v("Delete Problem")])], 1)], 1)], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }