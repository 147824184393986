var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [!this.user ? _c('div', [_vm._v("Please login first")]) : _vm._e(), this.user ? _c('span', [_c('div', {
    staticClass: "grey lighten-5 pl-12 pr-5 mx-0 my-0"
  }, [_c('v-row', [_c('v-col', [_c('span', {
    staticClass: "display-1 font-weight-bold"
  }, [_vm._v(" Submission Details ")])])], 1)], 1), _c('v-container', {
    staticClass: "pl-12 pr-5 mx-0 my-0",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', {
    staticClass: "py-5 px-2"
  }, [!_vm.submissionDetail ? _c('Loader') : _vm._e(), _c('error-message', {
    attrs: {
      "ex": _vm.ex
    }
  }), _vm.submissionDetail ? _c('v-col', [_c('v-row', [_c('v-col', [_c('v-card', [_c('v-card-title', [_vm._v(" Submission: " + _vm._s(_vm.id) + " ")]), _c('v-simple-table', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('thead', [_c('tr', _vm._l(_vm.headers, function (th) {
          return _c('th', {
            key: th.value,
            staticClass: "text-left"
          }, [_vm._v(" " + _vm._s(th.text) + " ")]);
        }), 0)]), _c('tbody', [_c('tr', [_c('td', [_vm._v(" " + _vm._s(_vm.submissionDetail.getSubmission().getId()) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.formatDate(_vm.submissionDetail.getSubmission().getSubmittedOn())) + " ")]), _c('td', [_vm.submissionDetail.getUserPreview().getHandle() ? _c('router-link', {
          staticClass: "text-decoration-none router_link",
          attrs: {
            "to": `/profiles/${_vm.submissionDetail.getUserPreview().getHandle()}`
          }
        }, [_vm._v(" " + _vm._s(_vm.submissionDetail.getUserPreview().getHandle()) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.submissionDetail.getUserPreview().getName()) + " ")])], 1), _c('td', [_c('router-link', {
          staticClass: "link",
          attrs: {
            "to": '/problems/' + _vm.submissionDetail.getProblemPreview().getUrl()
          }
        }, [_vm._v(" " + _vm._s(_vm.submissionDetail.getProblemPreview().getTitle()) + " ")])], 1), _c('td', [_vm._v(" " + _vm._s(_vm.allLanguages[_vm.languageKeys[_vm.submissionDetail.getSubmission().getLanguage()]]) + " ")]), _c('td', {
          staticClass: "text-capitalize",
          class: _vm.statusClass(_vm.submissionDetail.getSubmission().getStatus())
        }, [_vm._v(" " + _vm._s(_vm.submissionStatusTitle(_vm.submissionDetail.getSubmission())) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.submissionScoreString(_vm.submissionDetail.getSubmission())) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.submissionDetail.getSubmission().getTimeTakenInMs()) + " ms ")]), _c('td', [_vm._v(" " + _vm._s(_vm.submissionDetail.getSubmission().getMemoryTakenInKb()) + " KiB ")])])])];
      },
      proxy: true
    }], null, false, 323743910)
  })], 1)], 1)], 1), _c('v-row', [_c('v-col', [_c('v-card', [_c('v-row', {
    staticClass: "pl-1 align-center",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', [_c('v-card-title', [_vm._v("Code")])], 1), _c('v-col', {
    attrs: {
      "cols": 1
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.copyText
    }
  }, [_vm._v(" Copy ")])], 1)], 1), _c('v-divider'), _c('CodeView', {
    staticClass: "code-mirror",
    attrs: {
      "value": _vm.submissionDetail.getSubmission().getCode(),
      "lang": _vm.languageKeys[_vm.submissionDetail.getSubmission().getLanguage()]
    }
  })], 1)], 1)], 1), _c('v-row', [_c('v-col', [_c('v-card', [_c('v-card-title', {
    attrs: {
      "primary-title": ""
    }
  }, [_vm._v(" Test Cases ")]), _c('v-data-table', {
    attrs: {
      "headers": _vm.testCaseHeaders,
      "items": _vm.submissionDetail.getTestRunResultsList()
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(item.getTestKey().getTestCase()) + " ")]), _c('td', {
          staticClass: "text-center text-capitalize",
          class: _vm.statusClass(item.getStatus())
        }, [_vm._v(" " + _vm._s(_vm.statusTitle(item.getStatus())) + " ")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(item.getTimeTakenInMs()) + " ms ")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(item.getMemoryTakenInKb()) + " KiB ")])])];
      }
    }], null, false, 1631404763)
  })], 1)], 1)], 1), _vm.submissionDetail.getSubmission().getSubtasks() ? _c('v-row', [_c('v-col', [_c('v-card', [_c('v-card-title', {
    attrs: {
      "primary-title": ""
    }
  }, [_vm._v(" Subtasks ")]), _c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.subtasksHeaders,
      "items": _vm.subtaskList
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('tr', [_c('td', [_vm._v(_vm._s(item.getSubtaskId()))]), _c('td', {
          staticClass: "text-capitalize",
          class: _vm.statusClass(item.getStatus())
        }, [_vm._v(" " + _vm._s(_vm.statusTitle(item.getStatus())) + " ")]), _c('td', [_vm._v(_vm._s(100 * item.getScore()))])])];
      }
    }], null, false, 622606095)
  })], 1)], 1)], 1) : _vm._e()], 1) : _vm._e()], 1)], 1)], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }