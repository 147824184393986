<template>
  <div>
    <div>
      <h1>{{ customer && (customer?.getName() || customer?.getId()) }}</h1>
      <p>Total usage From Signup</p>
      <p>Domain: {{ customer?.getCompanyDomain() }}</p>
      <p>Add-Ons: {{ addOnsDisplayString }}</p>
      <p>Email Domains: {{ customer?.getEmailDomainsList()?.join(", ") }}</p>
      <p>Hiring Plan: {{ planTypeKeys[customer?.getHiringPlan()] }}</p>
      <p>Invite Sent Count: {{ customer?.getInviteCount() }}</p>
      <p>Test Created Count: {{ customer?.getTestCount() }}</p>
      <!-- Add more fields as needed -->
    </div>
    <div>
      <h3>Select Range to check usage for that duration</h3>
      <!-- Date selection for usage -->
      <v-row>
        <v-col>
          <v-text-field
            v-model="excludeDomains"
            label="Exclude Domains(comma-separated)"
            placeholder="Enter domains to exclude(comma-separated)"
            small-chips
            chips
            clearable
            @input="handleInput"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-menu
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                label="Start Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              @input="startMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col>
          <v-menu
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                label="End Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              @input="endMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col>
          <v-btn
            color="primary"
            @click="fetchCustomerUsage"
            :disabled="!startDate || !endDate"
            >Fetch Usage</v-btn
          >
        </v-col>
      </v-row>

      <!-- Download as CSV selected data as csv -->
      <div>
        <v-btn color="primary" @click="downloadCSV" :disabled="!customerUsage"
          >Download selected as CSV</v-btn
        >
      </div>
      <!-- Display customer usage -->

      <v-row v-if="customerUsage">
        <v-col>
          <v-text-field
            v-model="searchQuery"
            label="Search by Name"
            prepend-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
      </v-row>
      <div v-if="customerUsage">
        <v-row>
          <v-col>
            <v-alert type="info" dense>
              <p>
                Total Candidates Invited:
                {{ customerUsage.getTotalCandidatesInvited() }}
              </p>
              <p>
                Total Candidates Completed:
                {{ customerUsage.getTotalCandidatesCompleted() }}
              </p>
              <p>
                Total Candidates Deleted:
                {{ customerUsage.getTotalCandidatesDeleted() }}
              </p>
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              v-model="selectedStatus"
              :items="statusOptions"
              label="Filter by Status"
              clearable
            ></v-select>
            <v-select
              v-model="selectedStage"
              :items="stageOptions"
              label="Filter by Stage"
              clearable
            ></v-select>
            <v-data-table
              :headers="tableHeaders"
              :items="filteredInviteSummaries"
              :items-per-page="5"
              class="elevation-1"
            >
              <template v-slot:item.name="{ item }">
                {{ item.getName() }}
              </template>
              <template v-slot:item.email="{ item }">
                {{ item.getEmail() }}
              </template>
              <template v-slot:item.finishTime="{ item }">
                {{ formatDate(item.getFinishTime()) }}
              </template>
              <template v-slot:item.inviteTime="{ item }">
                {{ formatDate(item.getInviteTime()) }}
              </template>
              <template v-slot:item.stage="{ item }">
                {{ testStages(item.getStage()) }}
              </template>
              <template v-slot:item.testStatus="{ item }">
                {{ getstatus(item.getTestStatus()) }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";

import user from "codedrills_proto/io/codedrills/proto/user/user_service_grpc_web_pb";
var user_proto = proto.io.codedrills.proto.user;

export default {
  name: "CustomerDetails",
  data() {
    return {
      excludeDomains: [],
      rules: {
        required: (value) => !!value || "Required.",
      },
      customer: {},
      startDate: "",
      endDate: "",
      searchQuery: "",
      customerUsage: null,
      selectedStatus: null,
      selectedStage: null,
      tableHeaders: [
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Finish Time", value: "finishTime" },
        { text: "Invite Time", value: "inviteTime" },
        { text: "Stage", value: "stage" },
        { text: "Test Status", value: "testStatus" },
      ],
    };
  },
  computed: {
    ...mapGetters("admin", ["planTypeKeys", "addOnKeys"]),
    addOnsDisplayString() {
      if (!this.customer || !this.customer.getAddOns()) {
        return "";
      }
      const addOnList = this.customer.getAddOns().getAddOnList();
      const addOnsDisplay = addOnList
        .map((addOn) => this.addOnKeys[addOn] || addOn)
        .join(", ");
      return addOnsDisplay;
    },
    statusOptions() {
      return Object.keys(user_proto.TestStatus).map((key, index) => ({
        text: key.split("_").pop(),
        value: index, // Assuming the enum is zero-indexed
      }));
    },
    stageOptions() {
      return Object.keys(user_proto.CandidateStage).map((key, index) => ({
        text: key.split("_").pop(),
        value: index, // Assuming the enum is zero-indexed
      }));
    },
    filteredItems() {
      return this.items.filter((item) => {
        const statusMatch =
          this.selectedStatus !== null
            ? item.status === this.selectedStatus
            : true;
        const stageMatch =
          this.selectedStage !== null
            ? item.stage === this.selectedStage
            : true;
        return statusMatch && stageMatch;
      });
    },
    filteredInviteSummaries() {
      if (!this.customerUsage || !this.customerUsage.getInviteSummariesList()) {
        return [];
      }
      return this.customerUsage.getInviteSummariesList().filter((item) => {
        console.log(
          "status and selected status are  ..",
          item.getTestStatus(),
          this.selectedStatus
        );

        const statusMatch =
          this.selectedStatus !== null
            ? item.getTestStatus() === this.selectedStatus
            : true;
        const stageMatch =
          this.selectedStage !== null
            ? item.getStage() === this.selectedStage
            : true;
        console.log("Status Match is ..", statusMatch, stageMatch);
        return statusMatch && stageMatch;
      });
    },
  },
  methods: {
    ...mapActions("admin", ["getCustomer", "getCustomerUsage"]),
    selectPreviousMonth() {
      const now = new Date();
      // Calculate the first day of the previous month
      const startOfPreviousMonth = new Date(
        now.getFullYear(),
        now.getMonth() - 1,
        1
      );
      console.log("Start of previous month is:", startOfPreviousMonth);
      // Format startOfPreviousMonth for the datepicker
      const yearStart = startOfPreviousMonth.getFullYear();
      const monthStart = (startOfPreviousMonth.getMonth() + 1)
        .toString()
        .padStart(2, "0"); // Adjust month for 0-index
      const dayStart = startOfPreviousMonth
        .getDate()
        .toString()
        .padStart(2, "0");
      const datePickerFormatStart = `${yearStart}-${monthStart}-${dayStart}`;
      console.log(
        "Start of previous month in datepicker format is:",
        datePickerFormatStart
      );
      this.startDate = datePickerFormatStart;

      // Step 1: use the current date from the component's data

      // Step 2: Set the day to 0 to get the last day of the previous month
      const lastDayPreviousMonth = new Date(
        now.getFullYear(),
        now.getMonth(),
        0
      );

      // Step 3: Format lastDayPreviousMonth for the datepicker
      const yearEnd = lastDayPreviousMonth.getFullYear();
      const monthEnd = (lastDayPreviousMonth.getMonth() + 1)
        .toString()
        .padStart(2, "0"); // Adjust month for 0-index
      const dayEnd = lastDayPreviousMonth.getDate().toString().padStart(2, "0");
      const datePickerFormatEnd = `${yearEnd}-${monthEnd}-${dayEnd}`;

      console.log(
        "Last day of the previous month in datepicker format is:",
        datePickerFormatEnd
      );
      this.endDate = datePickerFormatEnd;
    },
    handleInput(value) {
      // Assuming domains are entered as a comma-separated list
      this.excludeDomains = value.split(",").map((domain) => domain.trim());
    },
    // Method to convert data to CSV format
    convertToCSV(data) {
      const csvRows = [];
      // Get headers
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(","));

      // Loop over the rows
      for (const row of data) {
        const values = headers.map((header) => {
          const escaped = ("" + row[header]).replace(/"/g, '\\"'); // Escape double quotes
          return `"${escaped}"`; // Wrap values in double quotes to handle commas and line breaks
        });
        csvRows.push(values.join(","));
      }

      return csvRows.join("\n");
    },
    // Method to trigger the download of the CSV file
    downloadCSV() {
      const data = this.prepareDataForCSV(); // You need to implement this method
      const csvString = this.convertToCSV(data);
      const blob = new Blob([csvString], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "customer-details.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    },
    prepareDataForCSV() {
      // Use filteredInviteSummaries as the source for CSV data
      return this.filteredInviteSummaries.map((item) => ({
        Name: item.getName(), // Assuming getName() returns the name
        Email: item.getEmail(), // Assuming getEmail() returns the email
        FinishTime: this.formatDate(item.getFinishTime()), // Use your existing formatDate method
        InviteTime: this.formatDate(item.getInviteTime()), // Use your existing formatDate method
        Stage: this.testStages(item.getStage()), // Assuming testStages() translates stage to a readable format
        TestStatus: this.getstatus(item.getTestStatus()), // Assuming getstatus() translates status to a readable format
      }));
    },
    getstatus(statusNum) {
      if (!statusNum) return "N/A";
      console.log(
        "Test Statuses are ..",
        Object.keys(user_proto.TestStatus)[statusNum]
      );
      return Object.keys(user_proto.TestStatus)[statusNum].split("_").pop();
    },

    testStages(stagNum) {
      if (!stagNum) return "N/A";
      return Object.keys(user_proto.CandidateStage)[stagNum].split("_").pop();
    },
    formatDate(timestamp) {
      if (!timestamp) return "N/A";
      const date = new Date(timestamp);
      return (
        date.toLocaleDateString("en-US") +
        " " +
        date.toLocaleTimeString("en-US")
      );
    },
    fetchCustomerUsage() {
      if (this.startDate && this.endDate) {
        console.log("domains to exclude ..", this.excludeDomains);
        const customerId = this.$route.params.id; // Assuming customer ID is in the route params
        // Convert startDate and endDate to seconds (Unix timestamp)
        const startTime = Math.floor(new Date(this.startDate).getTime() / 1000);
        const endTime = Math.floor(new Date(this.endDate).getTime() / 1000);
        this.getCustomerUsage({
          customerId,
          from: startTime,
          to: endTime,
          excludeDomains: this.excludeDomains,
        })
          .then((response) => {
            this.customerUsage = response;
          })
          .catch((error) => {
            console.error("Failed to fetch customer usage:", error);
          });
      }
    },
  },
  created() {},
  mounted() {
    console.log("Mounted Id is ..", this.$route.params.id);
    this.getCustomer({ id: this.$route.params.id }).then((res) => {
      this.customer = res.getCustomer();
      this.excludeDomains = this.customer?.getEmailDomainsList();
    });
    this.selectPreviousMonth();
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
