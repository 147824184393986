<template>
  <v-container fluid>
    <div v-if="!this.user">Please login first</div>
    <Loader v-if="this.user && loading" />
    <NotFoundText v-if="this.user && !loading && !content" item="MCQ" />
    <span v-if="this.user && !loading && content">
      <div class="grey lighten-5 pl-12 pr-5 mx-0 my-0">
        <v-row>
          <v-col>
            <span class="display-2 font-weight-bold">
              {{ content.getTitle() }}
            </span>
          </v-col>
        </v-row>
      </div>
      <v-container fluid class="pl-12 pr-5 mx-0 my-0">
        <v-row>
          <v-col>
            <v-btn
              :loading="isMCQsaved"
              :disabled="isMCQsaved || mcqTab === 2"
              color="accent"
              height="35"
              @click="saveMcq"
              >Save</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              :loading="isMcqPublished"
              :disabled="isPublishButtonDisabled"
              color="accent"
              height="35"
              @click="publishContentRequest"
              >Publish</v-btn
            >
          </v-col>
        </v-row>
        <v-row class="px-2">
          <v-col cols="4" sm="2">
            <v-select
              label="Publish Type"
              :items="publishTypeKeys"
              :value="publishType"
              @change="changePublishType"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-tabs color="accent" slider-color="accent" v-model="mcqTab">
              <v-tab>Preview</v-tab>
              <v-tab>Statement</v-tab>
              <v-tab>Options</v-tab>
              <v-tab>Metadata</v-tab>
              <v-tab>Permissions</v-tab>
              <v-tab>Attachment</v-tab>
            </v-tabs>

            <v-tabs-items v-model="mcqTab">
              <v-tab-item>
                <!-- mcq preview -->
                <v-row class="py-5 px-2">
                  <v-col>
                    <div v-html="view.getDataView().getMcqView()"></div>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item>
                <!-- statement -->
                <v-row class="px-2">
                  <v-col>
                    <v-text-field
                      :value="content.getTitle()"
                      @change="setTitle"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="py-5 px-2">
                  <v-col>
                    <MarkdownEditor
                      :editorialMode="false"
                      :submissionMode="true"
                      :code="
                        content.getData().getMcq() &&
                        content.getData().getMcq().getStatement()
                      "
                      :onCodeChange="onMarkdownCodeChange"
                    />
                  </v-col>
                </v-row>
              </v-tab-item>
              <!-- choices -->
              <v-tab-item>
                <mcq-options-pane :mcq="content" />
              </v-tab-item>
              <v-tab-item>
                <v-row class="px-2">
                  <v-col cols="6" sm="4">
                    <v-text-field
                      label="Time limit"
                      :value="getMcqTimeLimit"
                      @input="setMcqTimeLimit"
                    >
                    </v-text-field>
                    <v-select
                      label="MCQ Type Selection"
                      :value="mcqType"
                      :items="mcqTypeKeys"
                      @change="updateMcqType"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <!-- tags -->
                <v-row class="py-5 px-2">
                  <v-col>
                    <v-combobox
                      color="accent"
                      :items="tagsTypes"
                      :value="content.getMetaData().getTagsList()"
                      hide-selected
                      hint="tagType/tag"
                      label="Add company, problem type tags"
                      persistent-hint
                      multiple
                      small-chips
                      solo
                      @change="setTags"
                    >
                    </v-combobox>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <permissions
                  :permissions="content.getMetaData().getPermissionsMap()"
                  :initialRestrictedDomains="
                    content.getMetaData().getRestrictedDomainList()
                  "
                  @permissionChange="setPermissions"
                  @restrictedDomainChange="setRestrictedDomains"
                ></permissions>
              </v-tab-item>
              <v-tab-item>
                <!--Attachment-->
                <v-row>
                  <v-col>
                    <v-file-input
                      :rules="rules"
                      accept="image/*"
                      placeholder="Pick an avatar"
                      prepend-icon="mdi-camera"
                      label="Avatar"
                      v-model="attachmentFile"
                    ></v-file-input>
                    <v-btn color="accent" @click="uploadAttachmentSave"
                      >Save Attachment
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="attachment && attachment.getUrl()">
                  <v-col>
                    Uploaded Image URL ...: {{ attachment.getUrl() }}
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <confirm-dialog ref="confirm"></confirm-dialog>
            <v-btn color="error" height="35" @click="deleteContentRequest"
              >Delete MCQ</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </span>
  </v-container>
</template>

<script>
import { getters, mapActions, mapState, mapMutations, mapGetters } from "vuex";
import MarkdownEditor from "../components/MarkdownEditor";
import CodeEditor from "../components/CodeEditor";
import NotFoundText from "../components/NotFoundText";
import Loader from "../components/Loader";
import ConfirmDialog from "../components/ConfirmDialog.vue";
import McqOptionsPane from "../components/McqOptionsPane.vue";
import Permissions from "../components/Permissions.vue";

export default {
  props: ["url"],
  data() {
    return {
      mcqTab: null,
      isMCQsaved: false,
      isMcqPublished: false,
      tagsTypes: [
        "types/interview",
        "types/competitive",
        "drills/topic",
        "drills/company",
        "companies/adobe",
        "companies/amazon",
        "companies/apple",
        "companies/atlassian",
        "companies/cisco",
        "companies/directi",
        "companies/ebay",
        "companies/facebook",
        "companies/flipkart",
        "companies/goldman_sachs",
        "companies/google",
        "companies/jpmorgan_chase",
        "companies/linkedin",
        "companies/microsoft",
        "companies/oracle",
        "companies/paypal",
        "companies/sap",
        "companies/twitter",
        "companies/uber",
        "companies/vmware",
        "companies/walmart",
        "difficulties/easy",
        "difficulties/medium",
        "difficulties/hard",
      ],

      attachmentFile: null,
      rules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Avatar size should be less than 2 MB!",
      ],
    };
  },
  components: {
    MarkdownEditor,
    NotFoundText,
    Loader,
    ConfirmDialog,
    McqOptionsPane,
    Permissions,
  },
  computed: {
    ...mapState("mcq", ["content", "view", "loading"]),
    ...mapState("user", ["user", "hasPremiumAccess", "editorConfig"]),
    ...mapState("mcq", ["attachment"]),
    premiumBlock() {
      return this.premiumOnly && !this.hasPremiumAccess;
    },
    ...mapGetters("mcq", [
      "publishType",
      "publishTypeKeys",
      "mcqTypeKeys",
      "mcqType",
      "getMcqTimeLimit",
    ]),
    isPublishButtonDisabled() {
      return (
        this.content.getVersion() == this.content.getPublishVersion() ||
        this.isMcqPublished
      );
    },
  },
  methods: {
    ...mapActions("mcq", ["fetchMcq", "updateMcq"]),
    ...mapActions("mcq", ["updateAttachment"]),
    ...mapActions("content", ["publishContent", "deleteContent"]),
    ...mapMutations("mcq", [
      "setStatement",
      "setTitle",
      "changePublishType",
      "setMcqType",
      "setTags",
      "setMcqTimeLimit",
    ]),
    ...mapMutations("mcq", ["setPermissions", "setRestrictedDomains"]),
    onMarkdownCodeChange(newCode) {
      this.setStatement(newCode);
    },
    updateMcqType(typ) {
      this.setMcqType(typ);
      this.saveMcq();
    },
    saveMcq() {
      this.isMCQsaved = true;
      this.updateMcq({ autopublish: true }).then((__) =>
        this.$router
          .push("/mcqs/" + this.content.getUrl())
          .catch((__) => {})
          .finally((__) => (this.isMCQsaved = false))
      );
    },
    deleteContentRequest() {
      this.$refs.confirm
        .open("Delete", "Are you sure you want to delete this MCQ?", {
          color: "red",
        })
        .then((confirm) => {
          if (confirm)
            this.deleteContent({ id: this.content.getId() }).then((_) =>
              this.$router.push("/mcqs")
            );
        });
    },
    publishContentRequest() {
      if (
        this.content.toObject().version !=
        this.content.toObject().publishVersion
      ) {
        this.isMcqPublished = true;
        this.publishContent({ id: this.content.getId() }).then((_) =>
          this.$router.go("/mcqs/" + this.content.getUrl()).finally((__) => {
            this.isMcqPublished = false;
          })
        );
      }
    },
    uploadAttachmentSave() {
      console.log("File", this.attachmentFile);
      this.updateAttachment({ attachment: this.attachmentFile });
    },
  },
  watch: {
    user: function () {
      console.log("Changing user ...");
    },
    editorConfig: function () {
      this.updateUserPreference();
    },
  },
  created() {
    console.log("Creating...", this.url);
    this.fetchMcq({ url: this.url }).then((__) => {
      document.title = this.content.getTitle() + " - CodeDrills";
    });
    // console.log(
    //   `mcqMeta`,
    //   this.content.getMetaData().getMcqMeta().toObject(),
    //   this.mcqTypeKeys
    // );
  },
};
</script>
<style scoped>
.is-deleted {
  text-decoration: line-through;
}
</style>
