var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_vm.getTestCaseStatus != 2 ? _c('loader') : _c('v-container', [_c('v-row', [_c('v-col', [_vm._v(" Input ")])], 1), _c('v-row', [_c('v-col', [_c('code-display', {
    attrs: {
      "value": _vm.input,
      "availableLanguages": ['TXT']
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_vm._v(" Output ")])], 1), _c('v-row', [_c('v-col', [_c('code-display', {
    attrs: {
      "value": _vm.output,
      "availableLanguages": ['TXT']
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }