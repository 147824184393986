/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import builder from "codedrills_proto/io/codedrills/proto/builder/content_service_grpc_web_pb";
import { ApiCall } from "../../utils/api";
// import { version } from "core-js";
var content_proto = proto.io.codedrills.proto.content;
var builder_proto = proto.io.codedrills.proto.builder;

const builderService = new builder.ContentServicePromiseClient(
  process.env.VUE_APP_BUILDER_API_URL + "/builder",
  null,
  null
);

const state = {
  content: null,
  view: null,
  loading: null,
  fetchSubjectiveStatus: null,
  updateSubjectiveStatus: null,
};

const actions = {
  fetchSubjective: new ApiCall("fetchSubjective")
    .withServiceCall((r, h) => builderService.getContent(r, h))
    .withRequest(({ url }) => {
      var getContentRequest = new builder_proto.GetContentRequest();
      var contentId = new content_proto.ContentId();
      var contentAddress = new content_proto.ContentAddress();
      contentAddress.setContentType(
        proto.io.codedrills.proto.content.ContentType.SUBJECTIVE
      );
      contentAddress.setUrl(url);
      contentId.setContentAddress(contentAddress);
      getContentRequest.setContentId(contentId);

      return getContentRequest;
    })
    .onSuccess(({ commit }, res) => {
      commit("setSubjective", res);
      console.log(`setSubjective: `, res.toObject());
    })
    .build(),
  updateSubjective: new ApiCall("updateSubjective")
    .withServiceCall((r, h) => builderService.updateContent(r, h))
    .withRequest(({ autoPublish }) => {
      return new builder_proto.UpdateContentRequest()
        .setId(state.content.getId())
        .setTitle(state.content.getTitle())
        .setVersion(state.content.getVersion())
        .setMetaData(state.content.getMetaData())
        .setData(state.content.getData())
        .setPublishType(state.content.getPublishType())
        .setContentType(state.content.getContentType())
        .setAutoPublish(autoPublish);
    })
    .onSuccess(({ commit }, res) => {
      commit("setSubjective", res);
    })
    .build(),
};

const getters = {
  userToken: function (state, getters, rootState, rootGetters) {
    parameters.addValue("meta_data", content.getMetaData().toByteArray());
    console.log("rootGetters", rootGetters);
    return rootGetters["user/userToken"];
  },
  types: function () {
    return Object.keys(proto.io.codedrills.proto.content.ContentType);
  },
  publishType(state, getters) {
    return getters.publishTypeKeys[state.content.getPublishType()];
  },
  publishTypeKeys() {
    return Object.keys(content_proto.PublishType);
  },
  getSubjectiveTimeLimit(state) {
    return state.content.getMetaData()?.getTimeLimit() || 0;
  },
};

const mutations = {
  toggleLoading(state, content) {
    state.loading = content;
  },

  setSubjective(state, res) {
    state.content = res.getContent();
    state.view = res.getContentView();
  },
  fetchSubjectiveStatus(state, status) {
    state.fetchSubjectiveStatus = status;
  },
  updateSubjectiveStatus(state, status) {
    state.updateSubjectiveStatus = status;
  },
  setTitle(state, title) {
    state.content.setTitle(title);
  },

  setStatement(state, statement) {
    state.content.getData().setSubjective(new content_proto.Subjective());
    state.content.getData().getSubjective().setStatement(statement);
  },

  changePublishType(state, publishType) {
    console.log("Publish Type", publishType);
    state.content.setPublishType(content_proto.PublishType[publishType]);
  },

  setPermissions(state, ids) {
    console.log("ids", ids);
    state.content.getMetaData().getPermissionsMap().clear();
    for (var id of ids) {
      state.content
        .getMetaData()
        .getPermissionsMap()
        .set(
          id,
          new content_proto.Permission().setAccess(content_proto.Access.AUTHOR)
        );
    }
    console.log(
      "MetaData with permissions on skill",
      state.content.getMetaData().toObject()
    );
  },
  setTags(state, tags) {
    state.content.getMetaData().setTagsList(tags);
  },
  setSubjectiveTimeLimit(state, value) {
    state.content.getMetaData().setTimeLimit(value);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
