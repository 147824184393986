<template>
  <v-container fluid>
    <div v-if="!this.user">Please login first</div>
    <Loader v-if="this.user && loading" />
    <NotFoundText v-if="this.user && !loading && !content" item="article" />
    <span v-if="this.user && !loading && content">
      <div class="grey lighten-5 pl-12 pr-5 mx-0 my-0">
        <v-row>
          <v-col>
            <span class="display-2 font-weight-bold">
              {{ content.getTitle() }}
            </span>
          </v-col>
        </v-row>
      </div>
      <v-container fluid class="pl-12 pr-5 mx-0 my-0">
        <v-row>
          <v-col>
            <v-btn
              :loading="isArticleSaved"
              :disabled="isArticleSaved"
              color="accent"
              height="35"
              @click="saveArticle"
              >Save</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              :loading="isArticlePublished"
              :disabled="isPublishButtonDisabled"
              color="accent"
              height="35"
              @click="publishContentRequest"
              >Publish</v-btn
            >
          </v-col>
        </v-row>
        <v-row class="px-2">
          <v-col cols="4" sm="2">
            <v-select
              label="Publish Type"
              :items="publishTypeKeys"
              :value="publishType"
              @change="changePublishType"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-tabs color="accent" slider-color="accent" v-model="articleTab">
              <v-tab>Preview</v-tab>
              <v-tab>Statement</v-tab>
            </v-tabs>

            <v-tabs-items v-model="articleTab">
              <v-tab-item>
                <!-- article preview -->
                <v-row class="py-5 px-2">
                  <v-col>
                    <div v-html="view.getDataView().getArticleView()"></div>
                  </v-col>
                </v-row>
                <v-row class="py-5 px-2">
                  <v-col>
                    <v-chip
                      class="mr-1"
                      color="accent"
                      v-for="tag in content.getMetaData().getTagsList()"
                      :key="tag"
                    >
                      {{ tag }}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item>
                <!-- statement -->
                <v-row class="px-2">
                  <v-col>
                    <v-text-field
                      :value="content.getTitle()"
                      @change="setTitle"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="py-5 px-2">
                  <v-col>
                    <MarkdownEditor
                      :editorialMode="false"
                      :submissionMode="true"
                      :code="
                        content.getData().getArticle() &&
                        content.getData().getArticle().getStatementString()
                      "
                      :onCodeChange="onMarkdownCodeChange"
                    />
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <confirm-dialog ref="confirm"></confirm-dialog>
            <v-btn color="error" height="35" @click="deleteContentRequest"
              >Delete Problem</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </span>
  </v-container>
</template>

<script>
import { getters, mapActions, mapState, mapMutations, mapGetters } from "vuex";
import MarkdownEditor from "../components/MarkdownEditor";
import CodeEditor from "../components/CodeEditor";
import NotFoundText from "../components/NotFoundText";
import Loader from "../components/Loader";
import ConfirmDialog from "../components/ConfirmDialog.vue";
export default {
  props: ["url"],
  data() {
    return {
      activeTab: "description",
      articleTab: null,
      deletedFiles: new Map(),
      isArticleSaved: false,
      isArticlePublished: false,
    };
  },
  components: {
    MarkdownEditor,
    NotFoundText,
    Loader,
    ConfirmDialog,
  },
  computed: {
    ...mapState("article", ["content", "view", "loading"]),
    ...mapState("user", ["user", "hasPremiumAccess", "editorConfig"]),
    premiumBlock() {
      return this.premiumOnly && !this.hasPremiumAccess;
    },
    ...mapGetters("article", ["publishType", "publishTypeKeys"]),
    styles() {
      return { width: `${this.rating * 20}%` };
    },
    isPublishButtonDisabled() {
      return (
        this.content.getVersion() == this.content.getPublishVersion() ||
        this.isArticlePublished
      );
    },
  },
  methods: {
    ...mapActions("article", ["fetchArticle", "updateArticle"]),
    ...mapActions("content", ["publishContent", "deleteContent"]),
    ...mapMutations("article", [
      "setStatement",
      "setTitle",
      "changePublishType",
    ]),
    onMarkdownCodeChange(newCode) {
      this.setStatement(newCode);
    },
    changeTab(tab) {
      this.activeTab = tab;
    },
    run() {},

    saveArticle() {
      this.isArticleSaved = true;
      this.updateArticle({}).then((__) =>
        this.$router
          .push("/articles/" + this.content.getUrl())
          .catch((__) => {})
          .finally((__) => (this.isArticleSaved = false))
      );
    },
    deleteContentRequest() {
      this.$refs.confirm
        .open("Delete", "Are you sure you want to delete this article?", {
          color: "red",
        })
        .then((confirm) => {
          if (confirm)
            this.deleteContent({ id: this.content.getId() }).then((_) =>
              this.$router.push("/")
            );
        });
    },
    publishContentRequest() {
      if (
        this.content.toObject().version !=
        this.content.toObject().publishVersion
      ) {
        this.isArticlePublished = true;
        this.publishContent({ id: this.content.getId() }).then((_) =>
          this.$router.push("/").finally((__) => {
            this.isArticlePublished = false;
          })
        );
      }
    },
  },
  watch: {
    user: function () {
      console.log("Changing user ...");
    },
    editorConfig: function () {
      this.updateUserPreference();
    },
  },
  created() {
    console.log("Creating...", this.url);
    this.fetchArticle({ url: this.url }).then((__) => {
      //document.title = this.content.getTitle() + " - CodeDrills";
    });
  },
};
</script>
<style scoped>
.is-deleted {
  text-decoration: line-through;
}
</style>
