var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    staticClass: "px-0"
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', {
    staticClass: "py-0"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-select', {
    staticClass: "ml-0",
    attrs: {
      "items": _vm.languages,
      "outlined": "",
      "hide-details": "",
      "append-icon": ""
    },
    model: {
      value: _vm.language,
      callback: function ($$v) {
        _vm.language = $$v;
      },
      expression: "language"
    }
  })], 1), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0 px-1",
    attrs: {
      "cols": "1"
    }
  }, [_c('v-select', {
    staticClass: "ml-0 font-weight-light",
    attrs: {
      "items": _vm.themes,
      "outlined": "",
      "hide-details": "",
      "append-icon": ""
    },
    model: {
      value: _vm.theme,
      callback: function ($$v) {
        _vm.theme = $$v;
      },
      expression: "theme"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0 px-0",
    attrs: {
      "cols": "1"
    }
  }, [_c('v-select', {
    staticClass: "ml-0 font-weight-light",
    attrs: {
      "items": _vm.keyMaps,
      "outlined": "",
      "hide-details": "",
      "append-icon": ""
    },
    model: {
      value: _vm.keyMap,
      callback: function ($$v) {
        _vm.keyMap = $$v;
      },
      expression: "keyMap"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0 px-1",
    attrs: {
      "cols": "1"
    }
  }, [_c('v-select', {
    staticClass: "ml-0 font-weight-light",
    attrs: {
      "items": _vm.fontSizes,
      "outlined": "",
      "hide-details": "",
      "append-icon": ""
    },
    model: {
      value: _vm.fontSize,
      callback: function ($$v) {
        _vm.fontSize = $$v;
      },
      expression: "fontSize"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0 px-0 pr-3",
    attrs: {
      "cols": "1"
    }
  }, [_c('v-select', {
    staticClass: "ml-0 font-weight-light",
    attrs: {
      "items": _vm.tabSizes,
      "outlined": "",
      "hide-details": "",
      "append-icon": ""
    },
    model: {
      value: _vm.tabSize,
      callback: function ($$v) {
        _vm.tabSize = $$v;
      },
      expression: "tabSize"
    }
  })], 1)], 1), _c('v-divider'), _c('v-row', [_c('v-col', {
    staticClass: "py-0"
  }, [_c('CodeMirror', {
    ref: "myCm",
    class: _vm.fontSize,
    attrs: {
      "value": _vm.code,
      "options": _vm.cmOptions
    },
    on: {
      "ready": _vm.onCmReady,
      "focus": _vm.onCmFocus,
      "input": _vm.onCodeChange
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }