//import orb from '../../gen/orb_grpc_web_pb';
//import "../../gen/orb_pb";
import builder from "codedrills_proto/io/codedrills/proto/builder/content_service_grpc_web_pb";
import "codedrills_proto/io/codedrills/proto/builder/content_service_pb";
import "codedrills_proto/io/codedrills/proto/content/content_pb";
import { ApiCall } from "@/utils/api.js";
var content_proto = proto.io.codedrills.proto.content;
var builder_proto = proto.io.codedrills.proto.builder;

console.log("env", process.env.VUE_APP_BUILDER_API_URL);
const builderService = new builder.ContentServicePromiseClient(
  process.env.VUE_APP_BUILDER_API_URL + "/builder",
  null,
  null
);

const state = {
  contents: null,
  listContentsStatus: null,
  migrateTagsStatus: null,
  metaData: null,
};

const actions = {
  fetchContents: new ApiCall("listContents")
    .authRequired()
    .withServiceCall((r, h) => builderService.listContent(r, h))
    .withRequest(() => {
      return new builder_proto.ListContentRequest();
    })
    .onSuccess(({ commit }, res) =>
      commit("setContents", res.getContentsList().reverse())
    )
    .build(),

  migrateTags: new ApiCall("migrateTags")
    .authRequired()
    .withServiceCall((r, h) => builderService.migrateTags(r, h))
    .withRequest(() => {
      return new builder_proto.MigrateTagsRequest();
    })
    .onSuccess(({ commit }, res) => console.log("Migration over"))
    .build(),

  async createContent(
    { commit, state, getters, dispatch },
    { title, contentType, template }
  ) {
    return dispatch("user/ensureUserToken", null, { root: true }).then(
      async (userToken) => {
        var createContentRequest = new builder_proto.CreateContentRequest();
        console.log(
          "Title, contentType, ..",
          title,
          content_proto.ContentType[contentType]
        );
        createContentRequest.setTitle(title);
        createContentRequest.setType(content_proto.ContentType[contentType]);
        if (template != null)
          await template
            .text()
            .then((content) => createContentRequest.setContentJson(content));

        console.log("Create content request", createContentRequest.toObject());
        return builderService
          .createContent(createContentRequest, { Authorization: userToken })
          .then((res) => {
            console.log("SUCCESS", res.toObject());
            return res;
          })
          .catch((err) => {
            console.error("ERROR", err);
            throw err;
          });
      }
    );
  },

  async publishContent({ commit, state, getters, dispatch }, { id }) {
    return dispatch("user/ensureUserToken", null, { root: true }).then(
      (userToken) => {
        var publishContentRequest = new builder_proto.PublishContentRequest();
        publishContentRequest.setContentId(id);

        return builderService
          .publishContent(publishContentRequest, { Authorization: userToken })
          .then((res) => {
            console.log("SUCCESS", res.toObject());
            return res;
          })
          .catch((err) => {
            console.error("ERROR", err);
            throw err;
          });
      }
    );
  },
  async deleteContent({ commit, state, getters, dispatch }, { id }) {
    return dispatch("user/ensureUserToken", null, { root: true }).then(
      (userToken) => {
        var deleteContentRequest = new builder_proto.DeleteContentRequest();
        var contentId = new content_proto.ContentId();
        contentId.setId(id);
        deleteContentRequest.setContentId(contentId);

        console.log("Deleting ...", id);

        return builderService
          .deleteContent(deleteContentRequest, { Authorization: userToken })
          .then((res) => {
            console.log("SUCCESS", res.toObject());
            return res;
          })
          .catch((err) => {
            console.error("ERROR", err);
            throw err;
          });
      }
    );
  },

  fetchMetaData: new ApiCall("fetchMetaData")
    .withServiceCall((r, h) => builderService.getMetaData(r, h))
    .withRequest(({ url }) => {
      return new builder_proto.GetMetaDataRequest().setContentId(
        new content_proto.ContentId().setContentAddress(
          new content_proto.ContentAddress()
            .setUrl(url)
            .setContentType(content_proto.ContentType.TEST)
        )
      );
    })
    .onSuccess(({ commit }, res) => {
      commit("setMetaData", res);
      return res;
    })
    .build(),

  updatedAssessmentExpiry: new ApiCall("updatedAssessmentExpiry")
    .withServiceCall((r, h) => builderService.updateMetaData(r, h))
    .withRequest(({ url }) => {
      return new builder_proto.UpdateMetaDataRequest()
        .setMetaData(state.metaData)
        .setContentId(
          new content_proto.ContentId().setContentAddress(
            new content_proto.ContentAddress()
              .setUrl(url)
              .setContentType(content_proto.ContentType.TEST)
          )
        );
    })
    .onSuccess(({ commit }, res) => console.log("Uploading done ..."))
    .build(),
};

const getters = {
  contents: (state) => {
    return state.content;
  },
  userToken: function (state, getters, rootState, rootGetters) {
    console.log("rootGetters", rootGetters);
    return rootGetters["user/userToken"];
  },
  contentTypeKeys() {
    return Object.keys(content_proto.ContentType);
  },
  getExpiry() {
    state.metaData.getTestMeta().getTestUrlExpiry();
  },
};

const mutations = {
  setContents(state, contents) {
    state.contents = contents;
  },
  setCurrent(state, current) {
    state.current = current;
  },
  setContentType(state, contentType) {
    state.contentType = contentType;
  },
  listContentsStatus(state, status) {
    state.listContentsStatus = status;
  },
  migrateTagsStatus(state, status) {
    state.migrateTagsStatus = status;
  },
  setMetaData(state, res) {
    state.metaData = res.getMetaData();
  },
  setExpiry(state, expiry) {
    state.metaData.getTestMeta().setTestByUrlEnabled(true);
    state.metaData.getTestMeta().setTestUrlExpiry(expiry * 1000);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
