<template>
  <v-footer>
    <span class="font-weight-light">
      &copy; {{ new Date().getFullYear() }} Codedrills Education Pvt. Ltd. All
      Rights Reserved.
    </span>
    <v-spacer></v-spacer>
    <router-link to="privacy-policy"
      ><span class="font-weight-thin mr-2">Privacy Policy</span></router-link
    >
    |
    <router-link to="terms-of-service"
      ><span class="font-weight-thin ml-2">Terms of service</span></router-link
    >
  </v-footer>
</template>

<script>
export default {
  name: "tail",
  components: {},
};
</script>
