<template>
  <v-snackbar
    :value="visible"
    :color="type"
    elevation="0"
    :timeout="currentDuration"
  >
    <div class="d-flex">
      <v-icon large class="mr-5">
        {{ notifIcon }}
      </v-icon>
      <div class="text-h6 font-weight-medium text-center">
        {{ text }}
      </div>
    </div>
    <template v-slot:action="{ attrs }">
      <v-icon large color="white" v-bind="attrs" @click="visible = false">
        {{ mdiClose }}
      </v-icon>
    </template>
  </v-snackbar>
</template>
<script>
import { mapActions } from "vuex";
import {
  mdiClose,
  mdiCheck,
  mdiAlertCircle,
  mdiInformationOutline,
} from "@mdi/js";
/**
 * notification has
 * - id
 * - text
 * - type: 'success' | 'error' | 'info'
 * - duration?: optional
 */
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      validator: (value) => ["success", "error", "info"].includes(value),
      default: "info",
      required: true,
    },
    duration: {
      type: Number,
      required: false,
      default: 7000,
    },
  },
  data() {
    return {
      visible: false,
      mdiCheck,
      mdiAlertCircle,
      mdiClose,
      mdiInformationOutline,
      notifIcon: "",
      currentDuration: 0,
    };
  },
  created() {
    this.visible = true;
    this.currentDuration = this.duration;
    if (this.type === "success") {
      this.notifIcon = this.mdiCheck;
    } else if (this.type === "info") {
      this.notifIcon = this.mdiInformationOutline;
    } else {
      this.notifIcon = this.mdiAlertCircle;
      this.currentDuration = 20000;
    }
    console.log("Duration for id ", this.currentDuration, this.id);
  },
  methods: {
    ...mapActions("notifs", ["removeNotif"]),
  },
};
</script>
