import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Login from "./views/Login.vue";
import Problem from "./views/Problem.vue";
import Article from "./views/Article.vue";
import Drill from "./views/Drill.vue";
import Mcq from "./views/Mcq.vue";
import MCQs from "./views/MCQs.vue";
import Skills from "./views/Skills.vue";
import Skill from "./views/Skill.vue";
import SingleSubjective from "./views/SingleSubjective.vue";
import Subjectives from "./views/Subjectives.vue";
import Contest from "./views/Contest.vue";
import SubmissionDetails from "./views/SubmissionDetails.vue";
import ContestAdmin from "./views/ContestAdmin.vue";
import Admin from "./views/Admin.vue";
import TestCase from "./views/TestCase.vue";
import ProblemAdmin from "./views/ProblemAdmin.vue";
import Scoreboard from "./views/Scoreboard.vue";
import CustomerDetails from "./views/CustomerDetails.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: { title: "Contents - CodeDrills" },
    },
    {
      path: "/admin",
      name: "admin",
      component: Admin,
      props: true,
      meta: { title: "Admin - CodeDrills" },
    },
    // {
    //   path: "/:initialTab",
    //   name: "contents",
    //   component: Home,
    //   props: true,
    //   meta: { title: "Problems - CodeDrills" }
    // },
    {
      path: "/problems/:url",
      name: "Problem",
      component: Problem,
      props: true,
      meta: { title: "Problems - CodeDrills" },
    },
    {
      path: "/problems/:url/admin",
      name: "Problem Admin",
      component: ProblemAdmin,
      props: true,
      meta: { title: "Problems Admin - CodeDrills" },
    },
    {
      path: "/problems/:url/tests/:testSet/:testId",
      name: "TestCase",
      component: TestCase,
      props: true,
      meta: { title: "TestCase - CodeDrills" },
    },
    {
      path: "/articles/:url",
      name: "Article",
      component: Article,
      props: true,
      meta: { title: "Article - CodeDrills" },
    },
    {
      path: "/contests/:url",
      name: "Contests",
      component: Contest,
      props: true,
      meta: { title: "Contest - CodeDrills" },
    },
    {
      path: "/submissions/:id",
      name: "Submission Details",
      component: SubmissionDetails,
      props: true,
      meta: { title: "Submission Details - CodeDrills" },
    },
    {
      path: "/contests/:url/admin",
      name: "Contests Admin",
      component: ContestAdmin,
      props: true,
      meta: { title: "Contest Admin - CodeDrills" },
    },
    {
      path: "/contests/:url/scoreboard",
      name: "Contests Scoreboard",
      component: Scoreboard,
      props: true,
      meta: { title: "Contest Scoreboard - CodeDrills" },
    },
    {
      path: "/drills/:url",
      name: "Drill",
      component: Drill,
      props: true,
      meta: { title: "Drill - CodeDrills" },
    },
    {
      path: "/mcqs",
      name: "MCQs",
      component: MCQs,
      props: true,
      meta: { title: "MCQs - CodeDrills" },
    },
    {
      path: "/subjectives",
      name: "All Subjective Questions",
      component: Subjectives,
      props: true,
      meta: { title: "Subjective - CodeDrills" },
    },
    {
      path: "/subjectives/:url",
      name: "Subjective Question",
      component: SingleSubjective,
      props: true,
      meta: { title: "Subjective Qs - CodeDrills" },
    },
    {
      path: "/mcqs/:url",
      name: "MCQ",
      component: Mcq,
      props: true,
      meta: { title: "MCQs - CodeDrills" },
    },
    {
      path: "/skills",
      name: "All Skills",
      component: Skills,
      props: true,
      meta: { title: "Skills - CodeDrills" },
    },
    {
      path: "/skills/:url",
      name: "Skill",
      component: Skill,
      props: true,
      meta: { title: "Skills - CodeDrills" },
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: { title: "Login - CodeDrills" },
    },
    {
      path: "/admin/customer/:id",
      name: "CustomerDetails",
      component: CustomerDetails,
      meta: { title: "Customer Details - CodeDrills" },
    },
    {
      path: "/about",
      name: "about",
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/About.vue"),
      meta: { title: "About - CodeDrills" },
    },
    {
      path: "/terms-of-service",
      name: "terms-of-service",
      component: () => import(/* webpackChunkName: "tos" */ "./views/TOS.vue"),
      meta: { title: "Terms of Service - CodeDrills" },
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      component: () =>
        import(/* webpackChunkName: "privacy" */ "./views/Privacy.vue"),
      meta: { title: "Privacy Policy - CodeDrills" },
    },
  ],
});
