<template>
  <v-container fluid>
    <div v-if="!this.user">Please login first</div>
    <Loader v-if="this.user && loading" />
    <NotFoundText v-if="this.user && !loading && !content" item="subjective" />
    <span v-if="this.user && !loading && content">
      <div class="grey lighten-5 pl-12 pr-5 mx-0 my-0">
        <v-row>
          <v-col>
            <span class="display-2 font-weight-bold">
              {{ content.getTitle() }}
            </span>
          </v-col>
        </v-row>
      </div>
      <v-container fluid class="pl-12 pr-5 mx-0 my-0">
        <v-row>
          <v-col>
            <v-btn
              :loading="isSubjectiveSaved"
              :disabled="isSubjectiveSaved"
              color="accent"
              height="35"
              @click="saveSubjective"
              >Save</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              :loading="isSubjectivePublished"
              :disabled="isPublishButtonDisabled"
              color="accent"
              height="35"
              @click="publishContentRequest"
              >Publish</v-btn
            >
          </v-col>
        </v-row>
        <v-row class="px-2">
          <v-col cols="4" sm="2">
            <v-select
              label="Publish Type"
              :items="publishTypeKeys"
              :value="publishType"
              @change="changePublishType"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-tabs color="accent" slider-color="accent" v-model="subjTab">
              <v-tab>Preview</v-tab>
              <v-tab>Statement</v-tab>
              <v-tab>Metadata</v-tab>
              <v-tab>Permissions</v-tab>
            </v-tabs>

            <v-tabs-items v-model="subjTab">
              <v-tab-item>
                <!-- subjective preview -->
                <v-row class="py-5 px-2">
                  <v-col>
                    <div v-html="view.getDataView().getSubjectiveView()"></div>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item>
                <!-- statement -->
                <v-row class="px-2">
                  <v-col>
                    <v-text-field
                      :value="content.getTitle()"
                      @change="setTitle"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="py-5 px-2">
                  <v-col>
                    <MarkdownEditor
                      :editorialMode="false"
                      :submissionMode="true"
                      :code="
                        content.getData().getSubjective() &&
                        content.getData().getSubjective().getStatement()
                      "
                      :onCodeChange="onMarkdownCodeChange"
                    />
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <!-- metadata -->
                <v-row class="py-5 px-2">
                  <v-col>
                    <v-text-field
                      label="Time limit"
                      :value="getSubjectiveTimeLimit"
                      @input="setSubjectiveTimeLimit"
                    >
                    </v-text-field>
                    <v-combobox
                      color="accent"
                      :items="tagsTypes"
                      :value="content.getMetaData().getTagsList()"
                      hide-selected
                      hint="tagType/tag"
                      label="Add company, problem type tags"
                      persistent-hint
                      multiple
                      small-chips
                      solo
                      @change="setTags"
                    >
                    </v-combobox>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <permissions
                  :permissions="content.getMetaData().getPermissionsMap()"
                  :initialRestrictedDomains="
                    content.getMetaData().getRestrictedDomainList()
                  "
                  @permissionChange="setPermissions"
                  @restrictedDomainChange="setRestrictedDomains"
                ></permissions>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <confirm-dialog ref="confirm"></confirm-dialog>
            <v-btn color="error" height="35" @click="deleteContentRequest">
              Delete Subjective
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </span>
  </v-container>
</template>

<script>
import { getters, mapActions, mapState, mapMutations, mapGetters } from "vuex";
import MarkdownEditor from "../components/MarkdownEditor";
import NotFoundText from "../components/NotFoundText";
import Loader from "../components/Loader";
import ConfirmDialog from "../components/ConfirmDialog.vue";
import Permissions from "../components/Permissions.vue";

export default {
  props: ["url"],
  data() {
    return {
      subjTab: null,
      isSubjectiveSaved: false,
      isSubjectivePublished: false,
      tagsTypes: [
        "types/interview",
        "types/competitive",
        "drills/topic",
        "drills/company",
        "companies/adobe",
        "companies/amazon",
        "companies/apple",
        "companies/atlassian",
        "companies/cisco",
        "companies/directi",
        "companies/ebay",
        "companies/facebook",
        "companies/flipkart",
        "companies/goldman_sachs",
        "companies/google",
        "companies/jpmorgan_chase",
        "companies/linkedin",
        "companies/microsoft",
        "companies/oracle",
        "companies/paypal",
        "companies/sap",
        "companies/twitter",
        "companies/uber",
        "companies/vmware",
        "companies/walmart",
        "difficulties/easy",
        "difficulties/medium",
        "difficulties/hard",
      ],
    };
  },
  components: {
    MarkdownEditor,
    NotFoundText,
    Loader,
    ConfirmDialog,
    Permissions,
  },
  computed: {
    ...mapState("subjective", ["content", "view", "loading"]),
    ...mapState("user", ["user", "editorConfig"]),
    ...mapGetters("subjective", [
      "publishType",
      "publishTypeKeys",
      "getSubjectiveTimeLimit",
    ]),
    isPublishButtonDisabled() {
      return (
        this.content.getVersion() == this.content.getPublishVersion() ||
        this.isSubjectivePublished
      );
    },
  },
  methods: {
    ...mapActions("subjective", ["fetchSubjective", "updateSubjective"]),
    ...mapActions("content", ["publishContent", "deleteContent"]),
    ...mapMutations("subjective", [
      "setStatement",
      "setTitle",
      "changePublishType",
      "setTags",
      "setSubjectiveTimeLimit",
    ]),
    ...mapMutations("contest", ["setPermissions", "setRestrictedDomains"]),
    onMarkdownCodeChange(newCode) {
      this.setStatement(newCode);
    },
    saveSubjective() {
      this.isSubjectiveSaved = true;
      this.updateSubjective({}).then((__) =>
        this.$router
          .push("/subjectives/" + this.content.getUrl())
          .catch((__) => {})
          .finally((__) => (this.isSubjectiveSaved = false))
      );
    },
    deleteContentRequest() {
      this.$refs.confirm
        .open(
          "Delete",
          "Are you sure you want to delete this subjective question?",
          {
            color: "red",
          }
        )
        .then((confirm) => {
          if (confirm)
            this.deleteContent({ id: this.content.getId() }).then((_) =>
              this.$router.push("/subjectives")
            );
        });
    },
    publishContentRequest() {
      if (
        this.content.toObject().version !=
        this.content.toObject().publishVersion
      ) {
        this.isSubjectivePublished = true;
        this.publishContent({ id: this.content.getId() }).then((_) =>
          this.$router
            .go("/subjectives/" + this.content.getUrl())
            .finally((__) => {
              this.isSubjectivePublished = false;
            })
        );
      }
    },
  },
  watch: {
    user: function () {
      console.log("Changing user ...");
    },
    editorConfig: function () {
      this.updateUserPreference();
    },
  },
  created() {
    console.log("Creating...", this.url);
    this.fetchSubjective({ url: this.url }).then((__) => {
      document.title = this.content.getTitle() + " - CodeDrills";
    });
  },
};
</script>
<style scoped>
.is-deleted {
  text-decoration: line-through;
}
</style>
