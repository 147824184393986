var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', [_c('v-col', [_c('p', [_vm._v("This " + _vm._s(_vm.item) + " does not exist")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }