<template>
  <span>
    <v-row>
      <v-spacer></v-spacer>
      <v-col :cols="4">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search (title, tags)"
          clearable
          single-line
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="contents"
      disable-pagination
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-dialog v-model="addMcqDialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="accent" dark class="mb-2" v-bind="attrs" v-on="on">
                Add MCQ
              </v-btn>
            </template>
            <v-card v-if="!savingMcq">
              <v-card-title class="headline accent lighten-2" primary-title>
                Create New MCQ
              </v-card-title>

              <v-card-text>
                <v-form v-model="addMcqFormValid">
                  <v-text-field
                    v-model="newMcqTitle"
                    label="MCQ Title"
                    required
                  ></v-text-field>
                </v-form>
              </v-card-text>

              <v-divider></v-divider>
              <v-card-actions>
                <v-btn
                  :disabled="!addMcqFormValid"
                  color="accent"
                  @click="createMcq"
                >
                  Create
                </v-btn>
              </v-card-actions>
            </v-card>
            <v-card v-else>
              <v-card-title class="headline accent lighten-2" primary-title>
                Creating New MCQ
              </v-card-title>
              <v-card-actions>
                <loader></loader>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td>
            <router-link class="ml-1" :to="getUrl(item)">
              {{ item.title }}
            </router-link>
          </td>
          <td :colspan="3">
            <v-chip
              outlined
              color="grey lighten-2"
              text-color="grey"
              class="mr-2"
              v-for="t in item.metaData.tagsList"
              label
              :key="t"
              @click="searchTag"
            >
              {{ t }}
            </v-chip>
          </td>
        </tr>
      </template>
    </v-data-table>
  </span>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import Loader from "../components/Loader.vue";
export default {
  components: {
    Loader,
  },
  props: {
    contents: {
      type: Array,
    },
  },
  data: function () {
    return {
      showTags: true,
      addMcqDialog: false,
      savingMcq: false,
      addMcqFormValid: false,
      newMcqTitle: "",
      search: null,
      headers: [
        {
          text: "Title",
          align: "left",
          sortable: true,
          value: "title",
        },
        {
          text: "Tags",
          align: "left",
          sortable: true,
          value: "metaData.tagsList",
        },
      ],
    };
  },
  created() {
    console.log("contents", this.contents);
  },
  computed: {
    ...mapGetters("content", ["contentTypeKeys"]),
  },
  methods: {
    ...mapActions("content", ["createContent"]),
    getUrl(item) {
      return "/mcqs/" + item.url;
    },
    searchTag(e) {
      this.search = e.target.innerText;
    },
    getTags(t) {
      if (!this.showTags) return [];
      return (
        t && t.filter((t) => t.startsWith("topics/")).map((t) => t.substring(7))
      );
    },
    createMcq() {
      this.savingMcq = true;
      this.createContent({
        title: this.newMcqTitle,
        contentType: "MCQ",
        template: null,
      })
        .then((res) => {
          this.$router.push("mcqs/" + res.getContent().getUrl());
        })
        .catch((err) => console.error(err))
        .finally(() => {
          this.savingMcq = false;
          this.addMcqDialog = false;
        });
    },
  },
};
</script>
