/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import site from "codedrills_proto/io/codedrills/proto/site/content_view_service_grpc_web_pb";
import judge from "codedrills_proto/io/codedrills/proto/judge/judge_service_grpc_web_pb";
import { ApiCall } from "@/utils/api.js";

var judge_proto = proto.io.codedrills.proto.judge;
var content_proto = proto.io.codedrills.proto.content;

const judgeService = new judge.JudgeServicePromiseClient(
  process.env.VUE_APP_JUDGE_API_URL + "/judge",
  null,
  null
);
const state = {
  submissionStatus: null,
  submissionId: null,
  submission: null,
  solved: null,
  submissionHistory: null,
  submissionError: null,
  submissionDetail: null,
  codeTemplate: null,
  submissionPreviewsAdmin: null,
  submitStatus: 0,
  submissionStatusStatus: 0,
  fetchSubmissionsStatus: 0,
  fetchSubmissionDetailStatus: 0,
  fetchCodeTemplateStatus: 0,
  uploadContestSubmissionsStatus: 0,
  getProblemSubmissionsAdminStatus: 0,
};

const actions = {
  submit: new ApiCall("submit")
    .authRequired()
    .withServiceCall((r, h) => judgeService.submitCode(r, h))
    .withRequest(
      (
        { id, version, code, isFull, parentId, language },
        ut,
        { commit, rootGetters }
      ) => {
        console.log("Language", language);
        return new judge_proto.master.SubmitCodeRequest()
          .setProblemId(id)
          .setVersion(version)
          .setCode(code)
          .setLanguage(judge_proto.Language[language])
          .setParentId(parentId)
          .setSubmissionType(
            isFull
              ? judge_proto.SubmissionType.TEST_FULL
              : judge_proto.SubmissionType.TEST_SAMPLE
          );
      }
    )
    .onSuccess(({ commit, dispatch }, res, { id }) => {
      commit("setSubmissionId", res.getSubmissionId());
      dispatch("submissionStatus");
    })
    .build(),

  submissionStatus: new ApiCall("submissionStatus")
    .stream()
    .authRequired()
    .withServiceCall((r, h) => judgeService.submissionStatusListener(r, h))
    .withRequest((_, __, { state }) =>
      new judge_proto.master.SubmissionStatusRequest().setSubmissionId(
        state.submissionId
      )
    )
    .onUpdate(({ commit, getters, dispatch }, res) => {
      commit("setSubmissionStatus", res);
    })
    .onSuccess(({ state, dispatch }, res) => {
      if (!state.submissionStatus || state.submissionStatus.getStatus() < 10) {
        console.log("Retrying submissionStatus");
        setTimeout(() => dispatch("submissionStatus"), 3000);
      }
    })
    .onError((__, { dispatch }) =>
      setTimeout(() => dispatch("submissionStatus"), 3000)
    )
    .build(),

  fetchSubmissions: new ApiCall("fetchSubmissions")
    .authRequired()
    .withServiceCall((r, h) => judgeService.testSubmissionHistory(r, h))
    .withRequest(({ problemId, userId, parentId, paginationQuery }, _, __) => {
      console.log("pagination", paginationQuery);
      return new judge_proto.master.TestSubmissionHistoryRequest()
        .setProblemId(problemId)
        .setParentId(new content_proto.ContentId().setId(parentId))
        .setPaginationQuery(
          new content_proto.PaginationQuery()
            .setItemsPerPage(paginationQuery.itemsPerPage || 10)
            .setPageIndex((paginationQuery.page || 1) - 1)
        );
    })
    .onSuccess(({ commit, getters, dispatch }, res) => {
      console.log("Subs ..", res.toObject());
      commit("setSubmissionHistory", res.getSubmissionStatusList());
      return res;
    })
    .build(),

  fetchSubmissionDetail: new ApiCall("fetchSubmissionDetail")
    .authRequired()
    .withServiceCall((r, h) => judgeService.submission(r, h))
    .withRequest(({ submissionId }, _, { commit }) => {
      commit("setSubmissionDetail", null);
      return new judge_proto.master.SubmissionRequest().setSubmissionId(
        submissionId
      );
    })
    .onSuccess(({ commit, getters, dispatch }, res) => {
      commit("setSubmissionDetail", res);
      return res;
    })
    .build(),

  async loadHistory({ state, dispatch, commit }, args) {
    commit("setSubmissionDetail", null);
    return dispatch("fetchSubmissions", args);
  },

  async loadLastSubmission({ state, dispatch, commit }) {
    if (state.submissionHistory && state.submissionHistory.length > 0) {
      var lastSubmission = state.submissionHistory[0];
      if (lastSubmission.getStatus() < 10 && !state.submissionId) {
        commit("setSubmissionId", lastSubmission.getId());
        dispatch("submissionStatus");
      }
      dispatch("fetchSubmissionDetail", {
        submissionId: state.submissionHistory[0].getId(),
      });
    }
  },

  fetchCodeTemplate: new ApiCall("fetchCodeTemplate")
    .withServiceCall((r, h) => judgeService.createSolutionTemplate(r, h))
    .withRequest(({ problemId, version, language }, _, __) =>
      new judge_proto.master.CreateSolutionTemplateRequest()
        .setProblemId(problemId)
        .setVersion(version)
        .setLanguage(judge_proto.Language[language])
    )
    .onSuccess(({ commit, getters, dispatch }, res) => {
      commit("setCodeTemplate", res.getCode());
    })
    .build(),

  uploadContestSubmissions: new ApiCall("uploadContestSubmissions")
    .withServiceCall((r, h) => judgeService.uploadContestSubmissions(r, h))
    .withRequest(({ contestId }) =>
      new judge_proto.master.UploadContestSubmissionsRequest().setContestId(
        contestId
      )
    )
    .onSuccess(({ commit, getters, dispatch }, res) => {
      console.log("uploaded ..");
    })
    .build(),

  fetchAllProblemSubmissionsAdmin: new ApiCall("getProblemSubmissionsAdmin")
    .withServiceCall((r, h) => judgeService.getProblemSubmissionsAdmin(r, h))
    .withRequest(
      ({ url, submissionTypeList, statusList, paginationQuery }, _, __) =>
        new judge_proto.master.GetProblemSubmissionsAdminRequest()
          .setProblemUrl(url)
          .setSubmissionTypeList(
            submissionTypeList.map((st) => judge_proto.SubmissionType[st])
          )
          .setStatusList(statusList.map((status) => judge_proto.Status[status]))
          .setPaginationQuery(
            new content_proto.PaginationQuery()
              .setItemsPerPage(paginationQuery.itemsPerPage)
              .setPageIndex(paginationQuery.pageIndex - 1)
          )
    )
    .onSuccess(({ commit, getters, dispatch }, res) => {
      commit("setAllProblemSubmissions", res.getSubmissionPreviewList());
      return res;
    })
    .build(),
};

const getters = {
  userToken: function (state, getters, rootState, rootGetters) {
    return rootGetters["user/userToken"];
  },
  types: function () {
    return Object.keys(proto.io.codedrills.proto.content.ContentType);
  },
  languages() {
    return Object.keys(judge_proto.Language);
  },
  submissionStatus: (state) => state.submissionStatus,
  statusTypes() {
    return Object.keys(proto.io.codedrills.proto.judge.Status);
  },
  submissionTypes() {
    return Object.keys(proto.io.codedrills.proto.judge.SubmissionType);
  },
  statusKeys() {
    var retStatus = {};
    console.log("statusKeys", judge_proto.Status);
    for (var key in judge_proto.Status) {
      retStatus[judge_proto.Status[key]] = key;
    }
    return retStatus;
  },
  languageKeys() {
    var retLanguage = {};
    console.log("language Keys", judge_proto.Language);
    for (var key in judge_proto.Language) {
      retLanguage[judge_proto.Language[key]] = key;
    }
    return retLanguage;
  },
  judgingCompleted: (state) =>
    state.submissionStatus && state.submissionStatus.getStatus() >= 10,
  submitting: (state) => state.submitStatus == 1 && !state.submissionId,
};

const mutations = {
  setSubmissionId(state, submissionId) {
    state.submissionId = submissionId;
  },
  setSubmissionStatus(state, response) {
    state.submissionStatus = response;
  },
  submissionStatusStatus(state, response) {
    state.submissionStatusStatus = response;
  },
  setSubmissionHistory(state, response) {
    state.submissionHistory = response;
  },
  setSubmission(state, submission) {
    state.submission = submission;
  },
  clearHistory(state) {
    if (state.submissionHistory == null) state.submissionHistory = [];
    else state.submissionHistory = null;
  },
  setSubmissionDetail(state, submissionDetail) {
    state.submissionDetail = submissionDetail;
  },
  clearSubmissionDetail(state) {
    state.submissionDetail = null;
  },
  clearSubmission(state) {
    state.submission = null;
    state.submissionStatus = null;
    state.submissionId = null;
  },
  setSubmitted(state, value) {
    state.hasSubmitted = value;
  },
  setSubmissionError(state, error) {
    state.submissionError = error;
  },
  submitStatus(state, value) {
    state.submitStatus = value;
  },
  fetchSubmissionDetailStatus(state, value) {
    state.fetchSubmissionDetailStatus = value;
  },
  fetchSubmissionsStatus(state, value) {
    state.fetchSubmissionsStatus = value;
  },
  fetchCodeTemplateStatus(state, value) {
    state.fetchCodeTemplateStatus = value;
  },
  uploadContestSubmissionsStatus(state, value) {
    state.uploadContestSubmissionsStatus = value;
  },
  setCodeTemplate(state, code) {
    state.codeTemplate = code;
  },
  setAllProblemSubmissions(state, submissions) {
    state.submissionPreviewsAdmin = submissions;
  },
  getProblemSubmissionsAdminStatus(state, value) {
    state.getProblemSubmissionsAdminStatus = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
