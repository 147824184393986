var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-footer', [_c('span', {
    staticClass: "font-weight-light"
  }, [_vm._v(" © " + _vm._s(new Date().getFullYear()) + " Codedrills Education Pvt. Ltd. All Rights Reserved. ")]), _c('v-spacer'), _c('router-link', {
    attrs: {
      "to": "privacy-policy"
    }
  }, [_c('span', {
    staticClass: "font-weight-thin mr-2"
  }, [_vm._v("Privacy Policy")])]), _vm._v(" | "), _c('router-link', {
    attrs: {
      "to": "terms-of-service"
    }
  }, [_c('span', {
    staticClass: "font-weight-thin ml-2"
  }, [_vm._v("Terms of service")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }