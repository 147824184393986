<template>
  <v-app>
    <navbar />
    <List />
    <v-content>
      <router-view v-if="initialized && user" />
      <Loader v-else-if="user"></Loader>
      <Login v-else></Login>
    </v-content>
    <tail />
    <footer>
      <cookie-law theme="blood-orange" buttonText="Agree">
        <div slot="message">
          We use cookies to ensure you get the best user experience on our
          website. By continuing to use this site, you agree to the use of these
          cookies.
          <a href="https://codedrills.io/privacy-policy">Find out more</a>
        </div>
      </cookie-law>
    </footer>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import List from "./components/notifs/List.vue";

import Navbar from "@/components/Navbar.vue";
import Tail from "@/components/Tail.vue";
import Loader from "@/components/Loader.vue";
import Login from "@/views/Login.vue";
import CookieLaw from "vue-cookie-law";

export default {
  name: "app",
  data() {
    return {};
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from, next) {
        document.title = to.meta.title || "CodeDrills";
      },
    },
  },
  computed: mapState("user", ["initialized", "user"]),
  methods: {
    redirect() {
      console.log("Redirecting?", redirect);
      return redirect;
    },
  },
  components: {
    Navbar,
    Tail,
    Loader,
    Login,
    List,
    CookieLaw,
  },
};
</script>

<style>
html,
body {
  background-color: #fbfbfb;
}
</style>
