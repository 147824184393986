var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [!this.user ? _c('div', [_vm._v("Please login first")]) : _vm._e(), this.user && _vm.loading ? _c('Loader') : _vm._e(), this.user && !_vm.loading && !_vm.content ? _c('NotFoundText', {
    attrs: {
      "item": "drill"
    }
  }) : _vm._e(), this.user && !_vm.loading && _vm.content ? _c('span', [_c('div', {
    staticClass: "grey lighten-5 pl-12 pr-5 mx-0 my-0"
  }, [_c('v-row', [_c('v-col', [_c('span', {
    staticClass: "display-2 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.content.getTitle()) + " ")])])], 1)], 1), _c('v-container', {
    staticClass: "pl-12 pr-5 mx-0 my-0",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_c('v-col', [_c('v-btn', {
    attrs: {
      "loading": _vm.isDrillSaved,
      "disabled": _vm.isDrillSaved,
      "color": "accent",
      "height": "35"
    },
    on: {
      "click": _vm.saveDrill
    }
  }, [_vm._v("Save")])], 1), _c('v-col', [_c('v-btn', {
    attrs: {
      "loading": _vm.isDrillPublished,
      "disabled": _vm.isPublishButtonDisabled,
      "color": "accent",
      "height": "35"
    },
    on: {
      "click": _vm.publishContentRequest
    }
  }, [_vm._v("Publish")])], 1)], 1), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "sm": "2"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Publish Type",
      "items": _vm.publishTypeKeys,
      "value": _vm.publishType
    },
    on: {
      "change": _vm.changePublishType
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-tabs', {
    attrs: {
      "color": "accent",
      "slider-color": "accent"
    },
    model: {
      value: _vm.drillTab,
      callback: function ($$v) {
        _vm.drillTab = $$v;
      },
      expression: "drillTab"
    }
  }, [_c('v-tab', [_vm._v("Preview")]), _c('v-tab', [_vm._v("Introduction")]), _c('v-tab', [_vm._v("Problems")]), _c('v-tab', [_vm._v("Attachment")])], 1), _c('v-tabs-items', {
    model: {
      value: _vm.drillTab,
      callback: function ($$v) {
        _vm.drillTab = $$v;
      },
      expression: "drillTab"
    }
  }, [_c('v-tab-item', [_c('v-row', {
    staticClass: "py-5 px-2"
  }, [_c('v-col', [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.view.getDataView().getDrillView().getDescriptionHtml())
    }
  })])], 1), _c('v-row', {
    staticClass: "py-5 px-2"
  }, [_c('v-col', _vm._l(_vm.content.getMetaData().getTagsList(), function (tag) {
    return _c('v-chip', {
      key: tag,
      staticClass: "mr-1",
      attrs: {
        "color": "accent"
      }
    }, [_vm._v(" " + _vm._s(tag) + " ")]);
  }), 1)], 1)], 1), _c('v-tab-item', [_c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Title",
      "value": _vm.content.getTitle()
    },
    on: {
      "change": _vm.setTitle
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Image Url",
      "value": _vm.content.getMetaData().getImageUrl()
    },
    on: {
      "change": _vm.setImage
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', [_c('v-combobox', {
    attrs: {
      "color": "accent",
      "value": _vm.content.getMetaData().getTagsList(),
      "items": _vm.items,
      "hide-selected": "",
      "hint": "tagType/tag",
      "label": "Add company, problem type tags",
      "persistent-hint": "",
      "multiple": "",
      "small-chips": "",
      "solo": ""
    },
    on: {
      "change": _vm.setTags
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "py-5 px-2"
  }, [_c('v-col', [_c('MarkdownEditor', {
    attrs: {
      "editorialMode": false,
      "submissionMode": true,
      "code": _vm.content.getData().getDrill().getDescription(),
      "onCodeChange": _vm.onMarkdownCodeChange,
      "value": _vm.editorConfig
    }
  })], 1)], 1)], 1), _c('v-tab-item', [_c('v-row', [_c('v-col', [_vm._l(_vm.content.getData().getDrill().getSectionsList(), function (section, index) {
    return _c('div', {
      key: index
    }, [_c('section-card', {
      attrs: {
        "availableProblems": _vm.contentsObject,
        "section": section
      },
      on: {
        "remove": function ($event) {
          return _vm.removeSection(index);
        },
        "changeTitle": function (title) {
          return _vm.updateSectionTitle(index, title);
        },
        "changeDescription": function (description) {
          return _vm.updateSectionDescription(index, description);
        },
        "changeProblemIds": function (problemIds) {
          return _vm.updateSectionProblemIds(index, problemIds);
        }
      }
    })], 1);
  }), _c('v-row', {
    staticClass: "mt-5"
  }, [_c('v-col', [_c('v-btn', {
    on: {
      "click": _vm.addSection
    }
  }, [_vm._v("Add new section")])], 1)], 1)], 2)], 1)], 1), _c('v-tab-item', [_c('v-row', [_c('v-col', [_c('v-file-input', {
    attrs: {
      "rules": _vm.rules,
      "accept": "image/*",
      "placeholder": "Pick an avatar",
      "prepend-icon": "mdi-camera",
      "label": "Avatar"
    },
    model: {
      value: _vm.drillAvatar,
      callback: function ($$v) {
        _vm.drillAvatar = $$v;
      },
      expression: "drillAvatar"
    }
  }), _c('v-btn', {
    attrs: {
      "color": "accent"
    },
    on: {
      "click": _vm.uploadAttachmentSave
    }
  }, [_vm._v("Save Attachment ")])], 1)], 1), _vm.attachment && _vm.attachment.getUrl() ? _c('v-row', [_c('v-col', [_vm._v(" " + _vm._s(_vm.attachment.getUrl()) + " ")])], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', [_c('confirm-dialog', {
    ref: "confirm"
  }), _c('v-btn', {
    attrs: {
      "color": "error",
      "height": "35"
    },
    on: {
      "click": _vm.deleteContentRequest
    }
  }, [_vm._v("Delete Drill")])], 1)], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }