var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [!this.user ? _c('div', [_vm._v("Please login first")]) : _c('span', [_c('v-container', {
    staticClass: "pl-12 pr-5 mx-0 my-0",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-col', [_c('v-tabs', {
    attrs: {
      "color": "accent",
      "slider-color": "accent"
    },
    model: {
      value: _vm.problemAdmin,
      callback: function ($$v) {
        _vm.problemAdmin = $$v;
      },
      expression: "problemAdmin"
    }
  }, [_c('v-tab', [_vm._v("Submissions")])], 1), _c('v-tabs-items', {
    model: {
      value: _vm.problemAdmin,
      callback: function ($$v) {
        _vm.problemAdmin = $$v;
      },
      expression: "problemAdmin"
    }
  }, [_c('v-tab-item', [_c('v-row', [_c('v-col', [_c('v-btn', {
    on: {
      "click": _vm.fetchSubmissions
    }
  }, [_vm._v("Fetch Submissions")])], 1)], 1), _c('v-row', {
    staticClass: "py-5 px-2"
  }, [_c('v-col', [_c('v-card', [_c('v-card-title', [_vm._v(" All Submissions ")]), _c('v-spacer'), _c('v-card-actions', [_c('v-select', {
    attrs: {
      "label": "Status",
      "items": _vm.statusTypes,
      "multiple": ""
    },
    model: {
      value: _vm.statusList,
      callback: function ($$v) {
        _vm.statusList = $$v;
      },
      expression: "statusList"
    }
  }), _c('v-select', {
    attrs: {
      "label": "Submission Type",
      "items": _vm.submissionTypes,
      "multiple": ""
    },
    model: {
      value: _vm.submissionTypeList,
      callback: function ($$v) {
        _vm.submissionTypeList = $$v;
      },
      expression: "submissionTypeList"
    }
  })], 1), _c('v-data-table', {
    attrs: {
      "page": _vm.pagination.pageIndex,
      "pageCount": _vm.pageCount,
      "server-items-length": _vm.pagination.total,
      "headers": _vm.headers,
      "items": _vm.allProblemSubmissions,
      "footer-props": _vm.footerOptions,
      "loading": _vm.pageLoading,
      "options": _vm.tableOptions
    },
    on: {
      "update:footerProps": function ($event) {
        _vm.footerOptions = $event;
      },
      "update:footer-props": function ($event) {
        _vm.footerOptions = $event;
      },
      "update:options": function ($event) {
        _vm.tableOptions = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('tr', [_c('td', [_c('router-link', {
          staticClass: "link",
          attrs: {
            "to": '/submissions/' + item.id
          }
        }, [_vm._v(" " + _vm._s(item.id) + " ")])], 1), _c('td', [_vm._v(_vm._s(_vm.formatDate(item.submittedOn)))]), _c('td', [_vm._v(_vm._s(item.userPreview.name))]), _c('td', [_vm._v(" " + _vm._s(_vm.allLanguages[_vm.languageKeys[item.language]]) + " ")]), _c('td', {
          staticClass: "text-capitalize"
        }, [_vm._v(" " + _vm._s(_vm.statusKeys[item.status].split("_").join(" ").toLowerCase()) + " ")]), _c('td', [_vm._v(" " + _vm._s(item.status == 12 ? "-" : item.timeTakenInMs + " ms") + " ")]), _c('td', [_vm._v(" " + _vm._s(item.status == 16 ? "-" : item.memoryTakenInKb + " KiB") + " ")])])];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }