<template>
  <div v-if="listContentsStatus !== null">
    <v-row>
      <v-spacer></v-spacer>
      <v-col :cols="4">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search (title, tags)"
          clearable
          single-line
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-data-table
          :headers="allQsHeaders"
          :items="allQuestions"
          :loading="allQuestions.length === 0"
          :items-per-page="15"
          :search="search"
        >
          <template v-slot:top>
            <v-toolbar flat> All Questions </v-toolbar>
          </template>
          <template v-slot:item.title="{ item }">
            <router-link :to="item.url">
              {{ item.title }}
            </router-link>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn @click="addItem(item)" small color="success"> Add </v-btn>
          </template>
        </v-data-table>
      </v-col>

      <v-col cols="6">
        <v-data-table
          :headers="allQsHeaders"
          :items="selectedQuestions"
          :items-per-page="15"
        >
          <template v-slot:top>
            <v-toolbar flat> Always Included Questions </v-toolbar>
          </template>
          <template v-slot:item.title="{ item }">
            <router-link :to="item.url">
              {{ item.title }}
            </router-link>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn @click="removeItem(item)" small color="info"> Remove </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
  <div v-else>Loading</div>
</template>
<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      search: null,
      selectedQuestions: [],
      allQuestions: [],
      selectedQsHeaders: [
        {
          text: "Type",
          sortable: true,
          value: "type",
        },
        {
          text: "Name",
          align: "left",
          sortable: true,
          value: "title",
        },
        {
          text: "Action",
          align: "left",
          value: "action",
          sortable: false,
        },
      ],
      allQsHeaders: [
        {
          text: "Type",
          sortable: true,
          value: "type",
        },
        {
          text: "Name",
          align: "left",
          sortable: true,
          value: "title",
        },
        {
          text: "Action",
          align: "left",
          value: "action",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapState("content", ["contents", "listContentsStatus"]),
    ...mapState("skill", ["content"]),
    ...mapGetters("skill", ["contentTypeKeys"]),
  },
  methods: {
    ...mapActions("content", ["fetchContents"]),
    ...mapMutations("skill", ["setAlwaysIncludedQuestions"]),
    addItem(item) {
      let exists = false;
      this.selectedQuestions.forEach((q) => {
        if (q.id === item.id) {
          exists = true;
        }
      });
      if (!exists) {
        this.selectedQuestions.push(item);
        this.$nextTick(() => {
          let selectedQuestionIds = this.selectedQuestions.map((x) => x.id);
          this.setAlwaysIncludedQuestions(selectedQuestionIds);
          console.log(selectedQuestionIds);
        });
      } else {
        // say already exists, create a notification system somehow maybe?
      }
    },
    removeItem(item) {
      this.selectedQuestions = this.selectedQuestions.filter(
        (qs) => qs.id !== item.id
      );
      this.$nextTick(() => {
        let selectedQuestionIds = this.selectedQuestions.map((x) => x.id);
        this.setAlwaysIncludedQuestions(selectedQuestionIds);
      });
    },
  },
  created() {
    this.fetchContents().then((_) => {
      const qsIds = this.content
        ?.getData()
        ?.getSkill()
        ?.getQuestionSelectionCriteria()
        ?.getAlwaysIncludedQuestionIdList();
      console.log(qsIds);
      this.contents.forEach((ctp) => {
        let curr = ctp.toObject();
        let qType = this.contentTypeKeys[curr.contentType];
        if (qType === "SUBJECTIVE" || qType === "PROBLEM" || qType === "MCQ") {
          let single = {
            type: qType,
            title: curr.title,
            url: `/${qType.toLowerCase()}s/${curr.url}`,
            id: curr.id,
          };
          this.allQuestions.push(single);
          // since backend stores only ids
          // check if this id is stored, then push to selected questions
          if (qsIds.includes(curr.id)) {
            this.selectedQuestions.push(single);
          }
        }
      });
    });
  },
};
</script>
