var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    staticClass: "px-0"
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex",
    attrs: {
      "height": 75
    }
  }, [_c('v-row', {
    staticClass: "pl-1 align-center",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": 3,
      "md": 2
    }
  }, [_c('v-select', {
    staticClass: "ml-0",
    attrs: {
      "items": _vm.languages,
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "change": _vm.saveEditorConfig
    },
    model: {
      value: _vm.language,
      callback: function ($$v) {
        _vm.language = $$v;
      },
      expression: "language"
    }
  })], 1), _c('v-spacer', {
    staticClass: "hidden-sm-and-down"
  }), _c('v-col', {
    attrs: {
      "cols": 1,
      "align": "right"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": "",
            "color": "accent",
            "large": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          staticClass: "text-right",
          on: {
            "click": _vm.removeCode
          }
        }, [_vm._v("mdi-cached")])], 1)];
      }
    }])
  }, [_c('span', [_vm._v("Reset Code")])])], 1), _c('v-col', {
    staticClass: "py-0 pr-1",
    attrs: {
      "cols": 8,
      "md": 7,
      "lg": 6
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "py-0 pl-1",
    attrs: {
      "cols": 6,
      "md": 3
    }
  }, [_c('v-select', {
    staticClass: "ml-0 font-weight-light",
    attrs: {
      "items": _vm.themes,
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "change": _vm.saveEditorConfig
    },
    model: {
      value: _vm.theme,
      callback: function ($$v) {
        _vm.theme = $$v;
      },
      expression: "theme"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0 pl-1",
    attrs: {
      "cols": 6,
      "md": 3
    }
  }, [_c('v-select', {
    staticClass: "ml-0 font-weight-light",
    attrs: {
      "items": _vm.keyMaps,
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "change": _vm.saveEditorConfig
    },
    model: {
      value: _vm.keyMap,
      callback: function ($$v) {
        _vm.keyMap = $$v;
      },
      expression: "keyMap"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0 pl-1 hidden-sm-and-down",
    attrs: {
      "cols": 3
    }
  }, [_c('v-select', {
    staticClass: "ml-0 font-weight-light",
    attrs: {
      "items": _vm.fontSizes,
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "change": _vm.saveEditorConfig
    },
    model: {
      value: _vm.fontSize,
      callback: function ($$v) {
        _vm.fontSize = $$v;
      },
      expression: "fontSize"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0 pl-1 hidden-sm-and-down",
    attrs: {
      "cols": 3
    }
  }, [_c('v-select', {
    staticClass: "ml-0 font-weight-light",
    attrs: {
      "items": _vm.tabSizes,
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "change": _vm.saveEditorConfig
    },
    model: {
      value: _vm.tabSize,
      callback: function ($$v) {
        _vm.tabSize = $$v;
      },
      expression: "tabSize"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-row', [_c('v-col', {
    staticClass: "py-0"
  }, [_c('CodeMirror', {
    ref: "myCm",
    class: _vm.fontSize,
    attrs: {
      "value": _vm.value,
      "options": _vm.cmOptions
    },
    on: {
      "input": _vm.onCodeChange
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }