var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [!this.user ? _c('div', [_vm._v("Please login first")]) : _vm._e(), this.user && _vm.loading ? _c('Loader') : _vm._e(), this.user && !_vm.loading && !_vm.content ? _c('NotFoundText', {
    attrs: {
      "item": "contest"
    }
  }) : _vm._e(), this.user && !_vm.loading ? _c('span', [_c('div', {
    staticClass: "grey lighten-5 pl-12 pr-5 mx-0 my-0"
  }, [_c('v-row', [_c('v-col', [_c('span', {
    staticClass: "display-2 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.content.getTitle()))])])], 1)], 1), _c('v-container', {
    staticClass: "pl-12 pr-5 mx-0 my-0",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_c('v-col', [_c('v-btn', {
    attrs: {
      "loading": _vm.isContestSaved,
      "disabled": _vm.isContestSaved,
      "color": "accent",
      "height": "50",
      "width": "100"
    },
    on: {
      "click": _vm.saveContest
    }
  }, [_vm._v("Save")])], 1), _c('v-col', [_c('v-btn', {
    attrs: {
      "loading": _vm.isContestPublished,
      "disabled": _vm.isPublishButtonDisabled,
      "color": "accent",
      "height": "35"
    },
    on: {
      "click": _vm.publishContentRequest
    }
  }, [_vm._v("Publish")])], 1)], 1), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "sm": "2"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Publish Type",
      "items": _vm.publishTypeKeys,
      "value": _vm.publishType
    },
    on: {
      "change": _vm.changePublishType
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-btn', {
    attrs: {
      "color": "accent",
      "router-link": "",
      "to": this.contestUrl + '/admin'
    }
  }, [_vm._v("Contest Admin Dashboard")])], 1)], 1), _c('v-row', [_c('v-col', {
    class: {
      'text-center': _vm.$vuetify.breakpoint.mobile
    },
    attrs: {
      "cols": 12,
      "sm": 3,
      "lg": 2
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "accent",
      "router-link": "",
      "to": this.contestUrl + '/scoreboard'
    }
  }, [_vm._v(" Scoreboard ")])], 1)], 1), _c('v-row', [_c('v-col', [_c('v-tabs', {
    attrs: {
      "color": "accent",
      "slider-color": "accent"
    },
    model: {
      value: _vm.contestTab,
      callback: function ($$v) {
        _vm.contestTab = $$v;
      },
      expression: "contestTab"
    }
  }, [_c('v-tab', [_vm._v("Preview")]), _c('v-tab', [_vm._v("About")]), _c('v-tab', [_vm._v("Rules")]), _c('v-tab', [_vm._v("MetaData")]), _c('v-tab', [_vm._v("Team MetaData")]), _c('v-tab', [_vm._v("Problems")]), _c('v-tab', [_vm._v("Scoring")]), _c('v-tab', [_vm._v("Attachment")]), _c('v-tab', [_vm._v("Permissions")])], 1), _c('v-tabs-items', {
    model: {
      value: _vm.contestTab,
      callback: function ($$v) {
        _vm.contestTab = $$v;
      },
      expression: "contestTab"
    }
  }, [_c('v-tab-item', [_c('v-row', {
    staticClass: "py-5 px-2"
  }, [_c('v-col', [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.view.getDataView().getContestView())
    }
  })])], 1), _c('v-row', {
    staticClass: "py-5 px-2"
  }, [_c('v-col', _vm._l(_vm.content.getMetaData().getTagsList(), function (tag) {
    return _c('v-chip', {
      key: tag,
      staticClass: "mr-1",
      attrs: {
        "color": "accent"
      }
    }, [_vm._v(" " + _vm._s(tag) + " ")]);
  }), 1)], 1)], 1), _c('v-tab-item', [_c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Contest Title",
      "value": _vm.content.getTitle()
    },
    on: {
      "change": _vm.setTitle
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "py-5 px-2"
  }, [_c('v-col', [_c('MarkdownEditor', {
    attrs: {
      "editorialMode": false,
      "submissionMode": true,
      "code": _vm.content.getData().getContest().getAbout(),
      "onCodeChange": _vm.onAboutChange,
      "value": _vm.editorConfig
    }
  })], 1)], 1)], 1), _c('v-tab-item', [_c('v-row', {
    staticClass: "py-5 px-2"
  }, [_c('v-col', [_c('MarkdownEditor', {
    attrs: {
      "editorialMode": false,
      "submissionMode": true,
      "code": _vm.content.getData().getContest().getRules(),
      "onCodeChange": _vm.onRulesChange,
      "value": _vm.editorConfig
    }
  })], 1)], 1)], 1), _c('v-tab-item', [_c('v-row', {
    staticClass: "py-5 px-2"
  }, [_c('v-col', [_c('v-combobox', {
    attrs: {
      "color": "accent",
      "items": _vm.tagsTypes,
      "value": _vm.content.getMetaData().getTagsList(),
      "hide-selected": "",
      "hint": "tagType/tag",
      "label": "Add company, problem type tags",
      "persistent-hint": "",
      "multiple": "",
      "small-chips": "",
      "solo": ""
    },
    on: {
      "change": _vm.setTags
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', [_c('v-select', {
    attrs: {
      "multiple": "",
      "items": _vm.languageKeys,
      "value": _vm.getAllowedLanguageList,
      "label": "Select language to be allowed during test"
    },
    on: {
      "input": _vm.setAllowedLanguages
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Set icon url",
      "value": _vm.content.getMetaData().getImageUrl()
    },
    on: {
      "input": _vm.changeImageUrl
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Set banner url",
      "value": _vm.content.getMetaData().getContestMeta() && _vm.content.getMetaData().getContestMeta().getBannerUrl()
    },
    on: {
      "input": _vm.changeBannerUrl
    }
  })], 1)], 1), _vm.iamAdmin ? _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Is Submission Restricted",
      "items": _vm.restrictTypes,
      "value": _vm.isRestrictSubmission
    },
    on: {
      "input": _vm.setIsRestrictSubmission
    }
  })], 1)], 1) : _vm._e(), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Contest Submission Visibility",
      "items": _vm.submissionVisibilityKeys,
      "value": _vm.submissionVisibilityType
    },
    on: {
      "change": _vm.setSubmissionVisibility
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Set Scoreboard Visibility",
      "items": _vm.scoreboardVisibilityKeys
    },
    model: {
      value: _vm.scoreboardVisibility,
      callback: function ($$v) {
        _vm.scoreboardVisibility = $$v;
      },
      expression: "scoreboardVisibility"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Set Test case Visibility",
      "items": _vm.testCaseVisibilityKeys
    },
    model: {
      value: _vm.testCaseVisibility,
      callback: function ($$v) {
        _vm.testCaseVisibility = $$v;
      },
      expression: "testCaseVisibility"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('div', {
    staticClass: "title"
  }, [_vm._v("Pick Start Date and Time")])])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "offset": 1,
      "cols": 4
    }
  }, [_c('v-date-picker', {
    attrs: {
      "color": "accent"
    },
    model: {
      value: _vm.startDate,
      callback: function ($$v) {
        _vm.startDate = $$v;
      },
      expression: "startDate"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": 4
    }
  }, [_c('v-time-picker', {
    attrs: {
      "color": "accent"
    },
    model: {
      value: _vm.startTime,
      callback: function ($$v) {
        _vm.startTime = $$v;
      },
      expression: "startTime"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('div', {
    staticClass: "title"
  }, [_vm._v("Pick End Date and Time")])])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "offset": 1,
      "cols": 4
    }
  }, [_c('v-date-picker', {
    attrs: {
      "color": "accent"
    },
    model: {
      value: _vm.endDate,
      callback: function ($$v) {
        _vm.endDate = $$v;
      },
      expression: "endDate"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": 4
    }
  }, [_c('v-time-picker', {
    attrs: {
      "color": "accent"
    },
    model: {
      value: _vm.endTime,
      callback: function ($$v) {
        _vm.endTime = $$v;
      },
      expression: "endTime"
    }
  })], 1)], 1), _c('div', [_c('h2', [_vm._v("Any custom template for email:")]), _c('ul', _vm._l(_vm.customEmailTemplateIds, function (value, key) {
    return _c('li', {
      key: key
    }, [_vm._v(" " + _vm._s(value[0]) + ": " + _vm._s(value[1]) + " ")]);
  }), 0), _c('h2', [_vm._v("Add new key-value pair:")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Key"
    },
    model: {
      value: _vm.newKey,
      callback: function ($$v) {
        _vm.newKey = $$v;
      },
      expression: "newKey"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Value"
    },
    model: {
      value: _vm.newValue,
      callback: function ($$v) {
        _vm.newValue = $$v;
      },
      expression: "newValue"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4"
    }
  }, [_c('v-btn', {
    on: {
      "click": _vm.addNewCustomEmailTempate
    }
  }, [_vm._v("Add")])], 1)], 1)], 1)], 1), _c('v-tab-item', [_c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "sm": "2"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Contest type",
      "items": _vm.contestTypeKeys,
      "value": _vm.contestType
    },
    on: {
      "change": _vm.changeContestType
    }
  })], 1)], 1), _vm.isTeamContest ? _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "sm": "2"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Is team Editable",
      "items": _vm.boolTypes,
      "value": _vm.isEditable
    },
    on: {
      "input": _vm.setTeamEditablity
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "sm": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Minimum team size",
      "value": _vm.minTeamSize
    },
    on: {
      "input": _vm.updateMinTeamSize
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "sm": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Maximum team size",
      "value": _vm.maxTeamSize
    },
    on: {
      "input": _vm.updateMaxTeamSize
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "sm": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Team Name uniqueness",
      "items": _vm.nameUniquenessKeys,
      "value": _vm.nameUniquenessType
    },
    on: {
      "change": _vm.updateNameUniqueness
    }
  })], 1)], 1) : _vm._e(), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4",
      "sm": "2"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Is Coach Enabled",
      "items": _vm.boolTypes,
      "value": _vm.isCoachEnabled
    },
    on: {
      "input": _vm.setIsCoachEnabled
    }
  })], 1), _vm.isCoachEnabled ? _c('v-col', {
    attrs: {
      "cols": "4",
      "sm": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Coach Presence",
      "items": _vm.presenceKeys,
      "value": _vm.coachPresence
    },
    on: {
      "input": _vm.setCoachPresence
    }
  })], 1) : _vm._e()], 1), _c('v-row', {
    staticClass: "title"
  }, [_c('v-col', [_vm._v(" Team Custom Attributes ")])], 1), _c('v-row', [_c('v-col', [_c('custom-attributes', {
    attrs: {
      "customAttributesMap": _vm.teamCustomAttributesMap
    },
    on: {
      "add": function (attribute) {
        return _vm.addTeamCustomAttribute(attribute);
      },
      "remove": function (name) {
        return _vm.removeTeamCustomAttribute(name);
      }
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "title"
  }, [_c('v-col', [_vm._v(" Member Custom Attributes ")])], 1), _c('v-row', [_c('v-col', [_c('custom-attributes', {
    attrs: {
      "customAttributesMap": _vm.memberCustomAttributesMap
    },
    on: {
      "add": function (attribute) {
        return _vm.addMemberCustomAttribute(attribute);
      },
      "remove": function (name) {
        return _vm.removeMemberCustomAttribute(name);
      }
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "title"
  }, [_c('v-col', [_vm._v(" Coach Custom Attributes ")])], 1), _c('v-row', [_c('v-col', [_c('custom-attributes', {
    attrs: {
      "customAttributesMap": _vm.coachCustomAttributesMap
    },
    on: {
      "add": function (attribute) {
        return _vm.addCoachCustomAttribute(attribute);
      },
      "remove": function (name) {
        return _vm.removeCoachCustomAttribute(name);
      }
    }
  })], 1)], 1)], 1), _c('v-tab-item', [_c('search', {
    attrs: {
      "availableProblems": _vm.contentsObject,
      "selectedProblems": _vm.selectedProblems
    },
    on: {
      "change": _vm.updateProblem
    }
  })], 1), _c('v-tab-item', [_c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "ScoreboardType",
      "items": _vm.scoreboardTypeKeys,
      "value": _vm.scoreboardType
    },
    on: {
      "change": _vm.setScoreboardType
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "single": "",
      "line": "",
      "label": "Penalty time",
      "suffix": "s",
      "value": _vm.scoreboardPenalty
    },
    on: {
      "change": _vm.setScoreboardPenalty
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "single": "",
      "line": "",
      "label": "freeze offset time in sec",
      "suffix": "s",
      "value": _vm.freezeOffsetTimeInSec
    },
    on: {
      "change": _vm.setFreezeOffsetTimeInSecs
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Unfreeze",
      "items": [true, false],
      "value": _vm.unfreeze
    },
    on: {
      "input": _vm.setUnfreeze
    }
  })], 1)], 1)], 1), _c('v-tab-item', [_c('v-row', [_c('v-col', [_c('v-file-input', {
    attrs: {
      "rules": _vm.attachmentRules,
      "accept": "image/*",
      "placeholder": "Attachment",
      "prepend-icon": "mdi-camera",
      "label": "Attachment"
    },
    model: {
      value: _vm.attachmentFile,
      callback: function ($$v) {
        _vm.attachmentFile = $$v;
      },
      expression: "attachmentFile"
    }
  }), _c('v-btn', {
    attrs: {
      "color": "accent"
    },
    on: {
      "click": _vm.uploadAttachmentSave
    }
  }, [_vm._v("Save Attachment ")])], 1)], 1), _vm.attachment && _vm.attachment.getUrl() ? _c('v-row', [_c('v-col', [_vm._v(" " + _vm._s(_vm.attachment.getUrl()) + " ")]), _c('img', {
    staticStyle: {
      "max-width": "100%"
    },
    attrs: {
      "src": _vm.attachment.getUrl(),
      "alt": "Contest Banner",
      "srcset": ""
    }
  })], 1) : _vm._e()], 1), _c('v-tab-item', [_c('permissions', {
    attrs: {
      "permissions": _vm.content.getMetaData().getPermissionsMap(),
      "initialRestrictedDomains": _vm.getRestrictedDomains
    },
    on: {
      "permissionChange": function (ids, role) {
        return _vm.appendPermissions(ids, role);
      },
      "removePermission": function (ids, role) {
        return _vm.removePermissions(ids, role);
      },
      "restrictedDomainChange": _vm.setRestrictedDomains
    }
  })], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', [_c('confirm-dialog', {
    ref: "confirm"
  }), _c('v-btn', {
    attrs: {
      "color": "error",
      "height": "35"
    },
    on: {
      "click": _vm.deleteContentRequest
    }
  }, [_vm._v("Delete Contest")])], 1)], 1)], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }