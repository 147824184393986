<template>
  <v-row>
    <v-col>
      <CodeMirror :value="value" :options="cmOptions"></CodeMirror>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import { codemirror as CodeMirror } from "vue-codemirror";
import "@/assets/css/codemirror.css";
import "codemirror/mode/go/go.js";
import "codemirror/mode/javascript/javascript.js";
import "codemirror/mode/python/python.js";
import "codemirror/mode/clike/clike.js";
import "codemirror/mode/dart/dart.js";
import "codemirror/mode/r/r.js";
import "codemirror/mode/php/php.js";
import "codemirror/theme/dracula.css";
import "codemirror/theme/solarized.css";
import "codemirror/theme/idea.css";
import "codemirror/keymap/emacs.js";
import "codemirror/keymap/vim.js";
import "codemirror/keymap/sublime.js";
import "codemirror/lib/codemirror";

export default {
  props: {
    value: {
      type: String,
      required: false,
    },
    availableLanguages: {
      type: Array,
      required: false,
    },
    lang: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tabSize: 4,
      theme: "default",
      readOnly: "true",
      keyMap: "default",
      allLanguages: [
        { text: "C++", value: "CPP_17" },
        { text: "Java", value: "JAVA_11" },
        { text: "Python", value: "PYTHON_3" },
        { text: "Text", value: "TXT" },
        { text: "Kotlin", value: "KOTLIN_1_3" },
        { text: "MySQL 8", value: "MY_SQL_8" },
        { text: "JavaScript", value: "JAVASCRIPT" },
        { text: "Ruby", value: "RUBY" },
        { text: "C#", value: "CSHARP" },
        { text: "TypeScript", value: "TYPESCRIPT" },
        { text: "Go", value: "GO" },
        { text: "Dart", value: "DART" },
        { text: "R", value: "R" },
        { text: "PHP", value: "PHP" },
      ],
      cmModeMap: {
        CPP_17: "text/x-c++src",
        JAVA_11: "text/x-java",
        KOTLIN_1_3: "text/x-kotlin",
        PYTHON_3: "text/x-python",
        JAVASCRIPT: "text/javascript",
        TXT: "text/plain",
        MY_SQL_8: "text/x-mysql",
        RUBY: "text/x-ruby",
        CSHARP: "text/x-csharp",
        TYPESCRIPT: "text/typescript",
        GO: "text/x-go",
        DART: "dart",
        R: "text/x-rsrc",
        PHP: "text/x-php",
      },
      copied: true,
    };
  },
  components: {
    CodeMirror,
  },
  computed: {
    codemirror() {
      return this.$refs.myCm.codemirror;
    },
    cmOptions() {
      return {
        tabSize: this.tabSize,
        /*indentUnit: 4,*/
        indentWithTabs: true,
        mode: this.cmModeMap[this.lang],
        lineNumbers: true,
        line: true,
        styleActiveLine: true,
        matchBrackets: true,
        lineWrapping: true,
        theme: this.theme,
        foldGutter: true,
        keyMap: this.keyMap,
        readOnly: this.readOnly,
      };
    },
  },
};
</script>
