import Vue from "vue";
import Vuex from "vuex";
import VueShowdown from "vue-showdown";
import modules from "./modules";

Vue.use(Vuex);
// https://github.com/showdownjs/showdown#options
Vue.use(VueShowdown, {
  flavor: "github",
  options: {
    headerLevelStart: 2,
    parseImgDimensions: true,
    strikethrough: true,
    tables: true,
    tasklists: true,
    somoothLivePreview: true,
    simpleLineBreaks: true,
    requireSpaceBeforeheadingText: true,
    underline: true,
  },
});

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules,
  strict: debug,
});
