var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [!this.user ? _c('div', [_vm._v("Please login first")]) : _vm._e(), this.user && _vm.loading ? _c('Loader') : _vm._e(), this.user && !_vm.loading ? _c('span', [_c('div', {
    staticClass: "grey lighten-5 pl-12 pr-5 mx-0 my-0"
  }, [_c('v-row', [_c('v-col', [_c('span', {
    staticClass: "display-2 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.content.getTitle()) + " ")])])], 1)], 1), _c('v-container', {
    staticClass: "pl-12 pr-5 mx-0 my-0",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-col', [_c('v-tabs', {
    attrs: {
      "color": "accent",
      "slider-color": "accent"
    },
    model: {
      value: _vm.contestAdminTab,
      callback: function ($$v) {
        _vm.contestAdminTab = $$v;
      },
      expression: "contestAdminTab"
    }
  }, [_c('v-tab', [_vm._v("Submissions")]), _c('v-tab', [_vm._v("Scoreboard")]), _c('v-tab', [_vm._v("Downloads")]), _c('v-tab', [_vm._v("Upload Teams")]), _c('v-tab', [_vm._v("Teams")]), _c('v-tab', [_vm._v("Import Teams")]), _c('v-tab', [_vm._v("Contest Extension")]), _c('v-tab', [_vm._v("Send Email")]), _c('v-tab', [_vm._v("offline Contest")])], 1), _c('v-tabs-items', {
    model: {
      value: _vm.contestAdminTab,
      callback: function ($$v) {
        _vm.contestAdminTab = $$v;
      },
      expression: "contestAdminTab"
    }
  }, [_c('v-tab-item', [_c('v-row', {
    staticClass: "py-5 px-2"
  }, [_c('v-col', [_c('v-card', [_c('v-card-title', [_vm._v(" All Submissions "), _c('v-spacer')], 1), _c('v-data-table', {
    attrs: {
      "page": _vm.pagination.pageIndex,
      "pageCount": _vm.pageCount,
      "server-items-length": _vm.pagination.total,
      "headers": _vm.headers,
      "items": _vm.allContestSubmissions,
      "footer-props": _vm.footerOptions,
      "search": _vm.pagination.search,
      "loading": _vm.pageLoading,
      "options": _vm.tableOptions
    },
    on: {
      "update:footerProps": function ($event) {
        _vm.footerOptions = $event;
      },
      "update:footer-props": function ($event) {
        _vm.footerOptions = $event;
      },
      "update:options": function ($event) {
        _vm.tableOptions = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('tr', [_c('td', [_c('router-link', {
          staticClass: "link",
          attrs: {
            "to": '/submissions/' + item.id
          }
        }, [_vm._v(" " + _vm._s(item.id) + " ")])], 1), _c('td', [_vm._v(_vm._s(_vm.formatDate(item.submittedOn)))]), _c('td', [_vm._v(_vm._s(item.userPreview.name))]), _c('td', [_c('router-link', {
          staticClass: "link",
          attrs: {
            "to": '/problems/' + item.problemPreview.url
          }
        }, [_vm._v(" " + _vm._s(item.problemPreview.title) + " ")])], 1), _c('td', [_vm._v(" " + _vm._s(_vm.allLanguages[_vm.languageKeys[item.language]]) + " ")]), _c('td', {
          staticClass: "text-capitalize"
        }, [_vm._v(" " + _vm._s(_vm.statusKeys[item.status].split("_").join(" ").toLowerCase()) + " ")]), _c('td', [_vm._v(" " + _vm._s(item.status == 12 ? "-" : item.timeTakenInMs + " ms") + " ")]), _c('td', [_vm._v(" " + _vm._s(item.status == 16 ? "-" : item.memoryTakenInKb + " KiB") + " ")])])];
      }
    }], null, false, 1418420191)
  })], 1)], 1)], 1)], 1), _c('v-tab-item', [_c('v-row', [_c('v-col', [_c('v-btn', {
    attrs: {
      "loading": _vm.refreshDone
    },
    on: {
      "click": _vm.refreshingScoreboard
    }
  }, [_vm._v("Refresh Scoreboard")])], 1)], 1), _c('v-row', [_c('v-col', [_c('v-btn', {
    on: {
      "click": _vm.fetchScoreboard
    }
  }, [_vm._v("Fetch Scoreboard")])], 1)], 1), _vm.scoreboard ? _c('v-row', [_c('v-col', [_c('v-card', [_c('v-data-table', {
    attrs: {
      "items-per-page": 16,
      "hide-default-footer": true,
      "headers": _vm.staticHeaders,
      "items": _vm.userScores
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('tr', [_c('td', [_vm._v(" " + _vm._s(item.rank) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.getName(item)) + " ")]), _vm.isTeamContest ? _c('td', _vm._l(item.teamPreview.memberPreviewsList, function (m) {
          return _c('span', {
            key: m.id
          }, [_vm._v(" " + _vm._s(m.name) + " "), _c('user', {
            attrs: {
              "id": m.id
            }
          }), _c('br')], 1);
        }), 0) : _vm._e()])];
      }
    }], null, false, 1989365507)
  })], 1)], 1)], 1) : _vm._e()], 1), _c('v-tab-item', [_c('v-row', {
    staticClass: "mt-10"
  }, [_c('v-col', [_c('v-btn', {
    attrs: {
      "color": "green"
    },
    on: {
      "click": _vm.downloadAllSubmissionFull
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "color": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiCloudDownload) + " ")]), _vm._v(" Download Full Submissions ")], 1), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": "",
      "loading": _vm.downloadDone,
      "height": "40"
    },
    on: {
      "click": _vm.downloadTeams
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "color": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiCloudDownload) + " ")]), _vm._v(" Download Teams ")], 1), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.downloadSubmissions
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "color": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiCloudDownload) + " ")]), _vm._v(" Download Submissions ")], 1)], 1)], 1), _c('v-divider', {
    staticClass: "my-4"
  }), _c('v-row', [_c('v-col', [_c('v-btn', {
    staticClass: "mt-10",
    on: {
      "click": _vm.uploadScoreboard
    }
  }, [_vm._v(" Upload Current Scoreboard to Storage ")]), _c('v-spacer'), _c('v-btn', {
    staticClass: "mt-10",
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.downloadParticipants
    }
  }, [_vm._v(" Download Participants ")])], 1)], 1), _vm.scoreboard ? _c('v-row', {
    staticClass: "mt-10"
  }, [_c('v-col', [_vm._v(" team_name,team_org "), _c('br'), _vm._l(_vm.scoreboard.getUserScoresList(), function (item) {
    return _c('span', {
      key: item.getTeamId()
    }, [_vm._v(" " + _vm._s(item) + " " + _vm._s(_vm.getTeamName(item)) + "," + _vm._s(_vm.orgName(item)) + " "), _c('br')]);
  })], 2)], 1) : _vm._e()], 1), _c('v-tab-item', [_c('v-card', {
    staticClass: "mb-5",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-alert', {
    attrs: {
      "type": "error",
      "dense": "",
      "text": ""
    }
  }, [_vm._v(" This feature is for uploading teams for ICPC contest from CSV."), _c('br'), _vm._v(" If prefix is provided then offlineparser is used (old ICPC) - DEPRECATED, use offline contest for ICPC. ")]), _c('v-alert', {
    attrs: {
      "type": "success",
      "dense": "",
      "text": ""
    }
  }, [_vm._v(" Without prefix, onlineparser is used (AlgoQueen). ")])], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Email Prefix"
    },
    model: {
      value: _vm.emailPrefix,
      callback: function ($$v) {
        _vm.emailPrefix = $$v;
      },
      expression: "emailPrefix"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-file-input', {
    attrs: {
      "accept": "*",
      "label": "Select teams csv",
      "required": ""
    },
    model: {
      value: _vm.teamsCsv,
      callback: function ($$v) {
        _vm.teamsCsv = $$v;
      },
      expression: "teamsCsv"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-btn', {
    attrs: {
      "color": "accent",
      "height": "35"
    },
    on: {
      "click": _vm.saveTeamCsv
    }
  }, [_vm._v("Save")])], 1)], 1)], 1), _c('v-tab-item', [_c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Team Id"
    },
    model: {
      value: _vm.teamId,
      callback: function ($$v) {
        _vm.teamId = $$v;
      },
      expression: "teamId"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Member Email"
    },
    model: {
      value: _vm.memberEmail,
      callback: function ($$v) {
        _vm.memberEmail = $$v;
      },
      expression: "memberEmail"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "External TeamId"
    },
    model: {
      value: _vm.externalId,
      callback: function ($$v) {
        _vm.externalId = $$v;
      },
      expression: "externalId"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-btn', {
    on: {
      "click": _vm.findTeam
    }
  }, [_vm._v("Find Team")])], 1)], 1), _vm.isTeamFetched ? _c('v-row', [_c('v-col', [_c('team-detail', {
    attrs: {
      "teamView": _vm.teamAdmin,
      "teamExternalId": _vm.teamExternalId
    }
  })], 1)], 1) : _vm._e()], 1), _c('v-tab-item', [_c('v-row', [_c('v-col', [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Teams Ids if any"
    },
    model: {
      value: _vm.teamIds,
      callback: function ($$v) {
        _vm.teamIds = $$v;
      },
      expression: "teamIds"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "contest: From URL"
    },
    model: {
      value: _vm.fromContest,
      callback: function ($$v) {
        _vm.fromContest = $$v;
      },
      expression: "fromContest"
    }
  }), _c('v-btn', {
    attrs: {
      "color": "red"
    },
    on: {
      "click": _vm.importTeamsFunc
    }
  }, [_vm._v("import team to current contest")])], 1)], 1)], 1)], 1), _c('v-tab-item', [_c('ContestExtension', {
    attrs: {
      "url": _vm.content.getUrl()
    }
  })], 1), _c('v-tab-item', [_c('email-template', {
    on: {
      "email-sent": function (email) {
        return _vm.sendEmail(email);
      }
    }
  })], 1), _c('v-tab-item', [_c('offline-contest', {
    attrs: {
      "contest-url": _vm.content.getUrl(),
      "contentId": _vm.content.getId()
    }
  })], 1)], 1)], 1)], 1)], 1) : _c('span', [_c('v-row', [_c('v-col', [_vm._v(" " + _vm._s(_vm.userEmail) + " doesn't have admin access, please email to hello@codedrills.io ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }