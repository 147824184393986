<template>
  <v-container fluid>
    <div v-if="!this.user">Please login first</div>
    <Loader v-if="this.user && loading" />
    <NotFoundText v-if="this.user && !loading && !content" item="MCQ" />
    <span v-if="this.user && !loading && content">
      <div class="grey lighten-5 pl-12 pr-5 mx-0 my-0">
        <v-row>
          <v-col>
            <span class="display-2 font-weight-bold">
              {{ content.getTitle() }}
            </span>
          </v-col>
        </v-row>
      </div>
      <v-container fluid class="pl-12 pr-5 mx-0 my-0">
        <v-row>
          <v-col>
            <v-btn
              :loading="isSkillSaved"
              :disabled="isSkillSaved"
              color="accent"
              height="35"
              @click="saveMcq"
              >Save</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              :loading="isSkillPublished"
              :disabled="isPublishButtonDisabled"
              color="accent"
              height="35"
              @click="publishContentRequest"
              >Publish</v-btn
            >
          </v-col>
          <v-col>
            <v-btn color="accent" @click="exportSkillAndSave"
              >Export Skill Markdown</v-btn
            >
          </v-col>
        </v-row>
        <v-row class="px-2">
          <v-col cols="4" sm="2">
            <v-select
              label="Publish Type"
              :items="publishTypeKeys"
              :value="publishType"
              @change="changePublishType"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-tabs color="accent" slider-color="accent" v-model="skillTab">
              <v-tab>Preview</v-tab>
              <v-tab>Description</v-tab>
              <v-tab>Questions</v-tab>
              <v-tab>Metadata</v-tab>
              <v-tab>Selection Rules</v-tab>
              <v-tab>Mandatory Questions</v-tab>
              <v-tab>Attachments</v-tab>
              <v-tab>Permissions</v-tab>
            </v-tabs>

            <v-tabs-items v-model="skillTab">
              <v-tab-item>
                <!-- preview -->
                <div>Description preview</div>
                <v-row class="py-5 px-2">
                  <v-col>
                    <div
                      v-html="
                        view.getDataView().getSkillView().getDescriptionHtml()
                      "
                    ></div>
                  </v-col>
                </v-row>
                <div>Expert details preview</div>
                <v-row class="py-5 px-2">
                  <v-col>
                    <div
                      v-html="
                        view.getDataView().getSkillView().getExpertDetailsHtml()
                      "
                    ></div>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item>
                <!-- desc -->
                <v-row>
                  <v-col> Description </v-col>
                </v-row>
                <v-row class="px-2">
                  <v-col>
                    <v-text-field
                      :value="content.getTitle()"
                      @input="setTitle"
                      label="Title"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="px-2">
                  <v-col>
                    <v-text-field
                      :value="getImageUrl"
                      @input="setImageUrl"
                      label="Image URL"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="px-2">
                  <v-col>
                    <v-select
                      multiple
                      :items="languageKeys"
                      :value="getAllowedLanguageList"
                      @input="setAllowedLanguages"
                      label="Select language to be allowed during test"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row class="py-5 px-2">
                  <v-col>
                    <MarkdownEditor
                      :editorialMode="false"
                      :submissionMode="true"
                      :code="getDescription"
                      :onCodeChange="onMarkdownCodeChange"
                    />
                  </v-col>
                </v-row>
                <!-- expert image url -->
                <v-row class="px-2">
                  <v-col>
                    <v-text-field
                      :value="getExpertImageUrl"
                      @input="setExpertImageUrl"
                      label="Expert Image URL"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <!-- expert details-->
                <v-row>
                  <v-col> Expert Details </v-col>
                </v-row>
                <v-row class="py-5 px-2">
                  <v-col>
                    <MarkdownEditor
                      :editorialMode="false"
                      :submissionMode="true"
                      :code="getExpertDetails"
                      :onCodeChange="onExpertDetailsChange"
                    />
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <skill-questions-tab />
              </v-tab-item>
              <v-tab-item>
                <!-- metadata -->
                <v-row class="py-5 px-2">
                  <v-col>
                    <v-text-field
                      label="Time limit"
                      :value="getSkillTimeLimit"
                      @input="setSkillTimeLimit"
                    >
                    </v-text-field>
                    <v-combobox
                      color="accent"
                      :items="tagsTypes"
                      :value="content.getMetaData().getTagsList()"
                      hide-selected
                      hint="tagType/tag"
                      label="Add company, problem type tags"
                      persistent-hint
                      multiple
                      small-chips
                      solo
                      @change="setTags"
                    >
                    </v-combobox>
                  </v-col>
                </v-row>
                <v-row class="px-2">
                  <v-col>
                    <v-text-field
                      :value="getSkillMetaSummary"
                      @input="setSkillMetaSummary"
                      label="Summary"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="px-2">
                  <v-col>
                    <v-select
                      :items="skillLevelKeys"
                      :value="getSkillMetaSkillLevel"
                      @input="setSkillMetaSkillLevel"
                      label="Skill level"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row class="px-2">
                  <v-col>
                    <v-text-field
                      :value="getSkillMetaImageUrl"
                      @input="setSkillMetaImageUrl"
                      label="Image url"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <skill-selection-rules />
              </v-tab-item>
              <v-tab-item>
                <included-questions-in-skill />
              </v-tab-item>
              <v-tab-item>
                <!--Attachment-->
                <v-row>
                  <v-col>
                    <v-file-input
                      :rules="imageSizeRule"
                      accept="image/*"
                      placeholder="Pick an image"
                      prepend-icon="mdi-camera"
                      label="Attachment Image"
                      v-model="attachmentFile"
                    ></v-file-input>
                    <v-btn color="accent" @click="uploadAttachmentSave"
                      >Save Attachment
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="attachment && attachment.getUrl()">
                  <v-col>
                    {{ attachment.getUrl() }}
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <!--Permissions-->
                <permissions
                  :customerId="content.getCustomerId()"
                  :permissions="content.getMetaData().getPermissionsMap()"
                  :initialRestrictedDomains="
                    content.getMetaData().getRestrictedDomainList()
                  "
                  @permissionChange="setPermissions"
                  @restrictedDomainChange="setRestrictedDomains"
                  @addCustomers="addCustomersToSkill($event)"
                ></permissions>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <confirm-dialog ref="confirm"></confirm-dialog>
            <v-btn color="error" height="35" @click="deleteContentRequest">
              Delete Skill
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </span>
  </v-container>
</template>

<script>
import { getters, mapActions, mapState, mapMutations, mapGetters } from "vuex";
import MarkdownEditor from "../components/MarkdownEditor";
import CodeEditor from "../components/CodeEditor";
import NotFoundText from "../components/NotFoundText";
import Loader from "../components/Loader";
import ConfirmDialog from "../components/ConfirmDialog.vue";
import Permissions from "../components/Permissions.vue";
import SkillQuestionsTab from "../components/SkillQuestionsTab.vue";
import SkillSelectionRules from "../components/SkillSelectionRules.vue";
import IncludedQuestionsInSkill from "../components/IncludedQuestionsInSkill.vue";

export default {
  props: ["url"],
  data() {
    return {
      skillTab: null,
      isSkillSaved: false,
      isSkillPublished: false,
      attachmentFile: null,
      imageSizeRule: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Avatar size should be less than 2 MB!",
      ],
      tagsTypes: [
        "purpose/sample",
        "types/interview",
        "types/competitive",
        "drills/topic",
        "drills/company",
        "companies/adobe",
        "companies/amazon",
        "companies/apple",
        "companies/atlassian",
        "companies/cisco",
        "companies/directi",
        "companies/ebay",
        "companies/facebook",
        "companies/flipkart",
        "companies/goldman_sachs",
        "companies/google",
        "companies/jpmorgan_chase",
        "companies/linkedin",
        "companies/microsoft",
        "companies/oracle",
        "companies/paypal",
        "companies/sap",
        "companies/twitter",
        "companies/uber",
        "companies/vmware",
        "companies/walmart",
        "difficulties/easy",
        "difficulties/medium",
        "difficulties/hard",
      ],
    };
  },
  components: {
    MarkdownEditor,
    NotFoundText,
    Loader,
    ConfirmDialog,
    Permissions,
    SkillQuestionsTab,
    SkillSelectionRules,
    IncludedQuestionsInSkill,
  },
  computed: {
    ...mapState("skill", ["content", "view", "loading", "attachment"]),
    ...mapState("user", ["user", "hasPremiumAccess", "editorConfig"]),
    premiumBlock() {
      return this.premiumOnly && !this.hasPremiumAccess;
    },
    ...mapGetters("skill", [
      "languageKeys",
      "publishType",
      "publishTypeKeys",
      "skillLevelKeys",
      "getDescription",
      "getImageUrl",
      "getExpertDetails",
      "getExpertImageUrl",
      "getSkillMetaSummary",
      "getSkillMetaSkillLevel",
      "getSkillMetaImageUrl",
      "getSkillTimeLimit",
    ]),
    isPublishButtonDisabled() {
      return (
        this.content.getVersion() == this.content.getPublishVersion() ||
        this.isSkillPublished
      );
    },
    getAllowedLanguageList() {
      if (this.content.getData().getSkill().getLanguageRestriction()) {
        var lang = this.content
          .getData()
          .getSkill()
          .getLanguageRestriction()
          .getAllowLanguageList();
        return lang.map((l) => this.languageKeys[l]);
      }
    },
  },
  methods: {
    ...mapActions("skill", [
      "fetchSkill",
      "updateSkill",
      "updateAttachment",
      "exportSkill",
    ]),
    ...mapActions("content", ["publishContent", "deleteContent"]),
    ...mapMutations("skill", [
      "setDescription",
      "setTitle",
      "changePublishType",
      "setImageUrl",
      "setExpertDetails",
      "setExpertImageUrl",
      "setTags",
      "setPermissions",
      "setSkillMetaSummary",
      "setSkillMetaSkillLevel",
      "setSkillMetaImageUrl",
      "setInitSkillMeta",
      "setSkillTimeLimit",
      "setAllowedLanguages",
      "setCustomerId",
    ]),
    ...mapMutations("contest", ["setPermissions", "setRestrictedDomains"]),
    addCustomersToSkill(customers) {
      console.log("Adding customers to skill");
      console.log("Customers", customers);
      console.log("Content type is  .....", this.content.getContentType());
      var customerId = customers[0].id;
      this.setCustomerId(customerId);
    },
    uploadAttachmentSave() {
      console.log("File", this.attachmentFile);
      this.updateAttachment({ attachment: this.attachmentFile });
    },
    onMarkdownCodeChange(newCode) {
      this.setDescription(newCode);
    },
    onExpertDetailsChange(newCode) {
      this.setExpertDetails(newCode);
    },
    saveMcq() {
      this.isSkillSaved = true;
      this.updateSkill({ autopublish: true }).then((__) =>
        this.$router
          .push("/skills/" + this.content.getUrl())
          .catch((__) => {})
          .finally((__) => (this.isSkillSaved = false))
      );
    },
    exportSkillAndSave() {
      this.exportSkill().then((content) => {
        console.log("Path", this.contentJsonPath);
        var uriContent =
          "data:application/octet-stream," + encodeURIComponent(content);
        window.open(uriContent, "Save markdown");
      });
    },
    deleteContentRequest() {
      this.$refs.confirm
        .open("Delete", "Are you sure you want to delete this Skill?", {
          color: "red",
        })
        .then((confirm) => {
          if (confirm)
            this.deleteContent({ id: this.content.getId() }).then((_) =>
              this.$router.push("/skills")
            );
        });
    },
    publishContentRequest() {
      if (
        this.content.toObject().version !=
        this.content.toObject().publishVersion
      ) {
        this.isSkillPublished = true;
        this.publishContent({ id: this.content.getId() }).then((_) =>
          this.$router.go("/skills/" + this.content.getUrl()).finally((__) => {
            this.isSkillPublished = false;
          })
        );
      }
    },
  },
  watch: {
    user: function () {
      console.log("Changing user ...");
    },
    editorConfig: function () {
      this.updateUserPreference();
    },
  },
  created() {
    console.log("Creating...", this.url);
    this.fetchSkill({ url: this.url }).then((__) => {
      document.title = this.content.getTitle() + " - CodeDrills";
    });
    // this.setInitSkillMeta();
  },
};
</script>
<style scoped></style>
