var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    key: _vm.renderCount,
    attrs: {
      "grid-list-xs": ""
    }
  }, [_vm.teamView ? _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "444",
      "tile": "",
      "elevation": "1"
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.teamView.getName()) + " : " + _vm._s(_vm.teamView.getId()) + " : " + _vm._s(_vm.teamExternalId))]), _c('v-card-text', [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.teamView.getMembersList(), function (m, idx) {
    return _c('v-list-item', {
      key: idx
    }, [_c('v-list-item-title', [m.getHandle() ? _c('router-link', {
      staticClass: "text-decoration-none router_link",
      attrs: {
        "to": `/profiles/${m.getHandle()}`
      }
    }, [_vm._v(" " + _vm._s(m.getHandle()) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.memberName(m)) + " ")]), _c('br'), _c('span', [_vm._v(_vm._s(m.getEmail()))])], 1), _c('v-list-item-icon', [_c('v-tooltip', {
      attrs: {
        "top": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_c('v-icon', _vm._g(_vm._b({
            attrs: {
              "color": _vm.memberShipStatus[m.getMembershipState()].color
            }
          }, 'v-icon', attrs, false), on), [_vm._v(" " + _vm._s(_vm.memberShipStatus[m.getMembershipState()].icon) + " ")])];
        }
      }], null, true)
    }, [_c('span', [_vm._v(_vm._s(_vm.memberShipStatus[m.getMembershipState()].status))])])], 1)], 1);
  }), 1), _c('v-row')], 1), _c('confirm-dialog', {
    ref: "confirm"
  }), _c('v-card-actions', [_c('v-row', {
    staticClass: "mx-1"
  }, [_c('v-btn', {
    attrs: {
      "plain": "",
      "text": "",
      "color": "red"
    },
    on: {
      "click": _vm.handleDeleteTeam
    }
  }, [_vm._v("Delete")]), _c('error-message', {
    attrs: {
      "ex": _vm.ex
    }
  })], 1)], 1), _c('br')], 1)], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }