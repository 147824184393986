var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_c('v-col', [_c('span', {
    staticClass: "display-2 font-weight-bold"
  }, [_vm._v(" Contents ")])]), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('create-content')], 1)], 1), !_vm.contents ? _c('Loader') : _c('v-card', [_c('v-tabs', {
    attrs: {
      "color": "accent",
      "slider-color": "accent"
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', [_vm._v("Problems")]), _c('v-tab', [_vm._v("Drills")]), _c('v-tab', [_vm._v("Articles")]), _c('v-tab', [_vm._v("Contests")])], 1), _c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', [_c('content-list', {
    attrs: {
      "contents": _vm.problems
    }
  })], 1), _c('v-tab-item', [_c('content-list', {
    attrs: {
      "contents": _vm.drills
    }
  })], 1), _c('v-tab-item', [_c('content-list', {
    attrs: {
      "contents": _vm.articles
    }
  })], 1), _c('v-tab-item', [_c('content-list', {
    attrs: {
      "contents": _vm.contests
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }