var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "pa-3 mb-3",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "ID",
      "readonly": ""
    },
    model: {
      value: _vm.id,
      callback: function ($$v) {
        _vm.id = $$v;
      },
      expression: "id"
    }
  }), _vm.points >= 0 ? _c('v-text-field', {
    attrs: {
      "label": "Points",
      "type": "number",
      "rules": _vm.pointsRules
    },
    model: {
      value: _vm.points,
      callback: function ($$v) {
        _vm.points = $$v;
      },
      expression: "points"
    }
  }) : _vm.weightage >= 0 ? _c('v-text-field', {
    attrs: {
      "label": "Weightage",
      "type": "number",
      "rules": _vm.weightageRules
    },
    model: {
      value: _vm.weightage,
      callback: function ($$v) {
        _vm.weightage = $$v;
      },
      expression: "weightage"
    }
  }) : _vm._e(), _c('v-btn', {
    attrs: {
      "color": "red"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('update', _vm.group);
      }
    }
  }, [_vm._v("Update")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }