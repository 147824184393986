<template>
  <v-container fluid>
    <div v-if="!this.user">Please login first</div>
    <Loader v-if="this.user && loading" />
    <NotFoundText v-if="this.user && !loading && !content" item="problem" />
    <span v-if="this.user && !loading && content">
      <div class="grey lighten-5 pl-12 pr-5 mx-0 my-0">
        <v-row>
          <v-col>
            <span class="display-2 font-weight-bold">
              {{ content.getTitle() }}
            </span>
          </v-col>
        </v-row>
      </div>
      <v-container fluid class="pl-12 pr-5 mx-0 my-0">
        <v-row>
          <v-col>
            <v-btn
              :loading="isProblemSaved"
              :disabled="isProblemSaved"
              color="accent"
              height="35"
              @click="saveProblem"
              >Save</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              :loading="isProblemPublished"
              :disabled="isPublishButtonDisabled"
              color="accent"
              height="35"
              @click="publishContentRequest"
              >Publish</v-btn
            >
          </v-col>
          <v-col>
            <v-btn color="accent" @click="exportProblemRequest"
              >Export Problem</v-btn
            >
          </v-col>
        </v-row>
        <v-row class="px-2">
          <v-col cols="4" sm="2">
            <v-select
              label="Publish Type"
              :items="publishTypeKeys"
              :value="publishType"
              @change="changePublishType"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-tabs color="accent" slider-color="accent" v-model="problemTab">
              <v-tab>Preview</v-tab>
              <v-tab>Statement</v-tab>
              <v-tab>Judge</v-tab>
              <v-tab>Metadata</v-tab>
              <v-tab>Submission History</v-tab>
              <v-tab>Test</v-tab>
              <v-tab>Editorial</v-tab>
              <v-tab>Attachment</v-tab>
              <v-tab>Rejudge</v-tab>
              <v-tab>Permissions</v-tab>
            </v-tabs>
            <v-tabs-items v-model="problemTab">
              <v-tab-item>
                <!-- problem preview -->
                <v-row class="py-5 px-2">
                  <v-col>
                    <html-view
                      :html="view.getProblemView().getStatementView()"
                    ></html-view>
                  </v-col>
                </v-row>
                <v-row class="px-2">
                  <v-col cols="2">Time Limit</v-col>
                  <v-col cols="2">{{
                    content
                      .getMetaData()
                      .getProblemMeta()
                      .getConstraints()
                      .getTimeLimitInMs()
                  }}</v-col>
                </v-row>
                <v-row class="px-2">
                  <v-col cols="2">Memory Limit</v-col>
                  <v-col cols="2"
                    >{{
                      content
                        .getMetaData()
                        .getProblemMeta()
                        .getConstraints()
                        .getMemoryLimitInKb()
                    }}
                    KBytes</v-col
                  >
                </v-row>
                <v-row class="px-2">
                  <v-col cols="2">Source Limit</v-col>
                  <v-col cols="2"
                    >{{
                      content
                        .getMetaData()
                        .getProblemMeta()
                        .getConstraints()
                        .getSourceLimitInBytes()
                    }}
                    Bytes</v-col
                  >
                </v-row>
                <v-row>
                  <v-col class="mt-5">
                    <p class="headline">Editorial</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <html-view
                      :html="view.getProblemView().getEditorialView()"
                    ></html-view>
                  </v-col>
                </v-row>
                <v-row class="py-5 px-2">
                  <v-col>
                    <v-chip
                      class="mr-1"
                      color="#D5F9F9"
                      v-for="tag in content.getMetaData().getTagsList()"
                      :key="tag"
                    >
                      {{ tag }}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item>
                <!-- statement -->
                <v-row class="px-2">
                  <v-col>
                    <v-text-field
                      :value="content.getTitle()"
                      @change="setTitle"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="py-5 px-2">
                  <v-col>
                    <MarkdownEditor
                      :editorialMode="false"
                      :submissionMode="true"
                      :code="content.getData().getProblem().getStatement()"
                      :onCodeChange="onStatementChange"
                      :value="editorConfig"
                    />
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item>
                <!--Judge-->
                <v-row class="px-2">
                  <v-col cols="6" sm="4">
                    <v-select
                      label="Problem Type"
                      :items="problemTypeKeys"
                      :value="problemType"
                      @change="setProblemType"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row class="px-2">
                  <v-col cols="2">
                    <v-text-field
                      single
                      line
                      label="Time Limit"
                      suffix="MS"
                      :value="
                        content
                          .getMetaData()
                          .getProblemMeta()
                          .getConstraints()
                          .getTimeLimitInMs()
                      "
                      @change="setTimeLimitType"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      single
                      line
                      label="Memory Limit"
                      suffix="KBytes"
                      :value="
                        content
                          .getMetaData()
                          .getProblemMeta()
                          .getConstraints()
                          .getMemoryLimitInKb()
                      "
                      @change="setMemoryLimitInKb"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      single
                      line
                      label="Source Limit"
                      suffix="Bytes"
                      :value="
                        content
                          .getMetaData()
                          .getProblemMeta()
                          .getConstraints()
                          .getSourceLimitInBytes()
                      "
                      @change="setSourceLimitInBytes"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      single
                      line
                      label="Output limit"
                      suffix="KBytes"
                      :value="
                        content
                          .getMetaData()
                          .getProblemMeta()
                          .getConstraints()
                          .getOutputLimitInKb()
                      "
                      @change="setOutputLimitInKb"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="py-5 px-2">
                  <v-col>
                    <v-combobox
                      color="accent"
                      :items="verifiers"
                      :value="
                        content
                          .getData()
                          .getProblem()
                          .getJudgeSettings()
                          .getVerifier()
                      "
                      @change="changeVerifier"
                      label="Select Your verifier"
                      persistent-hint
                      solo
                    >
                    </v-combobox>
                  </v-col>
                </v-row>
                <v-row class="px-2">
                  <v-col cols="6" sm="4">
                    <v-select
                      label="IO Type"
                      :items="ioTypeKeys"
                      :value="ioType"
                      @change="setIOType"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row class="px-2">
                  <v-col cols="2">
                    <v-text-field
                      single
                      line
                      label="Class name"
                      :value="
                        content.getMetaData().getProblemMeta().getWrappedIo() &&
                        content
                          .getMetaData()
                          .getProblemMeta()
                          .getWrappedIo()
                          .getClassName()
                      "
                      @change="setClassName"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      single
                      line
                      label="Method name"
                      :value="
                        content.getMetaData().getProblemMeta().getWrappedIo() &&
                        content
                          .getMetaData()
                          .getProblemMeta()
                          .getWrappedIo()
                          .getMethodsList() &&
                        content
                          .getMetaData()
                          .getProblemMeta()
                          .getWrappedIo()
                          .getMethodsList()[0] &&
                        content
                          .getMetaData()
                          .getProblemMeta()
                          .getWrappedIo()
                          .getMethodsList()[0]
                          .getMethodName()
                      "
                      @change="setMethodName"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-select
                      label="Return type"
                      :items="argTypeKeys"
                      :value="returnType"
                      @change="setReturnType"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row class="px-2">
                  <v-col cols="6" sm="4">
                    <v-select
                      label="Evaluation Type"
                      :items="evaluationTypeKeys"
                      :value="evaluationType"
                      @change="setEvaluationType"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div class="title">Args</div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2">
                    <v-select
                      label="Arg type"
                      :items="argTypeKeys"
                      v-model="argType"
                    ></v-select>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      single
                      line
                      label="Arg name"
                      v-model="argName"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-btn color="accent" @click="addArg">Add</v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-row v-for="(a, i) in args" v-bind:key="i">
                      <v-col cols="2">
                        <v-select
                          label="Arg type"
                          :items="argTypeKeys"
                          :value="a.type"
                          @change="(t) => setArgType(i, t)"
                        ></v-select>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          single
                          line
                          label="Arg name"
                          :value="a.name"
                          @change="(n) => setArgName(i, n)"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-switch
                          color="error"
                          label="Delete"
                          :value="a.delete"
                          @change="(v) => setArgDelete(i, v)"
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <div class="title">Tests</div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div class="subtitle-1">Upload</div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-switch v-model="sample" :label="`Sample`"></v-switch>
                    <v-row>
                      <v-col cols="4">
                        <v-file-input
                          accept="*"
                          label="Select Test Files"
                          v-model="testFiles"
                          @change="testMethod"
                          multiple
                        ></v-file-input>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <!-- Add the Subtask Settings section here -->
                <v-row v-if="isSubtaskType">
                  <v-col>
                    <v-row>
                      <v-col>
                        <v-card class="pa-2" outlined tile>
                          <v-card-title>Subtask Rules</v-card-title>
                          <v-card-text>
                            <ul>
                              <li>
                                Subtask must have either positive points or
                                weightage but not both.
                              </li>
                              <li>
                                If weightage is selected, it will allow you to
                                publish and test. However, before publishing,
                                make sure the sum of weightage of all subtasks
                                is 100.
                              </li>
                              <li>
                                If the problem type is SUBTASK, the problem must
                                have subtask settings and at least 1 subtask
                                before publishing.
                              </li>
                            </ul>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-col>
                      <template>
                        <div>
                          <!-- ... -->
                          <v-btn
                            color="accent"
                            :loading="buttonLoading"
                            @click="addSubtaskGroup"
                            >Add New Subtask</v-btn
                          >
                          <!-- ... -->
                          <v-snackbar v-model="snackbar" :color="snackbarColor">
                            {{ snackbarText }}
                          </v-snackbar>
                        </div>
                      </template>
                      <div class="title">Subtask Settings</div>
                      <v-card class="pa-3" outlined>
                        <v-card-title>
                          <div class="subtitle-1">Add Subtask</div>
                        </v-card-title>
                        <v-select
                          v-model="scoreType"
                          :items="['weightage', 'points']"
                          label="Score Type"
                        ></v-select>
                        <v-text-field
                          v-model="scoreValue"
                          :label="scoreType"
                          type="number"
                        ></v-text-field>
                        <v-btn color="accent" @click="addSubtaskGroup"
                          >Add this Subtask</v-btn
                        >
                      </v-card>
                      <v-card outlined class="pa-3 mt-5">
                        <v-card-title>
                          <div class="subtitle-1">Subtasks</div>
                        </v-card-title>
                        <v-expansion-panels>
                          <v-expansion-panel
                            v-for="(group, index) in subtaskGroups"
                            :key="index"
                          >
                            <v-expansion-panel-header
                              >Subtask Group
                              {{ index }}</v-expansion-panel-header
                            >
                            <v-expansion-panel-content>
                              <SubtaskGroup
                                :group="group"
                                @update="(gr) => updateSubtaskGroup(index, gr)"
                              />
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-card>

                      <v-btn
                        color="red"
                        class="mt-5"
                        @click="removeLastSubtaskGroup"
                        >Remove Last Subtask</v-btn
                      >
                    </v-col>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <div class="title">Test Sets</div>
                  </v-col>
                </v-row>
                <v-row
                  v-for="(testSet, setId) in content
                    .getData()
                    .getProblem()
                    .getTestSetsMap().map_"
                  v-bind:key="setId"
                >
                  <v-col>
                    <v-row>
                      <v-col class="subtitle-1">{{ setId }} </v-col>
                      <v-col>
                        <v-btn
                          color="accent"
                          height="35"
                          @click="deleteAll(setId)"
                          >Delete All</v-btn
                        >
                      </v-col>
                      <v-col>
                        <v-btn
                          color="accent"
                          height="35"
                          @click="undeleteAll(setId)"
                          >Undelete All</v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-row class="subtitle-2">
                      <v-col>Input</v-col>
                      <v-col>Output</v-col>
                      <v-col>Tag</v-col>
                      <v-col>Remove</v-col>
                      <v-col>SubTask Index</v-col>
                    </v-row>
                    <v-row
                      v-for="(t, idx) in content
                        .getData()
                        .getProblem()
                        .getTestSetsMap()
                        .get(setId)
                        .getTestsList()"
                      :key="t.getId()"
                    >
                      <v-col v-bind:class="deletedClass(setId + '$' + idx)">
                        <router-link
                          :to="
                            '/problems/' +
                            content.getUrl() +
                            '/tests/' +
                            setId +
                            '/' +
                            t.getId()
                          "
                        >
                          {{ getFileNameFromTestPath(t.getInputId()) }}
                        </router-link>
                      </v-col>
                      <v-col v-bind:class="deletedClass(setId + '$' + idx)">
                        <router-link
                          :to="
                            '/problems/' +
                            content.getUrl() +
                            '/tests/' +
                            setId +
                            '/' +
                            t.getId()
                          "
                        >
                          {{ getFileNameFromTestPath(t.getOutputId()) }}
                        </router-link>
                      </v-col>
                      <v-col>
                        <v-form>
                          <v-container>
                            <v-row>
                              <v-col cols="12">
                                <v-autocomplete
                                  :value="getTestTagList(t)"
                                  :disabled="isUpdating"
                                  :items="tagTypeKeys"
                                  filled
                                  chips
                                  color="blue-grey lighten-2"
                                  label="Select"
                                  multiple
                                  @change="
                                    (tags) =>
                                      setTestTags({
                                        setId: setId,
                                        idx: idx,
                                        tags: tags,
                                      })
                                  "
                                >
                                  <template v-slot:selection="data">
                                    <v-chip
                                      v-bind="data.attrs"
                                      :input-value="data.selected"
                                      close
                                      @click="data.select"
                                      @click:close="
                                        () =>
                                          removeTestTag({
                                            setId: setId,
                                            idx: t.getId(),
                                            tag: data.item,
                                          })
                                      "
                                    >
                                      {{ data.item }}
                                    </v-chip>
                                  </template>
                                  <template v-slot:item="data">
                                    <template>
                                      <v-list-item-content
                                        v-text="data.item"
                                      ></v-list-item-content>
                                    </template>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-form>
                      </v-col>
                      <v-col>
                        <v-switch
                          color="error"
                          :value="deletedFiles.get(setId + '$' + idx)"
                          :id="setId + '$' + idx"
                          @change="(val) => deleteFile(setId + '$' + idx, val)"
                        ></v-switch>
                      </v-col>
                      <v-col>
                        <v-select
                          :value="t.getSubtaskIndexList()"
                          :items="subtaskGroups.map((_, i) => i)"
                          label="Select numbers"
                          multiple
                          @change="
                            (val) =>
                              setSubTaskIndex({
                                setId: setId,
                                idx: idx,
                                subTaskIndex: val,
                              })
                          "
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <!-- SQL/Library Editor -->
                <div v-if="evaluationType === 'EVALUATION_TYPE_SQL'">
                  <v-row>
                    <v-col> SQL Editor </v-col>
                  </v-row>
                  <v-row class="py-5 px-2">
                    <v-col>
                      <SqlEditor
                        :editorialMode="false"
                        :submissionMode="true"
                        :code="sqlInfo"
                        :onCodeChange="onSqlInfoChange"
                        :value="editorConfig"
                      />
                    </v-col>
                  </v-row>
                </div>
                <div v-if="evaluationType === 'EVALUATION_TYPE_LIBRARY'">
                  <v-row>
                    <v-col> Library Editor </v-col>
                  </v-row>
                  <v-row class="py-5 px-2">
                    <v-col>
                      <markdown-editor
                        :editorialMode="false"
                        :submissionMode="true"
                        :code="libraryInfo"
                        :onCodeChange="onLibraryInfoChange"
                        :value="editorConfig"
                      />
                    </v-col>
                  </v-row>
                </div>
              </v-tab-item>

              <v-tab-item>
                <!-- metaData -->
                <v-row class="py-5 px-2">
                  <v-col>
                    <v-combobox
                      color="accent"
                      :items="tagsTypes"
                      :value="content.getMetaData().getTagsList()"
                      hide-selected
                      hint="tagType/tag"
                      label="Add company, problem type tags"
                      persistent-hint
                      multiple
                      small-chips
                      solo
                      @change="setTags"
                    >
                    </v-combobox>
                  </v-col>
                </v-row>
                <v-row class="px-2">
                  <v-col cols="6" sm="4">
                    <v-select
                      label="Problem Submission Visibility"
                      :items="submissionVisibilityKeys"
                      :value="submissionVisibilityType"
                      @change="setSubmissionVisibility"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row class="px-2">
                  <v-col cols="6" sm="4">
                    <v-select
                      label="Select Language"
                      :items="languages"
                      :value="languageKeys"
                      @change="setLanguageType"
                    ></v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-file-input
                      accept="*"
                      label="Select Code Template"
                      v-model="codeTemplate"
                    ></v-file-input>
                  </v-col>
                </v-row>
                <v-row
                  v-if="
                    content.getMetaData().getProblemMeta().getCodeTemplateMap()
                  "
                >
                  <v-col>
                    <li
                      v-for="(value, key) in content
                        .getMetaData()
                        .getProblemMeta()
                        .getCodeTemplateMap().map_"
                      :key="key"
                    >
                      {{ value.key }} : {{ value.value }}
                    </li>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item>
                <!--Submissiom History -->
                <submission-history
                  :id="content.getId()"
                  :userId="userId"
                ></submission-history>
              </v-tab-item>

              <v-tab-item>
                <!--test -->
                <codingArea :contentView="content"></codingArea>
              </v-tab-item>

              <v-tab-item>
                <!-- editorial -->
                <v-row class="py-5 px-2">
                  <v-col>
                    <MarkdownEditor
                      :editorialMode="false"
                      :submissionMode="true"
                      :code="content.getData().getProblem().getEditorial()"
                      :onCodeChange="onEditorialChange"
                      :value="editorConfig"
                    />
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <!--Attachment-->
                <v-row>
                  <v-col>
                    <v-file-input
                      :rules="rules"
                      accept="image/*"
                      placeholder="Pick an avatar"
                      prepend-icon="mdi-camera"
                      label="Avatar"
                      v-model="attachmentFile"
                    ></v-file-input>
                    <v-btn color="accent" @click="uploadAttachmentSave"
                      >Save Attachment
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="attachment && attachment.getUrl()">
                  <v-col> Uploaded Image URL: {{ attachment.getUrl() }} </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <!--Rejudge -->
                <v-row>
                  <v-col>
                    <v-text-field
                      single
                      line
                      label="Min submission id"
                      v-model="rejudgeMin"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      single
                      line
                      label="Max submission id"
                      v-model="rejudgeMax"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-btn color="accent" height="35" @click="startRejudge"
                      >Rejudge</v-btn
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-simple-table
                    class="my-5"
                    v-if="rejudges && rejudges.rejudgesList"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Run ID</th>
                          <th class="text-left">Submitted on</th>
                          <th class="text-left">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in rejudges.rejudgesList"
                          :key="item.runId"
                        >
                          <td>{{ item.runId }}</td>
                          <td>{{ formatDate(item.submittedOn) }}</td>
                          <td>{{ formatStatus(item.status) }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <!--Permissions-->
                <permissions
                  :permissions="content.getMetaData().getPermissionsMap()"
                  :initialRestrictedDomains="
                    content.getMetaData().getRestrictedDomainList()
                  "
                  @permissionChange="
                    (ids, role) => appendPermissions(ids, role)
                  "
                  @removePermission="
                    (ids, role) => removePermissions(ids, role)
                  "
                  @restrictedDomainChange="setRestrictedDomains"
                ></permissions>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <confirm-dialog ref="confirm"></confirm-dialog>
            <v-btn color="error" height="35" @click="deleteContentRequest"
              >Delete Problem</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </span>
  </v-container>
</template>

<script>
import { getters, mapActions, mapState, mapMutations, mapGetters } from "vuex";
import MarkdownEditor from "../components/MarkdownEditor";
import SqlEditor from "../components/SqlEditor";
import NotFoundText from "../components/NotFoundText";
import Loader from "../components/Loader";
import Permissions from "../components/Permissions";
import CodingArea from "../components/CodingArea";
import Solution from "../components/Solution";
import HtmlView from "../components/HtmlView";
import SubmissionHistory from "../components/SubmissionHistory";
import ConfirmDialog from "../components/ConfirmDialog.vue";
import SubtaskGroup from "./SubtaskGroup.vue";

import judge from "codedrills_proto/io/codedrills/proto/judge/judge_pb";
var judge_proto = proto.io.codedrills.proto.judge;

export default {
  props: ["url"],
  data() {
    return {
      buttonLoading: false,
      snackbar: false,
      snackbarColor: "",
      snackbarText: "",
      subtaskGroups: [],
      scoreType: "weightage",
      scoreValue: "",
      verifiers: [
        "standard/token_matcher",
        "standard/floating_matcher",
        "standard/floating_matcher_6_digits",
        "standard/unordered_lines_matcher",
        "standard/sql_output_matcher",
        "standard/lenient_token_matcher",
      ],
      activeTab: "description",
      problemTab: null,
      testFiles: null,
      sample: false,
      deletedFiles: new Map(),
      args: [],
      argType: null,
      argName: null,
      autoUpdate: true,
      isUpdating: false,
      isProblemSaved: false,
      isProblemPublished: false,
      rejudgeMin: 0,
      rejudgeMax: 1000000000,
      codeTemplate: null,
      language: null,
      tagsTypes: [
        "types/interview",
        "types/competitive",
        "drills/topic",
        "drills/company",
        "companies/adobe",
        "companies/amazon",
        "companies/apple",
        "companies/atlassian",
        "companies/cisco",
        "companies/directi",
        "companies/ebay",
        "companies/facebook",
        "companies/flipkart",
        "companies/goldman_sachs",
        "companies/google",
        "companies/jpmorgan_chase",
        "companies/linkedin",
        "companies/microsoft",
        "companies/oracle",
        "companies/paypal",
        "companies/sap",
        "companies/twitter",
        "companies/uber",
        "companies/vmware",
        "companies/walmart",
        "difficulties/easy",
        "difficulties/medium",
        "difficulties/hard",
        "libraries/standard",
        "libraries/premium",
      ],
      attachmentFile: null,
      rules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Avatar size should be less than 2 MB!",
      ],
    };
  },
  components: {
    SubtaskGroup,
    CodingArea,
    MarkdownEditor,
    SqlEditor,
    NotFoundText,
    Loader,
    Permissions,
    Solution,
    HtmlView,
    SubmissionHistory,
    ConfirmDialog,
    MarkdownEditor,
  },
  computed: {
    ...mapState("problem", [
      "content",
      "view",
      "loading",
      "code",
      "runtimeError",
      "timeTaken",
      "memoryConsumed",
      "submissionStatus",
      "submissionId",
      "judgingCompleted",
      "contentJsonPath",
    ]),
    ...mapState("user", ["user", "hasPremiumAccess"]),
    ...mapState("problem", ["attachment", "rejudges"]),
    ...mapGetters("user", ["userId"]),
    premiumBlock() {
      return this.premiumOnly && !this.hasPremiumAccess;
    },
    ...mapGetters("problem", [
      "problemType",
      "problemTypeKeys",
      "ioType",
      "ioTypeKeys",
      "publishType",
      "publishTypeKeys",
      "argTypeKeys",
      "tagTypeKeys",
      "submissionVisibilityKeys",
      "submissionVisibilityType",
      "evaluationTypeKeys",
      "evaluationType",
    ]),
    ...mapGetters("judge", ["languageKeys", "languages"]),
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        return this.$route.query.tab;
      },
    },
    styles() {
      return { width: `${this.rating * 20}%` };
    },
    returnType() {
      if (
        !this.content.getMetaData().getProblemMeta().getWrappedIo() ||
        !this.content
          .getMetaData()
          .getProblemMeta()
          .getWrappedIo()
          .getMethodsList() ||
        this.content
          .getMetaData()
          .getProblemMeta()
          .getWrappedIo()
          .getMethodsList().length == 0
      )
        return null;
      return this.convertToType(
        this.content
          .getMetaData()
          .getProblemMeta()
          .getWrappedIo()
          .getMethodsList()[0]
          .getReturnType()
      );
    },
    isPublishButtonDisabled() {
      return (
        this.content.getVersion() == this.content.getPublishVersion() ||
        this.isProblemPublished
      );
    },
    sqlInfo() {
      if (this.content.getData().getProblem().getSqlInfo()) {
        return this.content.getData().getProblem().getSqlInfo().getSqlInit();
      }
    },
    libraryInfo() {
      if (this.content.getData().getProblem().getLibraryInfo()) {
        return this.content
          .getData()
          .getProblem()
          .getLibraryInfo()
          .getLibraryInit();
      }
    },
    isSubtaskType() {
      console.log("Subtask type is ", this.problemType);
      console.log("Subtask type condition ", this.problemType === "SUBTASK");
      console.log("Subtask keys ", this.problemTypeKeys);
      return this.problemType === "SUBTASK";
    },
  },
  methods: {
    ...mapActions("notifs", ["addNotif"]),
    ...mapActions("problem", [
      "fetchProblem",
      "updateProblem",
      "setReturnType",
      "setArgs",
      "exportProblem",
      "rejudge",
      "fetchRejudge",
    ]),
    ...mapActions("content", ["publishContent", "deleteContent"]),
    ...mapMutations("problem", [
      "setCode",
      "setStatement",
      "setEditorial",
      "setTitle",
      "setTags",
      "setProblemType",
      "setIOType",
      "setTimeLimitType",
      "setMemoryLimitInKb",
      "setSourceLimitInBytes",
      "setOutputLimitInKb",
      "uploadTestFile",
      "changePublishType",
      "setVerifier",
      "setTestTags",
      "removeTestTag",
      "setSubmissionVisibility",
      "setEvaluationType",
      "setSqlInit",
      "setLibraryInit",
      "setLanguageCodeTemplate",
      "setSubTaskIndex",
      "setSubtaskSettings",
      "updateSubtaskGroupsInSettings",
    ]),
    ...mapMutations("problem", [
      "setClassName",
      "setMethodName",
      "appendPermissions",
      "removePermissions",
      "setRestrictedDomains",
    ]),
    ...mapActions("problem", ["updateAttachment"]),
    async addSubtaskGroup() {
      this.buttonLoading = true;
      try {
        if (this.scoreValue < 0) {
          throw new Error("Score value must be greater than or equal to 0");
        }
        // Assert that either weightage or points is present, but not both
        if (
          (this.scoreType === "weightage" && this.scoreValue === "") ||
          (this.scoreType === "points" && this.scoreValue === "")
        ) {
          throw new Error(
            "A value for " + this.scoreType + " must be provided"
          );
        }

        // Create a new subtask group with a unique ID and the provided score
        const newSubtaskGroup = {
          id: this.content
            .getData()
            ?.getProblem()
            ?.getSubtaskSettings()
            ?.getSubtaskGroupsList().length, // Use the current timestamp as a unique ID
        };

        // Add the score to the new subtask group
        newSubtaskGroup[this.scoreType] = this.scoreValue;

        console.log(
          "Subtask settings before",
          this.content.getData().getProblem().getSubtaskSettings()
        );

        var subtaskGroupL = new judge_proto.SubtaskGroup();
        subtaskGroupL.setId(newSubtaskGroup.id);
        if (this.scoreType === "weightage") {
          subtaskGroupL.setWeightage(parseFloat(this.scoreValue));
        } else {
          subtaskGroupL.setPoints(parseInt(this.scoreValue));
        }

        this.setSubtaskSettings(subtaskGroupL);

        this.subtaskGroups = this.content
          .getData()
          .getProblem()
          .getSubtaskSettings()
          .getSubtaskGroupsList();

        await new Promise((resolve) => setTimeout(resolve, 1000));

        this.snackbarColor = "success";
        this.snackbarText = "Please save problem to presist the subtask";

        // Clear the score value
        this.scoreValue = "";
      } catch (error) {
        this.snackbarColor = "red";
        this.snackbarText = error.message;
        this.snackbar = true;
      } finally {
        this.buttonLoading = false;
        this.snackbar = true;
      }
    },
    removeLastSubtaskGroup() {
      // Remove the subtask group at the given index
      this.subtaskGroups.pop();
      console.log("Subtask settings after removing", this.subtaskGroups);
      this.updateSubtaskGroupsInSettings(this.subtaskGroups);

      this.snackbarColor = "success";
      this.snackbarText = "Please save problem to presist removed subtask";
      this.snackbar = true;
    },
    updateSubtaskGroup(index, updatedGroup) {
      console.log("Updating for ..", index, updatedGroup.toObject());
      // Update the subtask group at the given index with the updated group
      this.subtaskGroups[index] = updatedGroup;
      console.log("Subtask settings after updating", this.subtaskGroups);
      this.updateSubtaskGroupsInSettings(this.subtaskGroups);

      this.snackbarColor = "success";
      this.snackbarText = "Please save problem to presist the new points";
      this.snackbar = true;
    },
    setLanguageType(lan) {
      this.language = lan;
      console.log("Language type is ", this.language);
    },
    onStatementChange(newCode) {
      this.setStatement(newCode);
    },
    onSqlInfoChange(newCode) {
      this.setSqlInit(newCode);
    },
    onLibraryInfoChange(newCode) {
      this.setLibraryInit(newCode);
    },
    onEditorialChange(newCode) {
      this.setEditorial(newCode);
    },
    editorConfig(newConfig) {
      console.log("Change ", newConfig);
    },
    onCmCodeChange(newCode) {
      this.setCode(newCode);
    },
    changeTab(tab) {
      this.activeTab = tab;
    },
    formatDate(date) {
      const d = new Date(date);
      return d.toLocaleString();
    },
    formatStatus(status) {
      if (status == 1) return "Running";
      if (status == 2) return "Completed";
      else return "ERROR";
    },
    startRejudge() {
      this.rejudge({ min: this.rejudgeMin, max: this.rejudgeMax }).then((__) =>
        this.fetchRejudge()
      );
    },
    saveProblem() {
      this.isProblemSaved = true;
      this.updateProblem({
        testFiles: this.testFiles,
        sample: this.sample,
        deleteTestFiles: this.deletedFiles,
        language: this.language,
        codeTemplate: this.codeTemplate,
      })
        .then((__) => {
          this.clearTestFiles();
          this.codeTemplate = null;
          this.language = null;

          this.$store.dispatch("notifs/addNotif", {
            text: "Problem saved successfully",
            type: "success",
          });
        })
        .catch((error) => {
          console.log("Error in Probelm.vue", error);
          this.$store.dispatch("notifs/addNotif", {
            text: error.message,
            type: "error",
          });
        })
        .finally((__) => {
          this.isProblemSaved = false;
        });
    },
    deleteContentRequest() {
      this.$refs.confirm
        .open("Delete", "Are you sure you want to delete this problem?", {
          color: "red",
        })
        .then((confirm) => {
          if (confirm)
            this.deleteContent({ id: this.content.getId() }).then((_) =>
              this.$router.push("/")
            );
        });
    },
    publishContentRequest() {
      if (
        this.content.toObject().version !=
        this.content.toObject().publishVersion
      ) {
        this.isProblemPublished = true;
        this.publishContent({ id: this.content.getId() })
          .then((__) => {
            this.$store.dispatch("notifs/addNotif", {
              text: "Problem published successfully",
              type: "success",
            });
          })
          .catch((error) => {
            console.log("Error in Publishing problem", error);
            this.$store.dispatch("notifs/addNotif", {
              text: error.message,
              type: "error",
            });
          })
          .finally((__) => {
            this.isProblemPublished = false;
          });
      }
    },
    exportProblemRequest() {
      this.exportProblem({ url: this.content.getUrl() }).then((__) => {
        console.log("Path", this.contentJsonPath);
        window.location.href = this.contentJsonPath;
      });
    },
    testMethod() {
      console.log("Test data", this.testFiles);
    },
    getFileNameFromTestPath(testPath) {
      // TODO: Move to utils
      var lastIndex = testPath.lastIndexOf("/");
      var lastPath;
      if (lastIndex == -1) lastPath = testPath;
      else lastPath = testPath.substr(lastIndex + 1);

      var firstIndex = lastPath.indexOf("-");
      var name = lastPath;
      if (firstIndex != -1) name = lastPath.substr(firstIndex + 1);
      return name;
    },
    deletedClass(id) {
      return this.deletedFiles.get(id) ? "is-deleted" : "";
    },
    deleteFile(id, val) {
      this.deletedFiles = new Map(this.deletedFiles.set(id, val));
    },
    changeVerifier(newValue) {
      console.log("verifier ...", newValue);
      this.setVerifier(newValue);
    },
    clearTestFiles() {
      this.deletedFiles = new Map();
      this.testFiles = [];
      this.sample = false;
    },
    deleteAll(setId) {
      //console.log("testsets", this.content.getData().getProblem().getTestSetsMap().map_.entries());
      for (var k in this.content.getData().getProblem().getTestSetsMap().map_) {
        var v = this.content.getData().getProblem().getTestSetsMap().map_[k]
          .value[0];
        if (k == setId) {
          for (var i = 0; i < v.length; ++i) {
            this.deleteFile(k + "$" + i, true);
          }
        }
      }
    },
    undeleteAll(setId) {
      //console.log("testsets", this.content.getData().getProblem().getTestSetsMap().map_.entries());
      for (var k in this.content.getData().getProblem().getTestSetsMap().map_) {
        var v = this.content.getData().getProblem().getTestSetsMap().map_[k]
          .value[0];
        if (k == setId) {
          for (var i = 0; i < v.length; ++i) {
            this.deleteFile(k + "$" + i, false);
          }
        }
      }
    },
    addArg() {
      console.log("arg", this.argName, this.argType);
      this.args.push({
        name: this.argName,
        type: this.argType,
        delete: false,
      });
      this.setArgs(this.args);
    },
    setArgName(i, name) {
      this.args[i].name = name;
      this.setArgs(this.args);
    },
    setArgType(i, type) {
      this.args[i].type = type;
      this.setArgs(this.args);
    },
    setArgDelete(i, del) {
      this.args[i].delete = del;
      this.setArgs(this.args);
    },
    convertToType(t) {
      if (!t) return null;
      var type = "";
      if (t.hasNestedType()) {
        var nt = t.getNestedType();
        console.log("convertToType", nt, nt.getOuterType());
        if (nt.getOuterType() == 0) type += "Array";
        if (nt.getOuterType() == 1) type += "List";
        if (nt.getOuterType() == 2) type += "Tree";
        if (nt.getOuterType() == 3) type += "BinaryTree";
        type += "<" + this.convertToType(nt.getInnerTypesList()[0]) + ">";
        return type;
      }
      if (t.getPrimitiveType() == 0) return "Int32";
      if (t.getPrimitiveType() == 1) return "Int64";
      if (t.getPrimitiveType() == 2) return "Double";
      if (t.getPrimitiveType() == 3) return "String";
      if (t.getPrimitiveType() == 4) return "Boolean";
      if (t.getPrimitiveType() == 5) return "QuantumCircuit";
    },
    getTestTagList(t) {
      return t.getTagsList().map((tag) => this.tagTypeKeys[tag]);
    },
    uploadAttachmentSave() {
      console.log("File", this.attachmentFile);
      this.updateAttachment({ attachment: this.attachmentFile });
    },
  },
  watch: {
    user: function () {
      console.log("Changing user ...");
    },
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
  },
  created() {
    console.log("Creating.......");
    // console.log("keys", this.argTypeKeys);
    this.fetchProblem({ url: this.url })
      .then((__) => {
        console.log(
          "Problem type is ",
          this.problemTypeKeys[
            this.content.getMetaData().getProblemMeta().getProblemType()
          ]
        );
        console.log(
          "Is it subtask type ",
          this.problemTypeKeys[
            this.content.getMetaData().getProblemMeta().getProblemType()
          ] === "SUBTASK"
        );
        if (
          this.problemTypeKeys[
            this.content.getMetaData().getProblemMeta().getProblemType()
          ] === "SUBTASK" &&
          this.content
            .getData()
            .getProblem()
            .getSubtaskSettings()
            .getSubtaskGroupsList().length > 0
        ) {
          this.subtaskGroups = this.content
            .getData()
            .getProblem()
            .getSubtaskSettings()
            .getSubtaskGroupsList();
          console.log("Subtask groups ....", this.subtaskGroups);
        }
        if (
          !this.content.getMetaData().getProblemMeta().getWrappedIo() ||
          !this.content
            .getMetaData()
            .getProblemMeta()
            .getWrappedIo()
            .getMethodsList() ||
          this.content
            .getMetaData()
            .getProblemMeta()
            .getWrappedIo()
            .getMethodsList().length == 0 ||
          !this.content
            .getMetaData()
            .getProblemMeta()
            .getWrappedIo()
            .getMethodsList()[0]
            .getArgsList()
        )
          return;
        this.args = this.content
          .getMetaData()
          .getProblemMeta()
          .getWrappedIo()
          .getMethodsList()[0]
          .getArgsList()
          .map((a) => {
            return {
              name: a.getName(),
              type: this.convertToType(a.getType()),
              delete: false,
            };
          });
      })
      .then((__) => this.fetchRejudge());
  },
};
</script>
<style scoped>
.is-deleted {
  text-decoration: line-through;
}
</style>
