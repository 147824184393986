var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-container', [_c('v-card', {
    staticClass: "pa-4"
  }, [_c('h2', [_vm._v("Upload Teams for Offline Contest")]), _c('p', [_vm._v(" Format is \"(\"%s+%s@icpc.codedrills.io\", emailPrefix, id)\" where id is external id, and emailPrefix can be anything. CSV format will be - id, teamName, institution where id is external id. ")]), _c('v-form', [_c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Email Prefix"
    },
    model: {
      value: _vm.emailPrefix,
      callback: function ($$v) {
        _vm.emailPrefix = $$v;
      },
      expression: "emailPrefix"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-file-input', {
    attrs: {
      "accept": "*",
      "label": "Select teams csv"
    },
    model: {
      value: _vm.teamsCsv,
      callback: function ($$v) {
        _vm.teamsCsv = $$v;
      },
      expression: "teamsCsv"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-btn', {
    attrs: {
      "color": "accent",
      "height": "35"
    },
    on: {
      "click": _vm.saveTeamCsv
    }
  }, [_vm._v("Save")])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "my-4"
  }), _c('v-row', [_c('v-col', [_c('p', [_vm._v("Download Login Details here for this contest in CSV format")]), _c('v-btn', {
    staticClass: "mt-10",
    attrs: {
      "color": "accent"
    },
    on: {
      "click": _vm.fetchLoginDetails
    }
  }, [_vm._v(" Download Login Details ")])], 1)], 1), _c('v-divider', {
    staticClass: "my-4"
  }), _c('v-row', [_c('v-col', [_c('p', [_vm._v("Download Scoreboard from below after contest ends")]), _c('p', [_vm._v("NOTE: make sure tag \"contests/icpc\" is added for this to work")]), _c('v-btn', {
    staticClass: "mt-10",
    attrs: {
      "color": "accent"
    },
    on: {
      "click": _vm.downloadParticipants
    }
  }, [_vm._v(" Download Participants ")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }