<template>
  <v-container grid-list-xs :key="renderCount">
    <v-row v-if="teamView" justify="center">
      <v-col cols="12">
        <v-card class="mx-auto" max-width="444" tile elevation="1">
          <v-card-title>
            {{ teamView.getName() }} : {{ teamView.getId() }} :
            {{ teamExternalId }}</v-card-title
          >
          <v-card-text>
            <v-list dense>
              <v-list-item
                v-for="(m, idx) in teamView.getMembersList()"
                :key="idx"
              >
                <v-list-item-title>
                  <router-link
                    v-if="m.getHandle()"
                    class="text-decoration-none router_link"
                    :to="`/profiles/${m.getHandle()}`"
                  >
                    {{ m.getHandle() }}
                  </router-link>
                  <span v-else> {{ memberName(m) }} </span>
                  <br />
                  <span>{{ m.getEmail() }}</span>
                </v-list-item-title>

                <v-list-item-icon>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        :color="memberShipStatus[m.getMembershipState()].color"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ memberShipStatus[m.getMembershipState()].icon }}
                      </v-icon>
                    </template>
                    <span>{{
                      memberShipStatus[m.getMembershipState()].status
                    }}</span>
                  </v-tooltip>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
            <v-row> </v-row>
          </v-card-text>
          <confirm-dialog ref="confirm"></confirm-dialog>
          <v-card-actions>
            <v-row class="mx-1">
              <!-- <v-btn 
								plain
								text
								color="green"
								@click="handleTeamInvite('MEMBERSHIP_ACCEPTED')"
							>
								{{memberShipStatus[userStatus].message}}
							</v-btn>

							<v-btn
								plain
								text
								color="red"
								@click="handleTeamInvite('MEMBERSHIP_DECLINED')"
							>
								Decline Invite
							</v-btn>
 -->
              <v-btn @click="handleDeleteTeam" plain text color="red"
                >Delete</v-btn
              >
              <error-message :ex="ex"></error-message>
            </v-row>
          </v-card-actions>
          <br />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import ConfirmDialog from "../components/ConfirmDialog.vue";
import ErrorMessage from "../components/ErrorMessage";
import {
  mdiAccountAlertOutline,
  mdiAccountPlusOutline,
  mdiAccountCheckOutline,
  mdiAccountCancelOutline,
} from "@mdi/js";
import { normalizeUnits } from "moment";

export default {
  props: {
    teamView: {
      type: Object,
      require: true,
    },
    teamExternalId: {
      type: String,
      require: false,
    },
  },
  name: "teamView",
  components: {
    ConfirmDialog,
    ErrorMessage,
  },
  data: () => ({
    ex: null,
    teamAccepStatus: null,
    memberShipStatus: {
      0: {
        icon: mdiAccountAlertOutline,
        status: "UNKNOWN",
        color: "warning",
      },
      1: {
        icon: mdiAccountPlusOutline,
        status: "Invited",
        color: "accent",
        message: "Accept Invite",
      },
      2: {
        icon: mdiAccountCheckOutline,
        status: "Accepted",
        color: "success",
        message: "Decline Invite",
      },
      3: {
        icon: mdiAccountCancelOutline,
        status: "Declined",
        color: "danger",
        message: "Accept Invite",
      },
    },
    loading: true,
    renderCount: 0,
  }),
  methods: {
    ...mapActions("user", ["deleteTeamAdmin", "updateTeamInvite"]),
    handleDeleteTeam() {
      this.$refs.confirm
        .open("Delete", "Are you sure you want to delete this team?", {
          color: "red",
        })
        .then((confirm) => {
          if (confirm)
            this.deleteTeamAdmin({ teamId: this.teamView.getId() })
              .then((__) => {
                this.teamView = null;
                this.$store.dispatch("notifs/addNotif", {
                  text: "Deleted team!",
                  type: "success",
                });
              })
              .catch((ex) => {
                console.log("Error deleting team", ex);
                this.$store.dispatch("notifs/addNotif", {
                  text: ex.message || "Team deletion failed, please try again!",
                  type: "error",
                });
              });
        });
    },
    handleTeamInvite(state) {
      console.log("teamId ..", this.teamView.getId());
      this.updateTeamInvite({
        teamId: this.teamView.getId(),
        membershipState: this.teamMembership[state],
      })
        .then((__) => this.forceUpdate())
        .catch((ex) => (this.ex = ex));
    },
    forceUpdate() {
      console.log("Force udpate");
      this.$forceUpdate();
      this.renderCount += 1;
    },
    memberName(m) {
      if (this.isTeamMember) return m.getName() ? m.getName() : m.getEmail();
      else return m.getName() ? m.getName() : "UnNamed";
    },
  },
  computed: {
    ...mapState("user", ["getTeamStatus", "deleteTeamStatus"]),
    ...mapState("timesync", ["serverTime"]),
    ...mapGetters("user", ["user", "teamMembership", "teamMembershipKeys"]),
    userStatus() {
      if (this.teamView && this.user)
        var member = this.teamView
          .getMembersList()
          .find((u) => u.getEmail() === this.user.email);
      if (member) return member.getMembershipState();
    },
  },
};
</script>

<style scoped>
.router_link {
  color: inherit;
}
</style>
