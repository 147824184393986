var render = function render(){
  var _this$content, _this$content$getData, _this$content$getData2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "my-8 mx-4"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" Content Type ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" Count ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" Question Duration ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" Question Max Score ")]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Tags List ")])], 1), _vm._l((_this$content = this.content) === null || _this$content === void 0 ? void 0 : (_this$content$getData = _this$content.getData()) === null || _this$content$getData === void 0 ? void 0 : (_this$content$getData2 = _this$content$getData.getSkill()) === null || _this$content$getData2 === void 0 ? void 0 : _this$content$getData2.getQuestionSelectionCriteria().getSelectionRuleList(), function (rule, i) {
    return _c('v-row', {
      key: i
    }, [_c('v-col', {
      attrs: {
        "cols": "2"
      }
    }, [_vm._v(" " + _vm._s(_vm.contentTypeKeys[rule.getContentType()]) + " ")]), _c('v-col', {
      attrs: {
        "cols": "2"
      }
    }, [_vm._v(" " + _vm._s(rule.getNumberOfQuestions()) + " ")]), _c('v-col', {
      attrs: {
        "cols": "2"
      }
    }, [_vm._v(" " + _vm._s(rule.getQuestionDuration()) + " ")]), _c('v-col', {
      attrs: {
        "cols": "2"
      }
    }, [_vm._v(" " + _vm._s(rule.getQuestionMaxScore()) + " ")]), _c('v-col', {
      attrs: {
        "cols": "4"
      }
    }, [_vm._v(" " + _vm._s(rule.getTagList()) + " ")]), _c('v-col', [_c('v-btn', {
      attrs: {
        "color": "red"
      },
      on: {
        "click": function ($event) {
          return _vm.deleteRule(i);
        }
      }
    }, [_vm._v("Delete rule")])], 1)], 1);
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Content Type",
      "items": _vm.ruleContentTypes
    },
    model: {
      value: _vm.contentType,
      callback: function ($$v) {
        _vm.contentType = $$v;
      },
      expression: "contentType"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "placeholder": "Number of questions",
      "rules": [function (v) {
        return !!v || Number.isInteger(v) || 'Enter Valid Number';
      }, function (v) {
        return v && v >= 0 && v <= 150 || 'Between 0 to 150';
      }]
    },
    model: {
      value: _vm.count,
      callback: function ($$v) {
        _vm.count = $$v;
      },
      expression: "count"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "placeholder": "Question Duration in S",
      "rules": [function (v) {
        return !!v || Number.isInteger(v) || 'Enter Valid Number';
      }, function (v) {
        return v && v >= 0 && v <= 31536000 || 'Between 0s to 1yr';
      }]
    },
    model: {
      value: _vm.duration,
      callback: function ($$v) {
        _vm.duration = $$v;
      },
      expression: "duration"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "placeholder": "Question Max Score",
      "rules": [function (v) {
        return !!v || Number.isInteger(v) || 'Enter Valid Number';
      }, function (v) {
        return v && v >= 0 && v <= 10000 || 'Between 0 to 10000';
      }]
    },
    model: {
      value: _vm.score,
      callback: function ($$v) {
        _vm.score = $$v;
      },
      expression: "score"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-combobox', {
    attrs: {
      "color": "accent",
      "items": _vm.ruleValues,
      "hide-selected": "",
      "hint": "tagType/tag",
      "label": "Add company, problem type tags",
      "persistent-hint": "",
      "multiple": "",
      "small-chips": "",
      "solo": ""
    },
    on: {
      "change": function ($event) {
        return _vm.setRuleTags();
      }
    },
    model: {
      value: _vm.tags,
      callback: function ($$v) {
        _vm.tags = $$v;
      },
      expression: "tags"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "accent"
    },
    on: {
      "click": _vm.addRule
    }
  }, [_vm._v("Add")])], 1)], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }