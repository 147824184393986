<template>
  <span>
    <v-row>
      <v-spacer></v-spacer>
      <v-col :cols="4">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search (title, tags)"
          clearable
          single-line
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="contents"
      disable-pagination
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-dialog v-model="addSubjDialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="accent" dark class="mb-2" v-bind="attrs" v-on="on">
                Add Subjective
              </v-btn>
            </template>
            <v-card v-if="!savingSubj">
              <v-card-title class="headline accent lighten-2" primary-title>
                Create New Subjective
              </v-card-title>

              <v-card-text>
                <v-form v-model="newSubjForm">
                  <v-text-field
                    v-model="newSubjectiveTitle"
                    label="Subjective Title"
                    required
                  ></v-text-field>
                </v-form>
              </v-card-text>

              <v-divider></v-divider>
              <v-card-actions>
                <v-btn
                  :disabled="!newSubjForm"
                  color="accent"
                  @click="createSubjective"
                >
                  Create
                </v-btn>
              </v-card-actions>
            </v-card>
            <v-card v-else>
              <v-card-title class="headline accent lighten-2" primary-title>
                Creating New Subjective
              </v-card-title>
              <v-card-actions>
                <Loader></Loader>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td>
            <router-link class="ml-1" :to="getUrl(item)">
              {{ item.title }}
            </router-link>
          </td>
        </tr>
      </template>
    </v-data-table>
  </span>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";

import Loader from "../components/Loader.vue";
export default {
  component: {
    Loader,
  },
  props: {
    contents: {
      type: Array,
    },
  },
  data: function () {
    return {
      addSubjDialog: false,
      savingSubj: false,
      newSubjForm: false,
      newSubjectiveTitle: "",
      showTags: true,
      search: null,
      headers: [
        {
          text: "Title",
          align: "left",
          sortable: true,
          value: "title",
        },
      ],
    };
  },
  created() {
    console.log("contents", this.contents);
  },
  computed: {
    ...mapState("content", ["contents"]),
  },
  methods: {
    ...mapActions("content", ["createContent"]),
    getUrl(item) {
      var contentTypeString = this.contentTypeKeys[item.contentType];
      return "/" + contentTypeString.toLowerCase().trim() + "s/" + item.url;
    },
    searchTag(e) {
      this.search = e.target.innerText;
    },
    createSubjective() {
      this.savingSubj = true;
      this.createContent({
        title: this.newSubjectiveTitle,
        contentType: "SUBJECTIVE",
        template: null,
      })
        .then((res) => {
          this.$router.push("subjectives/" + res.getContent().getUrl());
        })
        .catch((err) => console.error(err))
        .finally(() => {
          this.savingSubj = false;
          this.addSubjDialog = false;
        });
    },
  },
  computed: {
    ...mapGetters("content", ["contentTypeKeys"]),
  },
};
</script>
