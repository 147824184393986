var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.iamAdmin ? _c('span', [_c('v-row', [_c('v-col', [_c('v-tabs', {
    attrs: {
      "align-with-title": "",
      "color": "accent",
      "slider-color": "accent"
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', [_vm._v("User List")]), _c('v-tab', [_vm._v("User Permission")]), _c('v-tab', [_vm._v("User Scope")]), _c('v-tab', [_vm._v("Upload MCQs")]), _c('v-tab', [_vm._v("Payment Plan")]), _c('v-tab', [_vm._v("Analytics")]), _c('v-tab', [_vm._v("Create coupons")]), _c('v-tab', [_vm._v("Test Report")]), _c('v-tab', [_vm._v("Assessment settings")])], 1), _c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', [_c('v-card', [_c('v-card-actions', [_c('v-btn', {
    attrs: {
      "loading": _vm.toggleloader,
      "disabled": !_vm.valid,
      "color": "accent"
    },
    on: {
      "click": _vm.fetchUsers
    }
  }, [_vm._v(" fetch Users ")])], 1), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('v-checkbox', {
    attrs: {
      "label": `Get Verification Link: ${_vm.getVerificationLink.toString()}`
    },
    model: {
      value: _vm.getVerificationLink,
      callback: function ($$v) {
        _vm.getVerificationLink = $$v;
      },
      expression: "getVerificationLink"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-combobox', {
    attrs: {
      "label": "Add member email IDs:",
      "multiple": "",
      "chips": "",
      "clearable": "",
      "color": "accent",
      "rules": _vm.emailRules,
      "hint": "Press Tab or Enter after each email(s)."
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          item = _ref.item;
        return [_c('v-chip', _vm._b({
          attrs: {
            "close": ""
          },
          on: {
            "click:close": function ($event) {
              return _vm.removeEmail(item);
            }
          }
        }, 'v-chip', attrs, false), [_vm._v(_vm._s(item) + " ")])];
      }
    }], null, false, 51963021),
    model: {
      value: _vm.emails,
      callback: function ($$v) {
        _vm.emails = $$v;
      },
      expression: "emails"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.adminUsersInfo
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('tr', [_c('td', [_vm._v(_vm._s(item.getName()))]), _c('td', [_vm._v(_vm._s(item.getId()))]), _c('td', [_vm._v(_vm._s(item.getEmail()))]), _c('td', [_vm._v(_vm._s(item.getHandle()))]), _c('td', [_vm._v(_vm._s(item.getVerified()))]), _c('td', [_vm._v(_vm._s(item.getVerificationLink()))])])];
      }
    }], null, false, 3044830577)
  })], 1)], 1)], 1)], 1)], 1), _c('v-tab-item', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": 4
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "email"
    },
    on: {
      "change": _vm.addUser
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": 4
    }
  }, [_c('v-combobox', {
    attrs: {
      "value": _vm.scopes,
      "items": _vm.scopeTypeKeys,
      "label": "User Scope",
      "multiple": "",
      "chips": ""
    },
    on: {
      "change": _vm.changeScopes
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": 4
    }
  }, [_c('v-combobox', {
    attrs: {
      "value": _vm.roles,
      "items": _vm.roleTypeKeys,
      "label": "Role",
      "chips": ""
    },
    on: {
      "change": _vm.changeRole
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-btn', {
    attrs: {
      "color": "accent"
    },
    on: {
      "click": _vm.updateUser
    }
  }, [_vm._v("Save")])], 1)], 1), _c('v-row', [_c('v-col', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  })], 1)], 1)], 1)], 1), _c('v-tab-item', [_c('v-row', [_c('v-col', [_c('v-btn', {
    on: {
      "click": _vm.getUsersWithScope
    }
  }, [_vm._v(" Fetch User with Scopes")])], 1)], 1), _c('v-row', [_c('v-col', [_c('v-data-table', {
    attrs: {
      "headers": _vm.scopeHeaders,
      "items": _vm.usersWithScopes
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('tr', [_c('td', [_vm._v(_vm._s(item.getEmail()))]), _c('td', [_vm._v(_vm._s(item.getName()))]), _c('td', [_vm._v(" " + _vm._s(_vm.scopeTypeKeys[item.getScopes().getScopesList()[0]]) + " ")])])];
      }
    }], null, false, 914382582)
  })], 1)], 1)], 1), _c('v-tab-item', [_c('v-row', [_c('v-col', [_c('v-file-input', {
    attrs: {
      "accept": "*",
      "label": "Select mcqs csv"
    },
    model: {
      value: _vm.mcqCsv,
      callback: function ($$v) {
        _vm.mcqCsv = $$v;
      },
      expression: "mcqCsv"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-btn', {
    attrs: {
      "color": "accent",
      "height": "35"
    },
    on: {
      "click": _vm.saveMcqCsv
    }
  }, [_vm._v("Save")])], 1)], 1), _c('v-row', [_c('v-col', [_vm.csvErrorText.length > 0 ? _c('v-btn', {
    staticClass: "px-5 py-6 text-none text-body-1 font-weight-medium",
    attrs: {
      "x-large": "",
      "color": "success",
      "download": "updated_mcqs.csv",
      "href": `data:application/octet-stream,${encodeURI(this.csvErrorText)}`
    }
  }, [_vm._v(" Download CSV with errors ")]) : _vm._e()], 1)], 1)], 1), _c('v-tab-item', [_c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Customer id"
    },
    model: {
      value: _vm.customerId,
      callback: function ($$v) {
        _vm.customerId = $$v;
      },
      expression: "customerId"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "label": "Plan Type",
      "items": _vm.planTypeKeys
    },
    model: {
      value: _vm.planType,
      callback: function ($$v) {
        _vm.planType = $$v;
      },
      expression: "planType"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "label": "Add on",
      "items": _vm.addOnKeys
    },
    model: {
      value: _vm.addOn,
      callback: function ($$v) {
        _vm.addOn = $$v;
      },
      expression: "addOn"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-btn', {
    attrs: {
      "color": "accent",
      "loading": _vm.addAdminLoading
    },
    on: {
      "click": _vm.updatePlan
    }
  }, [_vm._v("Update Plan")])], 1)], 1), _c('v-row', [_c('v-col', [_c('v-btn', {
    on: {
      "click": _vm.downlodCustomersCSV
    }
  }, [_vm._v("Download CSV")])], 1)], 1), _c('v-row', [_c('v-col', [_c('v-btn', {
    on: {
      "click": _vm.getAllPaidCustomersAPI
    }
  }, [_vm._v("Fetch Customers")])], 1)], 1), _c('v-row', [_c('v-col', [_c('v-data-table', {
    attrs: {
      "headers": _vm.customerHeaders,
      "items": _vm.paidCustomers
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('router-link', {
          attrs: {
            "to": `/admin/customer/${item.id}`
          }
        }, [_vm._v(_vm._s(item.name || item.id) + " ")])];
      }
    }, {
      key: "item.addOns",
      fn: function (_ref5) {
        var item = _ref5.item;
        return _vm._l(item.addOns, function (addOn) {
          return _c('v-chip', {
            key: addOn,
            attrs: {
              "small": ""
            }
          }, [_vm._v(_vm._s(addOn))]);
        });
      }
    }], null, false, 3292178520)
  })], 1)], 1)], 1), _c('v-tab-item', [_c('v-row', [_c('v-col', [_c('v-btn', {
    attrs: {
      "color": "accent",
      "height": "35"
    },
    on: {
      "click": _vm.candidateFeedback
    }
  }, [_vm._v("candidate Feedback")]), _c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.feedbackHeaders,
      "items": _vm.feedbackList,
      "items-per-page": 25
    }
  })], 1)], 1)], 1), _c('v-tab-item', [_c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Coupon"
    },
    model: {
      value: _vm.coupon,
      callback: function ($$v) {
        _vm.coupon = $$v;
      },
      expression: "coupon"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Count"
    },
    model: {
      value: _vm.count,
      callback: function ($$v) {
        _vm.count = $$v;
      },
      expression: "count"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-btn', {
    attrs: {
      "loading": _vm.createLoading,
      "color": "accent"
    },
    on: {
      "click": _vm.createCouponsReq
    }
  }, [_vm._v("Create coupons")])], 1)], 1), _vm.gsUrl ? _c('v-row', [_c('v-col', [_vm._v(" File url is : " + _vm._s(_vm.gsUrl) + " ")])], 1) : _vm._e()], 1), _c('v-tab-item', [_c('v-row', {
    staticClass: "mb-3"
  }, [_c('v-col', [_c('v-text-field', {
    staticClass: "pa-2",
    attrs: {
      "label": "Test ID",
      "type": "number"
    },
    model: {
      value: _vm.testId,
      callback: function ($$v) {
        _vm.testId = $$v;
      },
      expression: "testId"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-btn', {
    staticClass: "pa-2",
    attrs: {
      "loading": _vm.regenerateReportLoading,
      "color": "accent"
    },
    on: {
      "click": _vm.regenerateReportReq
    }
  }, [_vm._v(" Regenerate Report ")])], 1)], 1)], 1), _c('v-tab-item', [_c('v-text-field', {
    attrs: {
      "label": "URL"
    },
    model: {
      value: _vm.testURL,
      callback: function ($$v) {
        _vm.testURL = $$v;
      },
      expression: "testURL"
    }
  }), _c('v-btn', {
    attrs: {
      "color": "green"
    },
    on: {
      "click": _vm.fetchDateTime
    }
  }, [_vm._v("Fetch Expiry First")]), _c('v-card', {
    staticClass: "pa-5",
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-card-title', [_vm._v(" Test Settings ")]), _c('v-card-text', [_c('v-checkbox', {
    attrs: {
      "label": "Enable Test Invite by URL"
    },
    model: {
      value: _vm.testByUrlEnabled,
      callback: function ($$v) {
        _vm.testByUrlEnabled = $$v;
      },
      expression: "testByUrlEnabled"
    }
  }), _vm.testByUrlEnabled ? _c('div', [_c('label', {
    staticClass: "text-h6 black--text",
    attrs: {
      "for": "url-expiry-picker"
    }
  }, [_vm._v(" Set Expiry Date for Test URL ")]), _c('div', [_c('v-date-picker', {
    attrs: {
      "id": "url-expiry-picker",
      "allowed-dates": _vm.allowedDatesForLink,
      "elevation": "1"
    },
    model: {
      value: _vm.testUrlExpiryEpoch,
      callback: function ($$v) {
        _vm.testUrlExpiryEpoch = $$v;
      },
      expression: "testUrlExpiryEpoch"
    }
  })], 1), _c('div', [_c('v-time-picker', {
    attrs: {
      "color": "accent"
    },
    model: {
      value: _vm.expiryTime,
      callback: function ($$v) {
        _vm.expiryTime = $$v;
      },
      expression: "expiryTime"
    }
  })], 1)]) : _vm._e()], 1)], 1), _c('v-col', {
    staticClass: "d-flex flex-column text-h6",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-row"
  }, [_c('b', [_vm._v("Enable Test URL:")]), _c('div', {
    staticClass: "pl-3"
  }, [_vm._v(" " + _vm._s(_vm.testByUrlEnabled ? "Yes" : "No") + " ")])]), _c('div', {
    staticClass: "d-flex flex-row"
  }, [_vm.testByUrlEnabled ? _c('b', [_vm._v("Test URL Expiry:")]) : _vm._e(), _c('div', {
    staticClass: "pl-3"
  }, [_vm._v(" " + _vm._s(_vm.testByUrlEnabled ? _vm.stringFormatDateTime : "") + " ")])]), _c('v-btn', {
    attrs: {
      "color": "red"
    },
    on: {
      "click": _vm.updateEndDateTime
    }
  }, [_vm._v("Save date time")])], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }