<template>
  <v-container fluid>
    <div v-if="!this.user">Please login first</div>
    <Loader v-if="this.user && loading" />
    <NotFoundText v-if="this.user && !loading && !content" item="contest" />
    <span v-if="this.user && !loading">
      <div class="grey lighten-5 pl-12 pr-5 mx-0 my-0">
        <v-row>
          <v-col>
            <span class="display-2 font-weight-bold">
              {{ content.getTitle() }}</span
            >
          </v-col>
        </v-row>
      </div>
      <v-container fluid class="pl-12 pr-5 mx-0 my-0">
        <v-row>
          <v-col>
            <v-btn
              :loading="isContestSaved"
              :disabled="isContestSaved"
              color="accent"
              height="50"
              width="100"
              @click="saveContest"
              >Save</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              :loading="isContestPublished"
              :disabled="isPublishButtonDisabled"
              color="accent"
              height="35"
              @click="publishContentRequest"
              >Publish</v-btn
            >
          </v-col>
        </v-row>
        <v-row class="px-2">
          <v-col cols="4" sm="2">
            <v-select
              label="Publish Type"
              :items="publishTypeKeys"
              :value="publishType"
              @change="changePublishType"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="accent" router-link :to="this.contestUrl + '/admin'"
              >Contest Admin Dashboard</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col
            :cols="12"
            :sm="3"
            :lg="2"
            v-bind:class="{ 'text-center': $vuetify.breakpoint.mobile }"
          >
            <v-btn
              color="accent"
              router-link
              :to="this.contestUrl + '/scoreboard'"
            >
              Scoreboard
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-tabs color="accent" slider-color="accent" v-model="contestTab">
              <v-tab>Preview</v-tab>
              <v-tab>About</v-tab>
              <v-tab>Rules</v-tab>
              <v-tab>MetaData</v-tab>
              <v-tab>Team MetaData</v-tab>
              <v-tab>Problems</v-tab>
              <v-tab>Scoring</v-tab>
              <v-tab>Attachment</v-tab>
              <v-tab>Permissions</v-tab>
            </v-tabs>

            <v-tabs-items v-model="contestTab">
              <v-tab-item>
                <!-- contest preview -->
                <v-row class="py-5 px-2">
                  <v-col>
                    <div v-html="view.getDataView().getContestView()"></div>
                  </v-col>
                </v-row>
                <v-row class="py-5 px-2">
                  <v-col>
                    <v-chip
                      class="mr-1"
                      color="accent"
                      v-for="tag in content.getMetaData().getTagsList()"
                      :key="tag"
                    >
                      {{ tag }}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item>
                <!-- About -->
                <v-row class="px-2">
                  <v-col>
                    <v-text-field
                      label="Contest Title"
                      :value="content.getTitle()"
                      @change="setTitle"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="py-5 px-2">
                  <v-col>
                    <MarkdownEditor
                      :editorialMode="false"
                      :submissionMode="true"
                      :code="content.getData().getContest().getAbout()"
                      :onCodeChange="onAboutChange"
                      :value="editorConfig"
                    />
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item>
                <!-- Rules -->
                <v-row class="py-5 px-2">
                  <v-col>
                    <MarkdownEditor
                      :editorialMode="false"
                      :submissionMode="true"
                      :code="content.getData().getContest().getRules()"
                      :onCodeChange="onRulesChange"
                      :value="editorConfig"
                    />
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item>
                <!-- MetaData -->
                <v-row class="py-5 px-2">
                  <v-col>
                    <v-combobox
                      color="accent"
                      :items="tagsTypes"
                      :value="content.getMetaData().getTagsList()"
                      hide-selected
                      hint="tagType/tag"
                      label="Add company, problem type tags"
                      persistent-hint
                      multiple
                      small-chips
                      solo
                      @change="setTags"
                    >
                    </v-combobox>
                  </v-col>
                </v-row>
                <v-row class="px-2">
                  <v-col>
                    <v-select
                      multiple
                      :items="languageKeys"
                      :value="getAllowedLanguageList"
                      @input="setAllowedLanguages"
                      label="Select language to be allowed during test"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row class="px-2">
                  <v-col cols="6">
                    <v-text-field
                      label="Set icon url"
                      :value="content.getMetaData().getImageUrl()"
                      @input="changeImageUrl"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="px-2">
                  <v-col cols="6">
                    <v-text-field
                      label="Set banner url"
                      :value="
                        content.getMetaData().getContestMeta() &&
                        content.getMetaData().getContestMeta().getBannerUrl()
                      "
                      @input="changeBannerUrl"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="iamAdmin" class="px-2">
                  <v-col cols="6" sm="4">
                    <v-select
                      label="Is Submission Restricted"
                      :items="restrictTypes"
                      :value="isRestrictSubmission"
                      @input="setIsRestrictSubmission"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row class="px-2">
                  <v-col cols="6" sm="4">
                    <v-select
                      label="Contest Submission Visibility"
                      :items="submissionVisibilityKeys"
                      :value="submissionVisibilityType"
                      @change="setSubmissionVisibility"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row class="px-2">
                  <v-col cols="6" sm="4">
                    <v-select
                      label="Set Scoreboard Visibility"
                      :items="scoreboardVisibilityKeys"
                      v-model="scoreboardVisibility"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row class="px-2">
                  <v-col cols="6" sm="4">
                    <v-select
                      label="Set Test case Visibility"
                      :items="testCaseVisibilityKeys"
                      v-model="testCaseVisibility"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div class="title">Pick Start Date and Time</div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col :offset="1" :cols="4">
                    <v-date-picker
                      color="accent"
                      v-model="startDate"
                    ></v-date-picker>
                  </v-col>
                  <v-col :cols="4">
                    <v-time-picker
                      color="accent"
                      v-model="startTime"
                    ></v-time-picker>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div class="title">Pick End Date and Time</div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col :offset="1" :cols="4">
                    <v-date-picker
                      color="accent"
                      v-model="endDate"
                    ></v-date-picker>
                  </v-col>
                  <v-col :cols="4">
                    <v-time-picker
                      color="accent"
                      v-model="endTime"
                    ></v-time-picker>
                  </v-col>
                </v-row>
                <div>
                  <h2>Any custom template for email:</h2>
                  <ul>
                    <li
                      v-for="(value, key) in customEmailTemplateIds"
                      :key="key"
                    >
                      {{ value[0] }}: {{ value[1] }}
                    </li>
                  </ul>
                  <h2>Add new key-value pair:</h2>
                  <v-row>
                    <v-col cols="6" sm="4">
                      <v-text-field label="Key" v-model="newKey"></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="4">
                      <v-text-field
                        label="Value"
                        v-model="newValue"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-btn @click="addNewCustomEmailTempate">Add</v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-tab-item>

              <v-tab-item>
                <!-- Team MetaData -->
                <v-row class="px-2">
                  <v-col cols="4" sm="2">
                    <v-select
                      label="Contest type"
                      :items="contestTypeKeys"
                      :value="contestType"
                      @change="changeContestType"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row v-if="isTeamContest" class="px-2">
                  <v-col cols="4" sm="2">
                    <v-select
                      label="Is team Editable"
                      :items="boolTypes"
                      :value="isEditable"
                      @input="setTeamEditablity"
                    ></v-select>
                  </v-col>
                  <v-col cols="4" sm="2">
                    <v-text-field
                      label="Minimum team size"
                      :value="minTeamSize"
                      @input="updateMinTeamSize"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" sm="2">
                    <v-text-field
                      label="Maximum team size"
                      :value="maxTeamSize"
                      @input="updateMaxTeamSize"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" sm="4">
                    <v-select
                      label="Team Name uniqueness"
                      :items="nameUniquenessKeys"
                      :value="nameUniquenessType"
                      @change="updateNameUniqueness"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- coach settings-->
                  <v-col cols="4" sm="2">
                    <v-select
                      label="Is Coach Enabled"
                      :items="boolTypes"
                      :value="isCoachEnabled"
                      @input="setIsCoachEnabled"
                    ></v-select>
                  </v-col>
                  <v-col v-if="isCoachEnabled" cols="4" sm="4">
                    <v-select
                      label="Coach Presence"
                      :items="presenceKeys"
                      :value="coachPresence"
                      @input="setCoachPresence"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row class="title">
                  <v-col> Team Custom Attributes </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <custom-attributes
                      :customAttributesMap="teamCustomAttributesMap"
                      @add="(attribute) => addTeamCustomAttribute(attribute)"
                      @remove="(name) => removeTeamCustomAttribute(name)"
                    >
                    </custom-attributes>
                  </v-col>
                </v-row>
                <v-row class="title">
                  <v-col> Member Custom Attributes </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <custom-attributes
                      :customAttributesMap="memberCustomAttributesMap"
                      @add="(attribute) => addMemberCustomAttribute(attribute)"
                      @remove="(name) => removeMemberCustomAttribute(name)"
                    >
                    </custom-attributes>
                  </v-col>
                </v-row>
                <v-row class="title">
                  <v-col> Coach Custom Attributes </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <custom-attributes
                      :customAttributesMap="coachCustomAttributesMap"
                      @add="(attribute) => addCoachCustomAttribute(attribute)"
                      @remove="(name) => removeCoachCustomAttribute(name)"
                    >
                    </custom-attributes>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item>
                <!--Problems-->
                <search
                  :availableProblems="contentsObject"
                  :selectedProblems="selectedProblems"
                  @change="updateProblem"
                ></search>
              </v-tab-item>

              <v-tab-item>
                <!--Scoring-->
                <v-row class="px-2">
                  <v-col cols="6" sm="4">
                    <v-select
                      label="ScoreboardType"
                      :items="scoreboardTypeKeys"
                      :value="scoreboardType"
                      @change="setScoreboardType"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row class="px-2">
                  <v-col cols="3">
                    <v-text-field
                      single
                      line
                      label="Penalty time"
                      suffix="s"
                      :value="scoreboardPenalty"
                      @change="setScoreboardPenalty"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="px-2">
                  <v-col cols="3">
                    <v-text-field
                      single
                      line
                      label="freeze offset time in sec"
                      suffix="s"
                      :value="freezeOffsetTimeInSec"
                      @change="setFreezeOffsetTimeInSecs"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <!-- <v-text-field single line label="Unfreeze" :value="unfreeze" @change="setUnfreeze"></v-text-field> -->
                    <v-select
                      label="Unfreeze"
                      :items="[true, false]"
                      :value="unfreeze"
                      @input="setUnfreeze"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <!--Attachment-->
                <v-row>
                  <v-col>
                    <v-file-input
                      :rules="attachmentRules"
                      accept="image/*"
                      placeholder="Attachment"
                      prepend-icon="mdi-camera"
                      label="Attachment"
                      v-model="attachmentFile"
                    ></v-file-input>
                    <v-btn color="accent" @click="uploadAttachmentSave"
                      >Save Attachment
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="attachment && attachment.getUrl()">
                  <v-col>
                    {{ attachment.getUrl() }}
                  </v-col>
                  <img
                    :src="attachment.getUrl()"
                    style="max-width: 100%"
                    alt="Contest Banner"
                    srcset=""
                  />
                </v-row>
              </v-tab-item>

              <v-tab-item>
                <!--Permissions-->
                <permissions
                  :permissions="content.getMetaData().getPermissionsMap()"
                  :initialRestrictedDomains="getRestrictedDomains"
                  @permissionChange="
                    (ids, role) => appendPermissions(ids, role)
                  "
                  @removePermission="
                    (ids, role) => removePermissions(ids, role)
                  "
                  @restrictedDomainChange="setRestrictedDomains"
                ></permissions>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <confirm-dialog ref="confirm"></confirm-dialog>
            <v-btn color="error" height="35" @click="deleteContentRequest"
              >Delete Contest</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </span>
  </v-container>
</template>

<script>
import { getters, mapActions, mapState, mapMutations, mapGetters } from "vuex";
import MarkdownEditor from "../components/MarkdownEditor";
import NotFoundText from "../components/NotFoundText";
import Loader from "../components/Loader";
import Search from "../components/Search";
import moment, { isDate } from "moment";
import Permissions from "../components/Permissions";
import ConfirmDialog from "../components/ConfirmDialog.vue";
import CustomAttributes from "../components/CustomAttribute.vue";
import { mdiPlus } from "@mdi/js";

export default {
  props: ["url"],
  data() {
    return {
      newKey: "",
      newValue: "",
      activeTab: "description",
      contestTab: null,
      deletedFiles: new Map(),
      startDate: null,
      startTime: null,
      endDate: null,
      endTime: null,
      scoreboardVisibility: null,
      testCaseVisibility: null,
      boolTypes: [true, false],
      restrictTypes: [true, false],
      isContestSaved: false,
      isContestPublished: false,
      attachmentFile: null,
      attachmentRules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Attachment size should be less than 2 MB!",
      ],
      tagsTypes: [
        "contest/icpc",
        "types/interview",
        "types/competitive",
        "drills/topic",
        "drills/company",
        "companies/adobe",
        "companies/amazon",
        "companies/apple",
        "companies/atlassian",
        "companies/cisco",
        "companies/directi",
        "companies/ebay",
        "companies/facebook",
        "companies/flipkart",
        "companies/goldman_sachs",
        "companies/google",
        "companies/jpmorgan_chase",
        "companies/linkedin",
        "companies/microsoft",
        "companies/oracle",
        "companies/paypal",
        "companies/sap",
        "companies/twitter",
        "companies/uber",
        "companies/vmware",
        "companies/walmart",
        "difficulties/easy",
        "difficulties/medium",
        "difficulties/hard",
      ],
    };
  },
  components: {
    MarkdownEditor,
    NotFoundText,
    Loader,
    Search,
    Permissions,
    ConfirmDialog,
    CustomAttributes,
  },
  computed: {
    ...mapState("contest", ["content", "view", "loading", "attachment"]),
    ...mapState("user", ["user", "hasPremiumAccess", "iamAdmin"]),
    ...mapState("content", ["contents"]),
    ...mapGetters("contest", [
      "scoreboardType",
      "scoreboardTypeKeys",
      "scoreboardPenalty",
      "freezeOffsetTimeInSec",
      "unfreeze",
      "scoreboardVisibilityKeys",
      "testCaseVisibilityKeys",
    ]),
    ...mapGetters("skill", ["languageKeys"]),
    premiumBlock() {
      return this.premiumOnly && !this.hasPremiumAccess;
    },
    ...mapGetters("contest", [
      "publishType",
      "publishTypeKeys",
      "contestType",
      "contestTypeKeys",
      "submissionVisibilityKeys",
      "submissionVisibilityType",
      "nameUniquenessKeys",
      "nameUniquenessType",
      "presenceKeys",
      "types",
    ]),
    styles() {
      return { width: `${this.rating * 20}%` };
    },
    selectedProblems() {
      console.log("content ..", this.content.toObject());
      return this.content.getData().getContest().getProblemsList();
    },
    contentsObject() {
      if (this.contents)
        return this.contents
          .filter((c) => this.types[c.getContentType()] === "PROBLEM")
          .map((c) => {
            return c.toObject();
          });
    },
    minTeamSize() {
      return (
        this.content.getMetaData().getContestMeta().getTeamContestConfig() &&
        this.content
          .getMetaData()
          .getContestMeta()
          .getTeamContestConfig()
          .getMinTeamSize()
      );
    },
    maxTeamSize() {
      return (
        this.content.getMetaData().getContestMeta().getTeamContestConfig() &&
        this.content
          .getMetaData()
          .getContestMeta()
          .getTeamContestConfig()
          .getMaxTeamSize()
      );
    },
    isEditable() {
      return (
        this.content.getMetaData().getContestMeta().getTeamContestConfig() &&
        this.content
          .getMetaData()
          .getContestMeta()
          .getTeamContestConfig()
          .getIsEditable()
      );
    },
    isCoachEnabled() {
      return (
        this.content.getMetaData().getContestMeta() &&
        this.content.getMetaData().getContestMeta().getTeamContestConfig() &&
        this.content
          .getMetaData()
          .getContestMeta()
          .getTeamContestConfig()
          .getCoachSetting() &&
        this.content
          .getMetaData()
          .getContestMeta()
          .getTeamContestConfig()
          .getCoachSetting()
          .getCoachEnabled()
      );
    },
    coachPresence() {
      return (
        this.content.getMetaData().getContestMeta().getTeamContestConfig() &&
        this.content
          .getMetaData()
          .getContestMeta()
          .getTeamContestConfig()
          .getCoachSetting() &&
        this.presenceKeys[
          this.content
            .getMetaData()
            .getContestMeta()
            .getTeamContestConfig()
            .getCoachSetting()
            .getCoachPresence()
        ]
      );
    },
    isTeamContest() {
      var type =
        this.content.getMetaData().getContestMeta() &&
        this.content.getMetaData().getContestMeta().getContestType() === 1;
      console.log("Type ..", type);
      return type;
    },
    teamCustomAttributesMap() {
      if (
        this.content.getMetaData().getContestMeta() &&
        this.content.getMetaData().getContestMeta().getTeamContestConfig()
      ) {
        return (
          this.content.getMetaData().getContestMeta() &&
          this.content
            .getMetaData()
            .getContestMeta()
            .getTeamContestConfig()
            .toObject()
            .teamCustomAttributeMap.map((e) => e[1])
        );
      }
    },
    memberCustomAttributesMap() {
      if (
        this.content.getMetaData().getContestMeta() &&
        this.content.getMetaData().getContestMeta().getTeamContestConfig()
      ) {
        return this.content
          .getMetaData()
          .getContestMeta()
          .getTeamContestConfig()
          .toObject()
          .memberCustomAttributeMap.map((e) => e[1]);
      }
    },
    coachCustomAttributesMap() {
      if (
        this.content.getMetaData().getContestMeta() &&
        this.content.getMetaData().getContestMeta().getTeamContestConfig() &&
        this.content
          .getMetaData()
          .getContestMeta()
          .getTeamContestConfig()
          .getCoachSetting()
      ) {
        return (
          this.content.getMetaData().getContestMeta() &&
          this.content
            .getMetaData()
            .getContestMeta()
            .getTeamContestConfig()
            .getCoachSetting()
            .toObject()
            .coachCustomAttributeMap.map((e) => e[1])
        );
      }
    },
    isRestrictSubmission() {
      return (
        this.content.getMetaData().getContestMeta() &&
        this.content.getMetaData().getContestMeta().getRestrictSubmission()
      );
    },
    isPublishButtonDisabled() {
      return (
        this.content.getVersion() == this.content.getPublishVersion() ||
        this.isContestPublished
      );
    },
    contestUrl() {
      return "/contests/" + this.content.getUrl();
    },
    getAllowedLanguageList() {
      if (
        this.content.getMetaData().getContestMeta() &&
        this.content.getMetaData().getContestMeta().getLanguageRestriction()
      ) {
        var lang = this.content
          .getMetaData()
          .getContestMeta()
          .getLanguageRestriction()
          .getAllowLanguageList();

        return lang.map((l) => this.languageKeys[l]);
      }
    },
    customEmailTemplateIds() {
      if (this.content && this.content.getMetaData().getContestMeta()) {
        return (
          this.content
            .getMetaData()
            .getContestMeta()
            .getEmailTemplateIdMap()
            .toObject() || {}
        );
      }
    },
    getRestrictedDomains() {
      var domains = this.content.getMetaData().getRestrictedDomainList();
      console.log("Domains in ...", domains);
      return domains;
    },
  },

  methods: {
    ...mapActions("contest", [
      "fetchContest",
      "updateContest",
      "updateAttachment",
    ]),
    ...mapActions("content", [
      "publishContent",
      "deleteContent",
      "fetchContents",
    ]),
    ...mapActions("user", ["isAdmin"]),
    ...mapMutations("contest", [
      "setScoreboardVisibility",
      "setTestCaseVisibility",
      "setAbout",
      "setRules",
      "setTags",
      "setTitle",
      "setImageUrl",
      "setBannerUrl",
      "setStartAt",
      "setEndAt",
      "changePublishType",
      "changeContestType",
      "setProblems",
      "setScoreboardType",
      "setScoreboardPenalty",
      "updateMinTeamSize",
      "updateMaxTeamSize",
      "setTeamEditablity",
      "appendPermissions",
      "removePermissions",
      "setSubmissionVisibility",
      "setIsRestrictSubmission",
      "setFreezeOffsetTimeInSecs",
      "setUnfreeze",
      "updateNameUniqueness",
      "setIsCoachEnabled",
      "setCoachPresence",
      "addTeamCustomAttribute",
      "removeTeamCustomAttribute",
      "addMemberCustomAttribute",
      "removeMemberCustomAttribute",
      "addCoachCustomAttribute",
      "removeCoachCustomAttribute",
      "setAllowedLanguages",
      "setRestrictedDomains",
      "updateEmailTemplate",
    ]),
    onAboutChange(newCode) {
      this.setAbout(newCode);
    },
    onRulesChange(newCode) {
      this.setRules(newCode);
    },
    changeTab(tab) {
      this.activeTab = tab;
    },
    run() {},
    editorConfig(newConfig) {
      console.log("Change ", newConfig);
    },
    changeImageUrl(url) {
      console.log("vue URL ", url);
      this.setImageUrl(url);
    },
    changeBannerUrl(url) {
      console.log("banner URL ", url);
      this.setBannerUrl(url);
    },
    saveContest() {
      this.isContestSaved = true;
      this.updateContest({})
        .then(() => {
          this.isContestSaved = false;
          this.$store.dispatch("notifs/addNotif", {
            text: "Contest saved successfully",
            type: "success",
          });
        })
        .catch((err) => {
          console.log("Error updating contest ..", err);
          this.$store.dispatch("notifs/addNotif", {
            text: err.message || "Updating contest failed, please try again!",
            type: "error",
          });
        })
        .finally(() => {
          this.isContestSaved = false;
        });
    },
    deleteContentRequest() {
      this.$refs.confirm
        .open("Delete", "Are you sure you want to delete this contest?", {
          color: "red",
        })
        .then((confirm) => {
          if (confirm)
            this.deleteContent({ id: this.content.getId() }).then((_) =>
              this.$router.push("/")
            );
        });
    },
    publishContentRequest() {
      if (
        this.content.toObject().version !=
        this.content.toObject().publishVersion
      ) {
        this.isContestPublished = true;
        this.publishContent({ id: this.content.getId() })
          .then((__) => {
            this.$store.dispatch("notifs/addNotif", {
              text: "Contet published successfully",
              type: "success",
            });
          })
          .catch((error) => {
            console.log("Error in Publishing contest", error);
            this.$store.dispatch("notifs/addNotif", {
              text: error.message,
              type: "error",
            });
          })
          .finally((__) => {
            this.isContestPublished = false;
          });
      }
    },
    updateStartDateTime() {
      var epoch = this.calculateDatetime(this.startDate, this.startTime);
      console.log("Start epoch ", epoch);
      this.setStartAt(epoch);
    },
    updateEndDateTime() {
      var epoch = this.calculateDatetime(this.endDate, this.endTime);
      console.log("End epoch ", epoch);
      this.setEndAt(epoch);
    },
    calculateDatetime(date, time) {
      if (time && date) {
        var datetime = date + " " + time;
        return moment(datetime, "YYYY-MM-DD H:mm").unix();
      }
    },
    updateProblem(ids) {
      console.log("Problem to remove", ids);
      this.setProblems(ids);
    },
    uploadAttachmentSave() {
      console.log("File", this.attachmentFile);
      this.updateAttachment({ attachment: this.attachmentFile });
    },
    addNewCustomEmailTempate() {
      console.log("Adding new key-value pair", this.newKey, this.newValue);
      this.updateEmailTemplate({ key: this.newKey, value: this.newValue });
    },
  },
  watch: {
    user: function () {
      console.log("Changing user ...");
    },
    editorConfig: function () {
      this.updateUserPreference();
    },
    startDate: function () {
      console.log("Start Date", this.startDate);
      this.updateStartDateTime();
    },
    startTime: function () {
      this.updateStartDateTime();
    },
    endDate: function () {
      console.log("End Date", this.endDate);
      this.updateEndDateTime();
    },
    endTime: function () {
      console.log("End Time", this.endTime);
      this.updateEndDateTime();
    },
    scoreboardVisibility(newVal) {
      this.scoreboardVisibility = newVal;
      this.setScoreboardVisibility(this.scoreboardVisibility);
    },
    testCaseVisibility(newVal) {
      this.testCaseVisibility = newVal;
      this.setTestCaseVisibility(this.testCaseVisibility);
    },
  },
  created() {
    console.log("Fecthing contest from url ...", this.url);
    this.fetchContest({ url: this.url })
      .then((__) => {
        console.log("Contest fetched ...", this.content);
        //document.title = this.content.getTitle() + " - CodeDrills";
        this.startDate = moment
          .unix(this.content.getMetaData().getContestMeta().getStartAt())
          .format("YYYY-MM-DD");
        this.startTime = moment
          .unix(this.content.getMetaData().getContestMeta().getStartAt())
          .format("HH:mm");
        this.endDate = moment
          .unix(this.content.getMetaData().getContestMeta().getEndAt())
          .format("YYYY-MM-DD");
        this.endTime = moment
          .unix(this.content.getMetaData().getContestMeta().getEndAt())
          .format("HH:mm");

        var scoreboardVisibilityTypeInt = this.content
          .getMetaData()
          .getContestMeta()
          .getScoreboardVisibility();
        console.log(
          "Scoreboard visibility type int",
          scoreboardVisibilityTypeInt
        );
        var scoreboardVisibilityType =
          this.scoreboardVisibilityKeys[scoreboardVisibilityTypeInt];
        console.log(
          "Scoreboard visibility type string",
          scoreboardVisibilityType
        );
        this.scoreboardVisibility = scoreboardVisibilityType;
        this.testCaseVisibility =
          this.testCaseVisibilityKeys[
            this.content.getMetaData().getContestMeta().getTestCaseVisibility()
          ];
      })
      .then((_) => {
        this.fetchContents();
        this.isAdmin();
      });
  },
};
</script>
<style scoped>
.is-deleted {
  text-decoration: line-through;
}
</style>
