/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import builder from "codedrills_proto/io/codedrills/proto/builder/content_service_grpc_web_pb";
var content_proto = proto.io.codedrills.proto.content;
var builder_proto = proto.io.codedrills.proto.builder;

const builderService = new builder.ContentServicePromiseClient(
  process.env.VUE_APP_BUILDER_API_URL + "/builder",
  null,
  null
);

const state = {
  content: null,
  loading: null,
  attachment: null,
};

const actions = {
  async fetchDrill({ commit, state, getters, dispatch }, { url }) {
    console.log("TRYING TO FETCH", url);
    commit("toggleLoading", true);
    commit("clearContent");

    return dispatch("user/ensureUserToken", null, { root: true }).then(
      (userToken) => {
        var getContentRequest = new builder_proto.GetContentRequest();
        var contentId = new content_proto.ContentId();
        var contentAddress = new content_proto.ContentAddress();
        contentAddress.setContentType(
          proto.io.codedrills.proto.content.ContentType.DRILL
        );
        contentAddress.setUrl(url);
        contentId.setContentAddress(contentAddress);
        getContentRequest.setContentId(contentId);

        return builderService
          .getContent(getContentRequest, { Authorization: userToken })
          .then((res) => {
            console.log("SUCCESS", res.toObject());
            commit("setContent", res);
          })
          .catch((err) => {
            console.error("ERROR", err);
          })
          .finally(() => {
            commit("toggleLoading", false);
          });
      }
    );
  },

  // TODO: Move to common file
  async updateAttachment({ commit, state, getters, dispatch }, { attachment }) {
    return dispatch("user/ensureUserToken", null, { root: true }).then(
      async (userToken) => {
        var createAttachmentRequest =
          new builder_proto.CreateAttachmentRequest();
        createAttachmentRequest.setContentId(state.content.getId());
        createAttachmentRequest.setIsPublic(true);
        await attachment.arrayBuffer().then((arrayBuffer) => {
          createAttachmentRequest.setData(new Uint8Array(arrayBuffer));
        });
        createAttachmentRequest.setName(attachment.name);
        createAttachmentRequest.setType(attachment.type);

        console.log("Request", createAttachmentRequest);
        return builderService
          .createAttachment(createAttachmentRequest, {
            Authorization: userToken,
          })
          .then((res) => {
            console.log("SUCCESS", res.toObject());
            commit("setAttachment", res);
          })
          .catch((err) => {
            console.error("ERROR", err);
          });
      }
    );
  },

  async updateDrill({ commit, state, getters, dispatch }, { drillAvatar }) {
    return dispatch("user/ensureUserToken", null, { root: true }).then(
      async (userToken) => {
        var updateContentRequest = new builder_proto.UpdateContentRequest();
        updateContentRequest.setId(state.content.getId());
        updateContentRequest.setTitle(state.content.getTitle());
        updateContentRequest.setVersion(state.content.getVersion());
        updateContentRequest.setPublishType(state.content.getPublishType());
        updateContentRequest.setContentType(state.content.getContentType());
        updateContentRequest.setData(state.content.getData());
        updateContentRequest.setMetaData(state.content.getMetaData());

        console.log("REQUEST", updateContentRequest.toObject());

        return builderService
          .updateContent(updateContentRequest, { Authorization: userToken })
          .then((res) => {
            console.log("SUCCESS", res.toObject());
            commit("setContent", res);
          })
          .catch((err) => {
            console.error("ERROR", err);
          });
      }
    );
  },
};

const getters = {
  userToken: function (state, getters, rootState, rootGetters) {
    parameters.addValue("meta_data", content.getMetaData().toByteArray());
    console.log("rootGetters", rootGetters);
    return rootGetters["user/userToken"];
  },
  types: function () {
    return Object.keys(proto.io.codedrills.proto.content.ContentType);
  },
  statement: function (state) {
    return state.content.data.problem.statement;
  },
  publishType(state, getters) {
    return getters.publishTypeKeys[state.content.getPublishType()];
  },
  publishTypeKeys() {
    return Object.keys(content_proto.PublishType);
  },
};

const mutations = {
  toggleLoading(state, content) {
    state.loading = content;
  },

  clearContent(state) {
    state.content = null;
  },
  setContent(state, res) {
    state.content = res.getContent();
    state.view = res.getContentView();
  },
  setTags(state, tags) {
    state.content.getMetaData().setTagsList(tags);
  },
  setTitle(state, title) {
    state.content.setTitle(title);
  },

  setDescription(state, description) {
    state.content.getData().getDrill().setDescription(description);
  },

  changePublishType(state, publishType) {
    console.log("Publish Type", publishType);
    state.content.setPublishType(content_proto.PublishType[publishType]);
  },

  setAttachment(state, res) {
    state.attachment = res.getAttachment();
  },

  setImage(state, image) {
    console.log("image url", image);
    state.content.getMetaData().setImageUrl(image);
  },

  newSection(state, index) {
    var section = new content_proto.DrillSection()
      .setTitle("Title")
      .setDescription("Description");

    state.content.getData().getDrill().addSections(section);
  },
  setSectionTitle(state, { index, title }) {
    console.log("index, title, ", index, title);
    state.content.getData().getDrill().getSectionsList()[index].setTitle(title);
  },
  setSectionDescription(state, { index, description }) {
    state.content
      .getData()
      .getDrill()
      .getSectionsList()
      [index].setDescription(description);
  },
  setSectionProblemIds(state, { index, ids }) {
    console.log("ids...", ids);
    state.content
      .getData()
      .getDrill()
      .getSectionsList()
      [index].clearContentsList();
    state.content
      .getData()
      .getDrill()
      .getSectionsList()
      [index].setContentsList(
        ids.map((id) => new content_proto.DrillContent().setContentId(id))
      );
    console.log(
      "list",
      state.content
        .getData()
        .getDrill()
        .getSectionsList()
        [index].getContentsList()
    );
  },
  removeDrillSection(state, index) {
    state.content.getData().getDrill().getSectionsList().splice(index, 1);
  },
  updateDrillSection(state, { index, section }) {
    var list = this.content.getData().getDrill().getSectionsList();
    list[index] = section;
    this.content.getData().getDrill().clearSectionsList();
    this.content.getData().getDrill().addSections(list);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
