<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card>
          <v-card-title class="subtitle-2 accent white--text"
            >Login / Signup</v-card-title
          >
          <v-card-text class="pt-2">
            <div id="firebase-ui"></div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import firebase from "firebase/app";
import "firebase/auth";
import * as firebaseui from "firebaseui";
import auth from "../utils/auth";

export default {
  computed: {
    ...mapState("user", ["loginError", "user", "authInstance"]),
  },
  methods: {
    redirect() {
      return firebaseui.auth.AuthUI.getInstance().isPendingRedirect();
    },
  },
  name: "login",
  mounted() {
    console.log("Login", this.user, this.redirect());
    if (this.user && !this.user.isAnonymous && !this.redirect()) {
      console.log("Redirecting home...");
      this.$router.replace("/");
      return;
    }
    var ui = firebaseui.auth.AuthUI.getInstance();
    ui.reset();
    ui.start("#firebase-ui", {
      signInSuccessUrl: "/",
      signInFlow: "popup",
      signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      ],
      callbacks: {
        signInSuccessWithAuthResult: auth.signInSuccess,
      },
      tosUrl: "terms-of-service",
      privacyPolicyUrl: "privacy-policy",
    });
  },
};
</script>
<style scoped>
@import "./../../node_modules/firebaseui/dist/firebaseui.css";
</style>
