<template>
  <div>
    <!-- Use v-container to center and apply padding -->
    <v-container>
      <!-- Use v-card for better visual separation and padding -->
      <v-card class="pa-4">
        <h2>Upload Teams for Offline Contest</h2>
        <p>
          Format is "("%s+%s@icpc.codedrills.io", emailPrefix, id)" where id is
          external id, and emailPrefix can be anything. CSV format will be - id,
          teamName, institution where id is external id.
        </p>

        <!-- Use v-form for better form handling -->
        <v-form>
          <v-row>
            <v-col>
              <v-text-field
                v-model="emailPrefix"
                label="Email Prefix"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-file-input
                accept="*"
                label="Select teams csv"
                v-model="teamsCsv"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn color="accent" height="35" @click="saveTeamCsv"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-form>

        <v-divider class="my-4"></v-divider>
        <!-- Use v-divider for visual separation -->

        <v-row>
          <v-col>
            <p>Download Login Details here for this contest in CSV format</p>
            <v-btn class="mt-10" color="accent" @click="fetchLoginDetails">
              Download Login Details
            </v-btn>
          </v-col>
        </v-row>

        <v-divider class="my-4"></v-divider>
        <!-- Use v-divider for visual separation -->

        <v-row>
          <v-col>
            <p>Download Scoreboard from below after contest ends</p>
            <p>NOTE: make sure tag "contests/icpc" is added for this to work</p>
            <v-btn class="mt-10" color="accent" @click="downloadParticipants">
              Download Participants
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { getters, mapActions, mapState, mapMutations, mapGetters } from "vuex";

export default {
  name: "OfflineContest",
  props: {
    contestUrl: {
      type: String,
      required: true,
    },
    contentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      csvData: "",
      teamsCsv: null,
      emailPrefix: null,
    };
  },
  methods: {
    ...mapActions("user", ["getOfflineCredentials", "createTeamsBatch"]),
    ...mapActions("contest", ["fetchContestParticipents"]),

    async fetchLoginDetails() {
      const csvData = this.getOfflineCredentials({
        url: this.contestUrl,
      }).then((res) => {
        this.loginCsvText = res.getOfflineCredsCsv();
        // Set report as uri to save as file
        this.saveStringAsFile(this.loginCsvText);
        console.log("Csv .....", this.loginCsvText);
      });
    },
    saveStringAsFile(contentString) {
      const uriContent = "data:text/csv," + encodeURIComponent(contentString);
      location.href = uriContent;
    },
    async saveTeamCsv() {
      await this.teamsCsv.text().then((csvContent) => {
        this.createTeamsBatch({
          teamsCsv: csvContent,
          contentId: this.contentId,
          emailPrefix: this.emailPrefix,
        })
          .then((__) => {
            this.clearTeamCsv();
            alert("Teams created successfully");
            //BUG: not redirecting to admin page
            setTimeout(() => {
              this.$router
                .push("/contests/" + this.contestUrl + "/admin")
                .catch((__) => {});
            }, 0);
          })
          .catch((error) => {
            console.error("Error in createTeamsBatch:", error);
            alert("Error in creating teams");
          });
      });
    },
    clearTeamCsv() {
      this.teamsCsv = null;
    },
    async downloadParticipants() {
      const csvData = this.fetchContestParticipents({
        url: this.contestUrl,
      }).then((res) => {
        this.candidateCsvText = res.getParticipantsCsv();
        // Set report as uri to save as file
        this.saveStringAsFile(this.candidateCsvText);
        console.log("Csv .....", this.candidateCsvText);
      });
    },
  },
};
</script>

<style scoped>
/* Your styles go here */
</style>
