<template>
  <div>
    <!-- Form to extend contest for a participant -->
    <form @submit.prevent="extendContest">
      <v-select
        v-model="filter"
        :items="['All teams', 'Teams with extension']"
        label="Select filter"
      >
      </v-select>
      <v-text-field v-model="search" label="Search" single-line hide-details>
      </v-text-field>
      <v-data-table
        :headers="headers"
        :items="filteredParticipants"
        item-key="id"
        show-select
        v-model="selectedParticipants"
      >
        <template v-slot:item.name="{ item }">
          {{ item.name }}
        </template>
        <template v-slot:item.id="{ item }">
          {{ item.id }}
        </template>
        <template v-slot:item.extension="{ item }">
          {{ formatExtension(item.extension) }}
        </template>
      </v-data-table>

      <v-text-field
        v-model="extension"
        label="Enter an extension"
        type="number"
        min="0"
      >
      </v-text-field>

      <v-select
        v-model="extensionUnit"
        :items="['seconds', 'minutes', 'hours']"
        label="Select unit of time"
      >
      </v-select>

      <v-btn color="accent" type="submit" :loading="isSubmitting"
        >Extend Contest</v-btn
      >
      <v-alert v-model="showError" type="error">
        {{ errorMessage }}
      </v-alert>
    </form>
  </div>
</template>

<script>
import { getters, mapActions, mapState, mapMutations, mapGetters } from "vuex";

export default {
  data() {
    return {
      search: "",
      filter: "All teams",
      allParticipants: [],
      selectedParticipants: [],
      participantsWithExtensions: [],
      selectedParticipant: null,
      extension: "",
      extensionUnit: "seconds",
      headers: [
        { text: "Name", value: "name" },
        { text: "teamId", value: "id" },
        { text: "Extension", value: "extension" },
      ],
      showError: false,
      errorMessage: "",
      isSubmitting: false,
    };
  },
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  created() {
    this.fetchAllParticipants();
  },
  computed: {
    ...mapState("contest", [
      "extendContestForTeamsStatus",
      "fetchContestTeamsStatus",
    ]),

    filteredParticipants() {
      let participants;

      if (this.filter === "Teams with extension") {
        participants = this.allParticipants.filter(
          (participant) => participant.extension > 0
        );
      } else {
        participants = this.allParticipants;
      }

      return participants.filter((participant) => {
        return Object.values(participant).some((value) =>
          value.toString().toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
  },
  methods: {
    ...mapActions("contest", ["fetchContestTeams", "extendContestForTeams"]),

    fetchAllParticipants() {
      // Call your API to fetch all participants.
      // Update the allParticipants data property with the fetched data.
      this.fetchContestTeams({ url: this.url }).then((response) => {
        console.log(response);
        this.allParticipants = response.getTeamPreviewList().map((team) => {
          return {
            name: team.getName(),
            id: team.getId(),
            extension: team.getContestExtendedBySeconds(),
          };
        });
      });
    },
    extendContest() {
      let extensionInSeconds;
      this.isSubmitting = true;

      if (this.extensionUnit === "minutes") {
        extensionInSeconds = this.extension * 60;
      } else if (this.extensionUnit === "hours") {
        extensionInSeconds = this.extension * 60 * 60;
      } else {
        extensionInSeconds = this.extension;
      }
      if (extensionInSeconds <= 0) {
        this.showError = true;
        this.errorMessage = "Extension must be greater than 0.";
        return;
      }
      if (this.selectedParticipants.length === 0) {
        this.showError = true;
        this.errorMessage = "Please select at least one participant.";
        return;
      }
      this.extendContestForTeams({
        url: this.url,
        extensionInSecs: extensionInSeconds,
        teamIds: this.selectedParticipants.map((participant) => participant.id),
      })
        .then((response) => {
          console.log(response);
          this.fetchAllParticipants();
          this.isSubmitting = false;
        })
        .catch((error) => {
          console.log(error);
          this.showError = true;
          this.errorMessage = "An error occurred while extending the contest.";
          this.isSubmitting = false;
        });
    },
    formatExtension(extensionInSeconds) {
      const hours = Math.floor(extensionInSeconds / 3600);
      const minutes = Math.floor((extensionInSeconds % 3600) / 60);
      const seconds = extensionInSeconds % 60;
      return `${hours} hours ${minutes} minutes ${seconds} seconds`;
    },
  },
};
</script>

<style scoped>
/* Add your CSS styles here */
</style>
