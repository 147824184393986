var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.extendContest.apply(null, arguments);
      }
    }
  }, [_c('v-select', {
    attrs: {
      "items": ['All teams', 'Teams with extension'],
      "label": "Select filter"
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Search",
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.filteredParticipants,
      "item-key": "id",
      "show-select": ""
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.name) + " ")];
      }
    }, {
      key: "item.id",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.id) + " ")];
      }
    }, {
      key: "item.extension",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm.formatExtension(item.extension)) + " ")];
      }
    }]),
    model: {
      value: _vm.selectedParticipants,
      callback: function ($$v) {
        _vm.selectedParticipants = $$v;
      },
      expression: "selectedParticipants"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Enter an extension",
      "type": "number",
      "min": "0"
    },
    model: {
      value: _vm.extension,
      callback: function ($$v) {
        _vm.extension = $$v;
      },
      expression: "extension"
    }
  }), _c('v-select', {
    attrs: {
      "items": ['seconds', 'minutes', 'hours'],
      "label": "Select unit of time"
    },
    model: {
      value: _vm.extensionUnit,
      callback: function ($$v) {
        _vm.extensionUnit = $$v;
      },
      expression: "extensionUnit"
    }
  }), _c('v-btn', {
    attrs: {
      "color": "accent",
      "type": "submit",
      "loading": _vm.isSubmitting
    }
  }, [_vm._v("Extend Contest")]), _c('v-alert', {
    attrs: {
      "type": "error"
    },
    model: {
      value: _vm.showError,
      callback: function ($$v) {
        _vm.showError = $$v;
      },
      expression: "showError"
    }
  }, [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }