var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [!this.user ? _c('div', [_vm._v("Please login first")]) : _vm._e(), this.user && _vm.loading ? _c('Loader') : _vm._e(), this.user && !_vm.loading && !_vm.content ? _c('NotFoundText', {
    attrs: {
      "item": "problem"
    }
  }) : _vm._e(), this.user && !_vm.loading && _vm.content ? _c('span', [_c('div', {
    staticClass: "grey lighten-5 pl-12 pr-5 mx-0 my-0"
  }, [_c('v-row', [_c('v-col', [_c('span', {
    staticClass: "display-2 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.content.getTitle()) + " ")])])], 1)], 1), _c('v-container', {
    staticClass: "pl-12 pr-5 mx-0 my-0",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_c('v-col', [_c('v-btn', {
    attrs: {
      "loading": _vm.isProblemSaved,
      "disabled": _vm.isProblemSaved,
      "color": "accent",
      "height": "35"
    },
    on: {
      "click": _vm.saveProblem
    }
  }, [_vm._v("Save")])], 1), _c('v-col', [_c('v-btn', {
    attrs: {
      "loading": _vm.isProblemPublished,
      "disabled": _vm.isPublishButtonDisabled,
      "color": "accent",
      "height": "35"
    },
    on: {
      "click": _vm.publishContentRequest
    }
  }, [_vm._v("Publish")])], 1), _c('v-col', [_c('v-btn', {
    attrs: {
      "color": "accent"
    },
    on: {
      "click": _vm.exportProblemRequest
    }
  }, [_vm._v("Export Problem")])], 1)], 1), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "sm": "2"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Publish Type",
      "items": _vm.publishTypeKeys,
      "value": _vm.publishType
    },
    on: {
      "change": _vm.changePublishType
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-tabs', {
    attrs: {
      "color": "accent",
      "slider-color": "accent"
    },
    model: {
      value: _vm.problemTab,
      callback: function ($$v) {
        _vm.problemTab = $$v;
      },
      expression: "problemTab"
    }
  }, [_c('v-tab', [_vm._v("Preview")]), _c('v-tab', [_vm._v("Statement")]), _c('v-tab', [_vm._v("Judge")]), _c('v-tab', [_vm._v("Metadata")]), _c('v-tab', [_vm._v("Submission History")]), _c('v-tab', [_vm._v("Test")]), _c('v-tab', [_vm._v("Editorial")]), _c('v-tab', [_vm._v("Attachment")]), _c('v-tab', [_vm._v("Rejudge")]), _c('v-tab', [_vm._v("Permissions")])], 1), _c('v-tabs-items', {
    model: {
      value: _vm.problemTab,
      callback: function ($$v) {
        _vm.problemTab = $$v;
      },
      expression: "problemTab"
    }
  }, [_c('v-tab-item', [_c('v-row', {
    staticClass: "py-5 px-2"
  }, [_c('v-col', [_c('html-view', {
    attrs: {
      "html": _vm.view.getProblemView().getStatementView()
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("Time Limit")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(_vm._s(_vm.content.getMetaData().getProblemMeta().getConstraints().getTimeLimitInMs()))])], 1), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("Memory Limit")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(_vm._s(_vm.content.getMetaData().getProblemMeta().getConstraints().getMemoryLimitInKb()) + " KBytes")])], 1), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("Source Limit")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(_vm._s(_vm.content.getMetaData().getProblemMeta().getConstraints().getSourceLimitInBytes()) + " Bytes")])], 1), _c('v-row', [_c('v-col', {
    staticClass: "mt-5"
  }, [_c('p', {
    staticClass: "headline"
  }, [_vm._v("Editorial")])])], 1), _c('v-row', [_c('v-col', [_c('html-view', {
    attrs: {
      "html": _vm.view.getProblemView().getEditorialView()
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "py-5 px-2"
  }, [_c('v-col', _vm._l(_vm.content.getMetaData().getTagsList(), function (tag) {
    return _c('v-chip', {
      key: tag,
      staticClass: "mr-1",
      attrs: {
        "color": "#D5F9F9"
      }
    }, [_vm._v(" " + _vm._s(tag) + " ")]);
  }), 1)], 1)], 1), _c('v-tab-item', [_c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "value": _vm.content.getTitle()
    },
    on: {
      "change": _vm.setTitle
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "py-5 px-2"
  }, [_c('v-col', [_c('MarkdownEditor', {
    attrs: {
      "editorialMode": false,
      "submissionMode": true,
      "code": _vm.content.getData().getProblem().getStatement(),
      "onCodeChange": _vm.onStatementChange,
      "value": _vm.editorConfig
    }
  })], 1)], 1)], 1), _c('v-tab-item', [_c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Problem Type",
      "items": _vm.problemTypeKeys,
      "value": _vm.problemType
    },
    on: {
      "change": _vm.setProblemType
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "single": "",
      "line": "",
      "label": "Time Limit",
      "suffix": "MS",
      "value": _vm.content.getMetaData().getProblemMeta().getConstraints().getTimeLimitInMs()
    },
    on: {
      "change": _vm.setTimeLimitType
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "single": "",
      "line": "",
      "label": "Memory Limit",
      "suffix": "KBytes",
      "value": _vm.content.getMetaData().getProblemMeta().getConstraints().getMemoryLimitInKb()
    },
    on: {
      "change": _vm.setMemoryLimitInKb
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "single": "",
      "line": "",
      "label": "Source Limit",
      "suffix": "Bytes",
      "value": _vm.content.getMetaData().getProblemMeta().getConstraints().getSourceLimitInBytes()
    },
    on: {
      "change": _vm.setSourceLimitInBytes
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "single": "",
      "line": "",
      "label": "Output limit",
      "suffix": "KBytes",
      "value": _vm.content.getMetaData().getProblemMeta().getConstraints().getOutputLimitInKb()
    },
    on: {
      "change": _vm.setOutputLimitInKb
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "py-5 px-2"
  }, [_c('v-col', [_c('v-combobox', {
    attrs: {
      "color": "accent",
      "items": _vm.verifiers,
      "value": _vm.content.getData().getProblem().getJudgeSettings().getVerifier(),
      "label": "Select Your verifier",
      "persistent-hint": "",
      "solo": ""
    },
    on: {
      "change": _vm.changeVerifier
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "IO Type",
      "items": _vm.ioTypeKeys,
      "value": _vm.ioType
    },
    on: {
      "change": _vm.setIOType
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "single": "",
      "line": "",
      "label": "Class name",
      "value": _vm.content.getMetaData().getProblemMeta().getWrappedIo() && _vm.content.getMetaData().getProblemMeta().getWrappedIo().getClassName()
    },
    on: {
      "change": _vm.setClassName
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "single": "",
      "line": "",
      "label": "Method name",
      "value": _vm.content.getMetaData().getProblemMeta().getWrappedIo() && _vm.content.getMetaData().getProblemMeta().getWrappedIo().getMethodsList() && _vm.content.getMetaData().getProblemMeta().getWrappedIo().getMethodsList()[0] && _vm.content.getMetaData().getProblemMeta().getWrappedIo().getMethodsList()[0].getMethodName()
    },
    on: {
      "change": _vm.setMethodName
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Return type",
      "items": _vm.argTypeKeys,
      "value": _vm.returnType
    },
    on: {
      "change": _vm.setReturnType
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Evaluation Type",
      "items": _vm.evaluationTypeKeys,
      "value": _vm.evaluationType
    },
    on: {
      "change": _vm.setEvaluationType
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('div', {
    staticClass: "title"
  }, [_vm._v("Args")])])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Arg type",
      "items": _vm.argTypeKeys
    },
    model: {
      value: _vm.argType,
      callback: function ($$v) {
        _vm.argType = $$v;
      },
      expression: "argType"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "single": "",
      "line": "",
      "label": "Arg name"
    },
    model: {
      value: _vm.argName,
      callback: function ($$v) {
        _vm.argName = $$v;
      },
      expression: "argName"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "accent"
    },
    on: {
      "click": _vm.addArg
    }
  }, [_vm._v("Add")])], 1)], 1), _c('v-row', [_c('v-col', _vm._l(_vm.args, function (a, i) {
    return _c('v-row', {
      key: i
    }, [_c('v-col', {
      attrs: {
        "cols": "2"
      }
    }, [_c('v-select', {
      attrs: {
        "label": "Arg type",
        "items": _vm.argTypeKeys,
        "value": a.type
      },
      on: {
        "change": function (t) {
          return _vm.setArgType(i, t);
        }
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "2"
      }
    }, [_c('v-text-field', {
      attrs: {
        "single": "",
        "line": "",
        "label": "Arg name",
        "value": a.name
      },
      on: {
        "change": function (n) {
          return _vm.setArgName(i, n);
        }
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "2"
      }
    }, [_c('v-switch', {
      attrs: {
        "color": "error",
        "label": "Delete",
        "value": a.delete
      },
      on: {
        "change": function (v) {
          return _vm.setArgDelete(i, v);
        }
      }
    })], 1)], 1);
  }), 1)], 1), _c('v-row', [_c('v-col', [_c('div', {
    staticClass: "title"
  }, [_vm._v("Tests")])])], 1), _c('v-row', [_c('v-col', [_c('div', {
    staticClass: "subtitle-1"
  }, [_vm._v("Upload")])])], 1), _c('v-row', [_c('v-col', [_c('v-switch', {
    attrs: {
      "label": `Sample`
    },
    model: {
      value: _vm.sample,
      callback: function ($$v) {
        _vm.sample = $$v;
      },
      expression: "sample"
    }
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-file-input', {
    attrs: {
      "accept": "*",
      "label": "Select Test Files",
      "multiple": ""
    },
    on: {
      "change": _vm.testMethod
    },
    model: {
      value: _vm.testFiles,
      callback: function ($$v) {
        _vm.testFiles = $$v;
      },
      expression: "testFiles"
    }
  })], 1)], 1)], 1)], 1), _vm.isSubtaskType ? _c('v-row', [_c('v-col', [_c('v-row', [_c('v-col', [_c('v-card', {
    staticClass: "pa-2",
    attrs: {
      "outlined": "",
      "tile": ""
    }
  }, [_c('v-card-title', [_vm._v("Subtask Rules")]), _c('v-card-text', [_c('ul', [_c('li', [_vm._v(" Subtask must have either positive points or weightage but not both. ")]), _c('li', [_vm._v(" If weightage is selected, it will allow you to publish and test. However, before publishing, make sure the sum of weightage of all subtasks is 100. ")]), _c('li', [_vm._v(" If the problem type is SUBTASK, the problem must have subtask settings and at least 1 subtask before publishing. ")])])])], 1)], 1)], 1), _c('v-col', [[_c('div', [_c('v-btn', {
    attrs: {
      "color": "accent",
      "loading": _vm.buttonLoading
    },
    on: {
      "click": _vm.addSubtaskGroup
    }
  }, [_vm._v("Add New Subtask")]), _c('v-snackbar', {
    attrs: {
      "color": _vm.snackbarColor
    },
    model: {
      value: _vm.snackbar,
      callback: function ($$v) {
        _vm.snackbar = $$v;
      },
      expression: "snackbar"
    }
  }, [_vm._v(" " + _vm._s(_vm.snackbarText) + " ")])], 1)], _c('div', {
    staticClass: "title"
  }, [_vm._v("Subtask Settings")]), _c('v-card', {
    staticClass: "pa-3",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', [_c('div', {
    staticClass: "subtitle-1"
  }, [_vm._v("Add Subtask")])]), _c('v-select', {
    attrs: {
      "items": ['weightage', 'points'],
      "label": "Score Type"
    },
    model: {
      value: _vm.scoreType,
      callback: function ($$v) {
        _vm.scoreType = $$v;
      },
      expression: "scoreType"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.scoreType,
      "type": "number"
    },
    model: {
      value: _vm.scoreValue,
      callback: function ($$v) {
        _vm.scoreValue = $$v;
      },
      expression: "scoreValue"
    }
  }), _c('v-btn', {
    attrs: {
      "color": "accent"
    },
    on: {
      "click": _vm.addSubtaskGroup
    }
  }, [_vm._v("Add this Subtask")])], 1), _c('v-card', {
    staticClass: "pa-3 mt-5",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', [_c('div', {
    staticClass: "subtitle-1"
  }, [_vm._v("Subtasks")])]), _c('v-expansion-panels', _vm._l(_vm.subtaskGroups, function (group, index) {
    return _c('v-expansion-panel', {
      key: index
    }, [_c('v-expansion-panel-header', [_vm._v("Subtask Group " + _vm._s(index))]), _c('v-expansion-panel-content', [_c('SubtaskGroup', {
      attrs: {
        "group": group
      },
      on: {
        "update": function (gr) {
          return _vm.updateSubtaskGroup(index, gr);
        }
      }
    })], 1)], 1);
  }), 1)], 1), _c('v-btn', {
    staticClass: "mt-5",
    attrs: {
      "color": "red"
    },
    on: {
      "click": _vm.removeLastSubtaskGroup
    }
  }, [_vm._v("Remove Last Subtask")])], 2)], 1)], 1) : _vm._e(), _c('v-row', [_c('v-col', [_c('div', {
    staticClass: "title"
  }, [_vm._v("Test Sets")])])], 1), _vm._l(_vm.content.getData().getProblem().getTestSetsMap().map_, function (testSet, setId) {
    return _c('v-row', {
      key: setId
    }, [_c('v-col', [_c('v-row', [_c('v-col', {
      staticClass: "subtitle-1"
    }, [_vm._v(_vm._s(setId) + " ")]), _c('v-col', [_c('v-btn', {
      attrs: {
        "color": "accent",
        "height": "35"
      },
      on: {
        "click": function ($event) {
          return _vm.deleteAll(setId);
        }
      }
    }, [_vm._v("Delete All")])], 1), _c('v-col', [_c('v-btn', {
      attrs: {
        "color": "accent",
        "height": "35"
      },
      on: {
        "click": function ($event) {
          return _vm.undeleteAll(setId);
        }
      }
    }, [_vm._v("Undelete All")])], 1)], 1), _c('v-row', {
      staticClass: "subtitle-2"
    }, [_c('v-col', [_vm._v("Input")]), _c('v-col', [_vm._v("Output")]), _c('v-col', [_vm._v("Tag")]), _c('v-col', [_vm._v("Remove")]), _c('v-col', [_vm._v("SubTask Index")])], 1), _vm._l(_vm.content.getData().getProblem().getTestSetsMap().get(setId).getTestsList(), function (t, idx) {
      return _c('v-row', {
        key: t.getId()
      }, [_c('v-col', {
        class: _vm.deletedClass(setId + '$' + idx)
      }, [_c('router-link', {
        attrs: {
          "to": '/problems/' + _vm.content.getUrl() + '/tests/' + setId + '/' + t.getId()
        }
      }, [_vm._v(" " + _vm._s(_vm.getFileNameFromTestPath(t.getInputId())) + " ")])], 1), _c('v-col', {
        class: _vm.deletedClass(setId + '$' + idx)
      }, [_c('router-link', {
        attrs: {
          "to": '/problems/' + _vm.content.getUrl() + '/tests/' + setId + '/' + t.getId()
        }
      }, [_vm._v(" " + _vm._s(_vm.getFileNameFromTestPath(t.getOutputId())) + " ")])], 1), _c('v-col', [_c('v-form', [_c('v-container', [_c('v-row', [_c('v-col', {
        attrs: {
          "cols": "12"
        }
      }, [_c('v-autocomplete', {
        attrs: {
          "value": _vm.getTestTagList(t),
          "disabled": _vm.isUpdating,
          "items": _vm.tagTypeKeys,
          "filled": "",
          "chips": "",
          "color": "blue-grey lighten-2",
          "label": "Select",
          "multiple": ""
        },
        on: {
          "change": function (tags) {
            return _vm.setTestTags({
              setId: setId,
              idx: idx,
              tags: tags
            });
          }
        },
        scopedSlots: _vm._u([{
          key: "selection",
          fn: function (data) {
            return [_c('v-chip', _vm._b({
              attrs: {
                "input-value": data.selected,
                "close": ""
              },
              on: {
                "click": data.select,
                "click:close": function () {
                  return _vm.removeTestTag({
                    setId: setId,
                    idx: t.getId(),
                    tag: data.item
                  });
                }
              }
            }, 'v-chip', data.attrs, false), [_vm._v(" " + _vm._s(data.item) + " ")])];
          }
        }, {
          key: "item",
          fn: function (data) {
            return [[_c('v-list-item-content', {
              domProps: {
                "textContent": _vm._s(data.item)
              }
            })]];
          }
        }], null, true)
      })], 1)], 1)], 1)], 1)], 1), _c('v-col', [_c('v-switch', {
        attrs: {
          "color": "error",
          "value": _vm.deletedFiles.get(setId + '$' + idx),
          "id": setId + '$' + idx
        },
        on: {
          "change": function (val) {
            return _vm.deleteFile(setId + '$' + idx, val);
          }
        }
      })], 1), _c('v-col', [_c('v-select', {
        attrs: {
          "value": t.getSubtaskIndexList(),
          "items": _vm.subtaskGroups.map(function (_, i) {
            return i;
          }),
          "label": "Select numbers",
          "multiple": ""
        },
        on: {
          "change": function (val) {
            return _vm.setSubTaskIndex({
              setId: setId,
              idx: idx,
              subTaskIndex: val
            });
          }
        }
      })], 1)], 1);
    })], 2)], 1);
  }), _vm.evaluationType === 'EVALUATION_TYPE_SQL' ? _c('div', [_c('v-row', [_c('v-col', [_vm._v(" SQL Editor ")])], 1), _c('v-row', {
    staticClass: "py-5 px-2"
  }, [_c('v-col', [_c('SqlEditor', {
    attrs: {
      "editorialMode": false,
      "submissionMode": true,
      "code": _vm.sqlInfo,
      "onCodeChange": _vm.onSqlInfoChange,
      "value": _vm.editorConfig
    }
  })], 1)], 1)], 1) : _vm._e(), _vm.evaluationType === 'EVALUATION_TYPE_LIBRARY' ? _c('div', [_c('v-row', [_c('v-col', [_vm._v(" Library Editor ")])], 1), _c('v-row', {
    staticClass: "py-5 px-2"
  }, [_c('v-col', [_c('markdown-editor', {
    attrs: {
      "editorialMode": false,
      "submissionMode": true,
      "code": _vm.libraryInfo,
      "onCodeChange": _vm.onLibraryInfoChange,
      "value": _vm.editorConfig
    }
  })], 1)], 1)], 1) : _vm._e()], 2), _c('v-tab-item', [_c('v-row', {
    staticClass: "py-5 px-2"
  }, [_c('v-col', [_c('v-combobox', {
    attrs: {
      "color": "accent",
      "items": _vm.tagsTypes,
      "value": _vm.content.getMetaData().getTagsList(),
      "hide-selected": "",
      "hint": "tagType/tag",
      "label": "Add company, problem type tags",
      "persistent-hint": "",
      "multiple": "",
      "small-chips": "",
      "solo": ""
    },
    on: {
      "change": _vm.setTags
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Problem Submission Visibility",
      "items": _vm.submissionVisibilityKeys,
      "value": _vm.submissionVisibilityType
    },
    on: {
      "change": _vm.setSubmissionVisibility
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Select Language",
      "items": _vm.languages,
      "value": _vm.languageKeys
    },
    on: {
      "change": _vm.setLanguageType
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-file-input', {
    attrs: {
      "accept": "*",
      "label": "Select Code Template"
    },
    model: {
      value: _vm.codeTemplate,
      callback: function ($$v) {
        _vm.codeTemplate = $$v;
      },
      expression: "codeTemplate"
    }
  })], 1)], 1), _vm.content.getMetaData().getProblemMeta().getCodeTemplateMap() ? _c('v-row', [_c('v-col', _vm._l(_vm.content.getMetaData().getProblemMeta().getCodeTemplateMap().map_, function (value, key) {
    return _c('li', {
      key: key
    }, [_vm._v(" " + _vm._s(value.key) + " : " + _vm._s(value.value) + " ")]);
  }), 0)], 1) : _vm._e()], 1), _c('v-tab-item', [_c('submission-history', {
    attrs: {
      "id": _vm.content.getId(),
      "userId": _vm.userId
    }
  })], 1), _c('v-tab-item', [_c('codingArea', {
    attrs: {
      "contentView": _vm.content
    }
  })], 1), _c('v-tab-item', [_c('v-row', {
    staticClass: "py-5 px-2"
  }, [_c('v-col', [_c('MarkdownEditor', {
    attrs: {
      "editorialMode": false,
      "submissionMode": true,
      "code": _vm.content.getData().getProblem().getEditorial(),
      "onCodeChange": _vm.onEditorialChange,
      "value": _vm.editorConfig
    }
  })], 1)], 1)], 1), _c('v-tab-item', [_c('v-row', [_c('v-col', [_c('v-file-input', {
    attrs: {
      "rules": _vm.rules,
      "accept": "image/*",
      "placeholder": "Pick an avatar",
      "prepend-icon": "mdi-camera",
      "label": "Avatar"
    },
    model: {
      value: _vm.attachmentFile,
      callback: function ($$v) {
        _vm.attachmentFile = $$v;
      },
      expression: "attachmentFile"
    }
  }), _c('v-btn', {
    attrs: {
      "color": "accent"
    },
    on: {
      "click": _vm.uploadAttachmentSave
    }
  }, [_vm._v("Save Attachment ")])], 1)], 1), _vm.attachment && _vm.attachment.getUrl() ? _c('v-row', [_c('v-col', [_vm._v(" Uploaded Image URL: " + _vm._s(_vm.attachment.getUrl()) + " ")])], 1) : _vm._e()], 1), _c('v-tab-item', [_c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "single": "",
      "line": "",
      "label": "Min submission id"
    },
    model: {
      value: _vm.rejudgeMin,
      callback: function ($$v) {
        _vm.rejudgeMin = $$v;
      },
      expression: "rejudgeMin"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "single": "",
      "line": "",
      "label": "Max submission id"
    },
    model: {
      value: _vm.rejudgeMax,
      callback: function ($$v) {
        _vm.rejudgeMax = $$v;
      },
      expression: "rejudgeMax"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-btn', {
    attrs: {
      "color": "accent",
      "height": "35"
    },
    on: {
      "click": _vm.startRejudge
    }
  }, [_vm._v("Rejudge")])], 1)], 1), _c('v-row', [_vm.rejudges && _vm.rejudges.rejudgesList ? _c('v-simple-table', {
    staticClass: "my-5",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-left"
        }, [_vm._v("Run ID")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v("Submitted on")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v("Status")])])]), _c('tbody', _vm._l(_vm.rejudges.rejudgesList, function (item) {
          return _c('tr', {
            key: item.runId
          }, [_c('td', [_vm._v(_vm._s(item.runId))]), _c('td', [_vm._v(_vm._s(_vm.formatDate(item.submittedOn)))]), _c('td', [_vm._v(_vm._s(_vm.formatStatus(item.status)))])]);
        }), 0)];
      },
      proxy: true
    }], null, false, 825025494)
  }) : _vm._e()], 1)], 1), _c('v-tab-item', [_c('permissions', {
    attrs: {
      "permissions": _vm.content.getMetaData().getPermissionsMap(),
      "initialRestrictedDomains": _vm.content.getMetaData().getRestrictedDomainList()
    },
    on: {
      "permissionChange": function (ids, role) {
        return _vm.appendPermissions(ids, role);
      },
      "removePermission": function (ids, role) {
        return _vm.removePermissions(ids, role);
      },
      "restrictedDomainChange": _vm.setRestrictedDomains
    }
  })], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', [_c('confirm-dialog', {
    ref: "confirm"
  }), _c('v-btn', {
    attrs: {
      "color": "error",
      "height": "35"
    },
    on: {
      "click": _vm.deleteContentRequest
    }
  }, [_vm._v("Delete Problem")])], 1)], 1)], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }