<template>
  <v-card>
    <v-icon @click="removeSection">mdi-close</v-icon>
    <v-text-field
      label="Title"
      :value="section.getTitle()"
      @change="updateTitle"
    ></v-text-field>
    <v-text-field
      label="Description"
      :value="section.getDescription()"
      @change="updateDescription"
    ></v-text-field>
    <search
      :availableProblems="availableProblems"
      :selectedProblems="selectedProblems"
      @change="updateProblems"
    ></search>
  </v-card>
</template>
<script>
import Search from "../components/Search";

export default {
  props: {
    section: {
      type: Object,
      required: true,
    },
    availableProblems: {
      type: Array,
      required: true,
    },
  },
  components: {
    Search,
  },
  computed: {
    selectedProblems() {
      if (this.section) {
        return this.section.getContentsList().map((c) => c.getContentId());
      }
    },
  },
  methods: {
    removeSection() {
      this.$emit("remove");
    },
    updateTitle(title) {
      this.$emit("changeTitle", title);
    },
    updateDescription(description) {
      this.$emit("changeDescription", description);
    },
    updateProblems(ids) {
      console.log("card ids", ids);
      this.$emit("changeProblemIds", ids);
    },
  },
};
</script>
