import problem from "./problem";
import content from "./content";
import user from "./user";
import article from "./article";
import mcq from "./mcq";
import skill from "./skill";
import subjective from "./subjective";
import contest from "./contest";
import drill from "./drill";
import admin from "./admin";
import judge from "./judge";
import timesync from "./timesync";
import notifs from "./notifs";
export default {
  problem,
  user,
  content,
  article,
  mcq,
  subjective,
  contest,
  drill,
  skill,
  admin,
  judge,
  timesync,
  notifs,
};
