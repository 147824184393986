<template>
  <v-container fluid>
    <div v-if="!this.user">Please login first</div>
    <span v-else>
      <v-container fluid class="pl-12 pr-5 mx-0 my-0">
        <v-col>
          <v-tabs color="accent" slider-color="accent" v-model="problemAdmin">
            <v-tab>Submissions</v-tab>
          </v-tabs>
          <v-tabs-items v-model="problemAdmin">
            <v-tab-item>
              <v-row>
                <v-col>
                  <v-btn @click="fetchSubmissions">Fetch Submissions</v-btn>
                </v-col>
              </v-row>
              <v-row class="py-5 px-2">
                <v-col>
                  <v-card>
                    <v-card-title> All Submissions </v-card-title>
                    <v-spacer></v-spacer>
                    <v-card-actions>
                      <v-select
                        label="Status"
                        :items="statusTypes"
                        v-model="statusList"
                        multiple
                      ></v-select>
                      <v-select
                        label="Submission Type"
                        :items="submissionTypes"
                        v-model="submissionTypeList"
                        multiple
                      ></v-select>
                    </v-card-actions>
                    <v-data-table
                      :page="pagination.pageIndex"
                      :pageCount="pageCount"
                      :server-items-length="pagination.total"
                      :headers="headers"
                      :items="allProblemSubmissions"
                      :footer-props.sync="footerOptions"
                      :loading="pageLoading"
                      :options.sync="tableOptions"
                    >
                      <template v-slot:item="{ item }">
                        <tr>
                          <td>
                            <router-link
                              class="link"
                              :to="'/submissions/' + item.id"
                            >
                              {{ item.id }}
                            </router-link>
                          </td>
                          <td>{{ formatDate(item.submittedOn) }}</td>
                          <td>{{ item.userPreview.name }}</td>
                          <td>
                            {{ allLanguages[languageKeys[item.language]] }}
                          </td>
                          <td class="text-capitalize">
                            {{
                              statusKeys[item.status]
                                .split("_")
                                .join(" ")
                                .toLowerCase()
                            }}
                          </td>
                          <td>
                            {{
                              item.status == 12
                                ? "-"
                                : item.timeTakenInMs + " ms"
                            }}
                          </td>
                          <td>
                            {{
                              item.status == 16
                                ? "-"
                                : item.memoryTakenInKb + " KiB"
                            }}
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-container>
    </span>
  </v-container>
</template>

<script>
import { getters, mapActions, mapState, mapMutations, mapGetters } from "vuex";
import Loader from "../components/Loader";
import User from "../components/User";

export default {
  props: ["url"],
  data() {
    return {
      footerOptions: {
        itemsPerPageOptions: [10, 25, 50],
      },
      ex: null,
      pagination: {
        pageIndex: 1,
        itemsPerPage: 10,
        total: 0,
        search: null,
      },
      tableOptions: {},
      pageLoading: false,
      problemAdmin: null,
      statusList: [],
      submissionTypeList: [],
      headers: [
        {
          text: "ID",
          align: "left",
          value: "id",
        },
        {
          text: "Submitted On",
          align: "left",
          value: "submittedOn",
        },
        {
          text: "Submitted By",
          align: "left",
          value: "userPreview.name",
        },
        {
          text: "Language",
          align: "left",
          value: "language",
        },
        {
          text: "Status",
          align: "left",
          value: "status",
        },
        {
          text: "Time",
          align: "left",
          value: "timeTakenInMs",
        },
        {
          text: "Memory",
          align: "left",
          value: "memoryTakenInKb",
        },
      ],
      allLanguages: {
        CPP_17: "C++",
        JAVA_11: "Java",
        // KOTLIN_1_3: 'Kotlin',
        PYTHON_3: "Python",
        TXT: "Text",
      },
    };
  },
  components: {
    Loader,
    User,
  },
  computed: {
    ...mapState("user", ["user", "hasPremiumAccess"]),
    ...mapState("judge", ["submissionPreviewsAdmin"]),
    ...mapGetters("judge", ["statusKeys", "statusTypes", "submissionTypes"]),
    ...mapGetters("user", ["languageKeys"]),

    allProblemSubmissions() {
      if (!this.submissionPreviewsAdmin) return [];
      let submissions = new Array();
      this.submissionPreviewsAdmin.forEach((sub) =>
        submissions.push(sub.toObject())
      );
      console.log("Submission previews are ....", submissions);
      return submissions;
    },
    pageCount() {
      if (this.pagination.itemsPerPage == -1) return 1;
      return (
        (this.pagination.total + this.pagination.itemsPerPage - 1) /
        this.pagination.itemsPerPage
      );
    },
  },
  methods: {
    ...mapActions("judge", ["fetchAllProblemSubmissionsAdmin"]),
    formatDate(date) {
      const d = new Date(date);
      return d.toLocaleString();
    },

    async fetchSubmissions() {
      this.pageLoading = true;
      console.log("Url ", this.url);
      console.log("Pagination", this.pagination);
      return this.fetchAllProblemSubmissionsAdmin({
        url: this.url,
        submissionTypeList: this.submissionTypeList,
        statusList: this.statusList,
        paginationQuery: this.pagination,
      })
        .then((res) => {
          this.pagination.total =
            res && res.getPaginationResponse()
              ? res.getPaginationResponse().getTotal()
              : 0;
          this.pageLoading = false;
          console.log("res ...", res);
        })
        .catch((ex) => {
          console.log("Error while fetching submissions", ex);
          this.pageLoading = false;
        });
    },
  },

  async created() {
    this.fetchSubmissions();
  },

  watch: {
    pagination: {
      handler() {
        console.log("Calling submissions API......");
        this.fetchSubmissions();
      },
      deep: true,
    },
    tableOptions(to) {
      console.log("options.sync", to);
      this.pagination.pageIndex = to.page;
      this.pagination.itemsPerPage = to.itemsPerPage;
    },
  },
};
</script>

<style scoped>
.link {
  text-decoration: none;
  /* color: black; */
}
</style>
