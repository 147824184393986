<template>
  <span>
    <v-row>
      <v-spacer></v-spacer>
      <v-col :cols="4">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search (title, tags)"
          clearable
          single-line
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="contents"
      disable-pagination
      :search="search"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>
            <router-link class="ml-1" :to="getUrl(item)">
              {{ item.title }}
            </router-link>
          </td>
          <td :colspan="3">
            <v-chip
              outlined
              color="grey lighten-2"
              text-color="grey"
              class="mr-2"
              v-for="t in item.metaData.tagsList"
              label
              :key="t"
              @click="searchTag"
            >
              {{ t }}
            </v-chip>
          </td>
        </tr>
      </template>
    </v-data-table>
  </span>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  props: {
    contents: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      showTags: true,
      search: null,
      headers: [
        {
          text: "Title",
          align: "left",
          sortable: true,
          value: "title",
        },
        {
          text: "Tags",
          align: "left",
          sortable: true,
          value: "metaData.tagsList",
        },
      ],
    };
  },
  methods: {
    getUrl(item) {
      var contentTypeString = this.contentTypeKeys[item.contentType];
      return "/" + contentTypeString.toLowerCase().trim() + "s/" + item.url;
    },
    searchTag(e) {
      this.search = e.target.innerText;
    },
    getTags(t) {
      if (!this.showTags) return [];
      return t
        .filter((t) => t.startsWith("topics/"))
        .map((t) => t.substring(7));
    },
    toggleShowTags() {
      this.showTags = !this.showTags;
    },
  },
  computed: {
    ...mapGetters("content", ["contentTypeKeys"]),
  },
};
</script>
