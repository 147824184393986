<template>
  <v-row class="px-5 py-5">
    <v-col :cols="12">
      <v-card class="simpleMdEditor" outlined max-height="150px">
        <CodeMirror
          ref="myCm"
          :value="code"
          :options="cmOptions"
          @ready="onCmReady"
          @focus="onCmFocus"
          @input="onCodeChange"
          class="markdown"
          :class="fontSize"
        ></CodeMirror>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { codemirror as CodeMirror } from "vue-codemirror";
// import "@/assets/css/codemirror.css";
import "codemirror/mode/markdown/markdown.js";
import "codemirror/lib/codemirror";
export default {
  data() {
    return {
      showHint: false,
      fontSize: "small",
      tabSize: 4,
      theme: "default",
      language: "markdown",
      keyMap: "default",
      cmOptions: {
        tabSize: 4,
        indentUnit: 4,
        indentWithTabs: true,
        mode: null,
        lineNumbers: false,
        line: true,
        styleActiveLine: true,
        matchBrackets: true,
        lineWrapping: true,
        theme: "default",
        foldGutter: true,
        placeholder: "Please end with ';'",
        keyMap: "default",
      },
      languages: [
        { text: "Markdown", value: "markdown" },
        /*{ text: "Python", value: "python" },*/
      ],
      themes: [
        { text: "Idea", value: "idea" },
        { text: "Dracula", value: "dracula" },
        { text: "Solarized Dark", value: "solarized dark" },
        { text: "Solarized Light", value: "solarized light" },
        { text: "Default", value: "default" },
      ],
      keyMaps: [
        { text: "Vim", value: "vim" },
        { text: "Emacs", value: "emacs" },
        { text: "Sublime", value: "sublime" },
        { text: "Basic", value: "default" },
      ],
      fontSizes: [
        { text: "Small", value: "small" },
        { text: "Normal", value: "normal" },
        { text: "Large", value: "large" },
      ],
      tabSizes: [
        { text: "2 Spaces", value: 2 },
        { text: "4 Spaces", value: 4 },
      ],
      cmModeMap: {
        cpp: "text/x-c++src",
        python: "text/x-python",
        javascript: "text/javascript",
        markdown: "text/x-markdown",
      },
    };
  },
  props: {
    code: {
      type: String,
      required: false,
    },
    onCodeChange: {
      type: Function,
      required: true,
    },
    value: {
      type: Function,
      required: false,
    },
  },
  components: {
    CodeMirror,
  },
  computed: {
    codemirror() {
      return this.$refs.myCm.codemirror;
    },
  },
  watch: {
    language: function (newLang) {
      this.cmOptions.mode = this.cmModeMap[newLang];
      this.value.language = newLang;
      this.$emit("input", this.value);
    },
    tabSize: function (newTabSize) {
      this.cmOptions.tabSize = newTabSize;
      this.cmOptions.indentUnit = newTabSize;
      this.value.tabSize = newTabSize;
      this.$emit("input", this.value);
    },
    theme: function (newTheme) {
      this.cmOptions.theme = newTheme;
      this.value.theme = newTheme;
      this.$emit("input", this.value);
    },
    keyMap: function (newKeyMap) {
      this.cmOptions.keyMap = newKeyMap;
      this.value.keyMap = newKeyMap;
      this.$emit("input", this.value);
    },
    fontSize: function (newFontSize) {
      this.value.fontSize = newFontSize;
      this.$emit("input", this.value);
    },
  },
  methods: {
    onCmReady(cm) {
      cm.on("keypress", () => {
        if (this.showHint) {
          //cm.showHint();
        }
      });
    },
    onCmFocus() {
      //   console.log("the editor is focus!", cm);
    },
  },
  mounted() {
    // console.log("this is current codemirror object", this.codemirror);
    // you can use this.codemirror to do something...
    if (this.value.language) this.language = this.value.language;
    if (this.value.tabSize) this.tabSize = this.value.tabSize;
    if (this.value.theme) this.theme = this.value.theme;
    if (this.value.keyMap) this.keyMap = this.value.keyMap;
    if (this.value.fontSize) this.fontSize = this.value.fontSize;
    this.cmOptions.mode = this.cmModeMap[this.language];
    console.log("Mode", this.cmOptions.mode, this.language);
    // global style in codemirror.css overriding the <style scoped> here
    document
      .querySelectorAll(".simpleMdEditor > .vue-codemirror > .CodeMirror")
      .forEach((el) => (el.style.height = "149px"));
  },
};
</script>
<style scoped>
.simpleMdEditor > .vue-codemirror > .CodeMirror {
  height: 149px !important;
}
</style>
