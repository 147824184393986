<template>
  <v-card :loading="isUpdating">
    <v-form>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              :value="selectedProblems"
              :disabled="isUpdating"
              :items="availableProblems"
              filled
              chips
              color="blue-grey lighten-2"
              label="Select"
              item-text="title"
              item-value="id"
              multiple
              @change="updateProblems"
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="removeProblem(data.item.id)"
                >
                  {{ data.item.title }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <template v-if="checktype(data)">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="data.item.title"
                    ></v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import { getters, mapActions, mapState, mapMutations, mapGetters } from "vuex";

export default {
  props: {
    availableProblems: {
      type: Array,
      required: true,
    },
    selectedProblems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      autoUpdate: true,
      isUpdating: false,
    };
  },
  computed: {
    ...mapState("content", ["contents"]),
    ...mapState("user", ["user", "hasPremiumAccess"]),
    premiumBlock() {
      return this.premiumOnly && !this.hasPremiumAccess;
    },
  },
  methods: {
    updateProblems(ids) {
      console.log("Search Update ids", ids);
      this.$emit("change", ids);
    },
    removeProblem(id) {
      console.log("Search remove id", id);
      const index = this.selectedProblems.indexOf(id);
      if (index >= 0) this.selectedProblems.splice(index, 1);
      this.$emit("change", this.selectedProblems);
    },
    checktype(data) {
      return typeof data.item !== "object";
    },
  },
  watch: {
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
  },
  mounted() {
    console.log("available ...", this.availableProblems);
    console.log("selected ....", this.selectedProblems);
  },
};
</script>
