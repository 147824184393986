<template>
  <v-row>
    <v-col cols="6"> </v-col>
    <v-col cols="1">
      <v-progress-circular
        color="accent"
        indeterminate
        size="70"
        width="10"
      ></v-progress-circular>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "loader",
};
</script>
