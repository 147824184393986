var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    ref: "libraryDialog",
    attrs: {
      "raised": "",
      "width": "700px"
    }
  }, [_c('v-card-text', {
    staticClass: "pt-5"
  }, [_c('v-row', [_c('v-col', [_c('v-row', [_c('v-col', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Options")]), _c('v-btn', {
    staticClass: "ml-5",
    attrs: {
      "color": "info"
    },
    on: {
      "click": _vm.addOption
    }
  }, [_vm._v("Add Option")]), _c('v-btn', {
    staticClass: "ml-5",
    attrs: {
      "disabled": !_vm.answerFormValid,
      "color": "success"
    },
    on: {
      "click": _vm.saveOptions
    }
  }, [_vm._v(" Save Options ")])], 1)], 1), _c('v-form', {
    ref: "mcqAdd",
    model: {
      value: _vm.answerFormValid,
      callback: function ($$v) {
        _vm.answerFormValid = $$v;
      },
      expression: "answerFormValid"
    }
  }, _vm._l(_vm.getMcqOptions, function (opt, idx) {
    return _c('v-row', {
      key: idx
    }, [_c('v-col', {
      attrs: {
        "cols": 8
      }
    }, [_vm._v(" ID: " + _vm._s(opt.getId()) + " "), _c('SimpleMarkDownInput', {
      attrs: {
        "code": opt.getStatement(),
        "onCodeChange": function (v) {
          return _vm.optionStatement(opt, v);
        },
        "value": _vm.editorConfig
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": 4
      }
    }, [_c('v-switch', {
      attrs: {
        "value": opt.getId(),
        "label": "Correct Answer"
      },
      on: {
        "change": function (v) {
          return _vm.setCorrectAnswer(opt, v);
        }
      },
      model: {
        value: _vm.getMcqOptionAnswers,
        callback: function ($$v) {
          _vm.getMcqOptionAnswers = $$v;
        },
        expression: "getMcqOptionAnswers"
      }
    }), _c('v-btn', {
      staticClass: "ml-5",
      attrs: {
        "small": "",
        "color": "error"
      },
      on: {
        "click": function ($event) {
          return _vm.deleteOption(opt);
        }
      }
    }, [_vm._v(" Delete ")])], 1)], 1);
  }), 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }